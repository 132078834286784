import React, { useState, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Icon from "@material-ui/core/Icon"




const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

export const CommonPopup = (props) => {

    const [errorpopup, seterrorpopup] = React.useState(false)
    const [open_yes_no_popup, setopen_yes_no_popup] = useState(false);
    const [popupmessage_yes_no, setpopupmessage_yes_no] = useState("");

    useEffect(() => {
        seterrorpopup(props.popupstatus ? props.popupstatus : false)


    }, [props.popupstatus])

    useEffect(() => {
        setopen_yes_no_popup(props.popupstatus_yes_no ? props.popupstatus_yes_no : false)
        setpopupmessage_yes_no(props.message_yes_no)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.popupstatus_yes_no])

    const handleCloses_error = () => {
        seterrorpopup(false)
        props.close_popup()

    }

    const handleClose_yes_no = () => {
        setopen_yes_no_popup(false)
        props.close_popup_yes_no("no")
    }

    const Logout_clicked = () => {
        setopen_yes_no_popup(false)
        props.close_popup_yes_no("yes")

    }

    return (
        <>
            <Dialog
                onClose={handleCloses_error}
                aria-labelledby="customized-dialog-title"
                open={errorpopup}
                className="success-popup"
            >
                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon">
                        <Icon className="fa fa-exclamation-triangle" />
                    </div>
                    <h2 className="font-weight-bold m-10 success-head">Alert!</h2>
                    <p className="success-text mb-10">
                        Your assigned Doctor / Study Coordinator has not yet joined the conference. We'll notify you once they start the meeting.


                    </p>
                    <button type="submit" onClick={handleCloses_error} className="btn btn-primary mr-5 save-btn">
                        Ok
                    </button>
                </DialogContent>
            </Dialog>



            {/* with yes no button */}

            <Dialog
                onClose={handleClose_yes_no}
                aria-labelledby="customized-dialog-title"
                open={open_yes_no_popup}
                className="log-out-wrap"
            >
                <DialogTitle className="popup-heading">Alert!</DialogTitle>
                <DialogContent dividers className="middle-content">
                    <Typography gutterBottom className="logut-content">
                        {popupmessage_yes_no}
                    </Typography>
                </DialogContent>
                <DialogActions className="btn-wrapper">
                    <div className="card-toolbar">
                        <button className="btn btn-secondary mr-6" onClick={handleClose_yes_no} >
                            No
                        </button>
                        <button
                            type="submit"
                            onClick={Logout_clicked}
                            className="btn btn-danger logout-btn"

                        >
                            Yes
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}



