import React, {useState, useEffect} from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Texttype = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>
            <div className="multiple-text-line"> {children_elements.Meta?.Text?.blocks?.length > 1 ?

                children_elements.Meta?.Text.blocks.map((data, indx) => {
                    let placeholderText = data?.text

                    return [
                        <React.Fragment key={indx}>
                            {/* <textarea type="text" className={`${(children_elements.Meta?.Text?.blocks?.length - 1) === indx ? "" : "textype-border"}`} placeholder={placeholderText}
                                value={form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : form_value[children_elements.UUID]}
                                name={children_elements.UUID}
                                readOnly={true}
                            /> */}
                            {placeholderText && <p>{placeholderText}</p>}
                        </React.Fragment>
                    ]
                }) : (
                    <p>{children_elements.Meta?.Text.blocks?.[0].text ? children_elements.Meta?.Text.blocks?.[0].text : children_elements.Meta?.Text}</p>
                )
            }</div>

        </> : ""
    )
}
