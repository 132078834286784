/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function StickyToolbar() {
  return (
    <>
      {/* <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">Live Support</Tooltip>}
        >
          <li className="nav-item mb-2" data-placement="left">
            <Link
              to="/support"
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            >
              <i className="flaticon2-gear"></i>
            </Link>
          </li>
        </OverlayTrigger>
      </ul> */}
    </>
  );
}
