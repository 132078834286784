import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Form_section = (props, { lable_ }) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>
            <label className="label_ form-section-head" htmlFor="">{props.lable_}</label>
        </> : ""
    )
}
