import React, { useState, useEffect } from "react"
import { Feedback_visit, Get_Feedback_logo } from "../_redux/authCrud"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"
import Icon from "@material-ui/core/Icon"

export default function Feedback() {
    const [hide_thnaks_message, sethide_thnaks_message] = useState(true)
    const [apply_validation, setapply_validation] = useState(3)
    const [textbox_value, settextbox_value] = useState("")
    const [error_message, seterror_message] = useState(false)
    const [error_message_text, seterror_message_text] = useState("Please provide feedback for our team.")

    const [errorpopup, seterrorpopup] = React.useState(false)
    const [save_changes_button_status, setsave_changes_button_status] = useState(false)
    const [logo_for_visit, setlogo_for_visit] = useState("")




    // to get value from url
    const queryParams = new URLSearchParams(window.location.search)
    const feedbackid = queryParams.get("Feedback")
    const feedbac_baseurl = "https://" + queryParams.get("host")
    const SiteID = queryParams.get("SiteID")
    // to fetch logo 
    useEffect(() => {
        // sessionStorage.setItem("end_point", "https://dev.mystudymanager.com")

        Get_Feedback_logo(feedbac_baseurl, SiteID).then(data => { setlogo_for_visit(data.data.SiteLogoURL) }).catch(err => { })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // hiding side images and textbox
    useEffect(() => {
        const side_img = document.getElementById('auth-page')
        if (side_img) {
            side_img.classList.add("hidefooter");
            document.getElementById("first-text").classList.add('hidefooter')
            document.getElementById("second-text").classList.add('hidefooter')
            document.getElementById("exampleFormControlTextarea1").classList.add('hidefooter')

        }
        return () => {
            document.getElementById('auth-page').classList.remove("hidefooter");
        }
    }, [])
    // happy sad button pressed
    const ButtonPressed = (type) => {
        document.getElementById("exampleFormControlTextarea1").classList.remove('hidefooter')
        if (type === 1) {
            document.getElementById("button-happy").classList.add('active')
            document.getElementById("button-sad").classList.remove('active')
            document.getElementById("second-text").classList.add('hidefooter')
            document.getElementById("first-text").classList.remove('hidefooter')
            setapply_validation(1)
        } else {
            document.getElementById("button-happy").classList.remove('active')
            document.getElementById("button-sad").classList.add('active')
            document.getElementById("second-text").classList.remove('hidefooter')
            document.getElementById("first-text").classList.add('hidefooter')
            setapply_validation(0)

        }
    }
    // submitbutton clicked
    const SubmitClick = () => {
        setTimeout(() => {
            seterror_message(false)

        }, 3000);
        if (apply_validation === 3) {
            seterror_message(true)
            seterror_message_text("Please provide feedback for our team.")
            return
        }
        if (apply_validation === 0 && textbox_value.trim().length < 1) {
            seterror_message(true)
            seterror_message_text("Please provide feedback for our team.")

            return
        }
        if (textbox_value.trim().length > 1000) {
            seterror_message(true)
            seterror_message_text("Maximum 1000 characters are allowed.")

            return
        }

        setsave_changes_button_status(true)
        Feedback_visit(apply_validation, textbox_value, feedbackid, feedbac_baseurl).then(data => {
            sethide_thnaks_message(false)
            setsave_changes_button_status(false)

        }).catch(err => {
            seterrorpopup(true)
            setsave_changes_button_status(false)

        })

    }
    //popup methods
    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent)

    const handleCloses_error = () => {
        seterrorpopup(false)

    }



    return (
        <main className="feedback-main-wrap">
            <div className="text-center mt-4 feedback-site-logo"><img src={`${logo_for_visit ? sessionStorage.getItem("end_point") + logo_for_visit : '/media/misc/heade-RealTime-Logo-2.svg'}`} alt="" className="top-logo" /></div>
            {/* <div className="text-center mt-4 feedback-site-logo"><img src={`${logo_for_visit ? "https://val.mystudymanager.com" + logo_for_visit : '/media/misc/heade-RealTime-Logo-2.svg'}`} alt="" className="top-logo" /></div> */}
            {hide_thnaks_message ? <div className="feedback-wrap">
                <div className="feedback_inner">

                    <h3 className='second-text' > How was your visit experience?</h3>
                    <div className="select-feedback">
                        <button type='submit' className="select-feedback-button " id="button-happy" onClick={() => { ButtonPressed(1) }} >
                            <img src="/media/misc/happy-face.svg" alt="" />
                        </button>
                        <button type='submit' className="select-feedback-button " id="button-sad" onClick={() => { ButtonPressed(2) }}>
                            <img src="/media/misc/sad-face.svg" alt="" />
                        </button>
                    </div>
                    <div className='message-box'>
                        <h3 className="label-text" id="first-text">We're happy that you enjoyed your visit! Please tell us about your experience.</h3>
                        <h3 className="label-text" id="second-text"> Thank you for the feedback. We are sorry the experience didn't meet your expectations. Please let us know how we can better serve you moving forward. <span style={{ color: "red" }}>*</span></h3>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" onChange={(e) => { settextbox_value(e.target.value) }}></textarea>
                        <p className='error-msg mb-0' style={{ textAlign: "center" }}> {apply_validation === 0 ? "Please provide feedback on your unsatisfactory visit." : ""}</p>
                        <p className='error-msg mb-0' style={{ textAlign: "center" }}>{error_message ? error_message_text : ""}</p>
                        <div className="button-wrap">
                            <button className="submit-btn " type="submit" disabled={save_changes_button_status} onClick={SubmitClick}>
                                {!save_changes_button_status ? "Submit" : <p className="mb-0 pr-3">Saving... <span className="ml-3 spinner spinner-white"></span></p>}

                            </button>
                        </div>
                    </div>
                </div>
            </div> : <div className="thank-you-wrap">
                <div>
                    <img src="/media/misc/Accept-icon.svg" alt="" />
                </div>
                <h2>Thank You For Your Feedback!</h2>
            </div>}


            {/* Failed dialog */}
            <Dialog
                onClose={handleCloses_error}
                aria-labelledby="customized-dialog-title"
                open={errorpopup}
                className="success-popup"
            >
                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon">
                        <Icon className="fa fa-exclamation-triangle" />
                    </div>
                    <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
                    <p className="success-text mb-10">Please try again !</p>
                    <button
                        type="submit"
                        onClick={handleCloses_error}
                        className="btn btn-primary mr-5 save-btn"
                        disabled={false}
                    >
                        Ok
                    </button>
                </DialogContent>
            </Dialog>


        </main>
    )
}
