/* eslint-disable no-useless-concat */
import React, { useState, useCallback, useEffect, useRef } from "react"
import Video from "twilio-video"
import Room from "../../../pages/Room"
import { useDispatch } from "react-redux"
import {
    Twilio_token,
    Twilio_room,
    get_ctms_token,
    Ctms_videocall_auth,
    initiate_call,
    Get_single_event
} from "../../../modules/Auth/_redux/authCrud"
import { useHistory } from "react-router-dom"
import * as auth from "../_redux/authRedux"
export const Videochat_ctms = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const PatientID = queryParams.get("CTMSPatientID")
    const EventID = queryParams.get("EventID")
    const CTMSEventID = queryParams.get("CTMSEventID")
    const CTMSStudyID = queryParams.get("CTMSStudyID")
    const AuthToken = queryParams.get("AuthToken")
    const ctms_host_link = queryParams.get("CTMSHostLink")
    const site_id = queryParams.get("SiteID")
    sessionStorage.setItem("SiteID_local", site_id)
    const portal_id = queryParams.get("PatientID")
    const [but_disable, setbut_disable] = useState(true)
    const videoRef = useRef(null)
    const [message, setmessage] = useState("")
    const [room, setRoom] = useState(null)
    const [connecting, setConnecting] = useState(false)
    const [is_video_on, setis_video_on] = useState(false)
    const [is_video_on_replica, setis_video_on_replica] = useState(false)
    const [is_audio_on, setis_audio_on] = useState(false)
    const [is_checkbox_disabled, setis_checkbox_disabled] = useState(false)
    const [session_key, setsession_key] = useState("")
    const [roomname_trim, setroomname_trim] = useState("")
    const [audio_level, setaudio_level] = useState(5)
    const [room_sid, setroom_sid] = useState();
    // const [time_interval, settime_interval] = React.useState(1)

    let history = useHistory()
    let token = ""
    const dispatch = useDispatch()

    sessionStorage.setItem("ctms_host_link", "https://" + ctms_host_link)
    localStorage.setItem("PatientCTMSID", PatientID)
    localStorage.setItem("CTMSEventID", CTMSEventID)
    localStorage.setItem("join_status_id", EventID)
    localStorage.setItem("CTMSStudyID", CTMSStudyID)
    sessionStorage.setItem("id_admin", portal_id)

    const baseurl = "https://" + queryParams.get("host")

    useEffect(() => {
        ctms_user_auth()
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])
    // to check whether ctms  user is autheticate or not
    const ctms_user_auth = () => {
        Ctms_videocall_auth(PatientID, EventID, AuthToken, baseurl).then((data) => { Get_user_Credentials(data.data) }).catch((err) => { })
    }

    // to get ctms user Credential 
    const Get_user_Credentials = (data) => {
        let roomname = queryParams.get("roomname")
        let roomNameTrim
        if (roomname) {
            roomname.length > 100 ? roomNameTrim = roomname.substring(0, 100) : roomNameTrim = roomname
        }
        roomNameTrim = encodeURIComponent(roomNameTrim)
        setroomname_trim(roomNameTrim)
        get_ctms_token(site_id, portal_id)
            .then((value) => {
                setsession_key(value.data)
                sessionStorage.setItem("sessionKeyValue", value.data)
                // to create twilio room
                Twilio_room(roomNameTrim, value.data, CTMSEventID)
                    .then(data => { data.data.roomSid.length === undefined ? setroom_sid("null") : setroom_sid(data.data.roomSid); })
                    .catch()
                    .finally(() => {
                        setbut_disable(false)

                    })
                Get_single_event(EventID,null,null,1).then().catch()

            })
            .catch((error) => { })
    }
    //to hide header and footer
    useEffect(() => {
        const side_img = document.getElementById('auth-page')
        if (side_img) {
            side_img.classList.add("hidefooter");
            document.getElementById('LoginRoot').classList.remove("login-root");
            document.getElementById('LoginWrapper').classList.remove("login-wrapper");
            document.getElementById('LoginRoot').classList.add("ctms-video-conf");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
        return () => {
            document.getElementById('LoginRoot').classList.add("login-root");
            document.getElementById('LoginWrapper').classList.add("login-wrapper");
            document.getElementById('LoginRoot').classList.remove("ctms-video-conf");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])


    const Create_twilio_token = () => {
        let username = queryParams.get("CallerName") + " " + " }" + Math.floor(Math.random() * 61)
        Twilio_token(roomname_trim, username, session_key, room_sid)
            .then((data) => {
                token = data.data.AuthToken
                handleSubmit2()
            })
            .catch((data) => {
                setConnecting(false)

            })
    }
    const handleSubmit2 = () => {
        sessionStorage.setItem("is_ctms_user", true)
        Video.connect(token, {
            name: "webroom",
            dominantSpeaker: true,
        })
            .then((room) => {
                setConnecting(false)
                setRoom(room)
                // this api is for ctms_host_link, to change status of call
                initiate_call(PatientID, EventID, baseurl, room_sid).then(val => {
                    dispatch(auth.actions.setCallStartTime(val.data.TwilioStartTime))
                }).catch(err => { }).finally(() => { setRoom(room) })
            })
            .catch((err) => {
                setConnecting(false)
                setmessage("Access token is not valid")
            })
    }

    const handleLogout = useCallback(() => {
        setRoom((prevRoom) => {
            if (prevRoom) {
                prevRoom.localParticipant.tracks.forEach((trackPub) => {
                    trackPub.track.stop()
                })

                prevRoom.disconnect()
            }
            return null
        })
        if (sessionStorage.getItem("is_ctms_user")) {
            sessionStorage.removeItem("is_ctms_user")
            window.close();
        }
        history.push("/dashboard")
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    useEffect(() => {

        if (room) {
            const tidyUp = (event) => {
                if (event.persisted) {
                    return
                }
                if (room) {
                    handleLogout()
                }
            }
            window.addEventListener("pagehide", tidyUp)
            window.addEventListener("beforeunload", tidyUp)
            return () => {
                window.removeEventListener("pagehide", tidyUp)
                window.removeEventListener("beforeunload", tidyUp)
            }
        }
    }, [room, handleLogout])
    // click on join button
    const join_twili_call = () => {
        setConnecting(true)
        navigator.mediaDevices
            .getUserMedia({ video: { width: 600 }, audio: true })
            .then(() => {
                setConnecting(true)
                if (is_video_on) {
                    close_video(1)
                } else {
                    Create_twilio_token()
                }
            })
            .catch((err) => {
                setmessage("Camera and microphone permission are required !")
                setConnecting(false)


            })
    }
    // get video preview
    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({ video: { width: 600 } })
            .then((stream) => {
                let video = videoRef.current
                video.srcObject = stream
                video.play()
                setmessage("")

            })
            .catch((err) => {
                setmessage("could not detect the camera !")

            })
    }
    // stop video preview
    const close_video = (type) => {
        let video = videoRef.current
        const stream = video.srcObject
        const tracks = stream?.getTracks()
        if (stream !== null) {

            for (let i = 0; i < tracks.length; i++) {
                let track = tracks[i]
                track.stop()
            }
            setis_video_on(false)
            video.srcObject = null
            if (type === 1) {
                Create_twilio_token()
                setis_video_on_replica(true)
            }
        }
    }
    // Mice_toggle for preview
    const Mice_toggle = (e) => {
        setis_audio_on(e.currentTarget.checked)
    }
    // video_toggle for preview
    const Video_toggle = (e) => {
        setis_video_on(e.currentTarget.checked)
        if (e.currentTarget.checked) {
            getVideo()
            setis_checkbox_disabled(true)
            setTimeout(() => {
                setis_checkbox_disabled(false)
            }, 2100)
        } else {
            close_video(2)
        }
    }

    let render
    if (room) {
        render = (
            <Room
                room={room}
                handleLogout={handleLogout}
                event_title={
                    decodeURIComponent(queryParams.get("title"))}
                is_audio_on={is_audio_on}
                is_video_on={is_video_on_replica}
                audio_level={audio_level}
                sessionkey={session_key}
                roomNameTrim={roomname_trim}
            />
        )
    } else {
        render = (
            <>

                <div className="container choose-option">
                    <h4>Choose your video and audio options</h4>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="video-wrap">
                                <div className="middle-view">
                                    {is_video_on ? (
                                        <video ref={videoRef} />
                                    ) : (
                                        <>
                                            <img src="/media/misc/off-video.svg" alt="unable to load" />
                                            <p className="text-white mt-4">Your camera is turned off</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="audio-option-wrap">
                                <div className="audio-card p-0">
                                    <div className="d-flex justify-content-between align-items-center c-audio">
                                        <div className="d-flex align-items-center">
                                            <img src="/media/misc/computer-sound.svg" className="aduio_icon" alt="unable to load" />
                                            <p className="text-white m-0">Computer audio</p>
                                        </div>
                                    </div>

                                    <div className="selction-audio-wrap">
                                        <div className="selction-audio d-flex justify-content-between ">
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-secondary dropdown-toggle text-white"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Customized setup
                                                </button>

                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            {is_audio_on ? (
                                                <img
                                                    src="/media/misc/mice.svg"
                                                    className="aduio_icon"
                                                    style={{ width: 18 }}
                                                    alt="unable to load"
                                                />
                                            ) : (
                                                <img
                                                    src="/media/misc/mute-voice.svg"
                                                    className="aduio_icon"
                                                    style={{ width: 18 }}
                                                    alt="unable to load"
                                                />
                                            )}

                                            <div className="custom-control custom-switch" data-toggle="tooltip" title={is_audio_on ? "Turn off mic" : "Turn on mic"}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customSwitches2"
                                                    value={true}
                                                    onChange={(e) => Mice_toggle(e)}
                                                />
                                                <label className="custom-control-label" htmlFor="customSwitches2"></label>
                                            </div>
                                            <div className="d-flex volum-wrapper">
                                                <img src="/media/misc/volume.svg" style={{ height: 22 }} alt="unable to load" />
                                                <input type="range" id="volume" name="volume" min="1" max="10" value={audio_level} onChange={(e) => { setaudio_level(e.target.value) }} />                        {/* <label for="volume">Volume</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center c-audio">
                                        <div className="d-flex align-items-center">
                                            <img src="/media/misc/computer-sound.svg" className="aduio_icon" alt="unable to load" />
                                            <p className="text-white m-0">Computer video</p>
                                        </div>
                                    </div>
                                    <div className="selction-audio-wrap">
                                        <div className="selction-audio d-flex justify-content-between ">
                                            <p className="text-white m-0">Video setup</p>
                                        </div>
                                        <div className="d-flex">
                                            {is_video_on ? (
                                                <img
                                                    src="/media/misc/on-video.svg"
                                                    className="aduio_icon"
                                                    style={{ width: 18 }}
                                                    alt="unable to load"
                                                />
                                            ) : (
                                                <img
                                                    src="/media/misc/off-video.svg"
                                                    className="aduio_icon"
                                                    style={{ width: 18 }}
                                                    alt="unable to load"
                                                />
                                            )}

                                            <div className="custom-control custom-switch" data-toggle="tooltip" title={is_video_on ? "Turn off video" : "Turn on video"}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customSwitches"
                                                    onChange={(e) => Video_toggle(e)}
                                                    disabled={is_checkbox_disabled}
                                                    checked={is_video_on}
                                                />
                                                <label className="custom-control-label" htmlFor="customSwitches"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-right button-wrapper">
                        <h3 style={{ color: 'red' }}> {message}</h3>

                        <button
                            type="button"
                            className="cancel_button"
                            disabled={connecting}
                            onClick={() => {
                                window.close();

                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button "
                            className="join-btn"
                            onClick={join_twili_call}
                            disabled={connecting || but_disable}
                        >
                            {connecting ? "Connecting..." : "Join now"} <img src="/media/misc/on-video.svg" className="video-icon" alt="img" />
                            {connecting && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </div>
            </>
        )
    }
    return render
}

export default Videochat_ctms
