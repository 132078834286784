import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_common/layout";
import { Clear_pushnotification } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

// 24-12-2021 : added Clear_pushnotification() api
class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    let timeout = sessionStorage.getItem("sesson_logout_for_api")
    Clear_pushnotification(timeout, null)
    localStorage.clear()
    sessionStorage.clear()
    sessionStorage.removeItem("sesson_logout_for_api");

    const sidemenu = document.getElementById("kt_body");
    if (sidemenu.classList.contains("aside-minimize")) {
      sidemenu.classList.remove("aside-minimize");
    }

  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
