/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Dashboard_invite_api, get_ctms_token, Get_stipend_amount } from "../modules/Auth/_redux/authCrud"
import { Date_convert_without_time_zone } from "../modules/Auth/_redux/Common_code"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
// import TextField from "@material-ui/core/TextField"
import { useSubheader } from "../../_common/layout"
import { toAbsoluteUrl } from "../../_common/_helpers"
import TablePagination from "@material-ui/core/TablePagination"
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap"
import Icon from "@material-ui/core/Icon"

function TabPanel(props) {
  const { children, value, index, ...other } = props
  const suhbeader = useSubheader()
  suhbeader.setTitle(" Documents")
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Box p={3}>
            <span>{children}</span>
          </Box>
        </>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}
export const My_stipends = () => {
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)
  const stipends = useSelector((state) => state.auth.stipend, shallowEqual)
  const [paidstipend, setpaidstipend] = useState([])
  const [paidstipend_copy, setpaidstipend_copy] = useState([])
  const [paidstipend_for_sorting, setpaidstipend_for_sorting] = useState([])
  const [unpaidstipend, setunpaidstipend] = useState([])
  const [unpaidstipend_copy, setunpaidstipend_copy] = useState([])
  const [unpaidstipend_for_sorting, setunpaidstipend_for_sorting] = useState([])
  const [sorting_order1, setsorting_order1] = useState(true)
  const [sorting_order2, setsorting_order2] = useState(false)
  const [sorting_para1, setsorting_para1] = useState("StipendDate")
  const [sorting_para2, setsorting_para2] = useState("AssessDate")
  const [start_date, setstart_date] = useState()
  const [end_date, setend_date] = useState()
  const [error_message, seterror_message] = useState("")
  const [lodaing_status, setlodaing_status] = useState(true)
  const [earning, setearning] = useState(0)
  const [earning_pending, setearning_pending] = useState(0)
  const [grand_earning, setgrand_earning] = useState()
  const [lodaing_status_total, setlodaing_status_total] = useState(true)
  const [earning_visible, setearning_visible] = useState(true)
  // const [start_date_type, setstart_date_type] = useState("text");
  // const [end_date_type, setend_date_type] = useState("text");

  // for pagination start
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  // for pagination end


  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue === 0) {
      setearning_visible(true)
    } else {
      setearning_visible(false)
    }
    setPage(0)
  }
  // to get sesson key which need for api call
  useEffect(() => {
    if (studies_?.length > 0) {
      // const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
      get_ctms_token()
        .then((value) => {
          dispatch(auth.actions.request_mystipned(value.data))
        })
        .catch((error) => { })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (studies_ && studies_?.length === 0) {
      setlodaing_status(false)
    }
  }, [studies_])

  // calling invite api
  useEffect(() => {
    if (!studies_) {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (studies_?.length > 0) {

      // const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
      get_ctms_token()
        .then((value) => {
          Get_stipend_total(value.data)
        })
        .catch((error) => { })
      setlodaing_status_total(true)
    }
    if (studies_?.length === 0) {
      setlodaing_status_total(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipends])

  // to get stipend total
  const Get_stipend_total = async (key) => {
    await Get_stipend_amount(key)
      .then((data) => {
        setgrand_earning(data.data.AvailableBalance)
        setlodaing_status_total(false)
      })
      .catch((err) => {
        setlodaing_status_total(false)
        setgrand_earning(0.00)

      })
  }

  // to solve refresh issue
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => { })
  }
  // to set paid and unpaid stipend
  useEffect(() => {
    if (stipends) {
      setpaidstipend(paidstipend_for_sorting)
      setpaidstipend_copy(paidstipend_for_sorting)
      // to sort data by assign date
      const sortedoc = unpaidstipend_for_sorting?.sort((a, b) => {
        if (a["AssessDate"] < b["AssessDate"]) {
          return sorting_order2 ? -1 : 1
        }
        if (a["AssessDate"] > b["AssessDate"]) {
          return sorting_order2 ? 1 : -1
        }
        return 0
      })
      if (sortedoc) {
        setunpaidstipend(sortedoc)
        setunpaidstipend_copy(sortedoc)
      }
      setlodaing_status(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipends, paidstipend_for_sorting])

  // sorting table2 method
  const Filter_table2 = (value) => {
    setsorting_para2(value)
    setsorting_order2(!sorting_order2)
    setunpaidstipend([])

  }

  // sorting table 2
  useEffect(() => {
    const sortedoc = unpaidstipend_copy?.sort((a, b) => {
      if (a[sorting_para2] < b[sorting_para2]) {
        return sorting_order2 ? -1 : 1
      }
      if (a[sorting_para2] > b[sorting_para2]) {
        return sorting_order2 ? 1 : -1
      }
      return 0
    })

    if (sortedoc) {
      setunpaidstipend(sortedoc)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting_order2])
  // sorting table1 method

  const Filter_table1 = (value) => {
    setsorting_para1(value)
    setsorting_order1(!sorting_order1)
    setpaidstipend([])

  }
  // sorting table 1
  useEffect(() => {
    const sortedoc = paidstipend_copy?.sort((a, b) => {
      if (a[sorting_para1] < b[sorting_para1]) {
        return sorting_order1 ? -1 : 1
      }
      if (a[sorting_para1] > b[sorting_para1]) {
        return sorting_order1 ? 1 : -1
      }
      return 0
    })

    if (sortedoc) {
      setpaidstipend(sortedoc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting_order1])

  //  start and end dates
  const Startdate = (e) => {
    setstart_date(e.target.value)
  }
  const Enddate = (e) => {
    setend_date(e.target.value)
  }
  // filtering data by dates
  const Filtertable_byDate = () => {


    if (
      start_date === undefined ||
      end_date === undefined ||
      end_date === "mm/dd/yyyy" ||
      start_date === "mm/dd/yyyy" ||
      end_date === "" ||
      start_date === ""
    ) {
      seterror_message("Start date and End date are required")
      setTimeout(() => {
        seterror_message("")
      }, 3000)
      return
    }
    if (start_date > end_date) {
      seterror_message("Start date can't be greater than end date")
      setTimeout(() => {
        seterror_message("")
      }, 3000)
      return
    }

    const filtered_data = paidstipend_for_sorting.filter(
      (data) => data.StipendDate >= start_date && data.StipendDate <= end_date
    )

    setpaidstipend(filtered_data)
    setpaidstipend_copy(filtered_data)
    const filtered_data_unpiad = unpaidstipend_for_sorting?.filter(
      (data) => data.AssessDate >= start_date && data.AssessDate <= end_date
    )
    setunpaidstipend(filtered_data_unpiad)
    setunpaidstipend_copy(filtered_data_unpiad)

  }
  //  clearing all filters
  const Clerfilter = () => {
    setstart_date("mm/dd/yyyy")
    setend_date("mm/dd/yyyy")

    setpaidstipend(paidstipend_for_sorting)
    setpaidstipend_copy(paidstipend_for_sorting)
    setunpaidstipend(unpaidstipend_for_sorting)
    setunpaidstipend_copy(unpaidstipend_for_sorting)


  }

  // rough
  useEffect(() => {
    if (stipends) {
      setpaidstipend_for_sorting(stipends?.paidStipends?.map((task) => task.usVisit === "0" ? { ...task, U_visit: task.VisitName, Stipend: Number(task.Stipend), StipendNumber: Number(task.StipendNumber) } : { ...task, U_visit: task.usVisitName, Stipend: Number(task.Stipend), StipendNumber: Number(task.StipendNumber) }))
      setunpaidstipend_for_sorting(stipends?.unpaidStipends?.map((task) => task.usVisit === "0" ? { ...task, U_visit: task.VisitName, Stipend: Number(task.Stipend) } : { ...task, U_visit: task.usVisitName, Stipend: Number(task.Stipend) }))


    }
  }, [stipends])

  useEffect(() => {
  }, [paidstipend_for_sorting])

  useEffect(() => {
  }, [paidstipend])
  // rough end
  // calculating total earning

  useEffect(() => {
    let total_earning = 0
    // let grand_total = 0
    if (paidstipend) {
      paidstipend.map((data) => {
        total_earning = total_earning + Number(data.Stipend)
      })
    }


    setearning(total_earning)
    if (paidstipend?.length > 0) {
    }
  }, [paidstipend])

  useEffect(() => {
    let total_earning_pending = 0
    // let grand_total = 0

    if (unpaidstipend) {
      unpaidstipend.map((data) => {
        total_earning_pending = total_earning_pending + Number(data.Stipend)
      })
    }


    setearning_pending(total_earning_pending)

  }, [unpaidstipend])

  // to add  .00 to amount
  const Convert_value = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  return (
    <div className="row">
      <div className="my-document my-stipend-wrapper">
        <div className="my-stipend">
          <div className="my-stipend-inner">
            <div className="my-stipend-top">
              <img src="/media/misc/stipend-logo.png" alt="unable to load" />
            </div>
            <div className="my-stipend-top display-none" >
              {lodaing_status_total ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <>
                  <span
                    className={`${grand_earning?.length > 6 ? "error-msg" : ""
                      }`}
                  >
                    {grand_earning}
                  </span>

                  {grand_earning?.length > 6 ? (
                    <Icon
                      className="fa fa-exclamation-triangle"
                      style={{ width: 30, marginTop: 10, marginBottom: -4, color: "#ff5858", fontSize: 17 }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {grand_earning?.length > 6 ? (
                ""
              ) : (
                <p>Available Balance-all PAY cards</p>
              )}
            </div>


            <div className="my-stipend-top" style={{ borderRight: "none" }}>
              {lodaing_status_total ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <>
                  <span
                    className={`${grand_earning?.length > 6 ? "error-msg" : ""
                      }`}
                  >
                    {grand_earning}
                  </span>

                  {grand_earning?.length > 6 ? (
                    <Icon
                      className="fa fa-exclamation-triangle"
                      style={{ width: 30, marginTop: 10, marginBottom: -4, color: "#ff5858", fontSize: 17 }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {grand_earning?.length > 6 ? (
                ""
              ) : (
                <p>  Total Available Balance</p>
              )}
            </div>
          </div>
        </div>
        <div className="my-stipend-tabs">
          <div className="my-stipend-tab-inner">
            <div className="form-inline justify-content-between">
              <h3 className="mb-0 mr-3">Stipend Payments</h3>
              {/* <div>
                <button className="btn btn-primary add-btn"> <span className="addsymbol">+</span>Add Audit Trail</button>
              </div> */}
            </div>
            <div className="tab-wrapper">
              <AppBar position="static" elevation={0}>
                <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="nav tabs example">
                  <LinkTab className="first-tab" label=" Paid Stipends" href="/drafts" {...a11yProps(0)} />
                  <LinkTab className="second-tab" label="Pending Stipends" href="/trash" {...a11yProps(1)} />
                </Tabs>
              </AppBar>

              <div className="d-flex justify-content-between right stipend-filter">

                <div className="left-text-wrapper">
                  <span className="left-text">${Convert_value(earning_visible ? earning : earning_pending)}</span>
                  <span>{earning_visible ? "Total Payments" : "Total Pending "}</span>
                </div>
                <div className="right-section">
                  <div className="d-flex align-items-center validation-msg">{error_message}</div>

                  <div className="form-group select-box d-flex">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="user-notification-tooltip"
                          style={{ backgroundColor: "transparent", border: "0" }}
                        >
                          Start Date
                        </Tooltip>
                      }
                    >
                      {/* <TextField
                        name="someDate"
                        label="Start Date"
                        InputLabelProps={{ shrink: true, required: true }}
                        type="date"
                        value={start_date} onChange={(e) => Startdate(e)} 
                        className='start-date-input'
                        placeholder='dd-mm-yyyy'
                      /> */}
                      <input type="date" placeholder={start_date ? "" : "Start Date"} name="From" className='start-date-input' value={start_date ? start_date : ""} onChange={(e) => Startdate(e)} />
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="user-notification-tooltip"
                          style={{ backgroundColor: "transparent", border: "0" }}
                        >
                          End Date
                        </Tooltip>
                      }
                    >
                      {/* <TextField
                        name="To"
                        label="End Date"
                        InputLabelProps={{ shrink: true, required: true }}
                        type="date"
                        value={end_date} onChange={(e) => Enddate(e)}
                        className="end-date-input"
                      /> */}
                      <input type="date" placeholder={end_date ? "" : "End Date"} className="end-date-input" name="To" value={end_date ? end_date : ""} onChange={(e) => Enddate(e)} />
                    </OverlayTrigger>
                  </div>
                  <div className="Date-button">
                    <div className=" btn-wrp filter-btn-wrap">
                      <button className="btn btn-primary uplaod-btn" onClick={Filtertable_byDate}>
                        Filter
                      </button>
                    </div>
                    <div className="btn-wrp clear-btn-wrap">
                      <button className="btn btn-danger uplaod-btn" onClick={Clerfilter}>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <TabPanel value={value} index={0} className="tab">
                {lodaing_status ? (
                  // {true ? (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        height: "250px",
                        alignItems: "center",
                      }}
                    >
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </>
                ) : (
                  <>
                    {paidstipend?.length === 0 || paidstipend === undefined ? (
                      <div className="dash-top">
                        {" "}
                        <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
                        <p>No stipend data found</p>{" "}
                      </div>
                    ) : (
                      <div className="table-main-wrap">
                        <table
                          id="dtOrderExample"
                          className="table table-striped table-bordered table-sm table-wrapper mb-0"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm  " onClick={() => Filter_table1("U_visit")}>
                                Visit<i className="fas fa-sort ml-1"></i>
                              </th>
                              <th className="th-sm payment-date" onClick={() => Filter_table1("StipendDate")}>
                                Payment Date<i className="fas fa-sort ml-1"></i>
                              </th>
                              <th className="th-sm amount" onClick={() => Filter_table1("Stipend")}>
                                Amount<i className="fas fa-sort ml-1"></i>
                              </th>
                              {/* <th className="th-sm reference" onClick={() => Filter_table1("StipendNumber")}>
                                Reference<i className="fas fa-sort ml-1"></i>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {paidstipend
                              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((data, paidstipend_index) => {
                                return [
                                  <React.Fragment key={paidstipend_index}>
                                    <tr>
                                      <td>{data.U_visit}</td>
                                      <td>{Date_convert_without_time_zone(data.StipendDate, 4)} </td>
                                      <td>${Convert_value(data.Stipend)}</td>
                                      {/* <td>{data.StipendNumber}</td> */}
                                    </tr>
                                  </React.Fragment>,
                                ]
                              })}
                          </tbody>
                        </table>
                        <div className="pagination-wrap">
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={paidstipend?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={1} className="tab">
                {unpaidstipend?.length === 0 || unpaidstipend === undefined ? (
                  <div className="dash-top">
                    {" "}
                    <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
                    <p>No stipend data found</p>{" "}
                  </div>
                ) : (
                  <div className="table-main-wrap">
                    <table
                      id="dtOrderExample"
                      className="table table-striped table-bordered table-sm table-wrapper mb-0"
                      cellSpacing="0"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th className="th-sm" onClick={() => Filter_table2("U_visit")}>
                            Visit<i className="fas fa-sort ml-1"></i>
                          </th>
                          <th className="th-sm" onClick={() => Filter_table2("AssessDate")}>
                            Visit Completion Date<i className="fas fa-sort ml-1"></i>
                          </th>
                          <th className="th-sm" onClick={() => Filter_table2("Stipend")}>
                            Amount<i className="fas fa-sort ml-1"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {unpaidstipend
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((data, unpaidstipend_index) => {
                            return [
                              <React.Fragment key={unpaidstipend_index}>
                                <tr>
                                  <td>{data.U_visit}</td>
                                  <td>{Date_convert_without_time_zone(data.AssessDate, 4)}</td>
                                  <td>${Convert_value(data.Stipend)}</td>
                                </tr>
                              </React.Fragment>,
                            ]
                          })}
                      </tbody>
                    </table>
                    <div className="pagination-wrap">
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={unpaidstipend?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                )}
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
