/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
// eslint-disable-next-line no-unused-vars
import { Switch, Redirect, Route } from "react-router-dom"

import { ContentRoute } from "../../../../_common/layout"
import Login from "./Login"
import Registration from "./Registration"
import ResetPassword from "./ResetPassword"
// import Email_update from "./Email_update"
import Feedback from "./Feedback"
import Videochat_ctms from "./Videochat_ctms"
import Register_email_verify from "./Register_email_verify"

import Carousel from 'react-bootstrap/Carousel';
import ForgotPassword from "./ForgotPassword"
import { Get_Carousel_img } from "../_redux/authCrud"
import 'bootstrap/dist/css/bootstrap.css'
import "../../../../_common/_assets/sass/pages/login/login.scss"
import { useSelector, shallowEqual } from "react-redux"

export function AuthPage() {

  const url_for_carousel = useSelector((state) => state.auth.route_status, shallowEqual)


  // const [carousel_data, setcarousel_data] = useState([{
  //   content: "https://realtimestage.wpengine.com/wp-content/uploads/2021/12/pexels-cottonbro-3585074.jpg"
  // }])
  const [carousel_data, setcarousel_data] = useState([])

  useEffect(() => {
    if (url_for_carousel) {
      Get_Carousel_img(url_for_carousel?.url).then(data => { setcarousel_data(data.data) }).catch(err => { })
    }
  }, [url_for_carousel])



  return (
    <>


      <div id="LoginRoot" className="login-root">
        <div id="LoginWrapper" className="login-wrapper">
          <div className="d-flex flex-column flex-root login-wrapper-inner">
            {/*begin::Login*/}

            <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
              id="kt_login"
            >
              {/*begin::Content*/}
              <div className="d-flex flex-column  position-relative p-7 login-left">
                {/* begin::Content body */}
                <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0 login-left-top">
                  <Switch>
                    <ContentRoute path="/auth/login" component={Login} />
                    <ContentRoute path="/auth/registration" component={Registration} />
                    <ContentRoute path="/auth/reset-password" component={ResetPassword} />
                    <ContentRoute path="/auth/user/site/feedback" component={Feedback} />
                    <ContentRoute path="/auth/user/VideoCall" component={Videochat_ctms} />
                    <ContentRoute path="/auth/user/email_verify" component={Register_email_verify} />


                    {/* <Route path="/auth/user/VideoCall" component={Videochat_ctms} /> */}
                    <ContentRoute
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                    />

                    <Redirect from="/auth" exact={true} to="/auth/login" />
                    <Redirect to="/auth/login" />
                  </Switch>
                </div>
                {/*end::Content body*/}


              </div>
              {/*end::Content*/}
              {/*begin::Aside*/}
              <Carousel fade id="auth-page">

                {carousel_data?.map(data => {
                  return [

                    <Carousel.Item variant="dark">
                      <img
                        className="d-block w-100"
                        src={data.content}
                        alt="First slide"
                      />
                    </Carousel.Item>

                  ]
                })}
              </Carousel>
            </div>
            {/*end: Aside Container*/}
            {/*end::Aside*/}
          </div>
          {/*end::Login*/}

          <p className="copyright-footer"><img src="/media/misc/footer-icon.png" alt="" />
            Copyright © 2021 - 2025 | RealTime Participant Portal v1.2.0. | All Rights
            Reserved |{" "}
            <a
              href="
                https://www.realtime-ctms.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            | <a href="https://www.realtime-ctms.com/cookies-policy" target="_blank" rel="noopener noreferrer"> Cookies Policy</a>
          </p>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}
