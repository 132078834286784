import React from "react";
import {
  Dashboard
} from "../modules/Dashboard";




export function DashboardPage(props) {
  return <Dashboard {...props}/>;
}

