/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"
import { v4 as uuidv4 } from "uuid"
import { Addresstype } from "./Addresstype"
import { CameraType } from "./CameraType"
import { Checklist } from "./Checklist"
import { Date_of_birth } from "./Date_of_birth"
import { Date_time_type } from "./Date_time_type"
import { DividerType } from "./DividerType"
import { Dropdowntype } from "./Dropdowntype"
import { EmailType } from "./EmailType"
import { EsignType } from "./EsignType"
import { FileMangertype } from "./FileMangertype"
import { Form_section } from "./Form_section"
import { FullnameType } from "./FullnameType"
import { ImageManager_type } from "./ImageManager_type"
import { NumericType } from "./NumericType"
import { PhoneType } from "./PhoneType"
import { Radiogroup } from "./Radiogroup"
import { ResoueceManger_type } from "./ResourceManger_type"
import { Texttype } from "./Texttype"
import { Text_multiline } from "./Text_multiline"
import { Text_singleline } from "./Text_singleline"
import { Yes_noType } from "./Yes_noType"


export const InputListType = (props) => {
    const nested_data = props.children_elements
    const form_value = props.form_value
    const setform_value = props.setform_value
    const Clear_data = props.Clear_data
    const index_for_loader = props.index_for_loader
    const loading_status = props.loading_status
    const country_list = props.country_list
    const ON_change_inputfield = props.ON_change_inputfield
    const ON_blur_inputfield = props.ON_blur_inputfield
    const Esign_clicked = props.Esign_clicked
    const camera_button_clicked = props.camera_button_clicked
    const YesNo_clicked = props.YesNo_clicked
    const current_date_ = props.current_date_
    const Clear_data_for_date_and_time = props.Clear_data_for_date_and_time
    const Auto_fill_value = props.Auto_fill_value
    const Clear_data_for_dates = props.Clear_data_for_dates
    const clear_fields = props.clear_fields
    const form_status = props.form_status
    const open_uploaded_file = props.open_uploaded_file
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    let isAddRowDisabled = nested_data?.Children ? false : true

    useEffect(() => {
        let is_hidden = check_field_visibility(nested_data, form_value, null);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    // GP: this fuction add the new uuid to the array of input list
    const add_row_click = (parentid) => {
        let newuuid = uuidv4()
        setform_value({ ...form_value, [parentid]: [...form_value[parentid], newuuid] })
    }

    return (
        !isFieldHidden ? <>
            {form_value?.[nested_data.UUID]?.map((rowid, rowindex) => (
                <React.Fragment key={rowindex}>
                    <div className="input_lists">
                        <h3 className="input_list_header">#{rowindex + 1}  {nested_data.Meta.RowLabel}</h3>
                        <div className="inner_input_list">
                            {nested_data?.Children?.map((gnd_child, inputlist_indx) => {
                                return [
                                    <React.Fragment key={inputlist_indx}>
                                        {gnd_child.Type === "yes_no" ? <div className="main_container mt-5">
                                            <Yes_noType rowid={rowid} Clear_data={Clear_data} YesNo_clicked={YesNo_clicked} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}


                                        {gnd_child.Type === "text_multi_line" ? <div className="main_container">

                                            <Text_multiline rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} ON_blur_inputfield={ON_blur_inputfield} />
                                        </div> : ""}
                                        {gnd_child.Type === "text_single_line" ? <div className="main_container">
                                            <Text_singleline rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} ON_blur_inputfield={ON_blur_inputfield} />
                                        </div> : ""}

                                        {gnd_child.Type === "date_time" ? <div className="main_container">
                                            <Date_time_type rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} children_elements_parent={gnd_child} form_value={form_value} current_date_={current_date_} Clear_data_for_date_and_time={Clear_data_for_date_and_time} ON_blur_inputfield={ON_blur_inputfield} Auto_fill_value={Auto_fill_value} />

                                        </div> : ""}
                                        {gnd_child.Type === "file_manager" ? <div className="main_container">
                                            <FileMangertype open_uploaded_file={open_uploaded_file} index_for_loader={index_for_loader} loading_status={loading_status} rowid={rowid} Clear_data={Clear_data} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}

                                        {gnd_child.Type === "electronic_signature" ? <div className="main_container">
                                            <EsignType rowid={rowid} Esign_clicked={Esign_clicked} Clear_data={Clear_data} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}

                                        {gnd_child.Type === "dropdown" ? <div className="input_text">

                                            <Dropdowntype clear_fields={clear_fields} rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}

                                        {gnd_child.Type === "radio_group" ? <div className="main_container">
                                            <Radiogroup rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} clear_fields={clear_fields} />
                                        </div> : ""}

                                        {gnd_child.Type === "numeric" ? <div className="main_container">
                                            <NumericType rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} ON_blur_inputfield={ON_blur_inputfield} />

                                        </div> : ""}
                                        {
                                            gnd_child.Type === "form_section" ? <>
                                                <Form_section rowid={rowid} lable_={gnd_child.Meta.Heading?.blocks[0].text} children_elements={gnd_child} form_value={form_value} />
                                            </> : ""
                                        }


                                        {gnd_child.Type === "address" ? <div className="main_container">

                                            <Addresstype rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} country_list={country_list} ON_blur_inputfield={ON_blur_inputfield} />

                                        </div> : ""}

                                        {gnd_child.Type === "resource_manager" ? <div className="main_container">

                                            <ResoueceManger_type open_uploaded_file={open_uploaded_file} index_for_loader={index_for_loader} loading_status={loading_status} rowid={rowid} Clear_data={Clear_data} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />

                                        </div> : ""}
                                        {gnd_child.Type === "camera" ? <div className="main_container">

                                            <CameraType open_uploaded_file={open_uploaded_file} index_for_loader={index_for_loader} loading_status={loading_status} rowid={rowid} form_value={form_value} nested_data={gnd_child} camera_button_clicked={camera_button_clicked} Clear_data={Clear_data} />
                                        </div> : ""}
                                        {gnd_child.Type === "checklist" ? <div className="main_container">
                                            <Checklist clear_fields={clear_fields} rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}

                                        {gnd_child.Type === "date_of_birth" ? <div className="main_container">
                                            <Date_of_birth rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} current_date_={current_date_} ON_blur_inputfield={ON_blur_inputfield} Clear_data_for_dates={Clear_data_for_dates} />

                                        </div> : ""}
                                        {gnd_child.Type === "divider" ? <div className="main_container">
                                            {/* <hr /> */}
                                            <DividerType rowid={rowid} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}

                                        {gnd_child.Type === "email_address" ? <div className="main_container">

                                            <EmailType rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} ON_blur_inputfield={ON_blur_inputfield} />

                                        </div> : ""}


                                        {gnd_child.Type === "image_manager" ? <div className="main_container">

                                            <ImageManager_type open_uploaded_file={open_uploaded_file} index_for_loader={index_for_loader} loading_status={loading_status} rowid={rowid} Clear_data={Clear_data} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />

                                        </div> : ""}

                                        {gnd_child.Type === "full_name" ? <div className="main_container ">

                                            <FullnameType rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />

                                        </div> : ""}

                                        {gnd_child.Type === "text" ? <div className="input_text">
                                            <Texttype rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />
                                        </div> : ""}

                                        {gnd_child.Type === "phone_number" ? <div className="main_container">


                                            <PhoneType country_list={country_list} rowid={rowid} ON_change_inputfield={ON_change_inputfield} children_elements={gnd_child} form_value={form_value} />

                                        </div> : ""}

                                    </React.Fragment>
                                ]
                            })}
                        </div>
                    </div>
                    {form_value?.[nested_data.UUID].length - 1 == rowindex && !form_status && <button className="btn btn-primary add-row" type="button" disabled={isAddRowDisabled} onClick={() => add_row_click(nested_data.UUID)}>
                        Add Row
                    </button>}
                </React.Fragment>))}
        </> : ""
    )
}
