/* eslint-disable no-unused-vars */
/* eslint-disable no-self-assign */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";

import {
  esign_submit_api,
  DeleteFiletype,
  UploadFiletype,
  Get_saved_forms,
  Get_ctms_userInfo,
  Ctms_single_form,
  Dashboard_invite_api,
  ctms_country_list,
  Done_changes_api,
  Save_changes_form_api,
  get_form_status,
  update_form_status,
  get_ctms_token,
  Get_saved_images,
  Esource_form_status,
  get_session_data_by_id,
  Esource_page_status,
  Unlock_esource_form,
  unlock_by_window_id,
} from "../modules/Auth/_redux/authCrud";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
import { Date_convert } from "../modules/Auth/_redux/Common_code";

import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import { Spinner } from "react-bootstrap";
import { useHistory, Prompt } from "react-router-dom";
import { Form_section } from "./Forms_inputfield/Form_section";
import { Yes_noType } from "./Forms_inputfield/Yes_noType";
import { Texttype } from "./Forms_inputfield/Texttype";
import { Checklist } from "./Forms_inputfield/Checklist";
import { Date_time_type } from "./Forms_inputfield/Date_time_type";
import { EsignType } from "./Forms_inputfield/EsignType";
import { Text_multiline } from "./Forms_inputfield/Text_multiline";
import { Text_singleline } from "./Forms_inputfield/Text_singleline";
import { FileMangertype } from "./Forms_inputfield/FileMangertype";
import { Radiogroup } from "./Forms_inputfield/Radiogroup";
import { Dropdowntype } from "./Forms_inputfield/Dropdowntype";
import { NumericType } from "./Forms_inputfield/NumericType";

import { Date_of_birth } from "./Forms_inputfield/Date_of_birth";
import { Addresstype } from "./Forms_inputfield/Addresstype";
import { CameraType } from "./Forms_inputfield/CameraType";
import { PhoneType } from "./Forms_inputfield/PhoneType";
import { EmailType } from "./Forms_inputfield/EmailType";
import { FullnameType } from "./Forms_inputfield/FullnameType";
import { ResoueceManger_type } from "./Forms_inputfield/ResourceManger_type";
import { ImageManager_type } from "./Forms_inputfield/ImageManager_type";

import { Common_popup_esign } from "../pages/Common_popup_esign";
import { DividerType } from "./Forms_inputfield/DividerType";
import { InputListType } from "./Forms_inputfield/InputListType";
import { FormTemplate } from "./Forms_inputfield/FormTemplate";

import { v4 as uuidv4 } from "uuid";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const Myform_single_record = (props) => {
  let history = useHistory();


  const dispatch = useDispatch();
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual);
  const [forms, setforms] = useState([]);
  const [utc_date, setutc_date] = useState();
  const [pages, setpages] = useState([]);
  const [dropdown_login_info, setdropdown_login_info] = useState();
  const [study, setstudy] = useState([]);
  const [nodata_status, setnodata_status] = useState(true);
  const [open_success_popup, setopen_success_popup] = useState(false);
  const [errorpopup, seterrorpopup] = useState(false);
  const [open_success_popup_2, setopen_success_popup_2] = useState(false);
  const [Total_pages_count, setTotal_pages_count] = useState(0);
  const [current_pages_count, setcurrent_pages_count] = useState(0);
  const [current_pages_data, setcurrent_pages_data] = useState([]);
  const [country_list, setcountry_list] = useState([]);
  const [Open_camera, setOpen_camera] = useState(false);
  const [spinner_status, setspinner_status] = useState(false);
  const [loader_status, setloader_status] = useState(true);
  const [open_save_changes_popup, setopen_save_changes_popup] = useState(false);
  const [save_changes_button_status, setsave_changes_button_status] = useState(
    false
  );
  const [formdata_temp, setformdata_temp] = useState();
  const [current_data_object, setcurrent_data_object] = useState([]);
  const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url
  const videoRef = useRef(null);
  const canvasref = useRef(null);
  const converted_date = new Date();
  let day_ = ("0" + converted_date.getDate()).slice(-2);
  let month_ = ("0" + (converted_date.getMonth() + 1)).slice(-2);
  let year_ = converted_date.getFullYear();
  const queryParams = new URLSearchParams(window.location.search);
  let is_mob = queryParams.get("is_mob");

  const [form_value, setform_value] = useState({});
  const [required_fields, setrequired_fields] = useState([]);
  const [requi_field_errorpopup, setrequi_field_errorpopup] = useState(false);

  const [field_invalid_error, setfield_invalid_error] = useState(false);
  const [
    field_invalid_error_message,
    setfield_invalid_error_message,
  ] = useState("");
  let current_date_ = year_ + "-" + month_ + "-" + day_;
  const [esign_popup_status, setesign_popup_status] = useState(false);
  const [current_row_id, setcurrent_row_id] = useState("");
  const [loading_status, setloading_status] = useState(false);
  const [index_for_loader, setindex_for_loader] = useState("");
  const [form_status2, setform_status2] = useState(false);
  const saveIntervalRef = useRef();
  const [cam_err_status, setcam_err_status] = useState(false);
  const [camera_permission_error, setcamera_permission_error] = useState(false);
  const [camera_permission_message, setcamera_permission_message] = useState(
    ""
  );
  let dateCreated = queryParams.get("CreatedAt_");
  const [file_upload_error, setfile_upload_error] = useState(false);
  const [file_upload_error_message, setfile_upload_error_message] = useState(
    ""
  );
  const [has_unsaved_data, sethas_unsaved_data] = useState(false);

  const statusCheckIntervalRef = useRef();
  const [close_session_popup, setclose_session_popup] = useState(false);
  const [form_another_session, setform_another_session] = useState(false);
  const [form_session_data, setform_session_data] = useState([]);
  const [session_close_popup, setsession_close_popup] = useState(false);

  // to fix refresh issue
  useEffect(() => {
    setTimeout(() => {
      sessionStorage.removeItem("esourcemailRequest")
    }, 9000);

    if (!studies_) {
      apicall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // calling invite api  to resolve refresh issue
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies));
      })
      .catch((error) => { });
  };

  //to get session key//  this method will execute when click on form list
  const Get_sessionkey = (FormID, type, dropdown_data) => {
    get_ctms_token()
      .then((value) => {
        if (type === 1) {
          setpages([]);
          setspinner_status(true);
          Ctms_single_form_(value.data, FormID, dropdown_data);
          localStorage.setItem("FormID", FormID);
        }
        if (type === 2) {
          Done_changes_button_pressed(value.data, dropdown_data);
        }
        if (type === 3) {
          Save_changes_button_pressed(value.data);
        }
        if (type === 4) {
          setTotal_pages_count(0);
          setcurrent_pages_count(0);
          setcurrent_pages_data([]);
          setform_value({});
          setpages([]);
          setspinner_status(true);
          Ctms_single_form_(value.data, FormID, dropdown_data);
          localStorage.setItem("FormID", FormID);
        }
      })
      .catch((error) => { })
      .finally((data) => { });
  };
  // to get single form
  const Ctms_single_form_ = (key, formid, data_) => {
    Ctms_single_form(key, formid, data_)
      .then((data) => {
        Ctms_single_form_part2(data, key);
      })
      .catch((err) => {
        setspinner_status(false);
      });
  };
  const Ctms_single_form_part2 = (data, key) => {
    setloader_status(false);
    setnodata_status(false);
    setforms(data.data.form);
    let form_date = data.data.form?.DateAdded;
    var form_date_custom = new Date(form_date.replace(" ", "T") + "Z");

    setutc_date(form_date_custom.toString());

    localStorage.setItem("armId", data.data.form?.section?.ArmID)
    setTotal_pages_count(
      data.data.pages.filter((p) => p.Meta.Hidden === false)?.length
    );
    sessionStorage.setItem(
      "page_count",
      data.data.pages.filter((p) => p.Meta.Hidden === false)?.length
    );
    setstudy(data.data.study);

    setpages(data.data.pages.filter((p) => p.Meta.Hidden === false));
    setspinner_status(false);

    if (data.data.status === 0) {
      Get_saved_forms(key)
        .then((value) => {
          setform_value(value.data?.formData);
        })
        .catch((err) => { });
    }
  };

  // when esign saved gets saved data back
  const common_key_method = (type) => {
    get_ctms_token()
      .then((value) => {
        if (type === 1) {
          Save_changes_form_api(value.data, form_value)
            .then((data) => {
              setsave_changes_button_status(false);
              Get_saved_data(value.data);
            })
            .catch((err) => { });
        }
      })
      .catch((err) => { });
  };

  const Get_saved_data = (key) => {
    Get_saved_forms(key)
      .then((value) => {
        setform_value(value.data?.formData);
      })
      .catch((err) => { });
  };
  // when done button pressed
  const Done_changes_button_pressed = (key, incoming_data) => {
    let isAllFieldsFilled = check_required_fields();
    // if data is not saved then we will save data first
    Save_changes_form_api(key, form_value)
      .then((data) => {
        sethas_unsaved_data(false);
        if (isAllFieldsFilled) {
          Done_changes_button_pressed_part2(key, incoming_data);
        } else {
          document
            .getElementById("kt_body")
            .classList.remove("overflow-scroll");
          setrequi_field_errorpopup(true);
        }
      })
      .catch((err) => { });
  };
  const Done_changes_button_pressed_part2 = (key, incoming_data) => {
    Done_changes_api(key, incoming_data)
      .then((data) => {
        let form_id = queryParams.get("ID_");
        let patientId = queryParams.get("id");

        update_form_status(patientId, form_id)
          .then(() => {
            document.getElementById("kt_body").classList.add("overflow-scroll");
            setopen_success_popup_2(true);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };
  // save button pressed
  const Save_changes_button_pressed = (key) => {
    Save_changes_form_api(key, form_value)
      .then((data) => {
        sethas_unsaved_data(false);
        setsave_changes_button_status(false);
      })
      .catch((err) => { });
  };
  // to get  first page data out of multipe pages,on load of this page
  useEffect(() => {
    if (pages.length > 0) {
      let page_numbers = JSON.parse(localStorage.getItem("PageNumbers"));
      let form_id = queryParams.get("ID_");

      let total_pages = page_numbers?.[0][form_id]?.pageNum + 1 > pages.length;
      let last_used_page =
        page_numbers?.[0][form_id] && !total_pages
          ? page_numbers?.[0][form_id]?.pageNum
          : 0;

      if (total_pages) {
        save_page_number(0);
      }
      setcurrent_pages_count(last_used_page);
      setcurrent_pages_data([pages[last_used_page]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);
  // on click of back  next button
  const Clicked_next_button = () => {
    setform_another_session(false);
    setcurrent_pages_data([pages[current_pages_count + 1]]);
    setcurrent_pages_count(current_pages_count + 1);
    save_page_number(current_pages_count + 1);
    if (!form_status2 && !form_another_session && sessionStorage.getItem("is_admin_control") !== "true") {
      open_success_popup_method();
    }
  };

  const Clicked_back_button = () => {
    setform_another_session(false);
    setcurrent_pages_data([pages[current_pages_count - 1]]);
    setcurrent_pages_count(current_pages_count - 1);
    save_page_number(current_pages_count - 1);
    if (!form_status2 && !form_another_session && sessionStorage.getItem("is_admin_control") !== "true") {
      open_success_popup_method();
    }
  };

  useEffect(() => {
    ctms_country_list()
      .then((data_) => {
        setcountry_list(data_.data.records);
      })
      .catch((err) => { });
  }, []);

  //popup methods
  const handleCloses_Esign = () => {
    document.getElementById("kt_body").classList.add("overflow-scroll");
    setesign_popup_status(false);
  };
  const handleCloses_Esign_submit = (drpdown_value_esign_, username, password) => {
    document.getElementById("kt_body").classList.add("overflow-scroll");
    setesign_popup_status(false);
    get_ctms_token()
      .then((value) => {
        esign_submit_api(
          drpdown_value_esign_,
          current_data_object,
          value.data,
          form_value,
          current_row_id,
          username,
          password
        )
          .then((data) => {
            common_key_method(1);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };
  let camera_error_message = "please capture the image first";

  const handleCloses_camera_capture_submit = () => {
    if (img_To_file === "1") {
      setcam_err_status(true);

      close_video();
      getVideo();

      return;
    }
    setloading_status(true);

    if (img_To_file !== "1") {
      setOpen_camera(false);
      close_video();

      upload_file_type(
        img_To_file,
        current_data_object.UUID,
        current_data_object.Parent,
        current_row_id,
        "camera"
      );
      img_To_file = "1";
      setcam_err_status(false);
    }
    // to fix scroll issue
    document.getElementById("kt_body").classList.add("overflow-scroll");
  };
  const handleCloses_camera_capture = () => {
    setOpen_camera(false);
    close_video();
    setcam_err_status(false);
    // to fix scroll issue
    document.getElementById("kt_body").classList.add("overflow-scroll");
  };

  const handleCloses_conf_savec_changes_popup = () => {
    document.getElementById("kt_body").classList.add("overflow-scroll");
    setopen_success_popup(false);
  };
  const handleCloses_conf_savec_changes_popup_yes = () => {
    setopen_success_popup(false);
    Get_sessionkey(null, 2, dropdown_login_info);
  };

  const handleCloses_success_popup = () => {
    document.getElementById("kt_body").classList.add("overflow-scroll");
    setopen_success_popup_2(false);

    setform_status2(true);
  };
  // when clicked on save changs button
  const open_success_popup_method = () => {
    Get_sessionkey(null, 3, dropdown_login_info);
    setsave_changes_button_status(true);
  };

  const handleClose_save_changes_popup_yes = () => {
    setopen_save_changes_popup(false);
    Get_sessionkey(formdata_temp, 4);
  };
  const handleClose_save_changes_popup = () => {
    setopen_save_changes_popup(false);
  };

  const handleCloses_error = () => {
    seterrorpopup(false);
  };
  let img_To_file = "1";
  const capture_image = () => {
    const context = canvasref.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, 680, 360);

    const imgurl = canvasref.current.toDataURL("image/jpeg");
    img_To_file = onCapture(imgurl, "cam_photo.png");
  };
  const onCapture = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  //popup methods ends
  // E sign methods

  // get video preview
  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 600 } })
      .then((stream) => {
        let video = videoRef.current;
        // video.srcObject = stream;
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = URL.createObjectURL(stream)
        }

        video.play();
      })
      .catch((err) => { });
  };
  // stop video preview
  const close_video = () => {
    let video = videoRef.current;
    const stream = video.srcObject;
    const tracks = stream?.getTracks();
    if (stream !== null) {
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.stop();
        track.enabled = false;
      }
      video.srcObject = null;
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const upload_file_type = (file, name, parent, rid, input_type) => {
    convertBase64(file).then((base64_) => {
      upload_file_type_part2(base64_, file, name, parent, rid, input_type);
    });
  };

  const upload_file_type_part2 = (
    base64_,
    file,
    name,
    parent,
    rid,
    input_type
  ) => {
    get_ctms_token()
      .then((value) => {
        UploadFiletype(value.data, base64_, file)
          .then((data) => {
            if (data.data.status == "2") {
              document
                .getElementById("kt_body")
                .classList.remove("overflow-scroll");
              setfile_upload_error_message(data.data?.error);
              setfile_upload_error(true);
              return;
            }

            sethas_unsaved_data(true);
            if (parent) {
              let commonId = rid ? rid : form_value[parent]?.[0];
              if (commonId) {
                let prevFiles = form_value[name]?.[commonId]?.Files
                  ? [...form_value[name]?.[commonId]?.Files]
                  : [];
                let file_name =
                  data.data.record?.Name.split(".")[0] +
                  "-" +
                  (prevFiles.length + 1) +
                  "." +
                  data.data.record?.Name.split(".")[1];
                setform_value({
                  ...form_value,
                  [name]: {
                    ...form_value[name],
                    [commonId]: {
                      Files: [
                        ...prevFiles,
                        {
                          UserID: data.data.record?.UserID,
                          Name:
                            input_type === "camera"
                              ? file_name
                              : data.data.record?.Name,
                          Hash: data.data.record?.Hash,
                          Mime: data.data.record?.Mime,
                          Size: data.data.record?.Size,
                          DateAdded: data.data.record?.DateAdded,
                          DateUpdated: data.data.record?.DateUpdated,
                          ID: data.data.record?.ID,
                        },
                      ],
                    },
                  },
                });
              } else {
                let prevFiles = form_value[name]?.Files
                  ? [...form_value[name]?.Files]
                  : [];
                let file_name =
                  data.data.record?.Name.split(".")[0] +
                  "-" +
                  (prevFiles.length + 1) +
                  "." +
                  data.data.record?.Name.split(".")[1];
                setform_value({
                  ...form_value,
                  [name]: {
                    Files: [
                      ...prevFiles,
                      {
                        UserID: data.data.record?.UserID,
                        Name:
                          input_type === "camera"
                            ? file_name
                            : data.data.record?.Name,
                        Hash: data.data.record?.Hash,
                        Mime: data.data.record?.Mime,
                        Size: data.data.record?.Size,
                        DateAdded: data.data.record?.DateAdded,
                        DateUpdated: data.data.record?.DateUpdated,
                        ID: data.data.record?.ID,
                      },
                    ],
                  },
                });
              }
            } else {
              let prevFiles = form_value[name]?.Files
                ? [...form_value[name]?.Files]
                : [];
              let file_name =
                data.data.record?.Name.split(".")[0] +
                "-" +
                (prevFiles.length + 1) +
                "." +
                data.data.record?.Name.split(".")[1];
              setform_value({
                ...form_value,
                [name]: {
                  Files: [
                    ...prevFiles,
                    {
                      UserID: data.data.record?.UserID,
                      Name:
                        input_type === "camera"
                          ? file_name
                          : data.data.record?.Name,
                      Hash: data.data.record?.Hash,
                      Mime: data.data.record?.Mime,
                      Size: data.data.record?.Size,
                      DateAdded: data.data.record?.DateAdded,
                      DateUpdated: data.data.record?.DateUpdated,
                      ID: data.data.record?.ID,
                    },
                  ],
                },
              });
            }
          })
          .catch((err) => { })
          .finally(() => {
            setloading_status(false);
          });
      })
      .catch((err) => { });
  };

  const ON_change_inputfield = async (
    event,
    type,
    id,
    subtype,
    parent,
    country_default,
    bmi_parameter,
    rid,
    index_id
  ) => {
    sethas_unsaved_data(true);

    if (type === "inputfield") {
      // for bmi we are calculating some data

      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: event.target.value ? event.target.value : " ",
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: event.target.value,
        });
      }
    }
    if (type === "inputfield_phone") {
      let commonId = rid ? rid : form_value[parent]?.[0];
      let countryVal = form_value[event.target.name]?.[commonId]?.country
        ? form_value[event.target.name]?.[commonId]?.country
        : form_value[event.target.name]?.country;
      let def_country = "";
      country_default === "US"
        ? (def_country = "US")
        : (def_country = country_default);
      if (parent) {
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: {
                country: countryVal ? countryVal : def_country,
                phone: event.target.value,
              },
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: {
              country: countryVal ? countryVal : def_country,
              phone: event.target.value,
            },
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: {
            country: countryVal ? countryVal : def_country,
            phone: event.target.value,
          },
        });
      }
    }

    if (type === "inputfield_country") {
      let commonId = rid ? rid : form_value[parent]?.[0];
      let phoneVal = form_value[event.target.name]?.[commonId]?.phone
        ? form_value[event.target.name]?.[commonId]?.phone
        : form_value[event.target.name]?.phone;
      let def_country = "";
      country_default === "US"
        ? (def_country = "US")
        : (def_country = country_default);
      if (parent) {
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: {
                country: event.target.value ? event.target.value : def_country,
                phone: phoneVal ? phoneVal : "",
              },
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: {
              country: event.target.value ? event.target.value : def_country,
              phone: phoneVal ? phoneVal : "",
            },
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: {
            country: event.target.value ? event.target.value : def_country,
            phone: phoneVal ? phoneVal : "",
          },
        });
      }
    }

    if (type === "inputfield_date_birth") {
      let year_ = event.target.value.slice(0, 4);
      let month_ = event.target.value.slice(5, 7);
      let day_ = event.target.value.slice(8, 10);
      let time_digitone_ = -1;
      let time_digittwo_ = -1;
      month_ = month_ - 1;
      if (month_ < 10) {
        month_ = Number("0" + month_);
      }

      let conv_date =
        day_ +
        "/" +
        month_ +
        "/" +
        year_ +
        "/" +
        time_digitone_ +
        "/" +
        time_digittwo_;

      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: event.target.value,
            },
            [id]: { ...form_value[id], [commonId]: conv_date },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: event.target.value,
            [id]: conv_date,
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: event.target.value,
          [id]: conv_date,
        });
      }
    }
    if (type === "inputfield_date_time") {
      let commonId = rid ? rid : form_value[parent]?.[0];

      let Ongoing_value;

      if (subtype === "date") {
        let year_ = event.target.value.slice(0, 4);
        let month_ = event.target.value.slice(5, 7);
        let day_ = event.target.value.slice(8, 10);
        let time_digitone_ = -1;
        let time_digittwo_ = -1;

        month_ = month_ - 1;
        if (month_ < 10) {
          month_ = Number("0" + month_);
        }

        if (commonId) {
          if (form_value[id]?.[commonId]) {
            let splitdate = form_value[id]?.[commonId].split("/");
            time_digitone_ = splitdate[3];
            time_digittwo_ = splitdate[4];
            splitdate[5]
              ? (Ongoing_value = splitdate[5])
              : (Ongoing_value = null);
          } else {
            Ongoing_value = null;
          }
        } else {
          if (form_value[id]) {
            let splitdate = form_value[id].split("/");
            time_digitone_ = splitdate[3];
            time_digittwo_ = splitdate[4];
            splitdate[5]
              ? (Ongoing_value = splitdate[5])
              : (Ongoing_value = null);
          } else {
            Ongoing_value = null;
          }
        }

        let conv_date =
          day_ +
          "/" +
          month_ +
          "/" +
          year_ +
          "/" +
          time_digitone_ +
          "/" +
          time_digittwo_;
        Ongoing_value
          ? (conv_date = conv_date + "/" + Ongoing_value)
          : (conv_date = conv_date);

        if (parent) {
          let commonId = rid ? rid : form_value[parent]?.[0];
          if (commonId) {
            setform_value({
              ...form_value,
              [event.target.name]: {
                ...form_value[event.target.name],
                [commonId]: event.target.value,
              },
              [id]: { ...form_value[id], [commonId]: conv_date },
            });
          } else {
            setform_value({
              ...form_value,
              [event.target.name]: event.target.value,
              [id]: conv_date,
            });
          }
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: event.target.value,
            [id]: conv_date,
          });
        }
      } else {
        let year_ = "0000";
        let month_ = "00";
        let day_ = "00";
        let time_digitone_ = event.target.value.slice(0, 2);
        let time_digittwo_ = event.target.value.slice(3, 6);

        if (commonId) {
          if (form_value[id]?.[commonId]) {
            let splitdate = form_value[id]?.[commonId].split("/");
            year_ = splitdate[2];
            month_ = splitdate[1];
            day_ = splitdate[0];
            splitdate[5]
              ? (Ongoing_value = splitdate[5])
              : (Ongoing_value = null);

            time_digitone_ = event.target.value.slice(0, 2);
            time_digittwo_ = event.target.value.slice(3, 6);
          } else {
            Ongoing_value = null;
            time_digitone_ = event.target.value.slice(0, 2);
            time_digittwo_ = event.target.value.slice(3, 6);
          }
        } else {
          if (form_value[id]) {
            let splitdate = form_value[id]?.split("/");
            year_ = splitdate[2];
            month_ = splitdate[1];
            day_ = splitdate[0];
            splitdate[5]
              ? (Ongoing_value = splitdate[5])
              : (Ongoing_value = null);

            time_digitone_ = event.target.value.slice(0, 2);
            time_digittwo_ = event.target.value.slice(3, 6);
          } else {
            Ongoing_value = null;
            time_digitone_ = event.target.value.slice(0, 2);
            time_digittwo_ = event.target.value.slice(3, 6);
          }
        }

        let conv_date =
          day_ +
          "/" +
          month_ +
          "/" +
          year_ +
          "/" +
          time_digitone_ +
          "/" +
          time_digittwo_;

        Ongoing_value
          ? (conv_date = conv_date + "/" + Ongoing_value)
          : (conv_date = conv_date);

        if (parent) {
          let commonId = rid ? rid : form_value[parent]?.[0];
          if (commonId) {
            setform_value({
              ...form_value,
              [event.target.name]: {
                ...form_value[event.target.name],
                [commonId]: event.target.value,
              },
              [id]: { ...form_value[id], [commonId]: conv_date },
            });
          } else {
            setform_value({
              ...form_value,
              [event.target.name]: event.target.value,
              [id]: conv_date,
            });
          }
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: event.target.value,
            [id]: conv_date,
          });
        }
      }
    }
    if (type === "file") {
      if (index_id) {
        setindex_for_loader(index_id);
        setloading_status(true);
      }
      upload_file_type(event.target.files[0], event.target.name, parent, rid);
    }
    if (type === "radio") {
      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [id]: { ...form_value[id], [commonId]: event.target.value },
          });
        } else {
          setform_value({ ...form_value, [id]: event.target.value });
        }
      } else {
        setform_value({ ...form_value, [id]: event.target.value });
      }
    }
    if (type === "checkbox") {
      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          if (event.target.checked) {
            if (form_value[event.target.name]?.[commonId]) {
              setform_value({
                ...form_value,
                [event.target.name]: {
                  ...form_value[event.target.name],
                  [commonId]: [
                    ...form_value[event.target.name][commonId],
                    event.target.value,
                  ],
                },
              });
            } else {
              setform_value({
                ...form_value,
                [event.target.name]: {
                  ...form_value[event.target.name],
                  [commonId]: [event.target.value],
                },
              });
            }
          } else {
            let newArray = form_value[event.target.name][commonId].filter(
              (val_) => {
                return val_ != event.target.value;
              }
            );

            setform_value({
              ...form_value,
              [event.target.name]: {
                ...form_value[event.target.name],
                [commonId]: newArray,
              },
            });
          }
        } else {
          if (event.target.checked) {
            if (form_value[event.target.name]) {
              setform_value({
                ...form_value,
                [event.target.name]: [
                  ...form_value[event.target.name],
                  event.target.value,
                ],
              });
            } else {
              setform_value({
                ...form_value,
                [event.target.name]: [event.target.value],
              });
            }
          } else {
            let newArray = form_value[event.target.name].filter((val_) => {
              return val_ != event.target.value;
            });
            setform_value({ ...form_value, [event.target.name]: newArray });
          }
        }
      } else {
        if (event.target.checked) {
          if (form_value[event.target.name]) {
            setform_value({
              ...form_value,
              [event.target.name]: [
                ...form_value[event.target.name],
                event.target.value,
              ],
            });
          } else {
            setform_value({
              ...form_value,
              [event.target.name]: [event.target.value],
            });
          }
        } else {
          let newArray = form_value[event.target.name].filter((val_) => {
            return val_ != event.target.value;
          });
          setform_value({ ...form_value, [event.target.name]: newArray });
        }
      }
    }

    if (type === "full_name") {
      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: event.target.value ? event.target.value : " ",
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: event.target.value,
        });
      }
    }
  };
  const ON_blur_inputfield = (event, type, id, subtype, parent_, rid) => {
    sethas_unsaved_data(true);

    if (
      event.target.value === "" ||
      event.target.value === null ||
      event.target.value === undefined
    ) {
      return;
    }

    const parent = parent_.Parent;

    if (type === "inputfield_date_birth") {
      let year_ = event.target.value.slice(0, 4);
      let month_ = event.target.value.slice(5, 7);
      let day_ = event.target.value.slice(8, 10);
      let time_digitone_ = -1;
      let time_digittwo_ = -1;
      let invalid_date = false;

      month_ = month_ - 1;
      if (month_ < 10) {
        month_ = Number("0" + month_);
      }
      if (event.target.value.length > 10) {
        year_ = "0000";
        month_ = "00";
        day_ = "00";
        invalid_date = true;
        setfield_invalid_error_message("Please provide the valid Date ");
      }

      let date = new Date();
      let date2 = new Date(event.target.value);
      let age_of_user = date.getFullYear() - date2.getFullYear();

      if (age_of_user < parent_.Meta?.MinAge) {
        year_ = "0000";
        month_ = "00";
        day_ = "00";
        invalid_date = true;
        setfield_invalid_error_message(
          "Minimum age must be  " + parent_.Meta?.MinAge
        );
      }

      let isToday =
        date.getDate() == date2.getDate() &&
        date.getMonth() == date2.getMonth() &&
        date.getFullYear() == date2.getFullYear();

      if (!parent_.Meta?.Future && !isToday && date2 > date) {
        year_ = "0000";
        month_ = "00";
        day_ = "00";
        invalid_date = true;
        setfield_invalid_error_message(
          "Future date is not acceptable for this field"
        );
      } else if (!parent_.Meta?.Past && !isToday && date2 < date) {
        year_ = "0000";
        month_ = "00";
        day_ = "00";
        invalid_date = true;
        setfield_invalid_error_message(
          "Past date is not acceptable for this field"
        );
      }
      if (invalid_date) {
        document.getElementById("kt_body").classList.remove("overflow-scroll");
        setfield_invalid_error(true);
      }

      let conv_date =
        day_ +
        "/" +
        month_ +
        "/" +
        year_ +
        "/" +
        time_digitone_ +
        "/" +
        time_digittwo_;

      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: invalid_date ? "" : event.target.value,
            },
            [id]: {
              ...form_value[id],
              [commonId]: invalid_date ? "" : conv_date,
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: invalid_date ? "" : event.target.value,
            [id]: invalid_date ? "" : conv_date,
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: invalid_date ? "" : event.target.value,
          [id]: invalid_date ? "" : conv_date,
        });
      }
    }

    if (type === "inputfield_date_time") {
      let Ongoing_value;

      let commonId = rid ? rid : form_value[parent]?.[0];
      if (subtype === "date") {
        let year_ = event.target.value.slice(0, 4);
        let month_ = event.target.value.slice(5, 7);
        let day_ = event.target.value.slice(8, 10);
        let time_digitone_ = -1;
        let time_digittwo_ = -1;
        let invalid_date = false;

        month_ = month_ - 1;
        if (month_ < 10) {
          month_ = Number("0" + month_);
        }
        if (event.target.value.length > 10) {
          year_ = "0000";
          month_ = "00";
          day_ = "00";
          invalid_date = true;
          setfield_invalid_error_message("Please provide the valid Date");
        }

        if (commonId) {
          if (form_value[id]?.[commonId]) {
            let splitdate = form_value[id]?.[commonId].split("/");
            time_digitone_ = splitdate[3];
            time_digittwo_ = splitdate[4];
            splitdate[5]
              ? (Ongoing_value = splitdate[5])
              : (Ongoing_value = null);
          } else {
            Ongoing_value = null;
          }
        } else {
          if (form_value[id]) {
            let splitdate = form_value[id].split("/");
            time_digitone_ = splitdate[3];
            time_digittwo_ = splitdate[4];
            splitdate[5]
              ? (Ongoing_value = splitdate[5])
              : (Ongoing_value = null);
          } else {
            Ongoing_value = null;
          }
        }

        let date = new Date();
        let date2 = new Date(event.target.value);
        let isToday =
          date.getDate() == date2.getDate() &&
          date.getMonth() == date2.getMonth() &&
          date.getFullYear() == date2.getFullYear();

        if (event.target.value.length > 10) {
          year_ = "0000";
          month_ = "00";
          day_ = "00";
          invalid_date = true;
          setfield_invalid_error_message("Please provide the valid Date ");
        }

        if (!parent_.Meta?.Future && !isToday && date2 > date) {
          year_ = "0000";
          month_ = "00";
          day_ = "00";
          invalid_date = true;
          setfield_invalid_error_message(
            "Future date is not acceptable for this field"
          );
        } else if (!parent_.Meta?.Past && !isToday && date2 < date) {
          year_ = "0000";
          month_ = "00";
          day_ = "00";
          invalid_date = true;
          setfield_invalid_error_message(
            "Past date is not acceptable for this field"
          );
        }

        if (invalid_date) {
          document
            .getElementById("kt_body")
            .classList.remove("overflow-scroll");
          setfield_invalid_error(true);
        }

        let conv_date =
          day_ +
          "/" +
          month_ +
          "/" +
          year_ +
          "/" +
          time_digitone_ +
          "/" +
          time_digittwo_;
        Ongoing_value
          ? (conv_date = conv_date + "/" + Ongoing_value)
          : (conv_date = conv_date);

        if (parent) {
          let commonId = rid ? rid : form_value[parent]?.[0];
          if (commonId) {
            setform_value({
              ...form_value,
              [event.target.name]: {
                ...form_value[event.target.name],
                [commonId]: invalid_date ? "" : event.target.value,
              },
              [id]: {
                ...form_value[id],
                [commonId]: invalid_date ? "" : conv_date,
              },
            });
          } else {
            setform_value({
              ...form_value,
              [event.target.name]: invalid_date ? "" : event.target.value,
              [id]: invalid_date ? "" : conv_date,
            });
          }
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: invalid_date ? "" : event.target.value,
            [id]: invalid_date ? "" : conv_date,
          });
        }
      }
    }

    if (type === "inputfield_numeric") {
      let invalid_num = false;

      let meta_min = parent_.Meta?.Min;
      let meta_max = parent_.Meta?.Max;

      if (
        event.target.value < parent_.Meta?.Min &&
        meta_min !== null &&
        event.target.value.length > 0
      ) {
        invalid_num = true;
        setfield_invalid_error_message("Value must be greater than or equal to " + meta_min);
      }
      if (
        event.target.value > parent_.Meta?.Max &&
        meta_max !== null &&
        event.target.value.length > 0
      ) {
        invalid_num = true;
        setfield_invalid_error_message("Value must be less than or equal to " + meta_max);
      }
      if (event.target.value === 0) {
        invalid_num = false;
      }

      if (invalid_num) {
        document.getElementById("kt_body").classList.remove("overflow-scroll");
        setfield_invalid_error(true);
      }

      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: invalid_num ? " " : event.target.value,
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: invalid_num ? "" : event.target.value,
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: invalid_num ? "" : event.target.value,
        });
      }
    }

    if (type === "inputfield_email") {
      let emailPattern = /^([A-Za-z0-9._%+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      let isEmailValid = emailPattern.test(event.target.value);

      if (!isEmailValid) {
        document.getElementById("kt_body").classList.remove("overflow-scroll");
        setfield_invalid_error_message("Please provide valid Email address");
        setfield_invalid_error(true);
      }

      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: isEmailValid ? event.target.value : " ",
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: isEmailValid ? event.target.value : "",
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: isEmailValid ? event.target.value : "",
        });
      }
    }

    if (type === "inputfield") {
      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [event.target.name]: {
              ...form_value[event.target.name],
              [commonId]: event.target.value
                ? event.target.value?.trim() + " "
                : " ",
            },
          });
        } else {
          setform_value({
            ...form_value,
            [event.target.name]: event.target.value?.trim(),
          });
        }
      } else {
        setform_value({
          ...form_value,
          [event.target.name]: event.target.value?.trim(),
        });
      }
      // ......
    }
  };

  const ON_change_inputfield_bmi = async (
    event,
    type,
    id,
    subtype,
    parent,
    country_default,
    bmi_parameter
  ) => {
    sethas_unsaved_data(true);
    let radio_id;
    let height_id;
    let weight_id;
    let bmi_id;
    let bmi_value;
    let third_field;
    let event_name;
    let unit;

    if (bmi_parameter) {
      radio_id = bmi_parameter.Children[1].UUID;
      height_id = bmi_parameter.Children[2].UUID;
      weight_id = bmi_parameter.Children[3].UUID;
      bmi_id = bmi_parameter.Children[4].UUID;
    }

    if (type === "clear") {
      setform_value({
        ...form_value,
        [bmi_id]: "",
        [height_id]: "",
        [weight_id]: "",
      });
    }

    if (type === "inputfield") {
      if (bmi_parameter) {
        if (event.target.name === height_id) {
          third_field = weight_id;
          event_name = "height";
        } else {
          third_field = height_id;
          event_name = "weight";
        }
        unit = form_value[radio_id];

        if (true) {
          let denominator_;
          let numerator_;
          if (event_name === "height") {
            if (unit === "standard") {
              denominator_ = Number(event.target.value) / 39.37;
              numerator_ = Number(form_value[third_field]) / 2.205;
              bmi_value = numerator_ / (denominator_ * denominator_);
            } else {
              denominator_ = Number(event.target.value) / 100;
              bmi_value =
                Number(form_value[third_field]) / (denominator_ * denominator_);
            }
          } else {
            if (unit === "standard") {
              denominator_ = Number(form_value[third_field]) / 39.37;
              numerator_ = Number(event.target.value) / 2.205;

              bmi_value = numerator_ / (denominator_ * denominator_);
            } else {
              denominator_ = Number(form_value[third_field]) / 100;
              bmi_value =
                Number(event.target.value) / (denominator_ * denominator_);
            }
          }
        }
        if (form_value[radio_id]) {
          setform_value({
            ...form_value,
            [bmi_id]: bmi_value,
            [event.target.name]: event.target.value,
          });
        } else {
          setform_value({
            ...form_value,
            [bmi_id]: bmi_value,
            [event.target.name]: event.target.value,
            [radio_id]: "metric",
          });
        }
      }
    }

    if (type === "radio") {
      let height_ = form_value[height_id];
      let weight_ = form_value[weight_id];

      if (event.target.value === "standard") {
        height_ ? (height_ = height_ / 2.54) : (height_ = height_);
        weight_ ? (weight_ = weight_ * 2.205) : (weight_ = weight_);
      }
      if (event.target.value === "metric") {
        height_ ? (height_ = height_ * 2.54) : (height_ = height_);
        weight_ ? (weight_ = weight_ / 2.205) : (weight_ = weight_);
      }

      setform_value({
        ...form_value,
        [id]: event.target.value,
        [height_id]: height_,
        [weight_id]: weight_,
      });
    }
  };

  const ON_change_inputfield_drug_accountability = async (
    event,
    type,
    id,
    subtype,
    parent,
    country_default,
    bmi_parameter
  ) => {
    sethas_unsaved_data(true);
    let third_parameter;
    let Compliance_Percentage_value;
    if (bmi_parameter.Type === "drug_accountability") {
      let number_pills_id;
      let expected_number_pills_id;
      let Compliance_Percentage_id;

      if (bmi_parameter) {
        number_pills_id = bmi_parameter.Children[3].UUID;
        expected_number_pills_id = bmi_parameter.Children[4].UUID;
        Compliance_Percentage_id = bmi_parameter.Children[5].UUID;
      }

      if (type === "inputfield") {
        if (event.target.name === number_pills_id) {
          third_parameter = "pillnumber";
        }
        if (event.target.name === expected_number_pills_id) {
          third_parameter = "pillnumber_expected";
        }

        if (third_parameter === "pillnumber") {
          Compliance_Percentage_value =
            (event.target.value / form_value[expected_number_pills_id]) * 100;
        }
        if (third_parameter === "pillnumber_expected") {
          Compliance_Percentage_value =
            (form_value[number_pills_id] / event.target.value) * 100;
        }
        setform_value({
          ...form_value,
          [event.target.name]: event.target.value,
          [Compliance_Percentage_id]: Compliance_Percentage_value,
        });
      }
    }

    if (bmi_parameter.Type === "48600151-6d5f-46df-8b40-159bacbb8394") {
      let number_cigrate_id;
      let number_cigrate_year_id;
      let number_cigrate_result_id;

      if (bmi_parameter) {
        number_cigrate_id = bmi_parameter.Children[5].UUID;
        number_cigrate_year_id = bmi_parameter.Children[6].UUID;
        number_cigrate_result_id = bmi_parameter.Children[7].UUID;
      }

      if (type === "inputfield") {
        if (event.target.name === number_cigrate_id) {
          third_parameter = "no_of_cigrate";
        }
        if (event.target.name === number_cigrate_year_id) {
          third_parameter = "no_of_years";
        }

        if (third_parameter === "no_of_cigrate") {
          Compliance_Percentage_value =
            (event.target.value / 20) * form_value[number_cigrate_year_id];
        }
        if (third_parameter === "no_of_years") {
          Compliance_Percentage_value =
            (form_value[number_cigrate_id] / 20) * event.target.value;
        }
        setform_value({
          ...form_value,
          [event.target.name]: event.target.value,
          [number_cigrate_result_id]: Compliance_Percentage_value,
        });
      }
    }
  };

  const YesNo_clicked = (uid, Ans, parent, rid) => {
    sethas_unsaved_data(true);
    if (parent) {
      let commonId = rid ? rid : form_value[parent]?.[0];
      if (commonId) {
        setform_value({
          ...form_value,
          [uid]: { ...form_value[uid], [commonId]: Ans },
        });
      } else {
        setform_value({ ...form_value, [uid]: Ans });
      }
    } else {
      setform_value({ ...form_value, [uid]: Ans });
    }
  };

  useEffect(() => {
    let SiteID_local_ = queryParams.get("SiteID_local_");
    Get_ctms_userInfo(SiteID_local_)
      .then((data) => {
        getFormStatus(data.data);
        setupdata_forapi(data.data);
      })
      .catch((err) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupdata_forapi = (data_recv) => {
    let CTMSHostLink_ = queryParams.get("CTMSHostLink_");
    let FormID_ = queryParams.get("FormID_");
    localStorage.setItem("CTMSUsername", data_recv.CTMSUsername);
    localStorage.setItem("CTMSPassword", data_recv?.CTMSPassword);
    const data = {
      CTMSUsername: data_recv.CTMSUsername,
      CTMSPassword: data_recv.CTMSPassword,
      CTMSHostLink: "https://" + CTMSHostLink_,
    };
    setdropdown_login_info(data);
    localStorage.setItem("dropdown_login_info", JSON.stringify(data));

    Get_sessionkey(FormID_, 1, data);
  };

  const Esign_clicked = (children_elements, rid) => {
    document.getElementById("kt_body").classList.remove("overflow-scroll");
    setesign_popup_status(true);
    setcurrent_data_object(children_elements);
    setcurrent_row_id(rid);
  };

  useEffect(() => {
    const sidemenu = document.getElementById("kt_aside");
    const topheader = document.getElementById("kt_header");
    const footerclass = document.getElementById("kt_footer");

    const sidepadding = document.getElementById("kt_wrapper");
    const topheader_mobile = document.getElementById("kt_header_mobile");
    const mobile_subheader = document.getElementById("mobile_subheader");

    let is_mob = queryParams.get("is_mob");

    if (sidemenu && is_mob == "true") {
      sidemenu.classList.add("hidefooter");
      topheader.classList.add("hidefooter");

      sidepadding.classList.add("form-padding");
      sidepadding.classList.add("top-form-padding");
      footerclass.classList.add("full-width-footer");
      topheader_mobile.classList.add("hidefooter");
      if (mobile_subheader) {
        mobile_subheader.classList.add("hidefooter");
      }
    }
    return () => {
      if (sidemenu && is_mob == "true") {
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onclick on cross icon to clear data
  const Clear_data = (uuid, parent, type, rid, hashkey) => {
    let commonId;
    if (parent) {
      commonId = rid ? rid : form_value[parent]?.[0];

      if (commonId) {
        if (type === "itsfile") {
          get_ctms_token()
            .then((value) => {
              DeleteFiletype(value.data, hashkey)
                .then((data) => {
                  let filteredArr = form_value[uuid][commonId]?.Files.filter(
                    (file) => file.Hash != hashkey
                  );

                  setform_value({
                    ...form_value,
                    [uuid]: {
                      ...form_value[uuid],
                      [commonId]: { Files: filteredArr },
                    },
                  });
                })
                .then(() => {
                  setsave_changes_button_status(true);
                  let formval = JSON.parse(
                    sessionStorage.getItem("form_value")
                  );
                  Save_changes_form_api(value.data, formval)
                    .then((data) => {
                      setsave_changes_button_status(false);
                    })
                    .catch((err) => { });
                });
            })
            .catch((err) => { });
        }
      }
    } else {
      if (type === "itsfile") {
        get_ctms_token()
          .then((value) => {
            DeleteFiletype(value.data, hashkey)
              .then((data) => {
                let filteredArr = form_value[uuid]?.Files.filter(
                  (file) => file.Hash != hashkey
                );

                setform_value({
                  ...form_value,
                  [uuid]: { Files: filteredArr },
                });
              })
              .then(() => {
                setsave_changes_button_status(true);
                let formval = JSON.parse(sessionStorage.getItem("form_value"));
                Save_changes_form_api(value.data, formval)
                  .then((data) => {
                    setsave_changes_button_status(false);
                  })
                  .catch((err) => { });
              });
          })
          .catch((err) => { });
      }
    }

    if (type !== "itsfile") {
      sethas_unsaved_data(true);
      if (parent) {
        commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [uuid]: { ...form_value[uuid], [commonId]: "" },
          });
        } else {
          setform_value({ ...form_value, [uuid]: "" });
        }
      } else {
        setform_value({ ...form_value, [uuid]: "" });
      }
    }
  };

  const Clear_data_for_dates = (local_id, id, parent, type, rid) => {
    sethas_unsaved_data(true);
    if (type === "dob") {
      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [local_id]: { ...form_value[local_id], [commonId]: "" },
            [id]: { ...form_value[id], [commonId]: "" },
          });
        } else {
          setform_value({ ...form_value, [local_id]: "", [id]: "" });
        }
      } else {
        setform_value({ ...form_value, [local_id]: "", [id]: "" });
      }
    }
  };

  const Clear_data_for_date_and_time = (event, id, parent_, type, rid) => {
    sethas_unsaved_data(true);
    const parent = parent_.Parent;

    if (type === "date_time") {
      if (parent) {
        let commonId = rid ? rid : form_value[parent]?.[0];
        if (commonId) {
          setform_value({
            ...form_value,
            [parent_.Children[0].UUID]: {
              ...form_value[parent_.Children[0].UUID],
              [commonId]: "",
            },
            [parent_.Children[1].UUID]: {
              ...form_value[parent_.Children[1].UUID],
              [commonId]: "",
            },
            [id]: { ...form_value[id], [commonId]: "" },
          });
        } else {
          setform_value({
            ...form_value,
            [parent_.Children[0].UUID]: "",
            [parent_.Children[1].UUID]: "",
            [id]: "",
          });
        }
      } else {
        setform_value({
          ...form_value,
          [parent_.Children[0].UUID]: "",
          [parent_.Children[1].UUID]: "",
          [id]: "",
        });
      }
    }
    if (type === "Ongoing") {
      let full_value_of_field;
      let Upadted_full_value_of;

      let commonId = rid ? rid : form_value[parent]?.[0];

      if (commonId) {
        if (form_value[id]?.[commonId]) {
          full_value_of_field = form_value[id]?.[commonId];

          //checking whether check box is checked or not
          if (event.target.checked) {
            Upadted_full_value_of = full_value_of_field + "/" + 1;
          } else {
            let split_value = full_value_of_field.split("/");
            Upadted_full_value_of =
              split_value[0] +
              "/" +
              split_value[1] +
              "/" +
              split_value[2] +
              "/" +
              split_value[3] +
              "/" +
              split_value[4];
          }
        } else {
          event.target.checked
            ? (Upadted_full_value_of = "00/00/0000/-1/-1/1")
            : (Upadted_full_value_of = "00/00/0000/-1/-1");
        }
      } else {
        if (form_value[id]) {
          full_value_of_field = form_value[id];

          if (event.target.checked) {
            Upadted_full_value_of = full_value_of_field + "/" + 1;
          } else {
            let split_value = full_value_of_field.split("/");
            Upadted_full_value_of =
              split_value[0] +
              "/" +
              split_value[1] +
              "/" +
              split_value[2] +
              "/" +
              split_value[3] +
              "/" +
              split_value[4];
          }
        } else {
          event.target.checked
            ? (Upadted_full_value_of = "00/00/0000/-1/-1/1")
            : (Upadted_full_value_of = "00/00/0000/-1/-1");
        }
      }

      if (parent) {
        if (commonId) {
          setform_value({
            ...form_value,
            [id]: { ...form_value[id], [commonId]: Upadted_full_value_of },
          });
        } else {
          setform_value({ ...form_value, [id]: Upadted_full_value_of });
        }
      } else {
        setform_value({ ...form_value, [id]: Upadted_full_value_of });
      }
    }
  };

  const Auto_fill_value = (id, parent_, type, rid) => {
    sethas_unsaved_data(true);
    const parent = parent_.Parent;
    let current_date = new Date();
    let day_ = ("0" + current_date.getDate()).slice(-2);
    let month_ = ("0" + (current_date.getMonth() + 1)).slice(-2);
    let year_ = current_date.getFullYear();
    let hours_ = current_date.getHours();
    let minit_ = current_date.getMinutes();
    let time_digitone_ = hours_;
    let time_digittwo_ = minit_ < 10 ? "0" + minit_ : minit_;
    let hours_for_localtime = hours_ < 10 ? "0" + hours_ : hours_;
    let minit_for_localtime = minit_ < 10 ? "0" + minit_ : minit_;
    let month_for_localtime = month_;
    month_ = month_ - 1;

    let conv_date =
      day_ +
      "/" +
      month_ +
      "/" +
      year_ +
      "/" +
      time_digitone_ +
      "/" +
      time_digittwo_;
    let conv_date_single_field = year_ + "-" + month_for_localtime + "-" + day_;

    let conv_time_single_field =
      hours_for_localtime + ":" + minit_for_localtime;

    if (type === "date_time") {
      let commonId = rid ? rid : form_value[parent]?.[0];

      if (parent) {
        if (commonId) {
          setform_value({
            ...form_value,
            [parent_.Children[0].UUID]: {
              ...form_value[parent_.Children[0].UUID],
              [commonId]: conv_date_single_field,
            },
            [parent_.Children[1].UUID]: {
              ...form_value[parent_.Children[1].UUID],
              [commonId]: conv_time_single_field,
            },
            [id]: { ...form_value[id], [commonId]: conv_date },
          });
        } else {
          setform_value({
            ...form_value,
            [parent_.Children[0].UUID]: conv_date_single_field,
            [parent_.Children[1].UUID]: conv_time_single_field,
            [id]: conv_date,
          });
        }
      } else {
        setform_value({
          ...form_value,
          [parent_.Children[0].UUID]: conv_date_single_field,
          [parent_.Children[1].UUID]: conv_time_single_field,
          [id]: conv_date,
        });
      }
    }
  };

  // camera method
  const camera_button_clicked = (val, rid, index_id) => {
    setindex_for_loader(index_id);

    // Checking for camera permission
    navigator.mediaDevices
      .getUserMedia({ video: { width: 600 } })
      .then(() => {
        getVideo();
        setOpen_camera(true);
        setImageURL(null);
        setcurrent_data_object(val);
        setcurrent_row_id(rid);
        document.getElementById("kt_body").classList.remove("overflow-scroll");
      })
      .catch((err) => {
        setcamera_permission_error(true);
        setcamera_permission_message("Camera permission is required !");
      });
  };
  // clear data for radio fields
  const clear_fields = (id, rid) => {
    sethas_unsaved_data(true);

    if (rid) {
      setform_value({ ...form_value, [id]: { ...form_value[id], [rid]: "" } });
    } else {
      setform_value({ ...form_value, [id]: "" });
    }
  };

  // 10:02:2022 -  required field validation
  // setting required fields array
  useEffect(() => {
    let requi_fields = [];
    pages.map((page) => {
      page.elements.map((elem) => {
        if (elem.Type == "date_of_birth" && elem.Meta.Required == true) {
          elem.Children.map((child) => {
            if (child.Children) {
              child.Children.map((grandChild) => {
                if (grandChild.Type == "date") {
                  requi_fields.push(grandChild.UUID);
                }
              });
            }
          });
        } else if (elem.Type == "date_time" && elem.Meta.Required == true) {
          elem.Children.map((child) => {
            requi_fields.push(child.UUID);
          });
        } else if (elem.Children) {
          elem.Children.map((child) => {
            if (child.Type == "date_of_birth" && child.Meta.Required == true) {
              child.Children.map((grandChild) => {
                if (grandChild.Children) {
                  grandChild.Children.map((data) => {
                    if (data.Type == "date") {
                      requi_fields.push(data.UUID);
                    }
                  });
                }
              });
            } else if (
              child.Type == "date_time" &&
              child.Meta.Required == true
            ) {
              child.Children.map((grandChild) => {
                requi_fields.push(grandChild.UUID);
              });
            } else if (child.Children) {
              child.Children.map((grandChild) => {
                if (
                  grandChild.Type == "date_of_birth" &&
                  grandChild.Meta.Required == true
                ) {
                  grandChild.Children.map((data) => {
                    if (data.Children) {
                      data.Children.map((data_) => {
                        if (data_.Type == "date") {
                          requi_fields.push(data_.UUID);
                        }
                      });
                    }
                  });
                } else if (
                  grandChild.Type == "date_time" &&
                  grandChild.Meta.Required == true
                ) {
                  grandChild.Children.map((data) => {
                    requi_fields.push(data.UUID);
                  });
                } else if (grandChild.Children) {
                  grandChild.Children.map((data) => {
                    if (data.Meta.Required == true) {
                      requi_fields.push(data.UUID);
                    }
                  });
                } else if (grandChild.Meta.Required == true) {
                  requi_fields.push(grandChild.UUID);
                }
              });
            } else if (child.Meta.Required == true) {
              requi_fields.push(child.UUID);
            }
          });
        } else if (elem.Meta.Required == true) {
          requi_fields.push(elem.UUID);
        }
      });
    });
    setrequired_fields(requi_fields);
  }, [pages]);

  // return true if all fields are filled
  const check_required_fields = () => {
    let elemIds = Object.keys(form_value);
    let emptyFields = [];

    required_fields.map((fieldId) => {
      if (elemIds.includes(fieldId)) {
        if (
          form_value[fieldId].constructor == String &&
          form_value[fieldId] == ""
        ) {
          emptyFields.push(fieldId);
        }
        if (
          form_value[fieldId].constructor == Array &&
          form_value[fieldId].length == 0
        ) {
          emptyFields.push(fieldId);
        }
        if (form_value[fieldId].constructor == Object) {
          if (Object.keys(form_value[fieldId]).length == 0) {
            emptyFields.push(fieldId);
          } else {
            Object.keys(form_value[fieldId]).map((parentId) => {
              if (form_value[fieldId][parentId] == " ") {
                emptyFields.push(fieldId);
              }
            });
          }
        }
      } else {
        emptyFields.push(fieldId);
      }
    });
    return emptyFields.length == 0;
  };

  // rough code
  useEffect(() => {
    sessionStorage.setItem("form_value", JSON.stringify(form_value));
  }, [form_value]);

  // GP: this function get the form status on form load
  const getFormStatus = (data) => {
    let formid = queryParams.get("FormID_");
    let CTMSHostLink_ = queryParams.get("CTMSHostLink_");

    const drop_info = {
      CTMSUsername: data.CTMSUsername,
      CTMSPassword: data.CTMSPassword,
      CTMSHostLink: "https://" + CTMSHostLink_,
    };

    get_ctms_token()
      .then((value) => {
        get_form_status(value.data, drop_info, formid)
          .then((data) => {
            if (data.data?.records?.length > 0) {
              if (data.data.records[0].Completed == 1) {
                setform_status2(true);
              } else {
                setform_status2(false);
              }
            } else {
              setform_status2(false);
            }
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  useEffect(() => {
    sessionStorage.setItem("open_camera", Open_camera);
  }, [Open_camera]);

  useEffect(() => {
    sessionStorage.setItem("form_status", form_status2);
  }, [form_status2]);

  // Save Function
  const save_form_data = () => {
    let op_camera = sessionStorage.getItem("open_camera");
    let frm_status = sessionStorage.getItem("form_status");
    let another_session = sessionStorage.getItem("form_another_session");

    if (
      frm_status == "false" &&
      op_camera == "false" &&
      another_session == "false" &&
      sessionStorage.getItem("is_admin_control") !== "true"
    ) {
      setsave_changes_button_status(true);
      get_ctms_token()
        .then((value) => {
          let formval = JSON.parse(sessionStorage.getItem("form_value"));
          Save_changes_form_api(value.data, formval)
            .then((data) => {
              sethas_unsaved_data(false);
              setsave_changes_button_status(false);
            })
            .catch((err) => { });
        })
        .catch((err) => { });
    }
  };

  // GP: save function called by interval of 60sec to save form values
  useEffect(() => {
    saveIntervalRef.current = setInterval(() => {
      save_form_data();
    }, 60000);

    return () => {
      clearInterval(saveIntervalRef.current);
      save_form_data();
    };
  }, []);

  //  Save page number to the localstorage
  const save_page_number = (page_number) => {
    let form_id = queryParams.get("ID_");
    let prev_data = JSON.parse(localStorage.getItem("PageNumbers"));

    if (prev_data == null) {
      let data = [{ [form_id]: { pageNum: page_number } }];
      localStorage.setItem("PageNumbers", JSON.stringify(data));
    } else {
      let new_data = [{ ...prev_data[0], [form_id]: { pageNum: page_number } }];
      localStorage.setItem("PageNumbers", JSON.stringify([...new_data]));
    }
  };

  // Saving the form before closing tab
  useEffect(() => {
    const tidyUp = (event) => {
      save_form_data();
      unlock_session_by_window_id();
    };

    window.addEventListener("pagehide", tidyUp);
    window.addEventListener("beforeunload", tidyUp);
    return () => {
      window.removeEventListener("pagehide", tidyUp);
      window.removeEventListener("beforeunload", tidyUp);
    };
  }, []);

  // GP: This function Open / Download uploaded file
  const open_uploaded_file = (hash, mime, name) => {
    get_ctms_token()
      .then((value) => {
        Get_saved_images(value?.data, hash)
          .then((data) => {
            var blob = new Blob([data.data], { type: mime });
            var uriLink = window.URL.createObjectURL(blob);

            if (
              mime?.split("/")[0] === "image" ||
              mime?.split("/")[1] === "pdf"
            ) {
              window.open(uriLink);
            } else {
              const link_ = document.createElement("a");
              link_.href = uriLink;
              link_.download = name;
              link_.target = "_blank";
              document.body.appendChild(link_);
              setTimeout(() => {
                link_.click();
                document.body.removeChild(link_);
              }, 500);
            }
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  // Asking to save form before reload or closing tab if new data is added or not saved
  useEffect(() => {
    const beforeunload_handler = (e) => {
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave without saving form?";
      return "Are you sure you want to leave without saving form?";
    };

    window.addEventListener("beforeunload", beforeunload_handler);

    return () => {
      window.removeEventListener("beforeunload", beforeunload_handler);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("current_page_id", current_pages_data?.[0]?.UUID);

    let cur_page_uuid = sessionStorage.getItem("current_page_id")
    if (cur_page_uuid != "undefined") {
      sessionStorage.setItem("is_page_changed", "true")
      close_all_other_sessions()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_pages_data]);

  useEffect(() => {
    sessionStorage.setItem("form_another_session", form_another_session);
  }, [form_another_session]);

  useEffect(() => {
    let old_window_id = sessionStorage.getItem("window_id");

    if (old_window_id == null) {
      sessionStorage.setItem("window_id", uuidv4());
    } else {
      sessionStorage.setItem("window_id", old_window_id);
    }

    return () => {
      unlock_session_by_window_id();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GP: Checking the Form session status (Lock/Unlock)
  const check_esource_form_status = () => {
    get_ctms_token()
      .then((value) => {
        let formid = queryParams.get("FormID_");
        Esource_form_status(value.data, formid)
          .then((data) => {
            if (data.data.records.length > 0) {
              let session_id;
              data.data.records.map((rec) => {
                let pageid = sessionStorage.getItem("current_page_id");
                if (rec?.PageID === pageid) {
                  session_id = rec?.ID;
                }
              });

              if (session_id != undefined) {
                sessionStorage.setItem("session_id", session_id);
                get_session_data_by_id(value.data, session_id).then((data) => {
                  if (data.data.record != null) {
                    let utc_session_date =
                      data.data.record?.DateUpdated.replace(" ", "T") + ".000Z";
                    let date_updated_record = {
                      ...data.data.record,
                      DateUpdated: utc_session_date,
                    };
                    let wid = sessionStorage.getItem("window_id");

                    sessionStorage.setItem(
                      "session_window",
                      date_updated_record?.WindowID
                    );
                    if (
                      date_updated_record?.Active == "1" &&
                      wid == date_updated_record?.WindowID
                    ) {
                      setform_another_session(false);
                    } else {
                      let isPageChanged = sessionStorage.getItem("is_page_changed")
                      if (isPageChanged == "false") {
                        setform_another_session(true);
                      }
                    }
                    setform_session_data([date_updated_record]);
                  }
                });
              } else {
                let pageid = sessionStorage.getItem("current_page_id");
                check_esource_page_status(pageid);
                setform_another_session(false);
              }
            } else {
              let pageid = sessionStorage.getItem("current_page_id");
              check_esource_page_status(pageid);
              setform_another_session(false);
            }
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  // GP: Checking the page session status (Lock/Unlock)
  const check_esource_page_status = (page_id) => {
    if (page_id == "undefined") return;

    get_ctms_token()
      .then((value) => {
        let windowid = sessionStorage.getItem("window_id");
        Esource_page_status(value.data, page_id, windowid)
          .then((data) => {
            setform_another_session(false);
          })
          .catch((err) => { }).finally(() => {
            sessionStorage.setItem("is_page_changed", "false")
          })
      })
      .catch((err) => { });
  };

  //GP: Checking the Form session status After every 10 sec
  useEffect(() => {
    statusCheckIntervalRef.current = setInterval(() => {
      let op_camera = sessionStorage.getItem("open_camera");

      if (op_camera == "false") {
        check_esource_form_status();
      }
    }, 10000);

    return () => {
      clearInterval(statusCheckIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GP: Function to close the other sessions
  const close_all_other_sessions = () => {
    get_ctms_token().then((value) => {
      let pageid = sessionStorage.getItem("current_page_id");
      Unlock_esource_form(value.data).then((data) => {
        setform_another_session(false);
        check_esource_page_status(pageid)
      });
    });
  };

  // GP: Function to close the session by window id
  const unlock_session_by_window_id = () => {
    get_ctms_token().then((value) => {
      let windowid = sessionStorage.getItem("window_id");
      let session_wid = sessionStorage.getItem("session_window");
      let sid = sessionStorage.getItem("session_id");

      if (windowid == session_wid) {
        unlock_by_window_id(value.data, sid, windowid);
      }
    });
  };


  return (
    <main className={`my-form-wrapper ${form_status2 ? "disable-field" : ""}  ${queryParams.get("is_mob") === "true" ? "mobile-view" : ""}`}>
      <Prompt
        when={false}
        message="Are you sure you want to leave without saving form?"
      />
      <Common_popup_esign
        handleCloses_Esign_submit={handleCloses_Esign_submit}
        esign_popup_status={esign_popup_status}
        handleCloses_Esign={handleCloses_Esign}
        dropdown_login_info={dropdown_login_info}
      />{!Open_camera &&
        <div>
          <form className="bg-color">
            {loader_status && (
              <div style={{ textAlign: "center" }}>
                <Spinner
                  animation="border"
                  className="spinner-info"
                  variant="primary"
                />
              </div>
            )}

            {nodata_status && loader_status === false && (
              <div className="no-form d ">
                <img
                  src="/media/misc/NO-form.svg"
                  className="form-icon"
                  alt="Form"
                />
                <p className="mt-2">No preview available</p>
              </div>
            )}
            <>
              {!nodata_status && (
                <div className="form-content-wrap">
                  <div className="header-wrap">
                    {is_mob === "false" && (
                      <button
                        onClick={() => {
                          history.push("/MyForm");
                        }}
                        type="button"
                        className="back-btn"
                      >
                        {" "}
                        <img
                          src="/media/misc/arrow_left_icon.svg"
                          alt="Left"
                        />{" "}
                      </button>
                    )}
                    <h4 className="form-header">
                      {forms?.Title ? forms?.Title : "Form Title"}
                    </h4>

                    {/* <span className='text-white'>{Date_convert(dateCreated, 5)}</span> */}
                    <span className="text-white">
                      {dateCreated !== "null" ? Date_convert(dateCreated, 8) : "NA"}
                    </span>
                  </div>

                  {/* form start */}
                  <div
                    className={`form_inner ${form_another_session ? "form_inner_session" : ""}`}
                  >
                    <div
                      style={{
                        pointerEvents:
                          form_status2 || form_another_session || sessionStorage.getItem("is_admin_control") === "true" ? "none" : "all",
                      }}
                    >
                      {
                        <div>
                          {spinner_status ? (
                            <Spinner
                              className="spinner-wrap"
                              animation="border"
                              variant="primary"
                            />
                          ) : (
                            current_pages_data?.map((data, page_data_index) => {
                              return [
                                <React.Fragment key={page_data_index}>
                                  <div className="form_title">{data?.Title}</div>
                                  <div>
                                    {data?.elements?.map(
                                      (nested_data, main_index) => {
                                        return [
                                          <React.Fragment key={main_index}>
                                            {/* template start  */}
                                            {nested_data.Type ===
                                              "eb570309-a56a-409d-b1d9-0244017cf09c" ||
                                              nested_data.Type ===
                                              "3d20aa97-1cba-46c4-9e32-32007c448d22" ||
                                              nested_data.Type ===
                                              "66ed03ba-5198-41be-bc1d-3a17968fb866" ||
                                              nested_data.Type === "adverse_event" ||
                                              nested_data.Type ===
                                              "4bb0e0dd-74f0-44cc-8ff9-e21c349ba62f" ||
                                              nested_data.Type ===
                                              "3bfabb7c-39ea-4b4e-a413-783e5e941b08" ||
                                              nested_data.Type ===
                                              "d1ea0dbf-0fa8-4816-bfc5-a18feaf65cce" ||
                                              nested_data.Type ===
                                              "1ea9add6-8c9a-451c-9f21-d7f54b01ef0a" ||
                                              nested_data.Type ===
                                              "8e3c3b5a-e5b9-4f7e-b4cf-8654b6a3f1e6" ||
                                              nested_data.Type === "demographics" ||
                                              nested_data.Type ===
                                              "4debf9cc-9e14-4cda-9b6d-4888b873b0df" ||
                                              nested_data.Type ===
                                              "e5d0c857-62f5-442e-b2d6-396678a13aaf" ||
                                              nested_data.Type ===
                                              "6dff1e37-735a-410a-b75a-5abd21779afa" ||
                                              nested_data.Type ===
                                              "drug_accountability" ||
                                              nested_data.Type ===
                                              "0c42347b-1cc1-4c94-aa69-a1e7a24a026b" ||
                                              nested_data.Type ===
                                              "drug_dispensation" ||
                                              nested_data.Type ===
                                              "151c5489-468b-454f-a5dd-1993b3da13d9" ||
                                              nested_data.Type ===
                                              "502d4ed6-817f-4640-8012-4967b0bc7121" ||
                                              nested_data.Type ===
                                              "6acab0f2-bc83-4331-840b-ee83dbb86681" ||
                                              nested_data.Type === "ecg" ||
                                              nested_data.Type ===
                                              "1fcafa34-4dc6-429e-9f46-ffeef9a1207f" ||
                                              nested_data.Type ===
                                              "3b9cd8fc-eb67-4292-b8cf-b800a2b1595e" ||
                                              nested_data.Type ===
                                              "81b75536-be9e-40ba-be30-faa98767f235" ||
                                              nested_data.Type ===
                                              "fd4f1544-aaef-49f3-b968-b27efc0c84e9" ||
                                              nested_data.Type ===
                                              "21368277-acf1-453d-8e1c-828a7968e351" ||
                                              nested_data.Type ===
                                              "medical_history_ctms" ||
                                              nested_data.Type ===
                                              "90ef7eaa-0ab9-4f5e-9ec8-3272d1de5ac1" ||
                                              nested_data.Type ===
                                              "3683b6d9-267a-4a32-be72-0be32a486fc5" ||
                                              nested_data.Type ===
                                              "036fde33-bfdb-4ac8-bab0-79850cb6cd10" ||
                                              nested_data.Type ===
                                              "b1435e91-a6dd-4847-8d48-881a6662c493" ||
                                              nested_data.Type ===
                                              "915ad76e-89a9-4026-822d-f4112f9ebcce" ||
                                              nested_data.Type ===
                                              "010d7395-d187-45e3-b87b-14bba23279c2" ||
                                              nested_data.Type ===
                                              "ebd5ed33-1858-4ef3-84ad-670d582b45a5" ||
                                              nested_data.Type ===
                                              "c8a51b61-5848-40d1-9767-ac398f351b9d" ||
                                              nested_data.Type ===
                                              "e60581c-0de8-4392-9fe1-deffad06dcef" ||
                                              nested_data.Type ===
                                              "48600151-6d5f-46df-8b40-159bacbb8394" ||
                                              nested_data.Type ===
                                              "0ba3347c-c9e3-467a-8ca6-ff5791e5f9dc" ||
                                              nested_data.Type ===
                                              "820d1fb7-10b4-44e7-8ef6-ac038e38f1d1" ||
                                              nested_data.Type ===
                                              "fdb88fa0-8a62-4bb8-ba15-ac21adfd99a2" ||
                                              nested_data.Type ===
                                              "3eba126e-f7e7-4271-8e05-a6313c9a1b9d" ||
                                              nested_data.Type ===
                                              "urine_pregnancy_test" ||
                                              nested_data.Type ===
                                              "6a5e6911-39b2-4a5b-b776-a73f0a53fa2f" ||
                                              nested_data.Type === "vital_signs" ||
                                              nested_data.Type ===
                                              "9e8bca02-31ea-40d1-828f-95c168d6a0ea" ||
                                              nested_data.Type ===
                                              "d6af280c-8ac6-46df-ac26-b8dce0583bf9" ||
                                              nested_data.Type ===
                                              "7e60581c-0de8-4392-9fe1-deffad06dcef" ? (
                                              <div className="main_container">
                                                <FormTemplate
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                >
                                                  <div className="width">
                                                    {nested_data?.Children?.map(
                                                      (
                                                        children_elements,
                                                        index
                                                      ) => {
                                                        return [
                                                          <React.Fragment
                                                            key={index}
                                                          >
                                                            {children_elements.Type ===
                                                              "form_section" ? (
                                                              <>
                                                                <Form_section
                                                                  lable_={
                                                                    children_elements
                                                                      .Meta.Heading
                                                                      .blocks[0]
                                                                      .text
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {children_elements.Type ===
                                                              "yes_no" ? (
                                                              <div className="main_container mt-5">
                                                                <Yes_noType
                                                                  Clear_data={
                                                                    Clear_data
                                                                  }
                                                                  YesNo_clicked={
                                                                    YesNo_clicked
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {children_elements.Type ===
                                                              "text" ? (
                                                              <div className="input_text">
                                                                <Texttype
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {/* checklist start */}
                                                            {children_elements.Type ===
                                                              "checklist" ? (
                                                              <div className="main_container">
                                                                <Checklist
                                                                  clear_fields={clear_fields}
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* date_time start */}
                                                            {children_elements.Type ===
                                                              "date_time" ? (
                                                              <div className="main_container">
                                                                <Date_time_type
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  current_date_={
                                                                    current_date_
                                                                  }
                                                                  Clear_data_for_date_and_time={
                                                                    Clear_data_for_date_and_time
                                                                  }
                                                                  ON_blur_inputfield={
                                                                    ON_blur_inputfield
                                                                  }
                                                                  Auto_fill_value={
                                                                    Auto_fill_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {children_elements.Type ===
                                                              "electronic_signature" ? (
                                                              <div className="main_container">
                                                                <EsignType
                                                                  Esign_clicked={
                                                                    Esign_clicked
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  Clear_data={
                                                                    Clear_data
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* we are seprating numeric field for drug_accountability */}
                                                            {nested_data.Type ===
                                                              "drug_accountability" ? (
                                                              <>
                                                                {" "}
                                                                {children_elements.Type ===
                                                                  "numeric" ? (
                                                                  <div className="main_container">
                                                                    <NumericType
                                                                      ON_change_inputfield={
                                                                        ON_change_inputfield_drug_accountability
                                                                      }
                                                                      children_elements={
                                                                        children_elements
                                                                      }
                                                                      form_value={
                                                                        form_value
                                                                      }
                                                                      main_comp={
                                                                        nested_data
                                                                      }
                                                                      index={index}
                                                                      ON_blur_inputfield={
                                                                        ON_blur_inputfield
                                                                      }
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {" "}
                                                                {children_elements.Type ===
                                                                  "numeric" ? (
                                                                  <div className="main_container">
                                                                    <NumericType
                                                                      ON_change_inputfield={
                                                                        ON_change_inputfield
                                                                      }
                                                                      children_elements={
                                                                        children_elements
                                                                      }
                                                                      form_value={
                                                                        form_value
                                                                      }
                                                                      ON_blur_inputfield={
                                                                        ON_blur_inputfield
                                                                      }
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )}
                                                            {/* we are seprating numeric field for smoking history */}
                                                            {nested_data.Type ===
                                                              "48600151-6d5f-46df-8b40-159bacbb8394" ? (
                                                              <>
                                                                {" "}
                                                                {children_elements.Type ===
                                                                  "text_single_line" ? (
                                                                  <div className="main_container">
                                                                    <Text_singleline
                                                                      ON_change_inputfield={
                                                                        ON_change_inputfield_drug_accountability
                                                                      }
                                                                      children_elements={
                                                                        children_elements
                                                                      }
                                                                      form_value={
                                                                        form_value
                                                                      }
                                                                      main_comp={
                                                                        nested_data
                                                                      }
                                                                      index={index}
                                                                      ON_blur_inputfield={
                                                                        ON_blur_inputfield
                                                                      }
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {" "}
                                                                {children_elements.Type ===
                                                                  "text_single_line" ? (
                                                                  <div className="main_container">
                                                                    <Text_singleline
                                                                      ON_change_inputfield={
                                                                        ON_change_inputfield
                                                                      }
                                                                      children_elements={
                                                                        children_elements
                                                                      }
                                                                      form_value={
                                                                        form_value
                                                                      }
                                                                      ON_blur_inputfield={
                                                                        ON_blur_inputfield
                                                                      }
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )}

                                                            {children_elements.Type ===
                                                              "radio_group" ? (
                                                              <div className="main_container">
                                                                <Radiogroup
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  clear_fields={
                                                                    clear_fields
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {children_elements.Type ===
                                                              "text_multi_line" ? (
                                                              <div className="main_container">
                                                                <Text_multiline
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  ON_blur_inputfield={
                                                                    ON_blur_inputfield
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {children_elements.Type ===
                                                              "file_manager" ? (
                                                              <div className="main_container">
                                                                <FileMangertype
                                                                  open_uploaded_file={
                                                                    open_uploaded_file
                                                                  }
                                                                  index_for_loader={
                                                                    index_for_loader
                                                                  }
                                                                  loading_status={
                                                                    loading_status
                                                                  }
                                                                  index={index}
                                                                  Clear_data={
                                                                    Clear_data
                                                                  }
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {children_elements.Type ===
                                                              "dropdown" ? (
                                                              <div className="input_text">
                                                                <Dropdowntype
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  clear_fields={clear_fields}
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {children_elements.Type ===
                                                              "divider" ? (
                                                              <div className="main_container">
                                                                {/* <hr /> */}
                                                                <DividerType
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {children_elements.Type ===
                                                              "date_of_birth" ? (
                                                              <div className="main_container">
                                                                <Date_of_birth
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  current_date_={
                                                                    current_date_
                                                                  }
                                                                  ON_blur_inputfield={
                                                                    ON_blur_inputfield
                                                                  }
                                                                  Clear_data_for_dates={
                                                                    Clear_data_for_dates
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {/* type address */}
                                                            {children_elements.Type ===
                                                              "address" ? (
                                                              <div className="main_container">
                                                                <Addresstype
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  country_list={
                                                                    country_list
                                                                  }
                                                                  ON_blur_inputfield={
                                                                    ON_blur_inputfield
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* type address end*/}
                                                            {/* resource_manager start */}
                                                            <div>
                                                              {children_elements.Type ===
                                                                "resource_manager" ? (
                                                                <div className="main_container">
                                                                  <ResoueceManger_type
                                                                    open_uploaded_file={
                                                                      open_uploaded_file
                                                                    }
                                                                    index_for_loader={
                                                                      index_for_loader
                                                                    }
                                                                    loading_status={
                                                                      loading_status
                                                                    }
                                                                    index={index}
                                                                    Clear_data={
                                                                      Clear_data
                                                                    }
                                                                    ON_change_inputfield={
                                                                      ON_change_inputfield
                                                                    }
                                                                    children_elements={
                                                                      children_elements
                                                                    }
                                                                    form_value={
                                                                      form_value
                                                                    }
                                                                  />
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                            {/* resource_manager end */}
                                                            {/* camera start */}
                                                            {children_elements.Type ===
                                                              "camera" ? (
                                                              <div className="main_container">
                                                                <CameraType
                                                                  open_uploaded_file={
                                                                    open_uploaded_file
                                                                  }
                                                                  index_for_loader={
                                                                    index_for_loader
                                                                  }
                                                                  loading_status={
                                                                    loading_status
                                                                  }
                                                                  index={index}
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  nested_data={
                                                                    children_elements
                                                                  }
                                                                  camera_button_clicked={
                                                                    camera_button_clicked
                                                                  }
                                                                  Clear_data={
                                                                    Clear_data
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* img manager start */}
                                                            {children_elements.Type ===
                                                              "image_manager" ? (
                                                              <div className="main_container">
                                                                <ImageManager_type
                                                                  open_uploaded_file={
                                                                    open_uploaded_file
                                                                  }
                                                                  index_for_loader={
                                                                    index_for_loader
                                                                  }
                                                                  loading_status={
                                                                    loading_status
                                                                  }
                                                                  index={index}
                                                                  Clear_data={
                                                                    Clear_data
                                                                  }
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* img managar end */}
                                                            {children_elements.Type ===
                                                              "phone_number" ? (
                                                              <div className="main_container">
                                                                <PhoneType
                                                                  country_list={
                                                                    country_list
                                                                  }
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* phone end */}
                                                            {children_elements.Type ===
                                                              "full_name" ? (
                                                              <div className="main_container ">
                                                                <FullnameType
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* full name end */}
                                                            {/* email address startd */}
                                                            {children_elements.Type ===
                                                              "email_address" ? (
                                                              <div className="main_container">
                                                                <EmailType
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  ON_blur_inputfield={
                                                                    ON_blur_inputfield
                                                                  }
                                                                />
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* email address end */}

                                                            {children_elements.Type ===
                                                              "input_list" ? (
                                                              <>
                                                                <InputListType
                                                                  children_elements={
                                                                    children_elements
                                                                  }
                                                                  form_value={
                                                                    form_value
                                                                  }
                                                                  setform_value={
                                                                    setform_value
                                                                  }
                                                                  ON_change_inputfield={
                                                                    ON_change_inputfield
                                                                  }
                                                                  ON_blur_inputfield={
                                                                    ON_blur_inputfield
                                                                  }
                                                                  Esign_clicked={
                                                                    Esign_clicked
                                                                  }
                                                                  camera_button_clicked={
                                                                    camera_button_clicked
                                                                  }
                                                                  YesNo_clicked={
                                                                    YesNo_clicked
                                                                  }
                                                                  current_date_={
                                                                    current_date_
                                                                  }
                                                                  Auto_fill_value={
                                                                    Auto_fill_value
                                                                  }
                                                                  Clear_data_for_date_and_time={
                                                                    Clear_data_for_date_and_time
                                                                  }
                                                                  Clear_data_for_dates={
                                                                    Clear_data_for_dates
                                                                  }
                                                                  Clear_data={
                                                                    Clear_data
                                                                  }
                                                                  clear_fields={
                                                                    clear_fields
                                                                  }
                                                                  loading_status={
                                                                    loading_status
                                                                  }
                                                                  index_for_loader={
                                                                    index_for_loader
                                                                  }
                                                                  country_list={
                                                                    country_list
                                                                  }
                                                                  form_status={
                                                                    form_status2
                                                                  }
                                                                  open_uploaded_file={
                                                                    open_uploaded_file
                                                                  }
                                                                />
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </React.Fragment>,
                                                        ];
                                                      }
                                                    )}
                                                  </div>
                                                </FormTemplate>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* type template end */}

                                            {/* bmi test  */}
                                            {nested_data.Type === "bmi" &&
                                              nested_data.Meta.Hidden === false ? (
                                              <div className="main_container">
                                                <div className="width">
                                                  {nested_data?.Children?.map(
                                                    (children_elements, index) => {
                                                      return [
                                                        <React.Fragment key={index}>
                                                          {children_elements.Type ===
                                                            "form_section" ? (
                                                            <>
                                                              <Form_section
                                                                lable_={
                                                                  children_elements
                                                                    .Meta.Heading
                                                                    .blocks[0].text
                                                                }
                                                                children_elements={
                                                                  children_elements
                                                                }
                                                                form_value={
                                                                  form_value
                                                                }
                                                              />
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {children_elements.Type ===
                                                            "numeric" ? (
                                                            <div className="main_container mb-3">
                                                              <NumericType
                                                                ON_change_inputfield={
                                                                  ON_change_inputfield_bmi
                                                                }
                                                                children_elements={
                                                                  children_elements
                                                                }
                                                                form_value={
                                                                  form_value
                                                                }
                                                                main_comp={
                                                                  nested_data
                                                                }
                                                                index={index}
                                                                ON_blur_inputfield={
                                                                  ON_blur_inputfield
                                                                }
                                                              />
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {children_elements.Type ===
                                                            "radio_group" ? (
                                                            <div className="main_container">
                                                              <Radiogroup
                                                                ON_change_inputfield={
                                                                  ON_change_inputfield_bmi
                                                                }
                                                                children_elements={
                                                                  children_elements
                                                                }
                                                                form_value={
                                                                  form_value
                                                                }
                                                                main_comp={
                                                                  nested_data
                                                                }
                                                              />
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </React.Fragment>,
                                                      ];
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* bmi end  */}

                                            {/* input list start      */}

                                            {nested_data.Type === "input_list" ? (
                                              <>
                                                <InputListType
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  setform_value={setform_value}
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                  Esign_clicked={Esign_clicked}
                                                  camera_button_clicked={
                                                    camera_button_clicked
                                                  }
                                                  YesNo_clicked={YesNo_clicked}
                                                  current_date_={current_date_}
                                                  Auto_fill_value={Auto_fill_value}
                                                  Clear_data_for_date_and_time={
                                                    Clear_data_for_date_and_time
                                                  }
                                                  Clear_data_for_dates={
                                                    Clear_data_for_dates
                                                  }
                                                  Clear_data={Clear_data}
                                                  clear_fields={clear_fields}
                                                  loading_status={loading_status}
                                                  index_for_loader={
                                                    index_for_loader
                                                  }
                                                  country_list={country_list}
                                                  form_status={form_status2}
                                                  open_uploaded_file={
                                                    open_uploaded_file
                                                  }
                                                />
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            {/* type address */}
                                            {nested_data.Type === "address" ? (
                                              <div className="main_container">
                                                <Addresstype
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  country_list={country_list}
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* type address end*/}
                                            {/* resource_manager start */}
                                            <div>
                                              {nested_data.Type ===
                                                "resource_manager" ? (
                                                <div className="main_container">
                                                  <ResoueceManger_type
                                                    open_uploaded_file={
                                                      open_uploaded_file
                                                    }
                                                    index_for_loader={
                                                      index_for_loader
                                                    }
                                                    main_index={main_index}
                                                    loading_status={loading_status}
                                                    Clear_data={Clear_data}
                                                    ON_change_inputfield={
                                                      ON_change_inputfield
                                                    }
                                                    children_elements={nested_data}
                                                    form_value={form_value}
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            {/* resource_manager end */}
                                            {/* camera start */}
                                            {nested_data.Type === "camera" ? (
                                              <div className="main_container">
                                                <CameraType
                                                  open_uploaded_file={
                                                    open_uploaded_file
                                                  }
                                                  index_for_loader={
                                                    index_for_loader
                                                  }
                                                  main_index={main_index}
                                                  loading_status={loading_status}
                                                  form_value={form_value}
                                                  nested_data={nested_data}
                                                  camera_button_clicked={
                                                    camera_button_clicked
                                                  }
                                                  Clear_data={Clear_data}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {/* camera end */}
                                            {/* checklist start */}
                                            {nested_data.Type === "checklist" ? (
                                              <div className="main_container">
                                                <Checklist
                                                  clear_fields={clear_fields}
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {/* checcklist end */}
                                            {/* dob start */}
                                            {nested_data.Type ===
                                              "date_of_birth" ? (
                                              <div className="main_container date_of_birth">
                                                <Date_of_birth
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  current_date_={current_date_}
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                  Clear_data_for_dates={
                                                    Clear_data_for_dates
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* dob start end*/}
                                            {/* date_time start */}
                                            {nested_data.Type === "date_time" ? (
                                              <div className="main_container">
                                                <Date_time_type
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  current_date_={current_date_}
                                                  Clear_data_for_date_and_time={
                                                    Clear_data_for_date_and_time
                                                  }
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                  Auto_fill_value={Auto_fill_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* date_time end */}
                                            {/* divider end */}
                                            {nested_data.Type === "divider" ? (
                                              <div className="main_container">
                                                {/* <hr /> */}
                                                <DividerType
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* divider end end */}
                                            {/* electronic_signature start */}

                                            {nested_data.Type ===
                                              "electronic_signature" ? (
                                              <div className="main_container">
                                                <EsignType
                                                  Esign_clicked={Esign_clicked}
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  Clear_data={Clear_data}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* electronic_signature end */}

                                            {/* email address startd */}
                                            {nested_data.Type ===
                                              "email_address" ? (
                                              <div className="main_container">
                                                <EmailType
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* email address end */}
                                            {/* file manager start */}
                                            {nested_data.Type === "file_manager" ? (
                                              <div className="main_container">
                                                <FileMangertype
                                                  open_uploaded_file={
                                                    open_uploaded_file
                                                  }
                                                  index_for_loader={
                                                    index_for_loader
                                                  }
                                                  main_index={main_index}
                                                  loading_status={loading_status}
                                                  Clear_data={Clear_data}
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* file manager end */}
                                            {/* form section */}
                                            {nested_data.Type === "form_section" ? (
                                              <div>
                                                <Form_section
                                                  lable_={
                                                    nested_data.Meta.Heading
                                                      .blocks[0].text
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* form section end */}
                                            {/* full namestart */}
                                            {nested_data.Type === "full_name" ? (
                                              <div className="main_container ">
                                                <FullnameType
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* full name end */}
                                            {/* img manager start */}
                                            {nested_data.Type ===
                                              "image_manager" ? (
                                              <div className="main_container image-view-wrap">
                                                <ImageManager_type
                                                  open_uploaded_file={
                                                    open_uploaded_file
                                                  }
                                                  index_for_loader={
                                                    index_for_loader
                                                  }
                                                  main_index={main_index}
                                                  loading_status={loading_status}
                                                  Clear_data={Clear_data}
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* img managar end */}

                                            {/* text_multi_line start */}
                                            {nested_data.Type ===
                                              "text_multi_line" ? (
                                              <div className="main_container">
                                                <Text_multiline
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {/* text_multi_line end */}
                                            {/* number start */}

                                            {nested_data.Type === "numeric" ? (
                                              <div className="main_container">
                                                <NumericType
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* numeric end */}
                                            {nested_data.Type === "phone_number" ? (
                                              <div className="main_container">
                                                <PhoneType
                                                  country_list={country_list}
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* phone end */}
                                            {/* radio start */}
                                            {nested_data.Type === "radio_group" ? (
                                              <div className="main_container">
                                                <Radiogroup
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  clear_fields={clear_fields}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* radio_group end */}
                                            {nested_data.Type ===
                                              "text_single_line" ? (
                                              <div className="main_container">
                                                <Text_singleline
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  ON_blur_inputfield={
                                                    ON_blur_inputfield
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* text_single_line end */}
                                            {/* text start*/}

                                            {nested_data.Type === "text" ? (
                                              <div className="input_text">
                                                <Texttype
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* text end */}
                                            {/* yes_no start */}

                                            {nested_data.Type === "yes_no" ? (
                                              <div className="main_container mt-5">
                                                <Yes_noType
                                                  Clear_data={Clear_data}
                                                  YesNo_clicked={YesNo_clicked}
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* yes_no end */}
                                            {nested_data.Type === "dropdown" ? (
                                              <div className="main_container">
                                                <Dropdowntype
                                                  ON_change_inputfield={
                                                    ON_change_inputfield
                                                  }
                                                  children_elements={nested_data}
                                                  form_value={form_value}
                                                  clear_fields={clear_fields}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* dropdown end */}
                                          </React.Fragment>,
                                        ];
                                      }
                                    )}
                                  </div>
                                </React.Fragment>,
                              ];
                            })
                          )}
                        </div>
                      }
                      {/* footer button */}
                      <div className="card-toolbar">
                        <div className="left-btn" style={{ pointerEvents: "all" }}>
                          {current_pages_count > 0 && (
                            <div className="col change-detail-btn pl-0">
                              <button
                                className="btn btn-primary save-btn"
                                type="button"
                                onClick={Clicked_back_button}
                              >
                                Prev Page
                              </button>
                            </div>
                          )}

                          {Total_pages_count > 1 &&
                            current_pages_count + 1 !== Total_pages_count ? (
                            <button
                              type="button"
                              className="btn btn-primary mr-2 change-pass-btn"
                              onClick={Clicked_next_button}
                            >
                              Next Page
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="right-btn">
                          {Total_pages_count === current_pages_count + 1 && (!form_status2 || sessionStorage.getItem("is_admin_control") !== "true") ? (
                            <button
                              style={{ backgroundColor: "#E37D21" }}
                              className="btn  mr-2 text-white"
                              type="button"
                              onClick={() => {
                                document
                                  .getElementById("kt_body")
                                  .classList.remove("overflow-scroll");
                                setopen_success_popup(true);
                              }}
                            >
                              Submit
                            </button>
                          ) : (
                            ""
                          )}
                          {pages?.length > 0 && (!form_status2 || sessionStorage.getItem("is_admin_control") !== "true") ? (
                            <button
                              type="button"
                              className="btn btn-primary mr-2 change-pass-btn"
                              onClick={() => open_success_popup_method()}
                            >
                              {!save_changes_button_status ? (
                                "Save Changes"
                              ) : (
                                <p className="mb-0 pr-3">
                                  Saving...{" "}
                                  <span className="ml-3 spinner spinner-white"></span>
                                </p>
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* Form Progress */}
                      <div className="">
                        {Total_pages_count > 0 ? (
                          <p className="no-of-page">
                            Page {current_pages_count + 1} of {Total_pages_count}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {/* form end */}

                </div>
              )}
            </>


          </form>
          {form_another_session && !form_status2 ? (
            <div className="form-lock-footer-wrap">
              <div className="form-lock-footer">
                <p className="desktop-view-text">{`This page is currently locked for data entry by ${form_session_data?.[0]?.User?.FirstName
                  } ${form_session_data?.[0]?.User?.LastName} (${form_session_data?.[0]?.User?.UserName
                  } / ${form_session_data?.[0]?.IPAddress
                  }). Locked since ${Date_convert(
                    form_session_data?.[0]?.DateUpdated,
                    9
                  )} - This page is open in different screen/window/tab.`}</p>
                <div className="close-session-btn">
                  <button
                    className="btn"
                    onClick={() => {
                      document
                        .getElementById("kt_body")
                        .classList.remove("overflow-scroll");
                      setclose_session_popup(true);
                    }}
                  >
                    Close All Other Sessions?
                  </button>
                  <div className="Info-tooltip tooltip-show">
                    <img src="/media/misc/information.svg" className="info-icon" alt="unable to load" onClick={() => { setsession_close_popup(true) }} />

                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>}
      {/* camera open div  */}
      {Open_camera && <div className="capture-image-wrap">

        <div className="Top_header_sign">
          <h1>Capture Image</h1>
          <img
            className="close-icon"
            src="/media/misc/white-close.svg"
            alt="close"
            onClick={handleCloses_camera_capture}
          />
        </div>
        <div className="text-center p-10 inner_wrap">
          <div className="App">
            <div className="row">
              <div className="col-sm-6">
                <div className="on-video-section">
                  <video ref={videoRef} playsInline autoPlay />
                  <button
                    className="capture_btn"
                    onClick={() => {
                      capture_image();
                    }}
                  >
                    Capture image
                  </button>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="on-video-section">
                  <canvas
                    className="canvas-capture"
                    ref={canvasref}
                    width="680"
                    height="360"
                  />
                  <p className="color-red m-0">
                    {cam_err_status ? camera_error_message : ""}{" "}
                  </p>
                  <p className="m-0"> preview image</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <button
              type="submit"
              onClick={handleCloses_camera_capture}
              className="btn btn-danger mr-5"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleCloses_camera_capture_submit}
              className="btn btn-primary mr-5"
            >
              Submit
            </button>
          </div>
        </div>


      </div>}


      {/* camera capture  popup start */}
      <Dialog
        onClose={handleCloses_camera_capture}
        aria-labelledby="customized-dialog-title"
        open={false}
        className="success-popup upload_image_popup"
      >
        <div className="Top_header_sign">
          <h1>Capture Image</h1>
          <img
            className="close-icon"
            src="/media/misc/white-close.svg"
            alt="close"
            onClick={handleCloses_camera_capture}
          />
        </div>
        <DialogContent dividers className="text-center p-10">
          <div className="App">
            <div className="row">
              <div className="col-sm-6">
                <div className="on-video-section">
                  <video ref={videoRef} playsInline autoPlay />
                  <button
                    className="capture_btn"
                    onClick={() => {
                      capture_image();
                    }}
                  >
                    Capture image
                  </button>
                </div>
              </div>
              <div className="col-sm-6">
                <canvas
                  className="canvas-capture"
                  ref={canvasref}
                  width="680"
                  height="360"
                />
                <p className="color-red">
                  {cam_err_status ? camera_error_message : ""}{" "}
                </p>
                <div> preview image</div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <button
              type="submit"
              onClick={handleCloses_camera_capture}
              className="btn btn-danger mr-5"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleCloses_camera_capture_submit}
              className="btn btn-primary mr-5"
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
      {/* camera capture popup  end*/}
      {/* Successfully popup start*/}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloses_success_popup();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open_success_popup_2}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <h2 className="font-weight-bold m-10 success-head"></h2>
          <h2 className="success-text mb-10 font-weight-bold success-head">
            You have successfully submitted this form!
          </h2>
          <button
            type="submit"
            onClick={handleCloses_success_popup}
            className="btn btn-primary mr-5 save-btn"
          >
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* Successfully popup end */}
      {/* conf save chnages popup start */}

      <div>
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleCloses_conf_savec_changes_popup();
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={open_success_popup}
          className="log-out-wrap"
        >
          <DialogTitle className="popup-heading">Submit Form</DialogTitle>
          <DialogContent dividers className="middle-content">
            <Typography gutterBottom className="logut-content">
              Click yes to complete form and submit to the research site. Once
              submitted, you will no longer be able to make changes.
            </Typography>
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button
                className="btn btn-secondary btn-danger logout-btn mr-6"
                onClick={handleCloses_conf_savec_changes_popup}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleCloses_conf_savec_changes_popup_yes}
                className="btn save-btn btn-primary "
              >
                Yes
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      {/* conf save chnages popup end */}

      {/* unsaved data popup start */}
      <div>
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleClose_save_changes_popup();
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={open_save_changes_popup}
          className="log-out-wrap"
        >
          <DialogTitle className="popup-heading">Save changes</DialogTitle>
          <DialogContent dividers className="middle-content">
            <Typography gutterBottom className="logut-content">
              Are you sure you want to leave without saving changes?
            </Typography>
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button
                className="btn btn-secondary btn-danger logout-btn mr-6"
                onClick={handleClose_save_changes_popup}
              >
                No
              </button>
              <button
                type="submit"
                onClick={handleClose_save_changes_popup_yes}
                className="btn btn-danger save-btn"
              >
                Yes
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {/* Failed dialog */}
        <Dialog
          onClose={handleCloses_error}
          aria-labelledby="customized-dialog-title"
          open={errorpopup}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">Something went wrong !</p>
            <button
              type="submit"
              onClick={handleCloses_error}
              className="btn btn-primary mr-5 save-btn"
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* Required field error dialog */}
        <Dialog
          onClose={() => {
            document.getElementById("kt_body").classList.add("overflow-scroll");
            setrequi_field_errorpopup(false);
          }}
          aria-labelledby="customized-dialog-title"
          open={requi_field_errorpopup}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">
              A required field has been left blank, Enter a value in the
              required field before proceeding{" "}
            </p>
            <button
              type="submit"
              onClick={() => {
                document
                  .getElementById("kt_body")
                  .classList.add("overflow-scroll");
                setrequi_field_errorpopup(false);
              }}
              className="btn btn-primary mr-5 save-btn"
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* invalid fields error popup */}
        <Dialog
          onClose={() => {
            document.getElementById("kt_body").classList.add("overflow-scroll");
            setfield_invalid_error(false);
          }}
          aria-labelledby="customized-dialog-title"
          open={field_invalid_error}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">{field_invalid_error_message}</p>
            <button
              type="submit"
              onClick={() => {
                document
                  .getElementById("kt_body")
                  .classList.add("overflow-scroll");
                setfield_invalid_error(false);
              }}
              className="btn btn-primary mr-5 save-btn"
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* camera permission error */}
        <Dialog
          onClose={() => setcamera_permission_error(false)}
          aria-labelledby="customized-dialog-title"
          open={camera_permission_error}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">{camera_permission_message}</p>
            <button
              type="submit"
              onClick={() => {
                document
                  .getElementById("kt_body")
                  .classList.add("overflow-scroll");
                setcamera_permission_error(false);
              }}
              className="btn btn-primary mr-5 save-btn"
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* file upload error */}
        <Dialog
          onClose={() => {
            document.getElementById("kt_body").classList.add("overflow-scroll");
            setfile_upload_error(false);
            setfile_upload_error_message("");
          }}
          aria-labelledby="customized-dialog-title"
          open={file_upload_error}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">{file_upload_error_message}</p>
            <button
              type="submit"
              onClick={() => {
                document
                  .getElementById("kt_body")
                  .classList.add("overflow-scroll");
                setfile_upload_error(false);
                setfile_upload_error_message("");
              }}
              className="btn btn-primary mr-5 save-btn"
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* Session lock Popup */}
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setclose_session_popup(false);
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={close_session_popup}
          className="log-out-wrap"
        >
          <DialogTitle className="popup-heading">
            Lock All Other Sessions?
          </DialogTitle>
          <DialogContent dividers className="middle-content">
            <Typography gutterBottom className="logut-content">
              Are you sure you want to lock all other sessions and open this
              current session?
            </Typography>
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button
                className="btn btn-secondary btn-danger logout-btn mr-6"
                onClick={() => {
                  document
                    .getElementById("kt_body")
                    .classList.add("overflow-scroll");
                  setclose_session_popup(false);
                }}
              >
                No
              </button>
              <button
                type="submit"
                onClick={() => {
                  document
                    .getElementById("kt_body")
                    .classList.add("overflow-scroll");
                  setclose_session_popup(false)
                  close_all_other_sessions();
                }}
                className="btn save-btn btn-primary"
              >
                Yes
              </button>
            </div>
          </DialogActions>
        </Dialog>
        {/* session locked messge popup on press on i button */}
        <Dialog
          onClose={() => setsession_close_popup(false)}
          aria-labelledby="customized-dialog-title"
          open={session_close_popup}
          className="log-out-wrap"
        >
          <DialogTitle className="popup-heading">Session Locked</DialogTitle>
          <DialogContent dividers className="middle-content">
            <Typography gutterBottom className="logut-content">
              <p className="mobile-view-text">{`This page is currently locked for data entry by ${form_session_data?.[0]?.User?.FirstName
                } ${form_session_data?.[0]?.User?.LastName} (${form_session_data?.[0]?.User?.UserName
                } / ${form_session_data?.[0]?.IPAddress
                }). Locked since ${Date_convert(
                  form_session_data?.[0]?.DateUpdated,
                  9
                )} - This page is open in different screen/window/tab.`}</p>
            </Typography>
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button className="btn btn-danger mr-6" onClick={() => setsession_close_popup(false)} >
                Ok
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
};
