import React, { Suspense, lazy, } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_common/layout";

import { Admin_view } from "./pages/Admin_view";
import ChangePassword from "./modules/UserProfile/ChangePassword";

const UserProfilepage = lazy(() =>
    import("./modules/UserProfile/UserProfilePage")
);

export default function AdminBasePage() {


    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/Admin_View" />
                <ContentRoute path="/Admin_View" component={Admin_view} />
                <ContentRoute path="/Change_password" component={ChangePassword} />
                <Route path="/user-profile" component={UserProfilepage} />



                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
