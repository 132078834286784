import React, { useState, useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Dashboard_invite_api, } from "../modules/Auth/_redux/authCrud"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { useHistory } from "react-router-dom"
import { Spinner } from "react-bootstrap"
import { Get_esource_paraeter } from "../modules/Auth/_redux/authCrud"


import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Icon from "@material-ui/core/Icon"

export const EsourceMailLink = (props) => {
    const [loader_status, setloader_status] = useState(true)
    const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
    const [error_popup, seterror_popup] = useState(false);
    const dispatch = useDispatch()
    let history = useHistory()

    const queryParams = new URLSearchParams(window.location.search)
    let CTMSHostLink_ = queryParams.get("CTMSHostLink_")
    let formid = queryParams.get("FormID_")
    let CTMSStudyID = queryParams.get("CTMSStudyID")
    let CTMSSiteID = queryParams.get("CTMSSiteID")

    let PatientCTMSID_ = queryParams.get("PatientCTMSID_")
    localStorage.setItem("PatientCTMSID_", PatientCTMSID_)

    // history.push("/Form_details?CTMSHostLink_=" + CTMSHostLink_ + "&FormID_=" + FormID + "&is_mob=" + false + "&PatientCTMSID_=" + PatientCTMSID_ + "&SiteID_local_=" + SiteID_local_ + "&FormStatus_=" + FormStatus + "&ID_=" + ID + "&CreatedAt_=" + CreatedAt)

    useEffect(() => {
        Get_esource_data()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const Get_esource_data = () => {
        Get_esource_paraeter(CTMSStudyID, CTMSSiteID, CTMSHostLink_, formid).then((data) => { Get_esource_data_part2(data?.data[0]) }).catch((err) => { seterror_popup(true) })
    }
    // to redirect to esource page
    const Get_esource_data_part2 = (data) => {
        localStorage.setItem("SiteID_local", data.siteID)
        setloader_status(false)
        sessionStorage.setItem("esourcemailRequest","true")
        history.push("/Form_details?CTMSHostLink_=" + CTMSHostLink_ + "&FormID_=" + formid + "&is_mob=" + false + "&PatientCTMSID_=" + PatientCTMSID_ + "&SiteID_local_=" + data.siteID + "&FormStatus_=" + data.Status + "&ID_=" + data.id + "&CreatedAt_=" + data.AssignDate+"&CTMSStudyID="+CTMSStudyID)
      }

    // calling invite api
    useEffect(() => {
        if (!studies_) {
            apicall()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // calling invite api  to resolve refresh issue
    const apicall = () => {
        Dashboard_invite_api()
            .then((value) => {
                dispatch(auth.actions.setstudies_action(value.data[0].studies))
            })
            .catch((error) => {
            })
    }





    //popup method
    const handleCloses_error_popup = () => {
        seterror_popup(false)
    }


    return (
        <>
            {loader_status && <div style={{ textAlign: "center" }}>
                <Spinner animation="border" className="spinner-info" variant="primary" />
            </div>}


            {/* Success or failed popup */}
            <Dialog
                onClose={handleCloses_error_popup}
                aria-labelledby="customized-dialog-title"
                open={error_popup}
                className="success-popup"
            >

                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon">
                        <Icon className="fa fa-exclamation-triangle" />

                    </div>
                    <p className="success-text mb-10">
                        Something went wrong.
                    </p>
                    <button type="submit" onClick={handleCloses_error_popup} className="btn btn-primary mr-5 save-btn">
                        Ok
                    </button>
                </DialogContent>
            </Dialog>
        </>
    )
}
