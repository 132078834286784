/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import {
  Dashboard_invite_accept_n_reject,
  get_ctms_token,
  Study_api2,
  Ctms_invite_api,
  Reject_invite_status,
  getUserByTokenForInvites,
  migrateDataTo
} from "../../../../app/modules/Auth/_redux/authCrud"

import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { withStyles } from "@material-ui/core/styles"
import { Spinner } from "react-bootstrap"
import Typography from "@material-ui/core/Typography"
import * as auth from "../../../../app/modules/Auth"
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux"
import Icon from "@material-ui/core/Icon"

// popup design
const styles = (theme) => ({
  indicator: {
    backgroundColor: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.4),
    color: "#fff",
    paddingRight: "10px",
  },
})
//popup design end

export function Dash_Top_Wid({ studies, invites, apicall }) {
  const [open, setopen] = useState(false)
  const [opensMigrationPopup, setopensMigrationPopup] = useState(false)
  const [migrationPopupMessage, setmigrationPopupMessage] = useState("")
  const [migrationPopupLoader, setmigrationPopupLoader] = useState(false)
  const [migrationErrorPresent, setmigrationErrorPresent] = useState(null)
  const [spinner_loading, setspinner_loading] = React.useState(true)
  // eslint-disable-next-line no-unused-vars
  const [dashboard_api_data, setdashboard_api_data] = React.useState([])
  const [study_api_data, setstudy_api_data] = React.useState([])
  const [invite_data, setinvite_data] = React.useState([])
  const logo = useSelector((state) => state.auth.logo, shallowEqual)
  let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const [logo_for_popup, setlogo_for_popup] = useState();
  const [is_logo_present, setis_logo_present] = useState();

  const dispatch = useDispatch()


  useEffect(() => {
    setinvite_data(invites)


    // this code is for theme colour when no study is preset but 1 invite is present
    // eslint-disable-next-line eqeqeq
    if (studies?.length === 0 && invites?.length == 1) {

      let colourcode = {
        Accent_Color: invites[0].sites?.Accent_Color,
        Branding_Color: invites[0].sites?.Branding_Color,
        HighLight_Color: invites[0].sites?.HighLight_Color
      }
      dispatch(auth.actions.settheme(colourcode))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invites])


  // api call Accept invite
  const Accept_invites = (value) => {

    setmigrationPopupLoader(true)
    setopensMigrationPopup(true)
    setmigrationPopupMessage("We are setting up your profile. Please wait.")


    localStorage.setItem("site_id", value.siteID);
    Dashboard_invite_accept_n_reject(value.id, 1)
      .then((data) => {
        apicall()
        handleClickOpens("accept", value)
      })
      .catch((error) => { handleClickOpens(error.response?.data.message) }).finally(() => {

      })

  }
  // reject invite
  const Reject_invites = (value) => {

    setmigrationPopupLoader(true)
    setopensMigrationPopup(true)
    setmigrationPopupMessage("")
    localStorage.setItem("site_id", value.siteID);
    Dashboard_invite_accept_n_reject(value.id, 2)
      .then((data) => {

        apicall()
        handleClickOpens("reject", value)
      })
      .catch((error) => { handleClickOpens(error.response?.data.message) }).finally(() => {
      })
  }

  //to get session id
  const Get_sessionId = async (datas) => {

    await get_ctms_token(datas?.SiteID)
      .then((value) => {
        setdashboard_api_data(value.data.study)
        Get_study(value.data, datas)
      })
      .catch((error) => { })
  }
  //to get study site info

  const Get_study = async (SessionKey, datas) => {
    await Study_api2(SessionKey, datas)
      .then((value) => {
        setstudy_api_data([value.data])
        setspinner_loading(false)
      })
      .catch((error) => { })
  }
  // update status of invite to ctms
  const Ctms_invite_status_update = (sessonkey, datas, status) => {

    getUserByTokenForInvites(localStorage.getItem("id"), datas.sites.id).then((res) => {
      localStorage.setItem("PatientCTMSID", res.data.PatientCTMSID)

      Ctms_invite_status_update_part2(sessonkey, datas, status, res)
    }).catch(() => {

      errorAppeared()
    })
  }


  const Ctms_invite_status_update_part2 = (sessonkey, datas, status, res) => {
    Ctms_invite_api(sessonkey, datas, status)
      .then((data) => {

        let esourceData = data.data.eSource
        if (status === 1 && esourceData !== null) {
          migrateDataTo(data.data, datas.id).then(() => { setmigrationPopupMessage("Enrolled Successfully."); setmigrationErrorPresent(false) }).catch(() => { setmigrationPopupMessage("Something went Wrong."); setmigrationErrorPresent(true) }).finally(() => { setmigrationPopupLoader(false) })
        }
        if (esourceData === null && status === 1) {
          setmigrationPopupMessage("Enrolled Successfully.")
          setmigrationErrorPresent(false)
          setmigrationPopupLoader(false)
        }

        apicall()

      })
      .catch((error) => {
        errorAppeared()
      })
    if (status === 2) {
      Reject_invite_status(res.data.PatientCTMSID, res.data.PatientCTMSSiteID).then(apiresult => { }).catch(err => { })

    }
  }
  // common method for api call error
  const errorAppeared = () => {
    setmigrationPopupMessage("Something went wrong.");
    setmigrationErrorPresent(true)
    setmigrationPopupLoader(false)
  }


  // geting seesion for ctms site calls

  const common_sesson_ctms = async (datas, status) => {
    await get_ctms_token(datas?.SiteID)
      .then((value) => {
        Ctms_invite_status_update(value.data, datas, status)
      })
      .catch((error) => {
        errorAppeared()
      })
  }

  // api call end

  // popup methods

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions)
  // invite pooup methods
  const handleClose = () => {
    setopen(false)
    setstudy_api_data([])
  }
  const handleClickOpen = (data) => {
    setlogo_for_popup(end_point_url + data.sites.SiteLogoURL)
    setis_logo_present(data.sites.SiteLogoURL)
    Get_sessionId(data)
    setopen(true)
    setspinner_loading(true)
  }

  const handleClickOpens = (value, inviteData) => {
    if (value === "accept") {
      common_sesson_ctms(inviteData, 1)
    } else if (value === "reject") {
      setmigrationPopupMessage("Rejected Successfully.")
      setmigrationErrorPresent(false)
      setmigrationPopupLoader(false)
      common_sesson_ctms(inviteData, 2)
    } else {

      errorAppeared()
    }
  }


  const handleClosesMigrationPopup = () => {
    setopensMigrationPopup(false)
    setmigrationErrorPresent(null)
  }
  return (
    <>
      {invite_data.length > 0 &&
        invite_data.map((value, index) => {
          let logo_ = end_point_url + value.sites.SiteLogoURL
          return [
            <div className="dash-top" key={index}>
              <div className="dash-top-left">
                {/* <img src="/media/misc/teva.png" /> */}
                <div className="d-flex">
                  <div>
                    <img
                      style={{
                        // backgroundColor: "#5d6c77",
                        padding: "5px",
                        borderRadius: "4px",
                      }}
                      // src={logo ? `${logo}` : `/media/logos/logo.png`}
                      src={value.sites.SiteLogoURL ? logo_ : '/media/logos/logo.png'}
                      alt="unable to load"
                    />
                  </div>

                  <span>
                    <b>
                      {" "}
                      {value.sites.CTMSSiteTitle}{" "}
                      {value.CTMSDisplayedOnPatientPortal || value.CTMSIndication ? "-" : ""}{" "}
                      {value.CTMSDisplayedOnPatientPortal
                        ? value.CTMSDisplayedOnPatientPortal
                        : value.CTMSIndication}{" "}
                    </b>
                    ({value.CTMSStudySponsor}, {value.CTMSStudyProtocol})
                  </span>
                </div>

                <p>
                  You've been requested to enroll on the above study. Click to{" "}
                  <a
                    href="#"
                    onClick={() => {
                      handleClickOpen(value)
                    }}

                  >
                    see here
                  </a>{" "}
                  details.
                </p>
              </div>
              {/* hiding thid part if adin is there */}

              <div className="dash-top-right">
                <button
                  type="button"
                  className="blue-button"
                  onClick={() => {
                    Accept_invites(value)
                  }}
                  disabled={sessionStorage.getItem("is_admin_control") === "true" || migrationPopupLoader}
                >
                  Accept
                </button>
                <button
                  type="button"
                  className="red-button"
                  onClick={() => {
                    Reject_invites(value)
                    // common_sesson_ctms(value, 2)
                  }}
                  disabled={sessionStorage.getItem("is_admin_control") === "true" || migrationPopupLoader}
                >
                  Decline
                </button>
              </div>


            </div>,
          ]
        })}

      {/* dialog start */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="uplaod-profile info-popup invite-information"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className="popup-heading">
          Invite Information
        </DialogTitle>

        <DialogContent className="middle-content ">
          {/* <div className="info-heading">Hello </div> */}
          {spinner_loading && <Spinner animation="border" className="spinner-info" variant="primary" />}

          {study_api_data?.map((value, index) => {
            return [
              <div className="site-info" key={index}>
                <div className="site-info-title">
                  <div className="site-info-content">
                    {/* <img src="/media/misc/teva.png" /> */}
                    <img src={is_logo_present ? `${logo_for_popup}` : `/media/logos/logo.png`} alt="unable to load" />

                    {(value.SiteLocationShortName === "" || value.SiteLocationShortName === null) &&
                      (value.SiteName === "" || value.SiteName === null) ? (
                      ""
                    ) : (
                      <>
                        {value.SiteLocationShortName === "" || value.SiteLocationShortName === null ? (
                          <h2>
                            Site: <span>{value.SiteName}</span>{" "}
                          </h2>
                        ) : (
                          <h2>
                            Site:<span>{value.SiteLocationShortName}</span>{" "}
                          </h2>
                        )}
                      </>
                    )}
                    {(value?.Address1 === "" || value?.Address1 === null) &&
                      (value?.Address2 === "" || value?.Address2 === null) &&
                      (value?.State === "" || value?.State === null) &&
                      (value?.City === "" || value?.City === null) &&
                      (value?.ZipCode === "" || value?.ZipCode === null) ? (
                      ""
                    ) : (
                      <p>
                        <span>Site Address:</span>
                        {value?.Address1}{value.Address1 ? ", " : ""}{value?.Address2}
                        {value.Address2 ? ", " : ""}{value?.State}{value.State ? ", " : ""}
                        {value?.City}{value.City ? ", " : ""}
                        {value?.ZipCode}
                      </p>
                    )}

                    <div className="site-info-mail">
                      {value?.PrimaryEMail === "" || value?.PrimaryEMail === null || value?.PrimaryEMail === undefined ? (
                        ""
                      ) : (
                        <div className="site-info-mailbox">
                          <img src="/media/misc/email.png" alt="unable to load" />
                          <span>
                            {/* {value?.PrimaryEMail} */}
                            <a href={`mailto:${value?.PrimaryEMail}`}>{value.PrimaryEMail}</a>
                          </span>
                        </div>
                      )}
                      {value?.Phone1 === "" || value?.Phone1 === null || value?.Phone1 === undefined ? (
                        ""
                      ) : (
                        <div className="site-info-mailbox without-border">
                          <img src="/media/misc/phone-call.png" alt="unable to load" />
                          <span>
                            <a href={`tel:${value?.Phone1}`}>{value?.Phone1}</a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {(value?.DisplayedOnPatientPortal === null || value?.DisplayedOnPatientPortal === "") &&
                  (value?.Indication === "" || value?.Indication === null) &&
                  (value?.Protocol === "" || value?.Protocol === null) &&
                  (value?.Sponsor === "" || value?.Sponsor === null) &&
                  (value?.Description === "" || value?.Description === null) &&
                  (value?.FriendlyDescription === "" || value?.FriendlyDescription === null) ? ("") :
                  (<div className="site-info-title">
                    <div className="site-info-left">
                      <p>Study Info</p>
                    </div>
                    <div className="site-info-right">
                      {value?.DisplayedOnPatientPortal === null || value?.DisplayedOnPatientPortal === "" ? (
                        value?.Indication && <p>
                          Indication : <span>{value?.Indication}</span>
                        </p>
                      ) : (
                        <p>
                          Indication: <span>{value?.DisplayedOnPatientPortal}</span>
                        </p>
                      )}
                      {value?.Protocol === "" || value?.Protocol === null ? (
                        ""
                      ) : (
                        <p className="side-border">
                          Protocol: <span>{value?.Protocol}</span>
                        </p>
                      )}
                      {value?.Sponsor === "" || value?.Sponsor === null ? (
                        ""
                      ) : (
                        <p>
                          Sponsor: <span>{value?.Sponsor}</span>
                        </p>
                      )}
                      {(value?.Description === "" || value?.Description === null) &&
                        (value?.FriendlyDescription === "" || value?.FriendlyDescription === null) ? (
                        ""
                      ) : (
                        <>
                          {value?.FriendlyDescription === "" || value?.FriendlyDescription === null ? (
                            <p>
                              Description: <span>{value?.Description}</span>
                            </p>
                          ) : (
                            <p>
                              Description: <span>{value?.FriendlyDescription}</span>
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>)}

                {value.Investigator === "" || value.Investigator === null ? (
                  ""
                ) : (
                  <div className="site-info-title">
                    <div className="site-info-left">
                      <p>Investigator</p>
                    </div>
                    <div className="site-info-right">
                      <p>
                        Name: <span>{value.Investigator}</span>
                      </p>
                    </div>
                  </div>
                )}

                {value.SubInvestigatorsName === "" || value.SubInvestigatorsName === null ? (
                  ""
                ) : (
                  <div className="site-info-title">
                    <div className="site-info-left">
                      <p> Sub Investigator</p>
                    </div>
                    <div className="site-info-right">
                      <p>
                        Name: <span>{value.SubInvestigatorsName}</span>
                      </p>
                    </div>
                  </div>
                )}

                {(value.LeadCoordinatorEmail === null || value.LeadCoordinatorEmail === "") &&
                  (value.LeadCoordinatorName === null || value.LeadCoordinatorName === "") &&
                  (value.LeadCoordinatorPhone === null || value.LeadCoordinatorPhone === "") ? (
                  ""
                ) : (
                  <div className="site-info-title">
                    <div className="site-info-left">
                      <p> Lead Coordinator</p>
                    </div>
                    <div className="site-info-right">
                      {value.LeadCoordinatorName === "" || value.LeadCoordinatorName === null ? (
                        ""
                      ) : (
                        <p>
                          Name: <span>{value.LeadCoordinatorName}</span>
                        </p>
                      )}

                      <div className="site-info-mail">
                        {value.LeadCoordinatorEmail === "" || value.LeadCoordinatorEmail === null || value?.LeadCoordinatorEmail === undefined ? (
                          ""
                        ) : (
                          <div className="site-info-mailbox">
                            <img src="/media/misc/email.png" alt="unable to load" />
                            <span>
                              {/* {value.LeadCoordinatorEmail} */}
                              <a href={`mailto:${value?.LeadCoordinatorEmail}`}>{value.LeadCoordinatorEmail}</a>
                            </span>
                          </div>
                        )}
                        {value.LeadCoordinatorPhone === "" || value.LeadCoordinatorPhone === null || value.LeadCoordinatorPhone === undefined ? (
                          ""
                        ) : (
                          <div className="site-info-mailbox without-border">
                            <img src="/media/misc/phone-call.png" alt="unable to load" />
                            <span>
                              {/* {value.LeadCoordinatorPhone} */}
                              <a href={`tel:${value?.LeadCoordinatorPhone}`}>{value?.LeadCoordinatorPhone}</a>

                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>,
            ]
          })}

        </DialogContent>
        <DialogActions className="pb-6 pt-6 btn-wrap">
          <div className="card-toolbar">
            <button type="submit" className="btn btn-primary mr-5 save-btn" onClick={handleClose}>
              Ok
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* dialog end */}


      {/* migration popup */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosesMigrationPopup()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={opensMigrationPopup}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            {migrationErrorPresent === false && <Icon className="fa fa-check icon" />}
            {migrationErrorPresent === true && <Icon className="fa fa-exclamation-triangle" />}
            {migrationPopupLoader && <Spinner animation="border" className="spinner-info" variant="primary" />}

          </div>
          <h2 className="font-weight-bold m-10 success-head">Alert !</h2>
          <h2 className="success-text mb-10 font-weight-bold success-head">{migrationPopupMessage}</h2>
          {migrationPopupLoader === false && <button type="submit" onClick={handleClosesMigrationPopup} className="btn btn-primary mr-5 save-btn">
            Ok
          </button>}
        </DialogContent>
      </Dialog>
      {/* migration popup */}
    </>
  )
}

export default connect(null, auth.actions)(Dash_Top_Wid)
