/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useRef } from "react"
import Video from "twilio-video"
import Room from "./Room"
import { useSelector, shallowEqual } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import {
  Twilio_token,
  Twilio_room,
  get_ctms_token, Get_single_event
} from "../modules/Auth/_redux/authCrud"
import { useLocation } from "react-router"
import { useHistory } from "react-router-dom"
import { CommonPopup } from "../pages/Common_popup"


export const VideoChat = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const videoRef = useRef(null)
  const [message, setmessage] = useState("")
  const [room, setRoom] = useState(null)
  const [connecting, setConnecting] = useState(false)
  const [but_disable, setbut_disable] = useState(true)
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const [is_video_on, setis_video_on] = useState(false)
  const [is_video_on_replica, setis_video_on_replica] = useState(false)
  const [is_audio_on, setis_audio_on] = useState(false)
  const [is_checkbox_disabled, setis_checkbox_disabled] = useState(false)
  const [session_key, setsession_key] = useState("")
  const [roomname_trim, setroomname_trim] = useState("")
  const [audio_level, setaudio_level] = useState(5)
  const [popupstatus, setpopupstatus] = React.useState(false)
  const [room_sid, setroom_sid] = useState();
  let location = useLocation()
  let history = useHistory()
  let token = ""
  // to hide header when user share link to join Call,
  useEffect(() => {
    // to fix twilio link share to other userwith diffrent instance
    let hostForTokenApi = queryParams.get("host")
    if (hostForTokenApi) {
      sessionStorage.setItem("ctms_host_link", hostForTokenApi)
    }
    const roomname_for_call = queryParams.get("roomname")
    const header_part = document.getElementById("kt_header");
    const header_part_logo = document.getElementById("kt_brand");
    if (roomname_for_call) {
      if (header_part) {
        header_part.classList.add("hidefooter");
        header_part_logo.classList.add("hidefooter");
      }
    }

    return () => {
      if (header_part) {
        header_part.classList.remove("hidefooter");
        header_part_logo.classList.remove("hidefooter");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //trilio call api 
  useEffect(() => {
    let roomname = queryParams.get("roomname")
    let roomNameTrim
    if (roomname) {
      roomname.length > 100 ? roomNameTrim = roomname.substring(0, 100) : roomNameTrim = roomname
    }
    else {
      location.state?.title.length > 100 ? roomNameTrim = location.state?.title.substring(0, 100) : roomNameTrim = location.state?.title
    }
    roomNameTrim = encodeURIComponent(roomNameTrim) 
    setroomname_trim(roomNameTrim)
    const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
    const siteid = queryParams.get("siteid")
    get_ctms_token(siteid)
      .then((value) => {
        let CTMSEventID = localStorage.getItem("CTMSEventID")
        setsession_key(value.data)
        Twilio_room(roomNameTrim, value.data, CTMSEventID)
          .then(data => { data.data.roomSid.length === undefined ? setroom_sid(sessionStorage.getItem("RoomSID")) : setroom_sid(data.data.roomSid); })
          .catch((err) => { })
          .finally(() => {
            setbut_disable(false)

          })

      })
      .catch((error) => {
      }).finally(fin => {
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])
  const Create_twilio_token = () => {
    const room_sid_ = room_sid ? room_sid : sessionStorage.getItem("RoomSID")
    let roomname = queryParams.get("roomname")

    let username = user?.FirstName + " " + user?.LastName + " " + " }" + Math.floor(Math.random() * 61)
    Twilio_token(roomname_trim, username, session_key, room_sid_)
      .then((data) => {
        token = data.data.AuthToken
        handleSubmit2()
      })
      .catch((data) => {
        setConnecting(false)
      })
  }

  const handleSubmit2 = () => {
    Video.connect(token, {
      // name: location.state?.title,
      name: "webroom",
      dominantSpeaker: true,
      // identity: "rajeev",
    })
      .then((room) => {
        setConnecting(false)
        setRoom(room)
        sessionStorage.setItem("userJoinedCall", true)
      })
      .catch((err) => {
        setConnecting(false)
        setmessage("Error , could not connect.")
      })
  }

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop()
        })

        prevRoom.disconnect()
      }
      return null
    })
    history.push("/dashboard")
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {

    if (room) {

      const tidyUp = (event) => {
        if (event.persisted) {
          return
        }
        if (room) {

          handleLogout()

        }

      }
      window.addEventListener("pagehide", tidyUp)
      window.addEventListener("beforeunload", tidyUp)
      return () => {
        window.removeEventListener("pagehide", tidyUp)
        window.removeEventListener("beforeunload", tidyUp)
      }
    }

  }, [room, handleLogout])
  // click on join button
  const join_twili_call = () => {
    setConnecting(true)
    if (queryParams.get("join_status_id")) {
      let join_status_id = queryParams.get("join_status_id")
      localStorage.setItem("join_status_id", join_status_id)
    }

    let join_event_id = localStorage.getItem("join_status_id")
    if (join_event_id) {
      Get_single_event(join_event_id).then(result => { join_twili_call_part2(result.data); }).catch(err => { setpopupstatus(true) })

    }
    else {
      join_twili_call_part3()
    }
  }

  const join_twili_call_part2 = (data) => {
    if (data[0].IsInitiates === true) {
      sessionStorage.setItem("RoomSID", data[0].RoomSID)
      setroom_sid(data[0].RoomSID)
      join_twili_call_part3()
    } else {
      setpopupstatus(true)
    }
  }

  const join_twili_call_part3 = (room_id) => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 600 }, audio: true })
      .then(() => {
        setConnecting(true)
        if (is_video_on) {
          close_video(1)
        } else {
          Create_twilio_token()
        }
      })
      .catch((err) => {
        setmessage("Camera and microphone permission are required !")

      })
  }
  const close_popup = () => {
    setpopupstatus(false)
    setConnecting(false)

  }
  // get video preview
  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 600 } })
      .then((stream) => {
        setmessage("")

        let video = videoRef.current
        video.srcObject = stream
        video.play()
      })
      .catch((err) => {
        setmessage("could not detect the camera !")

      })
  }
  // stop video preview
  const close_video = (type) => {
    let video = videoRef.current
    const stream = video.srcObject
    const tracks = stream?.getTracks()
    if (stream !== null) {

      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i]
        track.stop()
      }
      setis_video_on(false)
      video.srcObject = null
      if (type === 1) {
        Create_twilio_token()
        setis_video_on_replica(true)
      }
    }
  }
  // Mice_toggle for preview
  const Mice_toggle = (e) => {
    setis_audio_on(e.currentTarget.checked)
  }
  // video_toggle for preview
  const Video_toggle = (e) => {
    setis_video_on(e.currentTarget.checked)
    if (e.currentTarget.checked) {
      getVideo()
      setis_checkbox_disabled(true)
      setTimeout(() => {
        setis_checkbox_disabled(false)
      }, 2100)
    } else {
      close_video(2)
    }
  }
  const Cancle_pressed = () => {
    let roomname_available = queryParams.get("roomname")
    if (roomname_available) {
      window.close()
      history.push("/dashboard")
    }
    else {
      history.goBack()

    }
  }
  let render
  if (room) {
    render = (
      <>
        <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

        <Room
          room={room}
          handleLogout={handleLogout}
          event_title={
            queryParams.get("title")
              ? decodeURIComponent(queryParams.get("title"))
              : decodeURIComponent(location.state?.only_title)
          }
          is_audio_on={is_audio_on}
          is_video_on={is_video_on_replica}
          audio_level={audio_level}
          sessionkey={session_key}
          roomNameTrim={roomname_trim}

        />
      </>
    )
  } else {
    render = (
      <>
        <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

        <div className="container choose-option">
          <h4>Choose your video and audio options</h4>
          <div className="row">
            <div className="col-sm-6">
              <div className="video-wrap">
                <div className="middle-view">
                  {is_video_on ? (
                    <video ref={videoRef} />
                  ) : (
                    <>
                      <img src="/media/misc/off-video.svg" alt="unable to load" />
                      <p className="text-white mt-4">Your camera is turned off</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="audio-option-wrap">
                <div className="audio-card p-0">
                  <div className="d-flex justify-content-between align-items-center c-audio">
                    <div className="d-flex align-items-center">
                      <img src="/media/misc/computer-sound.svg" className="aduio_icon" alt="unable to load" />
                      <p className="text-white m-0">Computer audio</p>
                    </div>
                  </div>

                  <div className="selction-audio-wrap">
                    <div className="selction-audio d-flex justify-content-between ">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle text-white"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Customized setup
                        </button>

                      </div>
                    </div>

                    <div className="d-flex">
                      {is_audio_on ? (
                        <img
                          src="/media/misc/mice.svg"
                          className="aduio_icon"
                          style={{ width: 18 }}
                          alt="unable to load"
                        />
                      ) : (
                        <img
                          src="/media/misc/mute-voice.svg"
                          className="aduio_icon"
                          style={{ width: 18 }}
                          alt="unable to load"
                        />
                      )}

                      <div className="custom-control custom-switch" data-toggle="tooltip" title={is_audio_on ? "Turn off mic" : "Turn on mic"}>

                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitches2"
                          value={true}
                          onChange={(e) => Mice_toggle(e)}
                        />
                        <label className="custom-control-label" htmlFor="customSwitches2"></label>
                      </div>

                      <div className="d-flex volum-wrapper">
                        <img src="/media/misc/volume.svg" style={{ height: 22 }} alt="unable to load" />
                        <input type="range" id="volume" name="volume" min="1" max="10" value={audio_level} onChange={(e) => { setaudio_level(e.target.value) }} />                        {/* <label for="volume">Volume</label> */}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center c-audio">
                    <div className="d-flex align-items-center">
                      <img src="/media/misc/computer-sound.svg" className="aduio_icon" alt="unable to load" />
                      <p className="text-white m-0">Computer video</p>
                    </div>
                  </div>
                  <div className="selction-audio-wrap">
                    <div className="selction-audio d-flex justify-content-between ">
                      <p className="text-white m-0">Video setup</p>
                    </div>
                    <div className="d-flex">
                      {is_video_on ? (
                        <img
                          src="/media/misc/on-video.svg"
                          className="aduio_icon"
                          style={{ width: 18 }}
                          alt="unable to load"
                        />
                      ) : (
                        <img
                          src="/media/misc/off-video.svg"
                          className="aduio_icon"
                          style={{ width: 18 }}
                          alt="unable to load"
                        />
                      )}

                      <div className="custom-control custom-switch" data-toggle="tooltip" title={is_video_on ? "Turn off video" : "Turn on video"}>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitches"
                          onChange={(e) => Video_toggle(e)}
                          disabled={is_checkbox_disabled}
                          checked={is_video_on}
                        />
                        <label className="custom-control-label" htmlFor="customSwitches"></label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="text-right button-wrapper">
            <h3 style={{ color: 'red' }}> {message}</h3>

            <button
              type="button"
              className="cancel_button"
              disabled={connecting}
              onClick={() => {
                Cancle_pressed()
              }}
            >
              Cancel
            </button>
            <button
              type="button "
              className="join-btn"
              onClick={join_twili_call}
              disabled={connecting || but_disable}
            >
              {connecting ? "Connecting..." : "Join now"} <img src="/media/misc/on-video.svg" className="video-icon" alt="unable to load" />
              {connecting && <span className="ml-3 mr-3 spinner spinner-white"></span>}
            </button>
          </div>
        </div>
      </>
    )
  }
  return render
}

export default VideoChat
