import React, { useState, useEffect } from "react"
// import * as auth from "../../../../app/modules/Auth/_redux/authRedux"
import { useSelector, shallowEqual } from "react-redux"
import { Get_stipend_amount, get_ctms_token } from "../../../../app/modules/Auth/_redux/authCrud"
import { Spinner } from "react-bootstrap"
import Icon from "@material-ui/core/Icon"

export function Dash_events_Wid() {
  const notifications = useSelector((state) => state.auth.notifications, shallowEqual)
  const [lodaing_status_total, setlodaing_status_total] = useState(true)
  const [grand_earning, setgrand_earning] = useState()
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)

  const [total_visit, settotal_visit] = useState("")
  const [total_visit_status, settotal_visit_status] = useState(true)

  const upcoming_events_All = useSelector(
    (state) => state.auth.upcoming_events,
    shallowEqual
  )


  useEffect(() => {
    if (upcoming_events_All) {

      settotal_visit_status(false)
      settotal_visit(upcoming_events_All[1].countEvents[0].Eventcount)
    }
  }, [upcoming_events_All])

  useEffect(() => {
    if (notifications) {
      if (studies_?.length > 0) {
        // Get_stipend_total()
        Get_sessionkey()
        setlodaing_status_total(true)
        if (localStorage.getItem("site_obj")) {
          // Get_sesson_key()
          // settotal_visit(upcoming_events_All[0].UpcomingEvents.length)
        }
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])
  //to get session key
  const Get_sessionkey = () => {
    // const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
    get_ctms_token()
      .then((value) => {
        Get_stipend_total(value.data)
      })
      .catch((error) => { })
  }

  // to get stipend total
  const Get_stipend_total = async (key) => {
    await Get_stipend_amount(key)
      .then((data) => {
        setgrand_earning(data.data.AvailableBalance)
        setlodaing_status_total(false)
      })
      .catch((err) => {
        setlodaing_status_total(false)
      })
  }

  // totoal vist count  code

  // let dropdown_Selected_data = JSON.parse(localStorage.getItem("site_obj"))

  // const Get_sesson_key = async () => {
  //   await Study_api(dropdown_Selected_data)
  //     .then((value) => {
  //       Get_Total_visit(value.data?.SessionKey)
  //       settotal_visit_status(true)
  //     })
  //     .catch((error) => {
  //       settotal_visit("something went wrong")
  //       settotal_visit_status(false)
  //     })
  // }
  // const Get_Total_visit = async (SessionKey) => {
  //   await Get_Total_visit_(SessionKey, dropdown_Selected_data)
  //     .then((value) => {
  //       settotal_visit(value.data.totalVisits)
  //       settotal_visit_status(false)
  //     })
  //     .catch((error) => {
  //       settotal_visit("something went wrong")
  //       settotal_visit_status(false)
  //     })
  // }

  useEffect(() => {
    // console.clear()
  })
  return (
    <>
      <div className="dash-event d-flex">
        <div className="dash-event-inner active status-wrap">
          <div className="event-info ">

            <p>Your status in this study is  <b>{localStorage.getItem("EnrollmentStatus") === "null" ? "NA" : localStorage.getItem("EnrollmentStatus")} </b></p>
          </div>
          <div className="event-img">
            <img src="/media/misc/status-.svg" className="status-image" alt="" />
          </div>
        </div>
        <div className="dash-event-inner active">
          <div className="event-info ">
            {total_visit_status ? <Spinner animation="border" variant="primary" /> : <h2>{total_visit}</h2>}

            <p>Total Events</p>
          </div>
          <div className="event-img">
            <img src="/media/misc/building.svg" alt="" />
          </div>
        </div>

        <div className="dash-event-inner d-flex align-items-center">
          <div className="event-info ">
            {lodaing_status_total ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div className="d-flex">
                <h2
                  className={`amount-text ${grand_earning?.length > 6 ? "error-msg" : ""
                    }`}
                >
                  {grand_earning}
                </h2>
                {grand_earning?.length > 6 ? (
                  <Icon
                    className="fa fa-exclamation-triangle"
                    style={{
                      marginBottom: -4,
                      fontSize: 17,
                      marginLeft: 5,
                      width: 20,
                      color: "#ff5858",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            )}{" "}
            <p className="total-bal-text">Total Available Balance</p>
          </div>
          <div className="event-img">
            <img src="/media/misc/earning.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  )
}
