import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"
import { Date_convert } from "../../modules/Auth/_redux/Common_code"


export const EsignType = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value

    const [isFieldHidden, setIsFieldHidden] = useState(false)


    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    let idterm
    if (commonId) {
        idterm = form_value[children_elements.UUID]?.[commonId]


    } else {
        idterm = form_value[children_elements.UUID]

    }

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])


    return (
        !isFieldHidden ? <>

            <div className="input_label">

            </div>
            <div className="flex-width">
                <div className="input_wrap upload-btn-wrapper e-sign">

                    <div className="">

                        <button type="button" className="form_btn ml-0" onClick={() => { props.Esign_clicked(children_elements, props.rowid) }}> Sign Electronically</button>
                        <p className="color-red" >{children_elements.Meta?.Required ? "*" : ""}</p>
                    </div>



                </div>

                <div className="mb-0 height-scroll">
                    {
                        idterm?.signatures?.map((data, index) => {
                            return [<div className="sign-data" key={index}>
                                <p><span className="sign-label">Signed By : </span>{data?.DisplayName}</p>
                                <p> <span className="sign-label">Statement : </span>{data?.Statement}</p>
                                <p><span className="sign-label">Signing Time : </span>{Date_convert(new Date(data?.DateSigned.replace(" ", "T") + "Z"), 5)}</p>
                                <p><span className="sign-label">IP Address : </span>{data?.IPAddress}</p>
                            </div>


                            ]
                        })
                    }
                </div>
            </div>
        </> : ""
    )
}
