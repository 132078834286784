import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Addresstype = (props) => {
    const children_elements_ = props.children_elements
    const form_value = props.form_value
    const country_list = props.country_list
    let commonId = props.rowid ? props.rowid : form_value[children_elements_?.Parent]?.[0]
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    const numCheck = (e) => {
        const characters = [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            '~', '`', '!', '@', '#', '$', '%', '^', '&', '*', ')', '(', '-', '=', '_', '+',
            '>', '<', '.', '?', '/', ':', ';', '|', '\\', ']', '}', '[', '{', "'", '"', ' '
        ];
        if (characters.includes(e.key)) e.preventDefault();
    }

     // Check element visibility when form_value changes
     useEffect(() => {
        let is_hidden = check_field_visibility(children_elements_, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    return (
        !isFieldHidden?<>

        <div className="input_label">
            <label className="label_" htmlFor="">{children_elements_.Meta.Label?.blocks?.[0].text ? children_elements_.Meta.Label?.blocks?.[0].text : children_elements_.Meta.Label}</label>
        </div>
        <div className="address_list">
            {children_elements_?.Children?.map((children_elements, index) => {

                return [
                    <React.Fragment key={index}>
                        {children_elements.Meta.Hidden === false && children_elements.Type === "text_single_line" ? <div className="address_list_input">
                            {index === 4 && <input
                                className="checkbox1"
                                name={children_elements.UUID}
                                type="number"
                                placeholder={children_elements.Meta.Label}
                                required={children_elements.Meta.Required}
                                onKeyDown={(evt) => numCheck(evt)}
                                onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield", children_elements.UUID, null, children_elements_, props.rowid) }}
                                value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId].trim() : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                                onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements_?.Parent, null, null, props.rowid) }}
                            />}
                            {index !== 4 && <input
                                className="checkbox1"
                                name={children_elements.UUID}
                                type="text"
                                placeholder={children_elements.Meta.Label}
                                required={children_elements.Meta.Required}
                                onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield", children_elements.UUID, null, children_elements_, props.rowid) }}
                                value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                                onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements_?.Parent, null, null, props.rowid) }}
                            />}


                            <p className="" style={{ color: "red" }}>{children_elements.Meta?.Required ? "*" : ""}</p> </div> : ""}

                        {children_elements_.Meta.International === true && children_elements.Meta.Hidden === false && children_elements.Type === "dropdown" ? <div>  <select required className=" country-select" name={children_elements.UUID}
                            value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                            onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements_?.Parent, null, null, props.rowid) }}
                           
                        >
                            <option value="">{children_elements.Meta.Label}</option>
                            {country_list?.map((country_array, country_index) => {
                                return [
                                    <option key={country_index} value={country_array.Code}>{country_array.Name}</option>

                                ]
                            })}

                        </select> <p className="" style={{ color: "red" }}>{children_elements.Meta.Required ? "*" : ""}</p></div> : ""}
                    </React.Fragment>
                ]
            })}
        </div>

    </>:""
    )
}
