import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { useSubheader } from "../../_common/layout"
import TextField from "@material-ui/core/TextField"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Dashboard_invite_api } from "../modules/Auth/_redux/authCrud"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { withStyles } from "@material-ui/core/styles"
import DrangDropFile from "../../_common/_helpers/Drag_n_drop_file"
import Icon from "@material-ui/core/Icon"
import { Spinner } from "react-bootstrap"
import { toAbsoluteUrl } from "../../_common/_helpers"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
// eslint-disable-next-line no-unused-vars
import ReactToPrint from "react-to-print"
import TablePagination from "@material-ui/core/TablePagination"
import { Date_convert_without_time_zone } from "../modules/Auth/_redux/Common_code"

import {
  update_document,
  get_ctms_token,
  view_download_document_ctms,
  Document_archive_flag,
  Ctms_archive_doc,
  edit_document2,
  Document_view_flag,
} from "../modules/Auth/_redux/authCrud"

const styles = (theme) => ({
  indicator: {
    backgroundColor: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.4),
    color: "#fff",
    paddingRight: "10px",
  },
})
function TabPanel(props) {
  const { children, value, index, ...other } = props
  const suhbeader = useSubheader()
  suhbeader.setTitle(" Documents")
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="div"
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}
export const My_documents = () => {
  const [value, setValue] = React.useState(0)
  const [img_value, setimg_value] = useState(false)
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const [doc_data_ssd, setdoc_data_ssd] = useState([])
  const [doc_data_ctms, setdoc_data_ctms] = useState([])
  const [doc_data_ctms_tab2, setdoc_data_ctms_tab2] = useState([])
  const CtmsDoc = useSelector((state) => state.auth.CtmsDoc, shallowEqual)
  const docpp = useSelector((state) => state.auth.docpp, shallowEqual)
  const [search_box, setsearch_box] = useState("")
  const [study_id_, setstudy_id_] = useState()
  const [submit_button_visible, setsubmit_button_visible] = useState(false)
  const [hide_fileUpload, sethide_fileUpload] = useState(false)
  const [Archive_value, setArchive_value] = useState("false")
  const [Id_sharedDoc, setId_sharedDoc] = useState("")
  const [title_sharedDoc, settitle_sharedDoc] = useState("")
  const [url_shareddoc, seturl_shareddoc] = useState("")
  const [File_name, setFile_name] = useState("File")
  const componentRef = useRef()
  const dispatch = useDispatch()
  const [file_, setfile_] = useState("http://www.pdf995.com/samples/pdf.pdf")
  const [data_1, setdata_1] = useState([
    {
      uri: "https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf",
    },
  ])
  // to detect ios devices
  let is_ios_device = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // for pagination start
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [iframeUrl, setIframeUrl] = React.useState("")
  const [openSignPopup, setOpenSignPopup] = React.useState(false)
  const [signFileName, setSignFileName] = React.useState("")



  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // to call ctms doc when clik on side menu
  useEffect(() => {
    if (studies_?.length > 0) {
      dispatch(auth.actions.requesShareddDoc())
      dispatch(auth.actions.requestGlobal_notification())
      get_ctms_econsent()

    }
    // closing esign popup on event
    window.addEventListener("message", function (event) {
      if (event.data === "ecsign_close") {
        setOpenSignPopup(false)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // calling invite api
  useEffect(() => {
    if (!studies_) {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setstudy_id_(JSON.parse(localStorage.getItem("StudyID_local")))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CtmsDoc])

  // redux data of ctms doc
  useEffect(() => {
    // to fix refresh issues
    if (studies_) {
      const study_id_local = JSON.parse(localStorage.getItem("StudyID_local"))
      setdoc_data_ctms(
        CtmsDoc?.filter((data) => {
          return (data?.dateSigned === null)

        })
      )

      setdoc_data_ctms_tab2(
        CtmsDoc?.filter((data) => {
          return (data?.dateSigned !== null)

        })
      )
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CtmsDoc])

  // redux data of shared doc
  useEffect(() => {
    if (studies_) {
      if (docpp) {
        setdoc_data_ssd(docpp)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docpp])

  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => {
      })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 2) {
      Toggle_sharedDoc("false")
    }
    setsearch_box("")
  }


  //form methods

  const [formdata, setformdata] = useState({
    Document_title: "",
    Document_Description: "",
    version: "",
    title_error: "",
    Description_error: "",
    version_error: "",
  })

  const field_change = (event, errorname) => {
    let value = event.target.value
    let name = event.target.name
    let namefor_errormessage = ""
    if (name === "Document_title") {
      namefor_errormessage = "title"
    } else {
      namefor_errormessage = "description"
    }

    if (value.trim().length > 0) {
      setformdata({ ...formdata, [errorname]: "", [name]: value })
    } else {
      setformdata({
        ...formdata,
        [errorname]: "Document " + namefor_errormessage + " is required",
        [name]: "",
      })
    }
    if (value.trim().length > 150) {
      setformdata({
        ...formdata,
        [errorname]: "Document " + namefor_errormessage + " is too lengthy",
      })
    }
  }

  const OnBlurField = (e, errorname) => {
    let value = e.target.value
    let name = e.target.name
    let namefor_errormessage = ""
    if (name === "Document_title") {
      namefor_errormessage = "title"
    } else {
      namefor_errormessage = "description"
    }

    if (value.trim().length === 0) {
      setformdata({
        ...formdata,
        [errorname]: "Document " + namefor_errormessage + " is required",
        [name]: value,
      })
    }
  }

  //popup methods
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions)

  const [open, setOpen] = React.useState(false)
  const [opens, setOpens] = React.useState(false)
  const [errorpopup, seterrorpopup] = React.useState(false)
  const [FileData, setFileData] = React.useState([])
  const [opensFile_pop, setopensFile_pop] = React.useState(false)
  const [loading, setloading] = React.useState(false)

  // method to update upload doc shared doc
  const update_mydocument = (file) => {
    update_document(
      file,
      formdata.Document_title,
      formdata.Document_Description,
      formdata.version,
      Id_sharedDoc,
      title_sharedDoc
    )
      .then((pre) => {
        setOpens(true)
        setimg_value(false)
        setsubmit_button_visible(false)
        sethide_fileUpload(false)
        setTimeout(() => {
          dispatch(auth.actions.requesShareddDoc())
          // dispatch(auth.actions.requesCTMSdDoc())
          get_ctms_econsent()
        }, 500)
      })
      .catch((error) => {
        setimg_value(false)
        setTimeout(() => {
          dispatch(auth.actions.requesShareddDoc())
        }, 1000)
        setsubmit_button_visible(false)
        sethide_fileUpload(false)
      })
  }

  // edit document shared without file

  const edit_mydocument2 = () => {
    edit_document2(Id_sharedDoc, formdata.Document_title, formdata.Document_Description, url_shareddoc)
      .then((pre) => {
        setOpens(true)
        setimg_value(false)
        setsubmit_button_visible(false)
        sethide_fileUpload(false)
        setTimeout(() => {
          dispatch(auth.actions.requesShareddDoc())
          get_ctms_econsent()
        }, 100)
      })
      .catch((error) => {
        setimg_value(false)
        setTimeout(() => {
          dispatch(auth.actions.requesShareddDoc())
        }, 1000)
        setsubmit_button_visible(false)
        sethide_fileUpload(false)
      })
  }

  const handleClickOpens = () => {
    if (FileData.length > 0 && FileData[0].size < 52444444) {
      setimg_value(true)
      setsubmit_button_visible(true)
      if (localStorage.getItem("tpeofcall") === "edit") {
        update_mydocument(FileData)
      } else {
        update_mydocument(FileData)
      }
      sethide_fileUpload(true)
    } else {
      if (Id_sharedDoc === "") {
        seterrorpopup(true)
      }
      if (Id_sharedDoc !== "") {
        edit_mydocument2()
        setimg_value(true)
      }
    }
  }
  const handleCloses = () => {
    setOpens(false)
    setFileData([])
    setOpen(false)
    setformdata({
      Document_title: "",
      Document_Description: "",
      version: "",
      title_error: "",
      Description_error: "",
      version_error: "",
    })
  }
  const handleCloses_error = () => {
    seterrorpopup(false)
    setFileData([])
  }

  // file view popup close
  const handleCloses_filePop = () => {
    setopensFile_pop(false)
  }

  // main dialog method
  const handleClose = () => {
    setOpen(false)
    setsubmit_button_visible(false)
    setFileData([])
    setformdata({
      Document_title: "",
      Document_Description: "",
      version: "",
      title_error: "",
      Description_error: "",
      version_error: "",
    })
    setimg_value(false)
  }
  // upload document
  const handleClickOpen = () => {
    localStorage.setItem("tpeofcall", "upload")
    setId_sharedDoc("")
    settitle_sharedDoc("")
    setOpen(true)
  }
  //edit document
  const handleClickedit = (value) => {
    localStorage.setItem("tpeofcall", "edit")
    setId_sharedDoc(value.DocID)
    settitle_sharedDoc(value.FileName)
    seturl_shareddoc(value.FilePath)
    setformdata({
      Document_title: value.Title,
      Document_Description: value.Description,
      version: "",
      title_error: "",
      Description_error: "",
      version_error: "",
    })
    setOpen(true)
  }
  const Returndata = (data) => {
    setFileData(data)
    // img_status.current = 0;
    settitle_sharedDoc("")
  }
  const toggle_spinner = () => {
    // img_status.current = 1;
    setimg_value(true)
  }

  // method to filter table
  const [sorting_order1, setsorting_order1] = useState(true)
  const [sorting_order2, setsorting_order2] = useState(false)
  const [sorting_order3, setsorting_order3] = useState(true)
  const [sorting_para1, setsorting_para1] = useState("UpdatedDate")
  const [sorting_para2, setsorting_para2] = useState("updatedAt")
  const [sorting_para3, setsorting_para3] = useState("updatedAt")

  useEffect(() => { }, [])

  // filter table3 method
  const Filter_table3 = (value) => {
    setsorting_para3(value)
    setsorting_order3(!sorting_order3)
  }
  // filter table2 method

  const Filter_table2 = (value) => {
    setsorting_para2(value)
    setsorting_order2(!sorting_order2)
  }
  // filter table1 method
  const Filter_table1 = (value) => {
    setsorting_para1(value)
    setsorting_order1(!sorting_order1)
  }

  // sorting table 3
  useEffect(() => {
    const value = Archive_value

    const sortedoc = docpp?.sort((a, b) => {
      if (a[sorting_para3] < b[sorting_para3]) {
        return sorting_order3 ? -1 : 1
      }
      if (a[sorting_para3] > b[sorting_para3]) {
        return sorting_order3 ? 1 : -1
      }
      return 0
    })


    if (sortedoc) {
      setdoc_data_ssd(sortedoc)
    }

    setdoc_data_ssd(
      docpp?.filter((data) => {
        if (value === "true") {
          return data.Deleted === true
        }
        if (value === "false") {
          return data.Deleted === false
        }
        return null
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting_order3])
  // sorting table 2
  useEffect(() => {
    const data_tab2 = CtmsDoc?.filter((data) => {
      return (data?.dateSigned !== null)
    })
    const sortedoc = data_tab2?.sort((a, b) => {
      if (a[sorting_para2] < b[sorting_para2]) {
        return sorting_order2 ? -1 : 1
      }
      if (a[sorting_para2] > b[sorting_para2]) {
        return sorting_order2 ? 1 : -1
      }
      return 0
    })


    if (sortedoc) {
      setdoc_data_ctms_tab2(sortedoc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting_order2])
  // sorting table 1
  useEffect(() => {
    const data_tab1 = CtmsDoc?.filter((data) => {
      return (data?.dateSigned === null)
    })

    const sortedoc = data_tab1?.sort((a, b) => {
      if (a[sorting_para1] < b[sorting_para1]) {
        return sorting_order1 ? -1 : 1
      }
      if (a[sorting_para1] > b[sorting_para1]) {
        return sorting_order1 ? 1 : -1
      }
      return 0
    })


    if (sortedoc) {
      setdoc_data_ctms(sortedoc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting_order1])

  // getting seesion id for api call
  const Get_sessionId = async (values, type, file_format) => {
    // we are not chanhing status on admin view
    if (values?.IsVieweDoc === false && sessionStorage.getItem("is_admin_control") !== "true") {
      Document_view_flag(values.id, "Documents")
        .then((dat) => { })
        .catch((err) => { })
    }

    setFile_name(values?.title)

    // const doc_id = values.CTMSDocumentID
    const doc_id = values?.documentID
    if (type === 0 && sessionStorage.getItem("is_admin_control") !== "true") {
      openDocumentToSign(values)

    } else {
      if (type === 1) {
        setopensFile_pop(true)
        setloading(true)
        // Setview_doc(true)

      }
      await get_ctms_token()
        .then((value) => {
          Download_view_doc(value.data, doc_id, type, file_format)
        })
        .catch((error) => {
        })
    }

  }

  // to download view single doc

  const Download_view_doc = async (key, doc_id, type, file_format) => {
    await view_download_document_ctms(key, doc_id)
      .then((value) => {

        if (file_format === "pdf") {
          setfile_(value.data.DownloadURL)
          setdata_1([{ uri: value.data.DownloadURL }])
        } else {
          setfile_(value.data.DownloadURL + "&original=1")
          setdata_1([{ uri: value.data.DownloadURL + "&original=1" }])
        }
        setloading(false)
        get_ctms_econsent()
        if (type === 3) {
          window.open(value.data.DownloadURL, '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0')
          return
        }
        if (type === 2) {
          // for iphone 
          if (is_ios_device) {
            window.open(value.data.DownloadURL, '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0')
            return
          }
          if (file_format === "pdf") {
            let url_tem = value.data.DownloadURL
            download_file(url_tem)
          } else {
            let url_tem_ = value.data.DownloadURL + "&original=1"

            download_file(url_tem_)
          }
        }
      })
      .catch((err) => {
        setloading(false)
        get_ctms_econsent()
      })
  }

  const download_file = (url) => {
    const link_ = document.createElement("a")
    link_.href = url
    link_.download = "document";
    link_.target = "_blank"
    document.body.appendChild(link_)
    setTimeout(() => {
      link_.click()
      document.body.removeChild(link_)
    }, 500);
  }

  // to view shared document
  const viewdocshared = (values) => {
    setfile_(values.FilePath)
    setdata_1([{ uri: values.FilePath }])
    // Setview_doc(true)
    setFile_name(values.FileName)
    setopensFile_pop(true)
    Document_view_flag(values.DocID, "SharedDocs")
      .then((dat) => dispatch(auth.actions.requesShareddDoc()))
      .catch((err) => dispatch(auth.actions.requesShareddDoc()))
  }
  // to archive shared doc
  const Archive_shared_doc = (values, type) => {
    Document_archive_flag(values.DocID, type)
      .then((data) => {
        dispatch(auth.actions.requesShareddDoc())
        ctms_archive_update(values.DocID, type)
      })
      .catch((err) => {
        dispatch(auth.actions.requesShareddDoc())
      })
  }
  // to show by default  un archieve doc
  useEffect(() => {
    if (docpp) {
      Toggle_sharedDoc(Archive_value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docpp])

  // to archive shared doc
  const Toggle_sharedDoc = (value) => {
    setArchive_value(value)
    setdoc_data_ssd(
      docpp?.filter((data) => {
        if (value === "true") {
          return data.Deleted === true
        }
        if (value === "false") {
          return data.Deleted === false
        }
        return null
      })
    )
  }

  // to update ctms about archive doc part1
  const ctms_archive_update = async (id, type) => {
    const dropdown_Selected_data = JSON.parse(localStorage.getItem("site_obj"))

    await get_ctms_token(dropdown_Selected_data)
      .then((value) => {
        ctms_archive_update2(value.data, id, type)
      })
      .catch((error) => {
      })
  }
  // to update ctms about archive doc part2

  const ctms_archive_update2 = (key, id, type) => {
    Ctms_archive_doc(key, id, type)
      .then((data) => {
      })
      .catch((err) => {
      })
  }


  // Function to request CTMS econsents
  const get_ctms_econsent = async () => {
    const dropdown_Selected_data = JSON.parse(localStorage.getItem("site_obj"))
    await get_ctms_token()
      .then((value) => {
        if (value.data.length > 0) {
          dispatch(auth.actions.requesCTMSdDoc(value.data))
        }
      })
      .catch((error) => {
      })
  }


  const openDocumentToSign = async (values) => {

    const url = values.ctmsURL

    await get_ctms_token()
      .then((data) => {
        // let src = "https://rt.dev4.redaxle.com/jb189/pdfviewer/?src=https%3A%2F%2Frt.dev4.redaxle.com%2Fjb189%2Findex.php%3Faction%3D%2Fdocuments%2Fview%26hash%3D8ebd939702d78eec6647cec17cfb425304a14955%26rand%3D55656565656%26layout%3Decsign%26sid%3D719%26Key%3D2a95c7f2acf12d09101496e4d1fcdd3d471c0372%26bu%3Dhttps%3A%2F%2Frt.dev4.redaxle.com%2Fjb189&uid=1277&did=3108"

        let src = url + "/pdfviewer/?src=" + (encodeURIComponent(url + "/index.php?action=/documents/view&hash=" + values?.hash + "&rand=" + Math.round(Math.random * 10000000) + "&layout=ecsign&sid=" + values?.studyID + "&Key=" + data?.data + "&bu=" + url + "&uid=" + values?.patientUserID + "&did=" + values?.documentID))
        console.log("EncodedUri:- ", src)
        setIframeUrl(src)
        setSignFileName(values?.title)
        setOpenSignPopup(true)
      })
      .catch((error) => {
      })
  }
  // to close iframe
  const iframeClose = () => {
    setOpenSignPopup(false)
    get_ctms_econsent()

  }
  return (
    <div className="row">

      <div className="my-document my-document-wrapper">
        <div className="document-inner">
          <div className="form-inline">
            <h3 className="mb-0 mr-3">My Study Consent Form</h3>
            <div className="d-flex justify-content-end right ">
              {value === 2 && studies_.length > 0 ? (
                <div className="mr-5 btn-wrp">
                  <button className="btn btn-primary uplaod-btn" onClick={handleClickOpen}>
                    <i className="fas fa-plus-circle"></i>
                    Upload Shared Document
                  </button>
                </div>
              ) : (
                ""
              )}
              {value === 2 && studies_?.length > 0 ? (
                <div className="form-group mr-5 select-box">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={(e) => {
                      Toggle_sharedDoc(e.target.value)
                    }}
                  >
                    <option disabled selected>
                      Type of documents
                    </option>
                    <option value={true}>Archieve</option>
                    <option value={false}>Un Archieve</option>
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className="form-group search-box">
                <input
                  className="form-control mr-sm-1 search-box"
                  type="search"
                  placeholder="Search by Title"
                  aria-label="Search"
                  onChange={(e) => {
                    setsearch_box(e.target.value)
                  }}
                  value={search_box}
                />
              </div>
            </div>
          </div>
          <div className="tab-wrapper">

            <AppBar position="static" elevation={0}>
              <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="nav tabs example">
                {/* on hold */}

                <LinkTab
                  className="first-tab"
                  label="Signature Requested"
                  icon={<i className="fa fa-file-signature"></i>}
                  href="/drafts"
                  {...a11yProps(0)}
                />

                <LinkTab
                  className="second-tab"
                  label="Completed Consent Form"
                  href="/trash"
                  icon={<CheckCircleIcon />}
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className="tab">
              {doc_data_ctms?.length === 0 || doc_data_ctms === undefined ? (
                <div className="dash-top">
                  {" "}
                  <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
                  <p>No document found</p>{" "}
                </div>
              ) : (
                <div className="table-main-wrap">
                  <table
                    id="dtOrderExample"
                    className="table table-striped table-bordered table-sm table-wrapper "
                    cellSpacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th className="th-sm" onClick={() => Filter_table1("title")}>
                          Document Title <i className="fas fa-sort ml-1 "></i>
                        </th>
                        <th className="th-sm" onClick={() => Filter_table1("dateRequested")}>
                          Date Requested<i className="fas fa-sort ml-1"></i>
                        </th>
                        <th className="th-sm" onClick={() => Filter_table1("dateRequested")}>
                         Requested By
                        </th>
                        <th className="th-sm" onClick={() => Filter_table1("Version")}>
                          Version<i className="fas fa-sort ml-1"></i>
                        </th>
                        <th className="th-sm text-center action"> Action</th>
                      </tr>
                    </thead>
                    <tbody key={345}>
                      {doc_data_ctms
                        ?.filter((v) => {
                          return v?.title.toLowerCase()?.includes(search_box.toLowerCase())
                        })
                        .map((values, index) => {
                          return [
                            <React.Fragment key={index}>
                              {(values?.dateSigned === null) ? (
                                <tr>
                                  <td>{values?.title}</td>
                                  <td>
                                    {Date_convert_without_time_zone(values?.dateRequested, 4)}
                                  </td>
                                  <td>
                                    {values?.signatureRequester ? values.signatureRequester.firstName + " " + values.signatureRequester.lastName : "-"}
                                  </td>
                                  <td>{values.Version ? values.Version : "-"}</td>
                                  <td className=" d-flex justify-content-center">
                                    <button onClick={() => {
                                      Get_sessionId(values, 0, "original")
                                    }} className="btn btn-primary sign-btn">
                                      <i
                                        className={`fa fa-file-signature icon`}

                                      ></i>
                                      SIGN
                                    </button>
                                    <div className="btn-group">
                                      <button
                                        type="button"
                                        className="btn dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-h icon"></i>
                                      </button>
                                      <div className="dropdown-menu">
                                        <button
                                          className="dropdown-item"
                                          onClick={() => {
                                            Get_sessionId(values, 3, "original")
                                          }}
                                        >
                                          <img src="/media/misc/print.svg" alt="unable to load" /> Print
                                        </button>
                                        <button
                                          className="dropdown-item"

                                          onClick={() => {
                                            Get_sessionId(values, 2, "original")
                                          }}
                                        >
                                          <img src="/media/misc/download.svg" alt="unable to load" /> Download

                                        </button>

                                      </div>
                                    </div>{" "}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </React.Fragment>,
                          ]
                        })}
                    </tbody>
                  </table>
                  <div className="pagination-wrap">
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={doc_data_ctms?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1} className="tab">
              {doc_data_ctms_tab2?.length === 0 || doc_data_ctms_tab2 === undefined ? (
                <div className="dash-top">
                  {" "}
                  <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
                  <p>No document found</p>{" "}
                </div>
              ) : (
                <div className="table-main-wrap">
                  <table
                    id="dtOrderExample"
                    className="table table-striped table-bordered table-sm table-wrapper "
                    cellSpacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th className="th-sm" onClick={() => Filter_table2("title")}>
                          Document Title <i className="fas fa-sort ml-1 "></i>
                        </th>
                        <th className="th-sm" onClick={() => Filter_table2("dateSigned")}>
                          Date Signed<i className="fas fa-sort ml-1"></i>
                        </th>
                        <th className="th-sm" onClick={() => Filter_table2("dateRequested")}>
                         Requested By
                        </th>
                        <th className="th-sm" onClick={() => Filter_table2("Version")}>
                          Version<i className="fas fa-sort ml-1"></i>
                        </th>
                        <th className="th-sm text-center action"> Action</th>
                      </tr>
                    </thead>
                    <tbody key={345}>
                      {doc_data_ctms_tab2
                        ?.filter((v) => {
                          return v?.title.toLowerCase()?.includes(search_box.toLowerCase())
                        })?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((values, index) => {
                          return [
                            <React.Fragment key={index}>
                              {(values?.dateSigned !== null) ? (
                                <tr >
                                  <td>{values?.title}</td>
                                  <td>
                                    {Date_convert_without_time_zone(values?.dateSigned, 4)}
                                  </td>
                                  <td>
                                    {values?.signatureRequester ? values.signatureRequester.firstName + " " + values.signatureRequester.lastName : "-"}
                                  </td>
                                  <td>{values.Version ? values.Version : "-"}</td>
                                  {<td className=" d-flex justify-content-center">
                                    <i
                                      className={`far fa-eye  mr-6 icon ${values?.IsVieweDoc ? "green-more" : ""
                                        } `}
                                      onClick={() => {
                                        Get_sessionId(values, 1, "original")
                                      }}
                                    ></i>
                                    {!is_ios_device &&
                                      <div className="btn-group">
                                        <button
                                          type="button"
                                          className="btn dropdown-toggle"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-h icon"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <button
                                            className="dropdown-item"
                                            onClick={() => {
                                              Get_sessionId(values, 3, "original")
                                            }}
                                          >
                                            <img src="/media/misc/print.svg" alt="unable to load" /> Print
                                          </button>
                                          <button
                                            className="dropdown-item"
                                            onClick={() => {
                                              Get_sessionId(values, 2, "original")
                                            }}
                                          >
                                            <img src="/media/misc/download.svg" alt="unable to load" /> Download Original

                                          </button>
                                          <button
                                            className="dropdown-item"

                                            onClick={() => {
                                              Get_sessionId(values, 2, "pdf")
                                            }}
                                          >
                                            <img src="/media/misc/download.svg" alt="unable to load" />
                                            Download Pdf
                                          </button>
                                        </div>
                                      </div>
                                    }
                                  </td>}
                                </tr>
                              ) : (
                                ""
                              )}
                            </React.Fragment>,
                          ]
                        })}
                    </tbody>
                  </table>
                  <div className="pagination-wrap">
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={doc_data_ctms_tab2?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={2} className="tab">
              {doc_data_ssd?.length === 0 || doc_data_ssd === undefined ? (
                <div className="dash-top">
                  {" "}
                  <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
                  <p>No document found</p>{" "}
                </div>
              ) : (
                <div className="table-main-wrap">
                  <table
                    id="dtOrderExample"
                    className="table table-striped table-bordered table-sm table-wrapper  "
                    cellSpacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th className="th-sm" onClick={() => Filter_table3("Title")}>
                          Document Title <i className="fas fa-sort ml-1 "></i>
                        </th>
                        <th className="th-sm">Site</th>

                        <th className="th-sm" onClick={() => Filter_table3("UploadedDate")}>
                          Uploaded date<i className="fas fa-sort ml-1"></i>
                        </th>
                        <th className="th-sm" onClick={() => Filter_table3("UpdatedDate")}>
                          Updated date<i className="fas fa-sort ml-1"></i>
                        </th>

                        <th className="th-sm">
                          Description<i className="fas fa-sort ml-1"></i>
                        </th>

                        <th className="th-sm" onClick={() => Filter_table3("Version")}>
                          Version<i className="fas fa-sort ml-1"></i>
                        </th>
                        <th className="th-sm text-center action"> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {doc_data_ssd
                        ?.filter((v) => {
                          return v.Title.toLowerCase()?.includes(search_box.toLowerCase())
                        })
                        .map((values, index) => {
                          return (
                            <tr key={index}>
                              <td>{values.Title}</td>

                              <td>
                                {values.CTMSSiteTitle}
                              </td>
                              <td>
                                {values.UploadedDate?.slice(8, 10)}-{values.UploadedDate?.slice(5, 7)}-
                                {values.UploadedDate?.slice(0, 4)}
                              </td>
                              <td>
                                {values.UpdatedDate?.slice(8, 10)}-{values.UpdatedDate?.slice(5, 7)}-
                                {values.UpdatedDate?.slice(0, 4)}
                              </td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="/media/misc/desc.svg" alt="unable to load" />
                                  </button>
                                  <div className="dropdown-menu">{values.Description}</div>
                                </div>
                              </td>
                              <td>{values.Version}</td>
                              <td className=" d-flex justify-content-center">
                                <i
                                  className="fas fa-pen  mr-6 icon"
                                  onClick={() => {
                                    handleClickedit(values)
                                  }}
                                ></i>
                                <i
                                  className={`far fa-eye mr-6 icon ${values.IsVieweDoc ? "green-more" : ""} `}
                                  onClick={() => {
                                    viewdocshared(values)
                                  }}
                                ></i>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis-h icon"></i>
                                  </button>
                                  <div className="dropdown-menu">
                                    <button className="dropdown-item" >
                                      <img src="/media/misc/print.svg" alt="unable to load" /> Print
                                    </button>
                                    <button
                                      className="dropdown-item"

                                      onClick={() => {
                                        download_file(values.FilePath)
                                      }}
                                    >
                                      <img src="/media/misc/download.svg" alt="unable to load" /> Download
                                    </button>
                                    {Archive_value === "false" ? (
                                      <button
                                        className="dropdown-item"

                                        onClick={() => {
                                          Archive_shared_doc(values, 1)
                                        }}
                                      >
                                        <img src="/media/misc/archieve.svg" alt="unable to load" /> Archieve
                                      </button>
                                    ) : (
                                      <button
                                        className="dropdown-item"

                                        onClick={() => {
                                          Archive_shared_doc(values, 0)
                                        }}
                                      >
                                        <img src="/media/misc/archieve.svg" alt="unable to load" /> UnArchieve
                                      </button>
                                    )}
                                  </div>
                                </div>{" "}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>
          </div>
        </div>

        <div>
          <Dialog
            onClose={handleClose}

            aria-labelledby="customized-dialog-title"
            open={open}
            className="uplaod-profile "
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose} className="popup-heading">
              {localStorage.getItem("tpeofcall") === "edit" ? "Edit Document " : "Upload Document"}
            </DialogTitle>
            <TextField
              id="standard-basic"
              label="Document Title"
              className="textfield"
              name="Document_title"
              onChange={(event) => {
                field_change(event, "title_error")
              }}
              value={formdata.Document_title}
              onBlur={(e) => OnBlurField(e, "title_error")}
            />

            <div className="color-red text-center">{true && formdata.title_error}</div>

            <TextField
              id="standard-basic"
              multiline
              label="Document Description"
              className="textfield"
              name="Document_Description"
              onChange={(event) => {
                field_change(event, "Description_error")
              }}
              value={formdata.Document_Description}
              onBlur={(e) => OnBlurField(e, "Description_error")}
            />
            <div className="color-red text-center"> {true && formdata.Description_error}</div>
            <DialogContent divider className="middle-content ">
              <div className="success-icon"> {title_sharedDoc}</div>
              {FileData.length > 0 ? (
                <div className="success-icon">
                  {FileData[0]?.name}
                  <Icon className="fa fa-check icon" />
                </div>
              ) : (
                ""
              )}
              {hide_fileUpload === false ? (
                <DrangDropFile
                  filetype=".pdf,.txt,.doc,.docx ,.png,.jpg,.ppt,.pptx,.xls,.xlsx,.tiff"
                  Returndata={Returndata}
                  toggle_spinner={toggle_spinner}
                />
              ) : (
                ""
              )}
              {img_value && <Spinner animation="border" variant="primary" />}
            </DialogContent>
            <DialogActions className="pb-6 pt-6 btn-wrap">
              <div className="card-toolbar">
                <button
                  type="submit"
                  className="btn btn-primary mr-5 save-btn"
                  onClick={handleClickOpens}
                  disabled={
                    formdata.Document_title === "" ||
                    formdata.Document_Description === "" ||
                    submit_button_visible === true ||
                    formdata.Description_error !== "" ||
                    formdata.title_error !== "" ||
                    (FileData.length === 0 && Id_sharedDoc === "")
                  }
                >
                  Submit
                </button>
                <button className="btn btn-secondary" onClick={handleClose} >
                  Cancel
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>

        {/* Successfully popup */}
        <Dialog
          onClose={handleCloses}
          aria-labelledby="customized-dialog-title"
          open={opens}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-check icon" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">Alert!</h2>
            <p className="success-text mb-10">Your file successfully uploaded!</p>
            <button
              type="submit"
              onClick={handleCloses}
              className="btn btn-primary mr-5 save-btn"
              disabled={false}
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* Failed dialog */}
        <Dialog
          onClose={handleCloses_error}
          aria-labelledby="customized-dialog-title"
          open={errorpopup}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">Please upload a file with size less than 50 MB !</p>
            <button
              type="submit"
              onClick={handleCloses_error}
              className="btn btn-primary mr-5 save-btn"
              disabled={false}
            >
              Ok
              {/* {formik.isSubmitting} */}
            </button>
          </DialogContent>
        </Dialog>

        {/* file view popup */}
        <Dialog
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleCloses_filePop()
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={opensFile_pop}
          className="success-popup view-file-popup uplaod-profile info-popup"
        >
          <DialogTitle id="customized-dialog-title" className="popup-heading" onClose={handleCloses_filePop}>
            <h6>{File_name}</h6>
            <div className="popup-buttons " data-toggle="tooltip" data-placement="bottom" title="Print">
              <div className="doc-print" >

                {/* <ReactToPrint
                  trigger={() => <i className="fas fa-print"></i>}
                  content={() => componentRef.current}
                /> */}
                <i className="fas fa-print" onClick={() => {
                  setopensFile_pop(false)
                  // eslint-disable-next-line no-unused-vars
                  var winPrint = window.open(file_, '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                }}></i>
              </div>

              <i
                className="fas fa-download"
                data-toggle="tooltip" data-placement="bottom" title="Download"
                onClick={() => {
                  download_file(file_)
                }}
              ></i>
            </div>
          </DialogTitle>

          <DialogContent dividers className="text-center p-10">
            <div className="success-icon"></div>
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div ref={componentRef}>
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={data_1}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                  }}
                />
              </div>
            )}

            <p className="success-text mb-10"></p>
          </DialogContent>
          <DialogActions className="pb-6 pt-6 btn-wrap">
            <div className="card-toolbar">
              <button type="submit" onClick={handleCloses_filePop} className="btn btn-primary mr-5 save-btn">
                Close
              </button>
            </div>
          </DialogActions>
        </Dialog>


        {/* Document sign popup */}
        <Dialog
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenSignPopup(false)
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={openSignPopup}
          className="success-popup view-file-popup uplaod-profile info-popup"
        >
          <DialogTitle id="customized-dialog-title" className="popup-heading sign-popup" onClose={() => { iframeClose() }}>
            <h6>{signFileName}</h6>
            <div className="popup-buttons " data-toggle="tooltip" data-placement="bottom" title="Print">
              <div className="doc-print" >
              </div>
            </div>
          </DialogTitle>

          <DialogContent dividers className="text-center p-10">
            <div>
              <iframe
                src={iframeUrl}
                title={signFileName}
                width="100%"
                height="800"
              ></iframe>
            </div>
            <p className="success-text mb-10"></p>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
