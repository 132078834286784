





import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Text_singleline = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    const main_comp = props.main_comp
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    
     // Check element visibility when form_value changes
     useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
         setIsFieldHidden(is_hidden);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>

                <div className="input_label">
                    <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}
                </div>
            {main_comp?.Type === "48600151-6d5f-46df-8b40-159bacbb8394" ? <div className="input_wrap">
                <div className="helptext-wrap">
                    <input
                        className="checkbox1 width"
                        name={children_elements.UUID}
                        type="text"
                        value={form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                        onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield", children_elements.UUID, null, children_elements) }}
                        onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, main_comp) }}
                        placeholder={children_elements.Meta?.Placeholder}
                       
                    />

                    <p className="help_text">{children_elements.Meta?.FieldText}</p>
                    <p> {children_elements.Meta.Note?.blocks?.[0].text ? children_elements.Meta.Note?.blocks?.[0].text : children_elements.Meta?.Note}</p>
                </div>
            </div> :
                <div className="input_wrap">
                    <div className="helptext-wrap">
                        <input
                            className="checkbox1 width"
                            name={children_elements.UUID}
                            type="text"                            
                            value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                            onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield", children_elements.UUID, null, children_elements, props.rowid) }}
                            onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, null, props.rowid) }}
                            placeholder={children_elements.Meta?.Placeholder}
                           
                        />

                        <p className="help_text">{children_elements.Meta?.FieldText}</p>
                        <p> {children_elements.Meta.Note?.blocks?.[0].text ? children_elements.Meta.Note?.blocks?.[0].text : children_elements.Meta?.Note}</p>
                    </div>
                </div>}
        </> : ""
    )
}



