import React, { useState, useEffect, } from "react"
import { Admin_view_dashboard, admin_login_to_patient_account } from "../modules/Auth/_redux/authCrud"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import TablePagination from "@material-ui/core/TablePagination"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Spinner } from "react-bootstrap"

export const Admin_view = () => {

    const [dashboard_data, setdashboard_data] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [dashboard_data2, setdashboard_data2] = useState([])
    const [dashboard_data_main, setdashboard_data_main] = useState([])
    const [loader_status, setloader_status] = useState(true)
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user, shallowEqual)
    const dropdown_id = useSelector((state) => state.auth.dropdown_id, shallowEqual)
    const search_value_redux = useSelector((state) => state.auth.search_value, shallowEqual)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [search_box, setsearch_box] = useState("")
    const [sorting_order, setsorting_order] = useState(true)
    const [sorting_para, setsorting_para] = useState("UpdatedDate")
    const [index_value, setindex_value] = useState()
    // eslint-disable-next-line no-unused-vars
    const [index_value_for_searchbox, setindex_value_for_searchbox] = useState()
    const [open_window_status, setopen_window_status] = useState(false)
    const [window_open_value, set_window_open_value] = useState()
    let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)


    useEffect(() => {
        if (user) {
            sessionStorage.setItem("PatientCTMSSiteID", user.PatientCTMSSiteID)

        }
    }, [user]);

    // to get dashboard data
    const Get_dashboard_data = (siteid) => {

        Admin_view_dashboard(siteid).then(res => { setdashboard_data(res.data); setdashboard_data_main(res.data); Filter_method_on_redirect(res.data); }).catch(err => { }).finally(() => { setloader_status(false); })
    }
    // this method will execute when reddirect from other page to admin view
    const Filter_method_on_redirect = (data) => {
        let mapdata = data

        if (sessionStorage.getItem("coming_from_my_sccount") === "yes") {

            // Global_search_clicked(search_value_redux?.value)

            let search_value = search_value_redux?.value
            if (search_value?.trim() !== "") {
                let studiesArr = []
                // eslint-disable-next-line array-callback-return
                mapdata.map((study) => {
                    let isStudyNameMatch = false
                    let studyName = `${study.CTMSStudySponsor}-${study.CTMSStudyProtocol}-${study.CTMSIndication}`
                    if (studyName.toLowerCase().includes(search_value.trim().toLowerCase())) {
                        isStudyNameMatch = true
                    }
                    let newPatientStudies = [];
                    // eslint-disable-next-line array-callback-return
                    study.patientstudies.map((patientstudy) => {
                        if (patientstudy.patients.Email.toLowerCase().includes(search_value.trim().toLowerCase()) ||
                            patientstudy.patients.patientsites[0].FullName.toLowerCase().includes(search_value.trim().toLowerCase()) ||
                            patientstudy.patients.patientsites[0].PatientCTMSID.toString().toLowerCase().includes(search_value.trim().toLowerCase())) {
                            newPatientStudies.push(patientstudy)
                        }
                    })

                    if (!isStudyNameMatch && newPatientStudies.length !== 0) {
                        studiesArr.push({ ...study, patientstudies: newPatientStudies })
                    } else if (isStudyNameMatch && newPatientStudies.length !== 0) {
                        studiesArr.push({ ...study, patientstudies: newPatientStudies })
                    } else if (isStudyNameMatch && newPatientStudies.length === 0) {
                        studiesArr.push({ ...study })
                    }
                })
                setdashboard_data(studiesArr)
            } else {

                setdashboard_data(dashboard_data_main)


            }

            sessionStorage.removeItem("coming_from_my_sccount")

        }
    }


    // pagenation methods
    const handleChangePage = (event, newPage) => {
        setPage(newPage)

    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    // to scroll to top of table
    useEffect(() => {
        if (page) {
            document.getElementById("demobutton").click()

        }
    }, [page]);

    const setLocale_ = (indx) => {
        localStorage.setItem("sdf", indx)

    }

    // pagenation methods ends



    // when page loads  we are setting first dropdown value as default
    useEffect(() => {
        if (dropdown_id) {
            setdashboard_data([])
            setloader_status(true)
            Get_dashboard_data(dropdown_id.id)


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdown_id])




    // when search value chages
    useEffect(() => {
        if (search_value_redux) {
            Global_search_clicked(search_value_redux.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search_value_redux])

    // sorting table methods
    useEffect(() => {

        if (sorting_para === "Email") {
            setdashboard_data2(
                dashboard_data?.map(value => {
                    return value.patientstudies = value.patientstudies?.sort((a, b) => {

                        if (a.patients[sorting_para] < b.patients[sorting_para]) {
                            return sorting_order ? -1 : 1
                        }
                        if (a.patients[sorting_para] > b.patients[sorting_para]) {
                            return sorting_order ? 1 : -1
                        }
                        return 0

                    })
                })
            )
        }
        if (sorting_para === "PatientCTMSID" || sorting_para === "FullName") {

            setdashboard_data2(
                dashboard_data?.map(value => {
                    return value.patientstudies = value.patientstudies?.sort((a, b) => {

                        if (a.patients.patientsites[0][sorting_para] < b.patients.patientsites[0][sorting_para]) {
                            return sorting_order ? -1 : 1
                        }
                        if (a.patients.patientsites[0][sorting_para] > b.patients.patientsites[0][sorting_para]) {
                            return sorting_order ? 1 : -1
                        }
                        return 0

                    })
                })
            )

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting_order])

    const Filter_table = (value) => {
        setsorting_para(value)
        setsorting_order(!sorting_order)
    }

    // sorting methods end
    // to clar search table field when click on other tab
    const Clrarsearch_result = () => {
        setsearch_box("")
        setPage(0)
    }
    // on click of global search in header
    const Global_search_clicked = (e) => {
        let search_value = e
        if (search_value?.trim() !== "") {
            let studiesArr = []
            // eslint-disable-next-line array-callback-return
            dashboard_data_main.map((study) => {
                let isStudyNameMatch = false
                let studyName = `${study.CTMSStudySponsor}-${study.CTMSStudyProtocol}-${study.CTMSIndication}`
                if (studyName.toLowerCase().includes(search_value.trim().toLowerCase())) {
                    isStudyNameMatch = true
                }
                let newPatientStudies = [];
                // eslint-disable-next-line array-callback-return
                study.patientstudies.map((patientstudy) => {
                    if (patientstudy.patients.Email.toLowerCase().includes(search_value.trim().toLowerCase()) ||
                        patientstudy.patients.patientsites[0].FullName.toLowerCase().includes(search_value.trim().toLowerCase()) ||
                        patientstudy.patients.patientsites[0].PatientCTMSID.toString().toLowerCase().includes(search_value.trim().toLowerCase())) {
                        newPatientStudies.push(patientstudy)
                    }
                })

                if (!isStudyNameMatch && newPatientStudies.length !== 0) {
                    studiesArr.push({ ...study, patientstudies: newPatientStudies })
                } else if (isStudyNameMatch && newPatientStudies.length !== 0) {
                    studiesArr.push({ ...study, patientstudies: newPatientStudies })
                } else if (isStudyNameMatch && newPatientStudies.length === 0) {
                    studiesArr.push({ ...study })
                }
            })
            setdashboard_data(studiesArr)
        } else {

            setdashboard_data(dashboard_data_main)


        }


    }
    let new_window


    // on click of login button
    const Clicked_on_login_icon = async (email, index, study_id_for_pp) => {

        setopen_window_status(true)
        setindex_value(index)

        if (sessionStorage.getItem("is_window_open") === "true") {
            closeWin()
        }
        admin_login_to_patient_account(email).then(data => {
            localStorage.setItem("id", data.data.id)
            localStorage.setItem("site_id", data.data.PatientCTMSSiteID)
            openWin(study_id_for_pp)
            sessionStorage.setItem("is_window_open", "true")

        }).catch(err => { }).finally(() => {
            setopen_window_status(false)
        })
    }

    const openWin = (study_id_for_pp) => {
        // new_window = window.open("http://localhost:3005/dashboard?view=secondary&study_id_for_pp=" + study_id_for_pp, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1000,height=1000")
        new_window = window.open(end_point_url + "/dashboard?view=secondary&study_id_for_pp=" + study_id_for_pp, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1000,height=1000")
        set_window_open_value(new_window)
        dispatch(auth.actions.set_window_status(new_window))
        localStorage.setItem("window_function", new_window)
    }

    const closeWin = () => {
        if (window_open_value) {
            window_open_value.close();   // Closes the new window
            sessionStorage.setItem("is_window_open", "")
            set_window_open_value()

        }
    }

    // rough  code is here
    useEffect(() => {
    }, [])



    return (
        <main className="admin-view-wrap">
            <section className="studies-wrap">
                <div className="container">

                    <div className="accordion-wrapper">
                        <h4 className="Top_heading_accordian">Studies</h4>
                        <div id="accordion" >

                            {loader_status && <div style={{ textAlign: "center" }}>
                                <Spinner animation="border" className="spinner-info" variant="primary" />
                            </div>}
                            {(dashboard_data?.length === 0 || dashboard_data === undefined) && (!loader_status) ? (<div className="dash-top">
                                {" "}
                                <img src={("/media/misc/no-data.svg")} alt="unable to load" />
                                <p>No data found</p>{" "}
                            </div>) : (
                                dashboard_data?.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <div className="card" >
                                            <div className="card-header" id={`headingTwo`}>
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link accordian-btn" data-toggle="collapse" data-target={`#card-${index}`} aria-expanded="false" aria-controls="collapseTwo" onClick={Clrarsearch_result}>
                                                        <span className="accordian_text">{data.CTMSStudySponsor}-{data.CTMSStudyProtocol}-{data.CTMSIndication} </span><span className="badge badge-blue">{data.patientstudies?.length}</span>
                                                        <span className="accordian-icon"><img src="/media/misc/chevron-down.svg" alt="unable to load" /></span>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id={`card-${index}`} className="collapse" aria-labelledby={`headingTwo`} data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className="table-heading-wrap">
                                                        <div className="left-heading">
                                                            <h5>Studies Subjects</h5>
                                                        </div>
                                                        <div className="search-box">
                                                            <div className="input-group rounded">
                                                                <input type="search" id={index} className="form-control rounded" placeholder="Search For MRN, Name or Email" aria-label="Search" aria-describedby="search-addon" value={search_box}
                                                                    onChange={(e) => {
                                                                        setsearch_box(e.target.value)
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table table-striped table-hover info-table ">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" onClick={() => Filter_table("PatientCTMSID")}>MRN<i className="fas fa-sort sort-icon"></i></th>
                                                                <th scope="col" onClick={() => Filter_table("FullName")}>Patient Name<i className="fas fa-sort sort-icon"></i></th>
                                                                <th scope="col" onClick={() => Filter_table("Email")}>Email<i className="fas fa-sort sort-icon"></i></th>
                                                                <th scope="col">Enrollment Status</th>
                                                                <th scope="col">Option</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.patientstudies?.filter((v) => {
                                                                return (v.patients.patientsites[0].PatientCTMSID.toString().toLowerCase()?.includes(search_box.toLowerCase()) || v.patients.patientsites[0].FullName.toLowerCase()?.includes(search_box.toLowerCase()) || v.patients.Email.toLowerCase()?.includes(search_box.toLowerCase()))
                                                            })?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => {
                                                                return [
                                                                    <tr key={index}>
                                                                        <th scope="row">{value.patients.patientsites[0]?.PatientCTMSID}</th>
                                                                        <td>{value.patients.patientsites[0]?.FullName}</td>
                                                                        <td>{value.patients?.Email}</td>
                                                                        <td><span className={`${value?.EnrollmentStatus === "Not Contacted" ? "enrollment-status" : "green-status"}`}>{value?.EnrollmentStatus}</span></td>
                                                                        <td className="option-img-wrap ">
                                                                            {(open_window_status && index_value === index) ? <p className="mb-0 pr-3"><span className="ml-3 spinner spinner-white"></span></p> : <span className="option-img go-to-dash"><img src="/media/misc/sign_in_alt_icon.svg" alt="unable to load" onClick={() => { Clicked_on_login_icon(value.patients?.Email, index, data?.id) }} /></span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ]
                                                            })}
                                                            {/* if  search result is 0 then  we are showing this */}
                                                            {data.patientstudies?.filter((v) => {
                                                                return (v.patients.patientsites[0].PatientCTMSID.toString().toLowerCase()?.includes(search_box.toLowerCase()) || v.patients.patientsites[0].FullName.toLowerCase()?.includes(search_box.toLowerCase()) || v.patients.Email.toLowerCase()?.includes(search_box.toLowerCase()))
                                                            })?.length > 0 ? null : <tr className="no-data-found">
                                                                <td colSpan={5}>
                                                                    <div className="dash-top">
                                                                        {" "}
                                                                        <img src={("/media/misc/no-data.svg")} alt="unable to load" />
                                                                        <p>No data found</p>{" "}
                                                                    </div>
                                                                </td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                    <div className="pagination-wrap" onClick={() => setLocale_(index)}>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                            component="div"
                                                            count={data.patientstudies?.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* hidden button to fix scroll issue */}
            <button id="demobutton" type="hidden" onClick={() => {
                
                const section = document.getElementById(localStorage.getItem("sdf"));
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}></button>
            {/* to fix collpse issue */}
            <button id="clear_data" type="hidden" onClick={() => {
                setdashboard_data([])
            }}></button>
        </main>
    )
}
export default Admin_view
