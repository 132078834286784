// convert date function
export function Date_convert(data, type) {

  const converted_date = new Date(data)
  let day_ = ("0" + converted_date.getDate()).slice(-2)
  let month_ = ("0" + (converted_date.getMonth() + 1)).slice(-2)
  let year_ = converted_date.getFullYear()
  const monthlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  // time
  let hours =
    converted_date.getHours() > 12
      ? converted_date.getHours() - 12
      : converted_date.getHours()
  let am_pm = converted_date.getHours() >= 12 ? "PM" : "AM"
  let minutes =
    converted_date.getMinutes() < 10
      ? "0" + converted_date.getMinutes()
      : converted_date.getMinutes()
  let time = hours + ":" + minutes + " " + am_pm

  if (type === 1) {
    return time
  }
  if (type === 2) {
    return day_ + "-" + month_ + "-" + year_
  }
  if (type === 3) {
    return day_ + "-" + month_ + "-" + year_ + "  |" + time
  }
  if (type === 4) {
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_
  }
  if (type === 5) {
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_ + "  |" + time
  }
  if (type === 6) {
    return weekday[converted_date.getDay()] + ", " + day_ + " " + monthlist[converted_date.getMonth()] + " " + year_ + ", " + time
  }
  if (type === 7) {
    return day_ + " " + monthlist[converted_date.getMonth()]
  }
  if (type === 8) {
    let hrs = hours === 0 ? 12 : hours
    let conv_time = hrs + ":" + minutes + " " + am_pm
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_ + "  |" + conv_time
  }
  if (type === 9) {
    let hrs = hours === 0 ? 12 : hours
    let seconds = ("0" + converted_date.getSeconds()).slice(-2)

    return day_ + "/" + monthlist[converted_date.getMonth()] + "/" + year_ + " " + ("0" + hrs).slice(-2) + ":" + minutes + ":" + seconds + " " + am_pm
  }

}


export function Date_convert_without_time_zone(data, type) {

  var target = new Date(data);
  var offset = target.getTimezoneOffset();
  var Y = target.getUTCFullYear();
  var M = target.getUTCMonth();
  var D = target.getUTCDate();
  var h = target.getUTCHours();
  var m = target.getUTCMinutes();
  var s = target.getUTCSeconds();

  const converted_date = new Date(Date.UTC(Y, M, D, h, m, s));
  const timezone_title = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const converted_date_full = new Date(data).toLocaleString('en-US', {
    timeZone: timezone_title,
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: "false",
    hourCycle: "h24"
  });
  let day_ = ("0" + converted_date.getDate()).slice(-2)
  let month_ = ("0" + (converted_date.getMonth() + 1)).slice(-2)
  let year_ = converted_date.getFullYear()
  const monthlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  // time
  let hours =
    converted_date.getHours() > 12
      ? converted_date.getHours() - 12
      : converted_date.getHours()
  let am_pm = converted_date.getHours() >= 12 ? "PM" : "AM"
  hours = hours < 10 ? "0" + hours : hours
  let minutes =
    converted_date.getMinutes() < 10
      ? "0" + converted_date.getMinutes()
      : converted_date.getMinutes()


  let time = hours + ":" + minutes + " " + am_pm

  if (type === 1) {
    return time
  }
  if (type === 2) {
    return day_ + "-" + month_ + "-" + year_
  }
  if (type === 3) {
    return day_ + "-" + month_ + "-" + year_ + "  |" + time
  }
  if (type === 4) {
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_
  }
  if (type === 5) {
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_ + "  |" + time
  }
  if (type === 6) {
    return weekday[converted_date.getDay()] + ", " + day_ + " " + monthlist[converted_date.getMonth()] + " " + year_ + " ," + time
  }
  if (type === 7) {
    return day_ + " " + monthlist[converted_date.getMonth()]
  }
  if (type === 8) {
    return converted_date
  }
  if (type === 9) {
    return converted_date.getTime()
  }

}


// This function checks field visibility state
export function check_field_visibility(children_elements, form_value, commonId) {
  let is_element_hidden = false
  let hidden = children_elements.Meta.Hidden
  if (hidden === true) {
    // If the hidden field is true then set isFieldHidden = true (element is hidden)
    is_element_hidden = true;
  } else {
    if (hidden === false) {
      // If the hidden field is false then set isFieldHidden = false (element is visible)
      is_element_hidden = false;
    } else {
      // If the Hidden field contains conditions then we need to 
      // check those conditions to set element visibility
      let operand = hidden.groups[0].operand

      // Mapping through the element array
      // eslint-disable-next-line array-callback-return
      hidden.groups[0].elements.map((elem) => {
        // Get the value from the form_value to which will be 
        // compared with condition value according to the operator
        let elem_value = commonId ? form_value[elem.uuid]?.[commonId] : form_value[elem.uuid]

        if (elem_value === "" || elem_value === undefined) {
          // If value got empty / undefined then set isFieldHidden = true (element is hidden)
          is_element_hidden = true;
        } else {
          // Result of comparison will be pushed to following array
          let condition_array = []

          // Mapping through the conditions array
          // eslint-disable-next-line array-callback-return
          elem.conditions.map((condition) => {
            // Switch-case to compare values as per operator i.e eq, lt, lte, gt, gte, contains
            switch (condition.operator) {
              case "eq":
                // Equal to
                let is_eq = elem_value === condition.value;
                condition_array.push(is_eq);
                break;

              case "lt":
                // Less than
                let is_lt = Number(elem_value) < Number(condition.value)
                condition_array.push(is_lt);
                break;

              case "lte":
                // Less than or equal to
                let is_lte = Number(elem_value) <= Number(condition.value);
                condition_array.push(is_lte);
                break;

              case "gt":
                // Greater than
                let is_gt = Number(elem_value) > Number(condition.value);
                condition_array.push(is_gt);
                break;

              case "gte":
                // Greater than or equal to
                let is_gte = Number(elem_value) >= Number(condition.value);
                condition_array.push(is_gte);
                break;
              case "contains":
                // Contains
                let is_contains = elem_value.includes(condition.value);
                condition_array.push(is_contains);
                break;

              default:
                break;
            }
          })

          // Checking operand
          if (operand === "OR") {
            // If at least one element of the array is true, the OR condition will be satisfied
            let is_one_true = condition_array.some(val => val === true);
            is_element_hidden = !is_one_true;
          } else if (operand === "AND") {
            // If all elements of array will be true which satisfies OR condition
            let is_all_true = condition_array.every(val => val === true);
            is_element_hidden = !is_all_true;
          } else {
            // Otherwise set isFieldHidden = true (element is hidden)
            is_element_hidden = true;
          }
        }
      })
    }
  }
  return is_element_hidden;
}


/** 14/04/2022 
 * This function return the browser name
 */
export function getBroserName() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera'
  }
  else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return 'Edge'
  }
  else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return 'Chrome'
  }
  else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return 'Safari'
  }
  else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return 'Firefox'
  }
  else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
    return 'IE'
  }
  else {
    return 'Unknown'
  }
}