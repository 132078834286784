/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { connect } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import * as Yup from "yup"
import { toAbsoluteUrl } from "../../../../_common/_helpers"
import SVG from "react-inlinesvg"

import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { resetPassword } from "../_redux/authCrud"



function ResetPassword(props) {
  const { intl } = props
  const [isRequested, setIsRequested] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error_message, seterror_message] = useState("error")
  const [error_message_visiblility, seterror_message_visiblility] = useState(false)
  const queryParams = new URLSearchParams(window.location.search)
  const ID = queryParams.get("id")
  const token = queryParams.get("ResetToken")
  const baseurl = queryParams.get("host")
  useEffect(() => {
    props.route_status("https://realtimestage.wpengine.com/study-login.php")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [css_class, setcss_class] = useState(true)
  const initialValues = {
    password: "",
    confpassword: "",

  }


  const ForgotPasswordSchema = Yup.object().shape({


    password: Yup.string()
      .trim()
      .min(8, "Minimum 8 characters required")
      .max(25, "Maximum 25 characters allowed")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain at least ONE( digit,lowercase character,uppercase character,special character)"
      ),
    confpassword: Yup.string()
      .trim()
      .required("Confirm Password is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      resetPassword(token, values.password, ID, baseurl)
        .then((res) => {
          setIsRequested(true)
          setcss_class(true)
          seterror_message(res?.data.message)
          seterror_message_visiblility(true)
          setSubmitting(false)
          setLoading(false)
          resetForm()
          setTimeout(() => {
            props.history.push("/auth")
          }, 2000);

        })
        .catch((err) => {
          setIsRequested(false)
          setcss_class(false)
          setSubmitting(false)
          seterror_message(err.response?.data.message)
          seterror_message_visiblility(true)
          setLoading(false)


        })
    },
  })

  return (
    <>

      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password</h3>
          <div className="text-muted font-weight-bold">
            Enter your new password
          </div>
        </div>

        {error_message_visiblility && (
          <div
            className={`alert alert-custom  fade show mb-10  ${css_class ? 'alert-light-success' : 'alert-light-danger'}`}

            role="alert"
          >
            <div className="alert-icon">
              <span className={` svg-icon svg-icon-3x${css_class ? 'svg-icon-success' : 'svg-icon-danger'}`}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}></SVG>{" "}
              </span>
            </div>
            <div className="alert-text font-weight-bold">{error_message}</div>
            <div
              className="alert-close"
              onClick={() => seterror_message_visiblility(false)}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
        )}

        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              placeholder="Password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              autocomplete="off"
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>

          {/* confpassword */}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              placeholder="Confirm password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "confpassword"
              )}`}
              name="confpassword"
              {...formik.getFieldProps("confpassword")}
            />
            {formik.touched.confpassword && formik.errors.confpassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.confpassword}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Submit
              {loading && <span className="ml-3 spinner spinner-white"></span>}

            </button>
            <Link to="/auth" className="forgot-cancel">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>

    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ResetPassword))
