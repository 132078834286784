import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Badge from "@material-ui/core/Badge"
import TextField from "@material-ui/core/TextField"
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import DrangDropFile from "../../_common/_helpers/Drag_n_drop_file"
import Icon from "@material-ui/core/Icon"
import { Spinner } from "react-bootstrap"
import MenuItem from "@material-ui/core/MenuItem"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { toAbsoluteUrl } from "../../_common/_helpers"
import { upload_ticket, Get_all_tickets, Dashboard_invite_api } from "../modules/Auth/_redux/authCrud"
import * as auth from "../../app/modules/Auth/_redux/authRedux"

const styles = (theme) => ({
  indicator: {
    backgroundColor: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.4),
    color: "#fff",
    paddingRight: "10px",
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
}))

export const Help_comm = () => {
  const classes = useStyles()
  const [img_value, setimg_value] = useState(false)
  const dispatch = useDispatch()

  const [formdata, setformdata] = useState({
    Document_title: "",
    Document_Description: "",
    title_error: "",
    Description_error: "",
  })
  // validation for field

  const field_change = (event, errorname) => {
    let value = event.target.value
    let name = event.target.name
    let namefor_errormessage = ""
    if (name === "Document_title") {
      namefor_errormessage = "title"
    } else {
      namefor_errormessage = "description"
    }

    if (value.trim().length > 0) {
      setformdata({ ...formdata, [errorname]: "", [name]: value })
    } else {
      setformdata({ ...formdata, [errorname]: "Topic " + namefor_errormessage + " is required", [name]: "" })
    }
  }

  // validation for field
  const OnBlurField = (e, errorname) => {
    let value = e.target.value
    let name = e.target.name
    let namefor_errormessage
    if (name === "Document_title") {
      namefor_errormessage = "title"
    } else {
      namefor_errormessage = "description"
    }

    if (value.trim().length === 0) {
      setformdata({
        ...formdata,
        [errorname]: "Topic " + namefor_errormessage + " is required",
        [name]: value,
      })
    }
  }

  //popup methods
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions)

  const [open, setOpen] = React.useState(false)
  const [opens, setOpens] = React.useState(false)
  const [errorpopup, seterrorpopup] = React.useState(false)
  const [comment_popup, setcomment_popup] = React.useState(false)
  const [FileData, setFileData] = React.useState([])
  const [study_id, setstudy_id] = React.useState()
  const [topic, settopic] = React.useState("")
  const [hide_fileUpload, sethide_fileUpload] = useState(false)
  const [submit_button_visible, setsubmit_button_visible] = useState(false)
  const [error_message, seterror_message] = useState("")
  const [dropdown_data_temp, setdropdown_data_temp] = useState([])
  const [dropdown_data, setdropdown_data] = useState([])
  const [Allticket, setAllticket] = useState([])
  const [search_box, setsearch_box] = useState("")
  const [sorting_order, setsorting_order] = useState(true)
  const [sorting_para, setsorting_para] = useState("createdAt")
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)

  // to get studies and site title
  useEffect(() => {
    if (studies_) {
      if (studies_?.length > 0) {
        setdropdown_data_temp(
          studies_?.map((num, index) => {
            return { sitename: num.sites.CTMSSiteTitle, siteid: num.sites.id, studyid: num.id }
          })
        )
      }
    } else {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies_])

  useEffect(() => {

    let unique_data = dropdown_data_temp.filter(
      (ele, ind) => ind === dropdown_data_temp.findIndex((elem) => elem.siteid === ele.siteid)
    )

    setdropdown_data(unique_data)
  }, [dropdown_data_temp])

  useEffect(() => {
  }, [dropdown_data])

  // to fix refresh issue
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => {
      })
  }

  // to get all token
  useEffect(() => {
    Get_AllTicket()
  }, [])
  // to get all token

  const Get_AllTicket = async () => {
    await Get_all_tickets()
      .then((data) => {
        setAllticket(data.data)
      })
      .catch((err) => {
      })
  }

  const Study_changed = (event) => {
    setstudy_id(event.target.value)
  }

  const Topic_changed = (event) => {
    settopic(event.target.value)
  }
  // submit data method 52444444
  const handleClickOpens = async () => {
    if (FileData.length > 0) {
      if (FileData[0].size < 52444444) {
        setsubmit_button_visible(true)
        sethide_fileUpload(true)
        setimg_value(true)
        await upload_ticket(
          FileData[0],
          study_id,
          formdata.Document_title,
          formdata.Document_Description,
          topic
        )
          .then((data) => {
            Get_AllTicket()
            setOpens(true)
            setimg_value(false)
          })
          .catch((err) => {
            setimg_value(false)

            seterror_message("something went wrong!")
            seterrorpopup(true)
          })
      } else {
        seterror_message("please upload a file with size less than 50 MB")
        seterrorpopup(true)
      }
    } else {
      setsubmit_button_visible(true)
      sethide_fileUpload(true)
      setimg_value(true)
      await upload_ticket(
        FileData[0],
        study_id,
        formdata.Document_title,
        formdata.Document_Description,
        topic
      )
        .then((data) => {
          Get_AllTicket()
          setOpens(true)
          setimg_value(false)
        })
        .catch((err) => {
          setimg_value(false)

          seterror_message("something went wrong!")
          seterrorpopup(true)
        })
    }
  }
  // success popup close method
  const handleCloses = () => {
    setOpens(false)
    setFileData([])
    setOpen(false)
    setsubmit_button_visible(false)
    sethide_fileUpload(false)
    setformdata({
      Document_title: "",
      Document_Description: "",
      title_error: "",
      Description_error: "",
    })
    setstudy_id()
    settopic("")
  }
  // close error popup
  const handleCloses_error = () => {
    seterrorpopup(false)
    setsubmit_button_visible(false)
    sethide_fileUpload(false)
  }

  // main close dialog method
  const handleClose = () => {
    setOpen(false)
    setsubmit_button_visible(false)
    sethide_fileUpload(false)
    setformdata({
      Document_title: "",
      Document_Description: "",
      title_error: "",
      Description_error: "",
    })
    setFileData([])
    setstudy_id()
    settopic("")
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const Returndata = (data) => {
    setFileData(data)
  }
  const toggle_spinner = () => {
    // img_status.current = 1;
    setimg_value(true)
  }
  // to open comment popup
  const OpenCommentPopup = () => {
    setcomment_popup(true)
  }
  // to close comment popup
  const handleCloses_commentSection = () => {
    setcomment_popup(false)
  }

  // filter table1 method
  const Filter_table1 = (value) => {
    setsorting_para(value)
    setsorting_order(!sorting_order)
  }

  // sorting table 1
  useEffect(() => {
    const sortedoc = Allticket?.sort((a, b) => {
      if (a[sorting_para] < b[sorting_para]) {
        return sorting_order ? -1 : 1
      }
      if (a[sorting_para] > b[sorting_para]) {
        return sorting_order ? 1 : -1
      }
      return 0
    })


    if (sortedoc) {
      setAllticket(sortedoc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sorting_order])

  return (
    <div className="row">
      <div className="my-document help-support">
        <div className="help-support-inner">
          <div className="form-inline">
            <div className="help-heading">
              <h3 className="mb-0 mr-3">Help & Communication</h3>
              <h4 className="color-red">In case of emergency call 911</h4>
            </div>
            <div className="d-flex justify-content-end right ">
              <div className="mr-5 btn-wrp">
                <button className="btn btn-primary uplaod-btn" onClick={handleClickOpen}>
                  <i className="fas fa-plus-circle"></i>
                  Start New Topic
                </button>
              </div>

              <div className="form-group search-box">
                <input
                  className="form-control mr-sm-1 search-box"
                  type="search"
                  placeholder="Search by Ticket"
                  aria-label="Search"
                  onChange={(e) => {
                    setsearch_box(e.target.value)
                  }}
                  value={search_box}
                />
              </div>
            </div>
          </div>
          <div className="tab-wrapper">
            {Allticket?.length === 0 || Allticket === undefined ? (
              <div className="dash-top">
                {" "}
                <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="" />
                <p>No ticket found</p>{" "}
              </div>
            ) : (
              <div className="table-main-wrap">
                <table
                  id="dtOrderExample"
                  className="table table-striped table-bordered table-sm table-wrapper  "
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th className="th-sm" onClick={() => Filter_table1("Title")}>
                        Title <i className="fas fa-sort ml-1 "></i>
                      </th>
                      <th className="th-sm" onClick={() => Filter_table1("TicketTypeShortName")}>
                        Ticket Number<i className="fas fa-sort ml-1"></i>
                      </th>
                      <th className="th-sm" onClick={() => Filter_table1("SiteName")}>
                        Site<i className="fas fa-sort ml-1"></i>
                      </th>
                      <th className="th-sm" onClick={() => Filter_table1("Type")}>
                        Topic Type<i className="fas fa-sort ml-1"></i>
                      </th>
                      <th className="th-sm" onClick={() => Filter_table1("createdAt")}>
                        Topic Date<i className="fas fa-sort ml-1"></i>
                      </th>

                      <th className="th-sm" onClick={() => Filter_table1("Status")}>
                        Status<i className="fas fa-sort ml-1"></i>
                      </th>
                      <th className="th-sm text-center">Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Allticket?.filter((v) => {
                      return v.TicketTypeShortName.toLowerCase()?.includes(search_box.toLowerCase())
                    }).map((data) => {
                      return [
                        <>
                          <tr>
                            <td>{data.Title}</td>
                            <td>{data.TicketTypeShortName}</td>
                            <td>{data.SiteName}</td>
                            <td>{data.Type}</td>
                            <td>
                              {" "}
                              {data.createdAt.slice(8, 10)}-{data.createdAt.slice(5, 7)}-
                              {data.createdAt.slice(0, 4)}
                            </td>
                            {data.Status === 0 ? <td>Pending</td> : <td>Resolved</td>}
                            <td className="text-center">
                              <Badge
                                badgeContent={2}
                                color="primary"
                                className="msg-icon"
                                onClick={() => OpenCommentPopup()}
                              >
                                <CommentOutlinedIcon />
                              </Badge>
                            </td>
                          </tr>
                        </>,
                      ]
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <div>
          <Dialog
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose()
              }
            }}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="uplaod-profile "
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose} className="popup-heading">
              Start New Topic
              <p>
                <h4 className="color-red">In case of emergency call 911</h4>
              </p>
            </DialogTitle>

            <div className="help-dropdown">
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Site</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={study_id}
                  onChange={Study_changed}
                >
                  {dropdown_data?.map((data, index) => (
                    <MenuItem key={index} value={data?.siteid}>
                      <p className="site_name">
                        {data?.sitename}
                      </p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Topic Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={topic}
                  onChange={Topic_changed}
                >
                  <MenuItem value={2}>Mobile App Support</MenuItem>
                  <MenuItem value={3}>Web App Support</MenuItem>
                  <MenuItem value={4}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            <TextField
              id="standard-basic"
              label="Title"
              className="textfield"
              name="Document_title"
              onChange={(event) => {
                field_change(event, "title_error")
              }}
              value={formdata.Document_title}
              onBlur={(e) => OnBlurField(e, "title_error")}
            />

            <div style={{paddingLeft: "25px" }} className="color-red">{true && formdata.title_error}</div>

            <TextField
              id="standard-basic"
              multiline
              label="Topic Description"
              className="textfield"
              name="Document_Description"
              onChange={(event) => {
                field_change(event, "Description_error")
              }}
              value={formdata.Document_Description}
              onBlur={(e) => OnBlurField(e, "Description_error")}
            />
            <div style={{paddingLeft: "25px" }} className="color-red"> {true && formdata.Description_error}</div>

            <DialogContent divider className="middle-content ">
              {FileData.length > 0 ? (
                <div className="success-icon">
                  {FileData[0]?.name}
                  <Icon className="fa fa-check icon" />
                </div>
              ) : (
                ""
              )}
              {hide_fileUpload === false ? (
                <DrangDropFile
                  filetype="jpeg,.png,.jpg,"
                  Returndata={Returndata}
                  toggle_spinner={toggle_spinner}
                />
              ) : (
                ""
              )}
              {img_value && <Spinner animation="border" variant="primary" />}
            </DialogContent>
            <DialogActions className="pb-6 pt-6 btn-wrap">
              <div className="card-toolbar">
                <button
                  type="submit"
                  className="btn btn-primary mr-5 save-btn"
                  onClick={handleClickOpens}
                  disabled={
                    formdata.Document_title === "" ||
                    formdata.Document_Description === "" ||
                    topic === "" ||
                    study_id === "" ||
                    // FileData.length === 0 ||
                    submit_button_visible === true
                  }
                >
                  Submit
                </button>
                <Link className="btn btn-secondary" onClick={handleClose} to="#">
                  Cancel
                </Link>
              </div>
            </DialogActions>
          </Dialog>
        </div>

        {/* Successfully popup */}
        <Dialog
          onClose={handleCloses}
          aria-labelledby="customized-dialog-title"
          open={opens}
          className="success-popup"
        >

          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-check icon" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">Successfully!</h2>
            <p className="success-text mb-10">Your ticket has been submitted successfully!</p>
            <button
              type="submit"
              onClick={handleCloses}
              className="btn btn-primary mr-5 save-btn"
              disabled={false}
            >
              Ok
              {/* {formik.isSubmitting} */}
            </button>
          </DialogContent>
        </Dialog>

        {/* Failed dialog */}
        <Dialog
          onClose={handleCloses_error}
          aria-labelledby="customized-dialog-title"
          open={errorpopup}
          className="success-popup"
        >
          <DialogContent dividers className="text-center p-10">
            <div className="success-icon">
              <Icon className="fa fa-exclamation-triangle" />
            </div>
            <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
            <p className="success-text mb-10">{error_message}</p>
            <button
              type="submit"
              onClick={handleCloses_error}
              className="btn btn-primary mr-5 save-btn"
              disabled={false}
            >
              Ok
            </button>
          </DialogContent>
        </Dialog>

        {/* comment section dialog */}
        <Dialog
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleCloses_commentSection()
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={comment_popup}
          className="success-popup helpsidebar"
        >
          <DialogContent dividers className="text-center p-10">

            <div className="helpsidebar-inner">
              <p>
                <u>Health Report</u>
                <span>Sitename</span>
              </p>
              <h4>Comments</h4>
              <div className="helpbox">
                <img src="/media/misc/help-dummy.png" alt="" />
                <div className="helpbox-inner">
                  <div className="helpbox-inner-inner">
                    <textarea placeholder="Write Your Comment"></textarea>
                    <div className="helpbox-attach">
                      <img src="/media/misc/attach.svg" alt="" />
                      Attach Files
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary mr-5 save-btn">
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={handleCloses_commentSection}
                    className="btn btn-primary mr-5 close-btn"
                    disabled={false}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="help-comment">
                <img src="/media/misc/help-dummy.png" alt="" />
                <div className="help-comment-inner">
                  <h6>
                    Frank Charles <span>2 Hours ago</span>
                  </h6>
                  <p>
                    Hi John, <br />
                    You can upload only pdf an word documents. Please let me know ig you face any issues with
                    this.
                    <br />
                    Thanks
                  </p>
                </div>
              </div>
              <div className="help-comment">
                <img src="/media/misc/help-dummy.png" alt="" />
                <div className="help-comment-inner">
                  <h6>
                    Frank Charles <span>2 Hours ago</span>
                  </h6>
                  <p>
                    Hi John, <br />
                    You can upload only pdf an word documents. Please let me know ig you face any issues with
                    this.
                    <br />
                    Thanks
                  </p>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
