import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Dashboard_invite_api, Get_single_event } from "../modules/Auth/_redux/authCrud"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Date_convert_without_time_zone } from "../modules/Auth/_redux/Common_code"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { CommonPopup } from "../pages/Common_popup"

const localizer = momentLocalizer(moment)

export const Study_calendar = (props) => {
  const dispatch = useDispatch()
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const Calandar_data = useSelector((state) => state.auth.calandar_data, shallowEqual)
  const [opens, setopens] = useState(false)
  const [eventdata, seteventdata] = useState([])
  const [custom_calandar_data, setcustom_calandar_data] = useState([])
  let history = useHistory()
  const [iscopy, setiscopy] = useState(false)
  const [default_date, setdefault_date] = useState(new Date())
  const [default_date2, setdefault_date2] = useState(new Date())
  const [popupstatus, setpopupstatus] = React.useState(false)
  let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)


  //  calling calandar api
  useEffect(() => {
    if (studies_?.length > 0) {
      dispatch(auth.actions.request_Calandar_data())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])


  useEffect(() => {
  }, [eventdata])

  // convering calandar data
  useEffect(() => {
    if (Calandar_data) {

      setcustom_calandar_data(
        Calandar_data.map((data) => {
          let startdatedata = data.StartDate
          let start_date_temp = Date_convert_without_time_zone(startdatedata, 8)
          let start_date_year = Number(start_date_temp.getFullYear())
          let start_date_month = Number(start_date_temp.getMonth())
          let start_date_day = Number(start_date_temp.getDate())
          let start_date_hour = Number(start_date_temp.getHours())
          let start_date_min = Number(start_date_temp.getMinutes())
          let enddatedata = data.EndDate
          let end_date_temp = Date_convert_without_time_zone(enddatedata, 8)
          let end_date_year = end_date_temp.getFullYear()
          let end_date_month = end_date_temp.getMonth()
          let end_date_day = end_date_temp.getDate()
          let end_date_hour = end_date_temp.getHours()
          let end_date_min = end_date_temp.getMinutes()

          let Start_date_full = Date_convert_without_time_zone(data.StartDate, 6)
          let Start_date_date_only = Date_convert_without_time_zone(data.StartDate, 7)
          let end_date_date_only = Date_convert_without_time_zone(data.EndDate, 7)
          let end_date_time_only = Date_convert_without_time_zone(data.EndDate, 1)
          let end_date_full = Date_convert_without_time_zone(data.EndDate, 6)

          let site_object = JSON.parse(localStorage.getItem("site_obj"))

          let typeIs = data?.Type ? "Visit Type : " + data?.Type : ""

          let typeIs2 = data?.Type ? " - Visit Type (" + data?.Type + ")" : ""

          let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";

          let titleIs = (data.VisitName === "" ? (data.Title + ' - ') : (data.VisitName + ' - ')) + StudyNameForEvents + typeIs2

          let visittitle = (data.VisitName === "" ? (data.Title + ' - ') : (data.VisitName + ' - ')) + StudyNameForEvents

          return {
            Title: data.Title,
            title: titleIs,
            visit_title: visittitle,
            visitType: typeIs,
            start: new Date(
              start_date_year,
              start_date_month,
              start_date_day,
              start_date_hour,
              start_date_min,
            ),
            end: new Date(
              end_date_year,
              end_date_month,
              end_date_day,
              end_date_hour,
              end_date_min
            ),
            StartDate: Start_date_full,
            start_date_date_only_: Start_date_date_only,
            end_date_date_only_: end_date_date_only,
            end_date_time_only_: end_date_time_only,
            EndDate: end_date_full,

            Description: data.Description,
            id: data.id,
            App_Route: data.App_Route,
            CTMSEventID: data.CTMSEventID,
            Deleted: data.Deleted,
            IsReminder: data.IsReminder,
            IsVirtualCall: data.IsVirtualCall,
            PatientID: data.PatientID,
            Status: data.Status,
            StudyID: data.StudyID,
            Type: data.Type,
            Url: data.Url,
            Web_Route: data.Web_Route,
            Location: data.Location,
            CreatedBy: data.CreatedBy,
            VisitName: data.VisitName
          }
        })
      )

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [Calandar_data])


  // calling invite api
  useEffect(() => {
    if (!studies_) {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])
  // calling invite api
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => {
      })
  }

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)
  // on click date
  const click_on_date = (data) => {
  }


  // on click events

  const Click_on_event = (data) => {
    seteventdata([data])
    setopens(true)
  }
  // popup close
  const handleCloses = () => {
    setopens(false)
    setiscopy(false)
  }
  const open_moredetail = (data) => {
    localStorage.setItem("prev_path", "study_cal")
    history.push({
      pathname: "/More_details",
      state: data,
    })
    setopens(false)
  }
  const Joincall = (title, id, join_status_id, custom_title) => {
    localStorage.setItem("join_status_id", join_status_id)
    localStorage.setItem("CTMSEventID", id)
    Get_single_event(join_status_id).then(result => { Joincall_part2(title, id, result.data, custom_title) }).catch(err => { })

  }


  const Joincall_part2 = (title, id, data, custom_title) => {

    if (data[0].IsInitiates === true) {
      // if (true) {
      sessionStorage.setItem("RoomSID", data[0].RoomSID)

      history.push({
        pathname: "/Meeting",
        state: { title: title + id, only_title: custom_title },
      })
    }
    else {
      setpopupstatus(true)
      setopens(false)
    }

  }


  const today = new Date();
  // const dayFormat = (date, culture, localizer) => localizer.format(date, 'D MMM YYYY', culture);
  // to change calendar header format
  const big_format = {
    dayFormat: (date, culture, localizer) => localizer.format(date, 'D MMM YYYY', culture),
    dayHeaderFormat: "dddd, MMMM D, YYYY",
    dayRangeHeaderFormat: ({ start, end }, culture, local) =>
      local.format(start, "MMMM D", culture) +
      " - " +
      local.format(end, "MMMM D, YYYY", culture)

  }
  const change_date = (e) => {
    setdefault_date(new Date(e.target.value))
    setdefault_date2(e.target.value)

  }
  const close_popup = () => {
    setpopupstatus(false)
  }

  return (
    <>
      <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

      <div className="custom-calendar">
        <div className="date_picker" title="Select Date">
          <input type="date" value={default_date2} onChange={(e) => { change_date(e) }} />
          <img src="/media/misc/picker_calendar.svg" className="picker-image" alt="unable to load" />
        </div>
        <Calendar
          formats={big_format}
          localizer={localizer}
          events={custom_calandar_data}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 900 }}
          views={["month", "day", "week"]}
          selectable
          onSelectSlot={click_on_date}
          onSelectEvent={Click_on_event}
          toolbar={true}
          step={30}
          dayLayoutAlgorithm="no-overlap"
          scrollToTime={new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            7

          )}
          date={default_date}
          onNavigate={date => {
            setdefault_date(date);
            let conv_date = new Date(date)
            let day_ = ("0" + conv_date.getDate()).slice(-2)
            let month_ = ("0" + (conv_date.getMonth() + 1)).slice(-2)
            setdefault_date2(conv_date.getFullYear() + "-" + month_ + "-" + day_)

          }}
        />
      </div>

      {/*  popup   showing events start */}
      <Dialog
        onClose={(event, reason) => {
          if (true) {
            handleCloses()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={opens}
        className="success-popup calendar-reminder"
      >
        <DialogContent dividers className="text-center p-10 event-popup">


          {eventdata?.map((data, index) => {
            return [
              <div className="calendar-meeting" key={index}>
                <div className="top-section">
                  <div className="d-flex justify-content-between align-items-center heading-section">
                    <span>My Calendar</span>
                    <button
                      className="details-btn"
                      onClick={() => {
                        open_moredetail(data)
                      }}
                    >
                      <img src="/media/misc/details.png" alt="unable to load" />
                    </button>
                  </div>
                  {data?.visitType ? <h2 className="visit-type">{data?.visitType}</h2> : ""}
                  <h1>{data?.visit_title}</h1>
                  <div className=" meeting-date">
                    {data.start_date_date_only_ !== data.end_date_date_only_ ? (
                      <>
                        <p className="">{data.StartDate} </p>
                        <p> {data.EndDate} </p>
                      </>
                    ) : (
                      <>
                        <p className="">
                          {data.StartDate}-{data.end_date_time_only_}{" "}
                        </p>
                      </>
                    )}
                  </div>

                  {data.Location === undefined ||
                    data.Location === "" ||
                    data.Location === null || data?.Type.trim() === 'Phone Visit' || data?.Type.trim() === 'Virtual Visit' ? (
                    " "
                  ) : (
                    <div className="location d-flex mt-4 mb-0">
                      <img src="/media/misc/location.png" alt="unable to load" />
                      <span className="rout">{data.Location}</span>
                    </div>
                  )}
                  <div className="d-flex">

                    <button
                      className="join-button"
                      onClick={() => {
                        Joincall(data.Title, data.CTMSEventID, data.id, data.title)
                      }}
                    >
                      Join <img src="/media/misc/on-video.svg" className="video-icon" alt="unable to load" />
                    </button>
                  </div>

                </div>
                <div className="bottom-section">

                  <div className="meeting-link d-flex">
                    <img src="/media/misc/link.png" alt="unable to load" />
                    <a href={`${end_point_url}/Meeting?roomname=${data.Title}${data.CTMSEventID}&title=${data.title}&join_status_id=${data.id}`} target="_blank" rel="noopener noreferrer">
                      {end_point_url}/Meeting?roomname={data.Title}{data.CTMSEventID}&title={data.title}&join_status_id={data.id}
                    </a>
                    {!iscopy && (
                      <CopyToClipboard text={`${end_point_url}/Meeting?roomname=${data.Title}${data.CTMSEventID}&title=${data.title}&join_status_id=${data.id}`}>
                        <img
                          src="/media/misc/copy.svg"
                          className="copy-icon"
                          onClick={() => {
                            setiscopy(true)
                            setTimeout(() => {
                              setiscopy(false)
                            }, 1200)
                          }}
                          alt=""
                        />
                      </CopyToClipboard>
                    )}

                    {iscopy && (
                      <img src="/media/misc/check-.svg" className="copy-icon" alt="unable to load" />
                    )}
                  </div>

                  <div className="calendar-user  organizer-user">
                    <img src="/media/svg/icons/General/user.png" alt="unable to load" />
                    <div className="calendar-organizer">
                      <p>{data.CreatedBy}</p>
                      <span>Organizer</span>
                    </div>
                  </div>
                </div>
              </div>,
            ]
          })}


        </DialogContent>
      </Dialog>
      {/*  popup   showing events end*/}
    </>
  )
}
