import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const FormTemplate = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, null);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    return (
        !isFieldHidden ? <>
           {props.children}
        </> : ""
    )
}
