/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from "react"
import { useHtmlClassService } from "../../_core/AppLayout"
import Select from "react-select"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as auth from "../../../../app/modules/Auth"
import { Check_user_availbality, login, createDeviceInfo, Dashboard_invite_api, Clear_push_notification_on_region_cahnge } from "../../../../app/modules/Auth/_redux/authCrud"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import Icon from "@material-ui/core/Icon"
import { toAbsoluteUrl, getPushToken } from "../../../../_common/_helpers"


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export function Footer() {
  const queryParams = new URLSearchParams(window.location.search)
  let is_mob = queryParams.get("is_mob")
  let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const [errorpopup, seterrorpopup] = useState(false);
  const [error_message, seterror_message] = useState();
  const uiService = useHtmlClassService()
  const dispatch = useDispatch()
  const [open_success, setopen_success] = useState(false);
  const [loading_status, setloading_status] = useState(false);
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const [dummy_var, setdummy_var] = useState(true);

  const [selectedOption_region, setselectedOption_region] = useState([
    {

      value: "https://dev.mystudymanager.com",
      // value: "https://val.mystudymanager.com",
      label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="" />United States</div>,

    }])

  const [country_name_, setcountry_name_] = useState([{

    value: "https://dev.mystudymanager.com",
    // value: "https://val.mystudymanager.com",
    label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="" />United States</div>,

  }, {
    value: "https://ca.mystudymanager.com",
    label: <div><img src="/media/misc/canada.svg" height="30px" width="30px" alt="" />Canada</div>,

  },])

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    }
  }, [uiService])

  // to set dropdown value  of region same as  at the time of login
  useEffect(() => {
    setselectedOption_region(country_name_.find(val => { return val.value === end_point_url }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdown_changed = (e) => {
    setloading_status(true)
    const email_address = localStorage.getItem("Email_address")
    Check_user_availbality(email_address, e.value).then(data => { Change_region(e, data, email_address) }).catch(err => {
      setloading_status(false)
    })
  }

  const Change_region = (e, data, email_address) => {
    if (data.data) {
      // if data find then we are  login to that region
      dispatch(auth.actions.end_point(e.value))
      const password_encrypted = localStorage.getItem("password_encrypted")
      let CryptoJS = require("crypto-js");
      var bytes = CryptoJS.AES.decrypt(password_encrypted, 'secret');
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      // this  "setdummy_var"will need otherwise end url is not getting updated when login api gets hit
      setdummy_var(!dummy_var)

      login(email_address, originalText, "", "")
        .then((data) => {
          setopen_success(true)

          localStorage.setItem("PatientCTMSID", data.data.PatientCTMSID)
          localStorage.setItem("PatientName", data.data.FirstName + " " + data.data.LastName)
          localStorage.setItem("Email_address", data.data.Email)
          if (data.data.LogInType === "Admin" || data.data.LogInType === "SuperAdmin") {
            sessionStorage.setItem("id", data.data.id)
            sessionStorage.setItem("site_id", data.data.PatientCTMSSiteID)
            sessionStorage.setItem("PatientName", data.data.FirstName + " " + data.data.LastName)

          }
          else {
            localStorage.setItem("id", data.data.id)
            localStorage.setItem("site_id", data.data.PatientCTMSSiteID)
            deviceInfo()

          }
          dispatch(auth.actions.login(data.data.authToken))
          const pathname = window.location.pathname
          if (pathname === "/Notifiction" || pathname === "/dashboard" || pathname === "/MyForm") {
            dispatch(auth.actions.requestNotification())
          }
          // to clear push notification of old region
          // Clear_push_notification_on_region_cahnge(selectedOption_region.value).then(dat => { console.log("dat", dat) }).catch(err => { console.log("err", err) })
          setselectedOption_region(e)

        })
        .catch((err) => {
          seterror_message("Could not change region, Password is different for that region")
          seterrorpopup(true)
          dispatch(auth.actions.end_point(selectedOption_region.value))


        }).finally(() => {
          setloading_status(false)
          Dashboard_invite_api()
            .then((value) => {
              dispatch(auth.actions.setstudies_action(value.data[0]?.studies))
            })
            .catch((error) => { })

        })

    } else {
      seterrorpopup(true)
      seterror_message("User not found in that region.")
      setloading_status(false)


    }

  }
  const deviceInfo = async () => {
    getPushToken().then((token) => {
      localStorage.setItem("PushToken", token);
      let PatientID = sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id");
      let DeviceID = "";

      let data = {
        "PatientID": sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
        "DeviceID": sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
        "DeviceINFO": "chrome",
        "PushToken": token,
      }

      createDeviceInfo(data).then((result) => {
      })
    }).catch((err) => {
    });
  }








  // popup methods
  const handleCloses_error = () => {
    seterrorpopup(false)
  }
  const handleCloses_success = () => {
    setopen_success(false)
  }

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div className={`${layoutProps.footerContainerClasses}`}>
        <div className="text-dark region-wrap">
          <span className="text-muted font-weight-bold mr-2"><img className="footer-img" src="/media/misc/footer-icon.png" alt="unable to load" />
            Copyright © 2021 - 2025 | RealTime Participant Portal v1.2.0. | All Rights Reserved
            |{" "}
            <a
              href="
             https://www.realtime-ctms.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            | <a href="https://www.realtime-ctms.com/cookies-policy" target="_blank" rel="noopener noreferrer">Cookies Policy</a>

          </span>
          {/* on hold  region change */}

          {/* {is_mob !== "true" && !sessionStorage.getItem("study_id_for_pp") && user?.LogInType === "Patient" && <Select
            value={selectedOption_region}
            onChange={(e) => {
              dropdown_changed(e)
            }}
            options={country_name_}
            name="region"
            className="region-select-drop"
            menuPlacement="top"
          />}
          {loading_status && <span className="ml-3 spinner spinner-white"></span>} */}


        </div>
      </div>
      {/* Failed dialog */}
      <Dialog
        onClose={handleCloses_error}
        aria-labelledby="customized-dialog-title"
        open={errorpopup}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-exclamation-triangle" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
          <p className="success-text mb-10"> {error_message}</p>
          <button type="submit" onClick={handleCloses_error} className="btn btn-primary mr-5 save-btn">
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* Failed dialog end*/}

      {/* Successfully popup */}
      <Dialog
        onClose={handleCloses_success}
        aria-labelledby="customized-dialog-title"
        open={open_success}
        className="success-popup"
      >

        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <p className="success-text mb-10">
            Region changed successfully .
          </p>
          <button type="submit" onClick={handleCloses_success} className="btn btn-primary mr-5 save-btn">
            Ok
          </button>
        </DialogContent>
      </Dialog>

    </div>
  )
}
