import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"
export const EmailType = (props) => {
    const nested_data = props.children_elements
    const form_value = props.form_value
   
    let commonId = props.rowid ? props.rowid : form_value[nested_data?.Parent]?.[0]
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    
    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(nested_data, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>
            <div className="input_label">
                <label className="label_" htmlFor="">{nested_data.Meta.Label}</label> {nested_data.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
            <div className="input_wrap">
                <input type="email"
                    placeholder={nested_data.Meta.Placeholder}
                    required={nested_data.Meta.Required}
                    title={nested_data.Meta?.ToolTip}
                    value={commonId ? form_value[nested_data?.UUID]?.[commonId] ? form_value[nested_data?.UUID]?.[commonId] : "" : form_value[nested_data.UUID] ? form_value[nested_data.UUID] : ""}
                    name={nested_data.UUID}
                    onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, nested_data?.Parent, null, null, props.rowid) }}
                    onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield_email", nested_data.UUID, null, nested_data, props.rowid) }}
                   
                />

            </div>
            <p className="help_text">{nested_data.Meta?.Note}</p>
        </> : ""
    )
}


