/* eslint-disable react/jsx-pascal-case */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_common/layout";
import { Admin_layout } from "../_common/layout/";

import BasePage from "./BasePage";
import AdminBasePage from "./AdminBasePage";

import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/Errors/ErrorsPage";
import AutoLogout from "./AutoLogout";

export function Routes() {

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const { admin_status } = useSelector(
    ({ auth }) => ({
      admin_status: auth.user?.LogInType,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {/* <Route path="/reset-password" component={ResetPassword} /> */}


      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (<>
        {/*AutoLogout  is used  for auto logout  and here we are seprating admin view and patient view */}
        {admin_status === "Patient" ? <Layout>
          <AutoLogout admin_status={admin_status} />
          <BasePage />
        </Layout>
          : <Admin_layout>
            <AutoLogout admin_status={admin_status} />
            <AdminBasePage />
          </Admin_layout>}

      </>

      )}
    </Switch>
  );
}
