/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import { withStyles } from "@material-ui/core/styles"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { Get_esign_dropdown, verify_password, Get_ctms_userInfo, get_ctms_token } from "../modules/Auth/_redux/authCrud"
import { shallowEqual, useSelector } from "react-redux"


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)



export const Common_popup_esign = (props) => {

    const [open, setopen] = React.useState(true)
    const [common_error_message, setcommon_error_message] = useState('');
    const [esign_password, setesign_password] = useState("");
    const [drpdown_value_esign, setdrpdown_value_esign] = useState("I have reviewed and approved");
    const [esign_dropdown, setesign_dropdown] = useState([]);
    const [esign_username, setesign_username] = useState(localStorage.getItem("PatientName"));
    const [esign_disabled, setesign_disabled] = useState(false);
    const queryParams = new URLSearchParams(window.location.search)
    const user = useSelector((state) => state.auth.user, shallowEqual)
    useEffect(() => {
        setopen(props.esign_popup_status)

    }, [props.esign_popup_status])

    useEffect(() => {
        let SiteID_local_ = queryParams.get("SiteID_local_")
        Get_ctms_userInfo(SiteID_local_).then(data => {
            get_esign_dropdown(data.data)
        }).catch(err => { })
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    // to get esign dropdown
    const get_esign_dropdown = (data_recv) => {



        let CTMSHostLink_ = queryParams.get("CTMSHostLink_")
        const drop_info = {
            CTMSUsername: data_recv.CTMSUsername,
            CTMSPassword: data_recv.CTMSPassword,
            CTMSHostLink: "https://" + CTMSHostLink_
        }

        get_ctms_token()
            .then((value) => {
                Get_esign_dropdown(value.data).then(data => {

                    setesign_dropdown(data.data.records)
                    // setdrpdown_value_esign(data.data.records[0]?.Description)

                })

            }).catch(err => { })


    }
    // const handleChange_esign_dropdown = (e) => {
    //     setdrpdown_value_esign(e.target.value)
    // }



    const handleCloses_Esign = () => {
        setopen(false)
        setcommon_error_message("")
        setesign_password("")
        props.handleCloses_Esign()
    }

    const handleCloses_Esign_submit = () => {
        setesign_disabled(true)
        if (esign_password?.trim()?.length === 0) {
            setcommon_error_message(" Please enter password ")
            return
        }
        // first we will verify password
        verify_password(esign_password).then(data => {
            setopen(false)
            props.handleCloses_Esign_submit(drpdown_value_esign, user?.PatientUserNameAtCTMS, user?.PatientPasswordAtCTMS)
            setcommon_error_message("")
            setesign_password("")
        }).catch(err => {
            setcommon_error_message("Wrong Password ")
        }).finally(() => {
            setesign_disabled(false)
        })



    }



    return (
        <>

            {/* with yes no button */}

            <Dialog onClose={handleCloses_Esign} aria-labelledby="customized-dialog-title" open={open} className="success-popup e-sign-popup">
                <div className="Top_header_sign">
                    <h1>Electronic Signature</h1>

                    <img className="close-icon" src="/media/misc/white-close.svg" alt="close" onClick={handleCloses_Esign} />
                </div>
                <DialogContent dividers className="text-center p-10">
                    <div className="App">


                        <form className='signelectronic-popup'>
                            <div className="mb-8">

                                {/* on hold client change */}
                                {/* <select onChange={handleChange_esign_dropdown} value={drpdown_value_esign}>

                                    {esign_dropdown?.map((data, index) => {
                                        let textvalue = data.Description;
                                        if (textvalue.length > 60)
                                            textvalue = textvalue.substring(0, 60) + "..";
                                        return <option value={data.Description} key={index}>{textvalue}</option>

                                    })}

                                </select> */}
                                <div className=" mb-8">
                                    <p className="approve-msg">I have reviewed and approved.</p>
                                </div>
                            </div>
                            <div className=" mb-8">
                                <input
                                    placeholder="User Name"
                                    type="text"
                                    name="name"
                                    maxLength={51}
                                    value={esign_username}
                                    readOnly={true}
                                />

                            </div>
                            <div className="  mb-8">
                                <input
                                    placeholder="Password"
                                    type="password"
                                    name="password"
                                    maxLength={51}
                                    value={esign_password}
                                    onChange={(e) => { setesign_password(e.target.value); setcommon_error_message("") }}

                                />
                                <p style={{marginTop: "6px" }} className="color-red text-left">{common_error_message} </p>
                            </div>


                        </form>



                    </div>
                    <div >
                        <button
                            type="submit"
                            onClick={handleCloses_Esign}
                            className="btn btn-danger mr-5"
                            disabled={esign_disabled}

                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleCloses_Esign_submit}
                            className="btn btn-primary mr-5 save-btn pb-3 pt-3"
                            disabled={esign_disabled}
                        >
                            Sign
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}



