import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"
export const FullnameType = (props) => {
    const nested_data = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[nested_data?.Parent]?.[0]

    const [isFieldHidden, setIsFieldHidden] = useState(false)

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(nested_data, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>


            <div className="input_label">
                <label className="label_" htmlFor="">{nested_data.Meta.Label}</label>{nested_data.Meta.Required ? <p className="" style={{ color: "red" }}>*</p> : ""}
            </div>
            <div className="input_wrap">
                {nested_data?.Children?.map((children_elements, index) => {
                    
                    return [
                        <React.Fragment key={index}>
                            {children_elements.Meta.Hidden === false && children_elements.Type === "text_single_line" ? <>
                                <div className="name_input"> <input
                                    className=""
                                    name={children_elements.UUID}
                                    type="text"
                                    placeholder={children_elements.Meta.Placeholder}
                                    required={children_elements.Meta.Required}
                                    value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                                    onChange={(e) => { props.ON_change_inputfield(e, "full_name", index, null, nested_data?.Parent, null, null, props.rowid) }}
                                    
                                />

                                </div>
                                {children_elements.Meta.Required ? <p className="" style={{ color: "red" }}>*</p> : ""} </> : ""}
                        </React.Fragment>
                    ]
                }
                )}</div>
        </> : ""
    )
}


