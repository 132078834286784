import React, { useState, useEffect } from "react"
import { useLocation } from "react-router"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Dashboard_invite_api, Get_single_event } from "../modules/Auth/_redux/authCrud"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Link } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Date_convert, Date_convert_without_time_zone } from "../modules/Auth/_redux/Common_code"
import { useHistory } from "react-router-dom"
import { CommonPopup } from "../pages/Common_popup"

export const Details_page = (props) => {
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const dispatch = useDispatch()
  let location = useLocation()
  const [event_info, setevent_info] = useState()
  const [iscopy, setiscopy] = useState(false)
  let history = useHistory()
  const [see_more_status, setsee_more_status] = useState(false)
  const [popupstatus, setpopupstatus] = React.useState(false)
  let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const l1 = location.state?.StartDate
  const l2 = location.state?.EndDate
  const [url_without_sapce, seturl_without_sapce] = useState();
  const [visitTitle_with_type, setvisitTitle_with_type] = useState("")
  const [visitTitle, setvisitTitle] = useState("")
  const [typeIs, settypeIs] = useState("")
  const hostLink = localStorage.getItem("CTMSHostLink")
  const siteIdLocal = localStorage.getItem("SiteID_local")
  const CTMS_study_ID = useSelector((state) => state.auth.ctms_studyID, shallowEqual)

  useEffect(() => {
  }, [CTMS_study_ID]);
  useEffect(() => {
    let site_object = JSON.parse(localStorage.getItem("site_obj"))

    let type_is = location.state?.Type ? "Visit Type : " + location.state?.Type : ""
    settypeIs(type_is)

    let typeIs2 = location.state?.Type ? " - Visit Type (" + location.state?.Type + ")" : ""
    let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";
    let visit_title = (location.state?.VisitName === "" ? (location.state?.Title + ' - ') : (location.state?.VisitName + ' - ')) + StudyNameForEvents
    setvisitTitle(visit_title)

    let visitTitle_type = (location.state?.VisitName === "" ? (location.state?.Title + ' - ') : (location.state?.VisitName + ' - ')) + StudyNameForEvents + typeIs2
    setvisitTitle_with_type(visitTitle_type)

    setevent_info(location.state)
    const url_with_space = end_point_url + "/Meeting?roomname=" + encodeURIComponent(location.state?.Title)+ location.state?.CTMSEventID + "&title=" + encodeURIComponent(visitTitle_type)+ "&join_status_id=" + location.state?.id + "&host=" + hostLink + "&siteid=" + siteIdLocal
    const without_space = url_with_space.replace(/\s+/g, "%20")
    seturl_without_sapce(without_space)
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    if (studies_) {
    } else {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies_])

  let Start_date_full
  let end_date_full
  let Start_date_day_month
  let end_date_day_month
  let end_date_time
  if (localStorage.getItem("prev_path") === "study_cal") {
    Start_date_full = Date_convert(l1, 6)
    end_date_full = Date_convert(l2, 6)
    Start_date_day_month = Date_convert(l1, 7)
    end_date_day_month = Date_convert(l2, 7)
    end_date_time = Date_convert(l2, 1)
  } else {
    Start_date_full = Date_convert_without_time_zone(l1, 6)
    end_date_full = Date_convert_without_time_zone(l2, 6)
    Start_date_day_month = Date_convert_without_time_zone(l1, 7)
    end_date_day_month = Date_convert_without_time_zone(l2, 7)
    end_date_time = Date_convert_without_time_zone(l2, 1)
  }

  // to fix refresh issue
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => {
      })
  }

  const close_page = () => {
    props.history.goBack()
  }

  // redirect to meeting
  const Joincall = (title, id, join_status_id) => {
    localStorage.setItem("CTMSEventID", id)
    localStorage.setItem("join_status_id", join_status_id)
    Get_single_event(join_status_id).then(result => { Joincall_part2(title, id, result.data) }).catch(err => { })

  }

  const Joincall_part2 = (title, id, data) => {

    if (data[0].IsInitiates === true) {
      sessionStorage.setItem("RoomSID", data[0].RoomSID)

      history.push({
        pathname: "/Meeting",
        state: { title: title + id, only_title: visitTitle_with_type },
      })
    }
    else {
      setpopupstatus(true)
    }

  }

  const close_popup = () => {
    setpopupstatus(false)
  }

  return (

    <div className="event-details">
      <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

      <div className="top-heading">
        <h2>{localStorage.getItem("CTMSSiteTitle")}</h2>
        <div className="button-group">
          <button
            className="join-button"
            onClick={() => {
              Joincall(event_info?.Title, event_info?.CTMSEventID, event_info.id)
            }}
          >
            {" "}
            Join{" "}<img src="/media/misc/on-video.svg" className="video-icon" alt="unable to load" />
          </button>
          <button className="close-button" onClick={close_page}>
            Close
          </button>
        </div>
      </div>
      <CopyToClipboard
        text={url_without_sapce}
      >
        <div className="meeting-link d-flex second-header">
          <img
            src="/media/misc/link-.svg"
            className="link-Icon"
            onClick={() => {
              setiscopy(true)
              setTimeout(() => {
                setiscopy(false)
              }, 1000)
            }}
            alt="unable to load"
          />
          <div
            className="mb-0"

          >
            Copy link :{" "}
            <button className="see_more_btn" onClick={() => { setsee_more_status(!see_more_status) }}>{see_more_status ? "Hide URL" : "Show URL"} </button>

            {see_more_status && <div className="url-link-wrapper">
              <a
                href={url_without_sapce}
                className="url-link"
                target="_blank"
                rel="noopener noreferrer"
              ><p>{url_without_sapce}</p>
              </a>

              <img src="/media/misc/copy.svg" className="copy-icon" alt="unable to load"
                onClick={() => {
                  setiscopy(true)
                  setTimeout(() => {
                    setiscopy(false)
                  }, 1000)
                }}
              />
              {iscopy && <img src="/media/misc/check-.svg" className="copy-icon" alt="unable to load" />}
            </div>}
          </div>

        </div>
      </CopyToClipboard>
      <div className="main-wrapper d-flex">
        <div className="left-section">
          <div className="meeting-info">
            <div className="meeting-date-time">
              <img src="/media/misc/event-calendar-yellow.svg" alt="unable to load" />

              {Start_date_day_month === end_date_day_month ? (
                <span>
                  {Start_date_full}-{end_date_time}
                </span>
              ) : (
                <span>
                  {Start_date_full} - {end_date_full}
                </span>
              )}
            </div>
            {event_info?.Location && event_info?.Type.trim() !== 'Phone Visit' && event_info?.Type.trim() !== 'Virtual Visit' ? (
              <div className="meeting-date-time" style={{ marginLeft: -4 }}>
                <img
                  src="/media/misc/location.png"
                  style={{ width: 30, marginRight: 24 }}
                  alt="unable to load"
                />
                <span>{event_info?.Location}</span>
              </div>
            ) : (
              ""
            )}

          </div>
          <div className="middle-section">
            {typeIs ? <h2 className="visit-type">{typeIs}</h2> : ""}
            <h2>{visitTitle}</h2>
            <p>Join on your computer or mobile app</p>
            <button
              className="join-button"
              onClick={() => {
                Joincall(event_info?.Title, event_info?.CTMSEventID, event_info?.id)
              }}
            >
              Click here to join the meeting
            </button>
            <div className="d-flex mt-5 options">

              <img src="/media/misc/help-yellow.svg" alt="unable to load" />
              <Link to="/Site_info">Contact Site</Link>
            </div>
          </div>
          <p className="description">{event_info?.Description}</p>
        </div>
        <div className="right-section">
          <div className="pl-4">
            <h3>Created By</h3>
            <div className="participant">
              <img src="/media/svg/icons/General/user.png" alt="unable to load" />
              <span>{event_info?.CreatedBy}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
