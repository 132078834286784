import React, { useState, useEffect, } from "react"
import { Admin_view_dropdown } from "../../modules/Auth/_redux/authCrud"
import { Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import * as auth from "../../../app/modules/Auth/_redux/authRedux"
import { Clear_pushnotification } from "../../../app/modules/Auth/_redux/authCrud";
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useHistory } from "react-router-dom"

// popup styles
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

// popup styles end


export const AdminHeader = () => {
    const [open, setOpen] = React.useState(false)
    const [drpdown_value, setdrpdown_value] = useState("")
    const [dropdown_data, setdropdown_data] = useState([])
    const [global_search_box, setglobal_setsearch_box] = useState("")
    const [sitelogo, setsitelogo] = useState();
    const user = useSelector((state) => state.auth.user, shallowEqual)
    const dispatch = useDispatch()
    let history = useHistory()
    const window_value = useSelector((state) => state.auth.window_vaue, shallowEqual)
    let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)


    useEffect(() => {
        Get_dropdown_data()

    }, [])
    // to get dropdown data
    const Get_dropdown_data = () => {
        Admin_view_dropdown().then(res => { setdropdown_data(res.data) }).catch(err => { })
    }

    const handleChange = (e) => {
        // to clear dashboard data
        let clear_button = document.getElementById("clear_data")
        if (clear_button) {
            clear_button.click()
        }

        setdrpdown_value(e.target.value)
        dispatch(auth.actions.set_drodown_id(e.target.value))
        const specificItem = dropdown_data.find((item) => item.id === e.target.value)

        let colourcode = {
            Accent_Color: specificItem?.Accent_Color,
            Branding_Color: specificItem?.Branding_Color,
            HighLight_Color: specificItem?.HighLight_Color
        }
        dispatch(auth.actions.settheme(colourcode))

        sessionStorage.setItem("dropdown_value", JSON.stringify(specificItem))
        setglobal_setsearch_box("")
        setsitelogo(specificItem?.SiteLogoURL)
        dispatch(auth.actions.set_search_value(""))

    }

    // when page loads  we are setting first dropdown value as default
    useEffect(() => {
        if (dropdown_data?.length > 0) {
            setdrpdown_value(dropdown_data[0].id)
            dispatch(auth.actions.set_drodown_id(dropdown_data[0].id))
            sessionStorage.setItem("dropdown_value", JSON.stringify(dropdown_data[0]))
            setsitelogo(dropdown_data[0].SiteLogoURL)

            let colourcode = {
                Accent_Color: dropdown_data[0]?.Accent_Color,
                Branding_Color: dropdown_data[0]?.Branding_Color,
                HighLight_Color: dropdown_data[0]?.HighLight_Color
            }
            dispatch(auth.actions.settheme(colourcode))


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [dropdown_data])

    // popup methods
    const handleClose = () => {
        setOpen(false)
    }
    const Logout_clicked = () => {

        if (window_value) {
            window_value.close()
        }
        setOpen(false)
        dispatch(auth.actions.logout())
        let timeout = sessionStorage.getItem("sesson_logout_for_api")
        const id = sessionStorage.getItem("id")

        Clear_pushnotification(timeout, id)
        localStorage.clear()
        sessionStorage.clear()
        history.push("/")
    }



    // on click of global search in header
    const Global_search_clicked = (e) => {
        e.preventDefault()
        dispatch(auth.actions.set_search_value(global_search_box))
        const pathname = window.location.pathname

        if ((pathname === "/user-profile/personal-information-readonly" || pathname === "/user-profile/Manage-admin") && global_search_box.trim().length > 0) {
            sessionStorage.setItem("coming_from_my_sccount", "yes")
            history.push("/Admin_View")

        }
    }



    // when length is grter thna  3  global search in header
    const Global_search_clicked_second_way = (e) => {
        // e.preventDefault()
        if (true) {
            dispatch(auth.actions.set_search_value(e.target?.value))
            const pathname = window.location.pathname

            if ((pathname === "/user-profile/personal-information-readonly" || pathname === "/user-profile/Manage-admin") && global_search_box.trim().length > 0) {
                sessionStorage.setItem("coming_from_my_sccount", "yes")
                history.push("/Admin_View")

            }
        }

    }




    return (
        <div className="admin-view-wrap mb-0 sticky-nav-bar">

            <Navbar bg="white" className="top_header">
                <div className="nav_wrap">
                    <Link onClick={() => { setglobal_setsearch_box("") }} to="/Admin_View" className="navi-item cursor-pointer">
                        <div className="site-logo"><img src={`${sitelogo ? end_point_url + sitelogo : '/media/logos/logo.png'}`} alt="" /></div>

                    </Link>
                    <div className="d-flex study_search">
                        <span className="Label_Site">Sites</span>
                        <TextField
                            select
                            className="select-dropdown"
                            value={drpdown_value}
                            onChange={handleChange}
                            disabled={dropdown_data.length < 2}
                        >
                            {dropdown_data.map((data, index) => (
                                <MenuItem value={data.id} key={index}>{data.CTMSSiteTitle}</MenuItem>
                            ))}
                        </TextField>

                        <div className="search-box">

                            <form>
                                <div className="input-group rounded">
                                    <input type="search" className="form-control rounded" placeholder="Search For Study Name,Email,Name or MRN" aria-label="Search" aria-describedby="search-addon"
                                        value={global_search_box} onChange={(e) => { setglobal_setsearch_box(e.target.value); Global_search_clicked_second_way(e) }} />
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip
                                                id="user-notification-tooltip"
                                                style={{ backgroundColor: "transparent", border: "0" }}
                                            >
                                                Clear
                                            </Tooltip>
                                        }
                                    >
                                        <button type="button" className="close-icon-btn" onClick={() => { setglobal_setsearch_box(""); dispatch(auth.actions.set_search_value("")) }}>
                                            <img src="/media/misc/clear.svg" className="close-icon" alt="" />
                                        </button>

                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip
                                                id="user-notification-tooltip"
                                                style={{ backgroundColor: "transparent", border: "0" }}
                                            >
                                                Search
                                            </Tooltip>
                                        }
                                    >

                                        <button
                                            type="submit"
                                            className="search-icon-btn"
                                            onClick={(e) => { Global_search_clicked(e) }}
                                        >
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </OverlayTrigger>

                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="account-wrap">
                        <Navbar.Collapse id="basic-navbar-nav" className="account-dropdown-wrap">
                            <Nav className="me-auto">
                                <span className="symbol-label header-profile font-size-h5 font-weight-bold">
                                    {user.ImageURL ? <img
                                        src={`${sessionStorage.getItem("end_point")}${user.ImageURL}`}
                                        alt=""
                                        id="img"
                                        className="img"

                                    /> : <>{user.FirstName[0].toUpperCase()} {user.LastName[0].toUpperCase()} </>}
                                </span>
                                <NavDropdown title={`${user.FirstName} ${user.LastName}`} id="basic-nav-dropdown">
                                    <div
                                        className="d-flex align-items-center justify-content-between px-5 py-8 bgi-size-cover bgi-no-repeat rounded-top backgroundColor"
                                    // style={{

                                    //     backgroundColor: "#E7C235",
                                    // }}
                                    >
                                        <div className="symbol mr-3 profile_text">
                                            <span className="symbol-label dropdown-profile font-size-h5 font-weight-bold">
                                                {user.ImageURL ? <img
                                                    src={`${sessionStorage.getItem("end_point")}${user.ImageURL}`}
                                                    alt=""
                                                    id="img"
                                                    className="img"

                                                /> : <>{user.FirstName[0].toUpperCase()} {user.LastName[0].toUpperCase()} </>}
                                            </span>                                                <span className="full_name">{user?.FirstName} {user?.LastName}</span>
                                        </div>

                                    </div>
                                    <div onClick={() => { setglobal_setsearch_box("") }} className="navi navi-spacer-x-0 pt-5">
                                        <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
                                            <div className="navi-link">
                                                <div className="navi-icon mr-2">
                                                    <i className="flaticon2-calendar-3 text-success" />
                                                </div>
                                                <div className="navi-text">
                                                    <div className="font-weight-bold cursor-pointer">My Account</div>
                                                </div>
                                            </div>
                                        </Link>
                                        {user?.LogInType === "SuperAdmin" && <Link to="/user-profile/Manage-admin" className="navi-item px-8 cursor-pointer">
                                            <div className="navi-link">
                                                <div className="navi-icon mr-2">
                                                    {/* <i className="flaticon2-calendar-3 text-success" /> */}
                                                    <img src="/media/svg/icons/General/User.svg" className="close-icon" alt="" />
                                                    {/* <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                                                        ></SVG> */}
                                                </div>
                                                <div className="navi-text">
                                                    <div className="font-weight-bold cursor-pointer">Manage Admin</div>
                                                </div>
                                            </div>
                                        </Link>}


                                    </div>

                                    <NavDropdown.Divider />
                                    <button onClick={() => { setOpen(true) }} className="btn btn-primary font-weight-bold mx-5 mb-5 log-out-btn">Log Out</button>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                        <span className="Custom-tooltip">My account</span>
                    </div>
                </div>
            </Navbar>


            {/* popups starts */}
            <div>
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    className="log-out-wrap"
                >
                    <DialogTitle className="popup-heading">Log Out</DialogTitle>
                    <DialogContent dividers className="middle-content">
                        <Typography gutterBottom className="logut-content">
                            Are you sure you want to log out?
                        </Typography>
                    </DialogContent>
                    <DialogActions className="btn-wrapper">
                        <div className="card-toolbar">
                            <button className="btn btn-danger mr-6" onClick={handleClose} >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={Logout_clicked}
                                className="btn  logout-btn"

                            >
                                Log Out
                            </button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}
export default AdminHeader