
import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Text_multiline = (props) => {
    // Change the height of text area according to the scroll height
    function changeTextareaHeight() {
        let textElement = document.getElementById(children_elements?.UUID)
        textElement.style = "auto"
        textElement.style.height = (textElement.scrollHeight) + "px";
    }

    const children_elements = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]

    const [isFieldHidden, setIsFieldHidden] = useState(false)


    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
        changeTextareaHeight()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>
          
            <div className="input_label">
                <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}
            </div>
            <div className="helptext-wrap">
                <div className="input_wrap text-area-wrap">
                    <textarea  placeholder={children_elements.Meta.Placeholder}
                    className="hide-scrollbar"
                        required={children_elements.Meta.Required}
                        value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                        id={children_elements.UUID}
                        name={children_elements.UUID}
                        onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield", children_elements.UUID, null, children_elements, props.rowid) }}
                        onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, null, props.rowid) }}
                    >
                    </textarea>
                </div>

                <p className="help_text"> {children_elements.Meta.Note?.blocks?.[0].text ? children_elements.Meta.Note?.blocks?.[0].text : children_elements.Meta?.Note}</p>

            </div>
           
        </> : ""
    )
}













