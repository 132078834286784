
import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Radiogroup = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    const main_comp = props.main_comp
    let checkClearButton = commonId ? form_value[children_elements.UUID]?.[commonId] : form_value[children_elements.UUID]
    const [isFieldHidden, setIsFieldHidden] = useState(false)

      // Check element visibility when form_value changes
      useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
          setIsFieldHidden(is_hidden);
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>
            <div className="input_label">
                <label className="label_" htmlFor="">{(children_elements.Meta.Label?.blocks?.[0].text === "" || children_elements.Meta.Label?.blocks?.[0].text) ? children_elements.Meta.Label?.blocks?.[0]?.text : children_elements.Meta?.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
         

            {main_comp ?
                <div className=" check_box_container">
                    {children_elements.Meta.DataSources.options.map((grand_child, index) => {
                        let valuefor_check_condition = grand_child.id ? grand_child.id : grand_child?.value

                        return [<div className="check_box_wrap" key={index}>
                            <input type="radio" value={grand_child.id ? grand_child.id : grand_child?.value}
                                onChange={(e) => { props.ON_change_inputfield(e, "radio", children_elements.UUID, null, children_elements?.Parent,null,main_comp) }}
                                checked={form_value[children_elements.UUID] === valuefor_check_condition || form_value[children_elements.UUID]?.[commonId] === valuefor_check_condition}
                               
                            />
                            <label className="label_" htmlFor="">{grand_child?.label}</label>
                        </div>]

                    })}

                </div> :
                <div className=" check_box_container">
                    {children_elements.Meta.DataSources.options.map((grand_child, index) => {
                        let valuefor_check_condition = grand_child.id ? grand_child.id : grand_child?.value

                        return [<div className="" key={index}>
                            <div>
                                {index === 0 && children_elements.Meta.EmptyOption?.label ? <div className="check_box_wrap">
                                    <input type="radio" value={children_elements.Meta.EmptyOption?.label}
                                         onChange={(e) => { props.ON_change_inputfield(e, "radio", children_elements.UUID, null, children_elements?.Parent, null, null, props.rowid) }}
                                        checked={commonId ? form_value[children_elements.UUID]?.[commonId] === children_elements.Meta.EmptyOption?.label : form_value[children_elements.UUID] === children_elements.Meta.EmptyOption?.label}
                                       
                                    />
                                    <label className="label_" htmlFor="">{children_elements.Meta.EmptyOption?.label}</label>
                                </div> : ""}
                                <div className="check_box_wrap">
                                    <input type="radio" value={grand_child.id ? grand_child.id : grand_child?.value}
                                         onChange={(e) => { props.ON_change_inputfield(e, "radio", children_elements.UUID, null, children_elements?.Parent, null, null, props.rowid) }}                                      
                                        checked={commonId ? form_value[children_elements.UUID]?.[commonId] === valuefor_check_condition : form_value[children_elements.UUID] === valuefor_check_condition}
                                        
                                    />
                                    <label className="label_" htmlFor="">{grand_child?.label}</label>
                                </div>
                            </div>
                        </div>]

                    })}{checkClearButton ? <div className="clear-text">
                        <span title="Clear" onClick={() => { props.clear_fields(children_elements.UUID, props.rowid) }}>Clear</span>
                       
                    </div> : ""}

                </div>
            }


        </> : ""
    )
}


