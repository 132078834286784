/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../_common/_partials/controls";
import { toAbsoluteUrl } from "../../../_common/_helpers";
import * as auth from "../Auth";
import { updatePasswordMethod } from "../Auth/_redux/authCrud";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";


function ChangePassword(props) {

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);


  // Fields
  const [loading, setloading] = useState(false);
  const [isError, setisError] = useState(false);
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [opens, setOpens] = React.useState(false);
  const [errorpopup, seterrorpopup] = React.useState(false);
  const [popupmessage, setpopupmessage] = React.useState('');

  let history = useHistory();

  const handleCloses = () => {
    setOpens(false);
    // dispatch(auth.actions.logout())
    history.push("/user-profile/personal-information-readonly")
    formik.resetForm()
  };
  const handleCloses_error = () => {
    seterrorpopup(false);
    formik.resetForm()
  };
  useEffect(() => { }, [user]);
  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    setisError(false);
    const updatedUser = Object.assign(user, {
      password: values.password,
    });

    let CryptoJS = require("crypto-js");

    let encripted = CryptoJS.AES.encrypt(values.cPassword, 'secret').toString();


    updatePasswordMethod(values.currentPassword, values.cPassword).then(data => {
      setloading(false); setSubmitting(false); setOpens(true);
      localStorage.setItem("password_encrypted", encripted)

    }).catch(error => { setloading(false); setSubmitting(false); seterrorpopup(true); setpopupmessage(error.response.data.message) });

  };



  // UI Helpers
  const initialValues = {
    currentPassword: "",
    password: "",
    cPassword: "",
  };
  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    password: Yup.string().required("New password is required").min(8, "minium 8 charchters required !").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password must contain at least ONE( digit,lowercase character,uppercase character,special character)'),
    cPassword: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({

    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    validate: values => {
    },
    onReset: (values, { resetForm }) => {
      // resetForm();
    },

  });

  return (
    <>
      <form className="card card-custom change-pass-form" onSubmit={formik.handleSubmit}>
        {loading && <ModalProgressBar />}

        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Change Password
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Change your account password
            </span>
          </div>

        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          <div className="card-body">
            {/* begin::Alert */}
            {isError && (
              <div
                className="alert alert-custom alert-light-danger fade show mb-10"
                role="alert"
              >
                <div className="alert-icon">
                  <span className="svg-icon svg-icon-3x svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                    ></SVG>{" "}
                  </span>
                </div>
                <div className="alert-text font-weight-bold">
                  Configure user passwords to expire periodically. Users will need
                  warning that their passwords are going to expire,
                  <br />
                  or they might inadvertently get locked out of the system!
                </div>
                <div className="alert-close" onClick={() => setisError(false)}>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="ki ki-close"></i>
                    </span>
                  </button>
                </div>
              </div>
            )}
            {/* end::Alert */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                Current Password
              </label>
              <div className="col-lg-9 col-xl-9">
                <input
                  type="password"
                  placeholder="Current Password"
                  className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                    "currentPassword"
                  )}`}
                  name="currentPassword"
                  maxLength={51}
                  {...formik.getFieldProps("currentPassword")}
                />
                {formik.touched.currentPassword &&
                  formik.errors.currentPassword ? (
                  <div className="invalid-feedback">
                    {formik.errors.currentPassword}
                  </div>
                ) : null}

              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                New Password
              </label>
              <div className="col-lg-9 col-xl-9">
                <input
                  type="password"
                  placeholder="New Password"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  maxLength={51}
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">{formik.errors.password}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                Confirm Password
              </label>
              <div className="col-lg-9 col-xl-9">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "cPassword"
                  )}`}
                  name="cPassword"
                  maxLength={51}
                  {...formik.getFieldProps("cPassword")}
                />
                {formik.touched.cPassword && formik.errors.cPassword ? (
                  <div className="invalid-feedback">
                    {formik.errors.cPassword}
                  </div>
                ) : null}
              </div>

            </div>
            <div className="card-toolbar text-right mt-10">
              <button
                type="submit"
                className="btn btn-primary mr-5"
                disabled={
                  formik.isSubmitting || (formik.touched && !formik.isValid)
                }
              >
                Save Changes
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/personal-information-readonly"
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>

        </div>

        {/* end::Form */}
      </form>
      {/* popup starts */}
      {/* Successfully popup */}
      <Dialog onClose={handleCloses} aria-labelledby="customized-dialog-title" open={opens} className="success-popup">

        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">Successful!</h2>
          <p className="success-text mb-10">Your password  successfully updated!</p>
          <button
            type="submit"
            onClick={handleCloses}
            className="btn btn-primary mr-5 save-btn"

          >
            Ok
          </button>
        </DialogContent>

      </Dialog>


      {/* Failed dialog */}
      <Dialog onClose={handleCloses_error} aria-labelledby="customized-dialog-title" open={errorpopup} className="success-popup">
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-exclamation-triangle" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
          <p className="success-text mb-10">{popupmessage}</p>
          <button
            type="submit"
            onClick={handleCloses_error}
            className="btn btn-primary mr-5 save-btn"

          >
            Ok
          </button>
        </DialogContent>

      </Dialog>

    </>
  );
}

export default connect(null, auth.actions)(ChangePassword);
