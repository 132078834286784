/* eslint-disable no-unused-vars */
import React, { useEffect, useState, } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as auth from "../../../../app/modules/Auth"
import { useHistory } from "react-router-dom"
import { Change_medication_status, Get_single_event } from "../../../../app/modules/Auth/_redux/authCrud"
import { Date_convert_without_time_zone } from "../../../../app/modules/Auth/_redux/Common_code"
import { CommonPopup } from "../../../../app/pages/Common_popup"
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap"
import Icon from "@material-ui/core/Icon"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"

export function Dash_AllReminder_Wid(props) {
  const upcoming_events_All = useSelector(
    (state) => state.auth.upcoming_events,
    shallowEqual
  )

  const dispatch = useDispatch()

  let history = useHistory()

  const [upcoming_events, setupcoming_events] = useState([])
  const [todayevent, settodayevent] = useState([])
  // const [medication, setmedication] = useState([])
  const [loader, setloader] = useState(true)
  // const [medication_button, setmedication_button] = useState(false)
  const [open_medication, setopen_medication] = React.useState(false)

  const [popupstatus, setpopupstatus] = React.useState(false)
  const [index_value_tody_event, setindex_value_tody_event] = useState()
  const [index_value_upcoming_event, setindex_value_upcoming_event] = useState()

  const [loading_status, setloading_status] = useState()





  // to  assign api data to var
  useEffect(() => {
    if (upcoming_events_All) {
      setupcoming_events(upcoming_events_All[0].UpcomingEvents)
      settodayevent(upcoming_events_All[2].TodaysPlanEvents)
      // setmedication()
    }
  }, [upcoming_events_All])

  useEffect(() => {
  }, [upcoming_events])

  // medication button pressed
  // const Medication_button_pressed = (id) => {
  //   setopen_medication(true)
  //   Change_medication_status(id)
  //     .then((data) => {
  //       dispatch(auth.actions.requestUpcomingEvent())
  //     })
  //     .catch((err) => { })
  //   setmedication_button(true)
  //   setTimeout(() => {
  //     setmedication_button(false)
  //   }, 600)
  // }

  const converttimezone = (date_) => {
    let date = new Date()
    let date_from_response = new Date(date_)
    if (date.getTime() > date_from_response.getTime()) {
      return true
    } else {
      return false
    }
  }


  // redirect to calendar page
  const open_moredetail = (data) => {
    localStorage.setItem("prev_path", "dash_all_remind")

    history.push({
      pathname: "/More_details",
      state: data,
    })
  }
  // adding loader
  useEffect(() => {
    if (upcoming_events_All?.length >= 0) {
      setloader(false)
    }
    setTimeout(() => {
      setloader(false)
    }, 7000)
  }, [upcoming_events_All])

  // medication popup method
  const handleCloses_medication = () => {
    setopen_medication(false)
  }
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  // redirect to meeting
  const Joincall = (title, id, join_status_id, type, index, custom_title) => {
    // for loader on join button
    setloading_status(true)
    if (type === 1) {
      setindex_value_tody_event(index)
      setindex_value_upcoming_event("")

    } else {
      setindex_value_upcoming_event(index)
      setindex_value_tody_event("")

    }
    localStorage.setItem("CTMSEventID", id)
    localStorage.setItem("join_status_id", join_status_id)
    Get_single_event(join_status_id).then(result => { Joincall_part2(title, id, result.data, custom_title) }).catch(err => { setloading_status(false) })
  }

  const Joincall_part2 = (title, id, data, custom_title) => {
    setloading_status(false)
    if (data[0].IsInitiates === true) {
      sessionStorage.setItem("RoomSID", data[0].RoomSID)
      history.push({
        pathname: "/Meeting",
        state: { title: title + id, only_title: custom_title },
      })
    }
    else {
      setpopupstatus(true)
    }

  }
  const close_popup = () => {
    setpopupstatus(false)
  }
  return (
    <>
      <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

      <div className="dash-reminder" style={{ position: "relative" }}>
        {loader ? (
          <div
            style={{ textAlign: "center", position: "absolute", top: "50%", left: "50%" }}
          >
            <Spinner animation="border" className="spinner-info" variant="primary" />
          </div>
        ) : (
          <>
            <div className="todays-plan-wrapper" key={1}>
              <div className="today-plan-heading">
                <img src="/media/misc/cal.svg" alt="" />
                <span>Today's Plan</span>
              </div>
              {todayevent?.length > 0 ? (
                <div className="today-inner">
                  <div
                    style={{
                      paddingTop: 0,
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingBottom: 15,
                    }}
                  >
                    {todayevent?.map((value, index) => {
                      let site_object = JSON.parse(localStorage.getItem("site_obj"))

                      let typeIs = value?.Type ? " - Visit Type (" + value?.Type + ")" : ""

                      let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";

                      let visitTitle = (value?.VisitName === "" ? (value?.Title + ' - ') : (value?.VisitName + ' - ')) + StudyNameForEvents + typeIs

                      return [
                        <React.Fragment key={index}>
                          <div className="today-plan-content-wrap" >
                            <div className="vertical-line">
                              <span className="circle check"></span>
                              {converttimezone(value.StartDate) ? (
                                <img src="/media/misc/check.svg" className="check-icon" alt="" />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="today-plan-content meeting-content d-block">
                              <div className="d-flex">
                                <div
                                  className="left-section"
                                  onClick={() => open_moredetail(value)}
                                >
                                  <div className="time">
                                    <div className="time_left">
                                      <img
                                        className="clock-icon"
                                        src="/media/misc/wall-clock.png"
                                        alt=""
                                      />
                                      <div className="d-flex justify-content-between align-items-center resize-wrap">
                                        <span>
                                          {Date_convert_without_time_zone(value?.StartDate, 1)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="title-wrap">
                                    <span className="mr-4">
                                      <b className="event-title">{visitTitle}</b>
                                    </span>
                                  </div>

                                  {(value?.Location === "" ||
                                    value?.Location === undefined ||
                                    value?.Location === null) || value?.Type.trim() === 'Phone Visit' || value?.Type.trim() === 'Virtual Visit' ? (
                                    " "
                                  ) : (
                                    <div className="location d-flex">
                                      <img src="/media/misc/location.png" alt="" />

                                      <p className="rout mb-0">{value.Location}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="right-section">
                                  <img
                                    className="resize-icon"
                                    src="/media\misc\details-gray.svg"
                                    onClick={() => {
                                      open_moredetail(value)
                                    }}
                                    alt=""
                                    title="Event Details"
                                  />

                                </div>

                              </div>
                              {true ? (
                                <div className="text-right">
                                  <button type="button" className="blue-button " style={{ marginRight: 0 }} onClick={() => {
                                    Joincall(value?.Title, value?.CTMSEventID, value?.id, 1, index, visitTitle)
                                  }}>
                                    Join <img src="/media/misc/on-video.svg" className="video-icon" alt="video" />{loading_status && index_value_tody_event === index && <span className="ml-3 spinner spinner-white"></span>}
                                  </button>
                                </div>

                              ) : (
                                ""
                              )}
                            </div>

                          </div>
                        </React.Fragment>,
                      ]
                    })}


                    {/* this code is on hold */}



                    {/* {medication?.map((value,index) => {
                      return [
                        <>
                          <div className="today-plan-content-wrap" key={index}>
                            <div className="vertical-line">
                              <span className="circle check"></span>
                              {value.MedicationTreatmentStatuses === false ? (
                                ""
                              ) : (
                                <img src="/media/misc/check.svg" className="check-icon" />
                              )}
                            </div>
                            <div className="today-plan-content">
                              <div className="time">
                              
                              </div>
                              <div className="title-wrap">
                                <span className="medication-head">
                                  <b>{value.Medication}</b>
                                </span>
                                <h6 className="time_right">{value.Regimen}</h6>
                              </div>

                              <span className="rout">
                                {value.Route} {value.Route ? "  ," : " "}{" "}
                              </span>
                              <span className="rout">
                                {value.Dosage || value.DosageUnit ? "Dosage -" : ""}{" "}
                                {value.Dosage} {value.DosageUnit ? "- " : " "}{" "}
                                {value.DosageUnit}
                              </span>
                              <p className="taken-dosages">Dosages Taken -{value.MedicationCount[0].MedicationCount}</p>
                              {value.MedicationTreatmentStatuses === false ? (
                                <div className="text-right">
                                  <button
                                    type="button"
                                    className="blue-button mark-completed"
                                    onClick={() => {
                                      Medication_button_pressed(value.id)
                                    }}
                                    disabled={medication_button}
                                  >
                                    Mark As Completed
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>,
                      ]
                    })} */}

                  </div>
                </div>
              ) : (
                <div
                  style={{
                    paddingTop: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBottom: 15,
                  }}
                >
                  <div className="no-events ">
                    {" "}
                    <img className="No-event-icon" src="/media\misc\no-events.png" alt="" />
                    <span>No Events Today</span>{" "}
                  </div>
                </div>
              )}
            </div>
            <div style={{ height: "100%" }}>
              <div className="reminder-heading">
                <img src="/media/misc/cal.svg" alt="" />
                <span>Upcoming Events</span>
              </div>
              <div className="upcomining-inner">
                {upcoming_events?.length > 0 ? (
                  upcoming_events?.map((data, index) => {
                    let site_object = JSON.parse(localStorage.getItem("site_obj"))

                    let typeIs = data?.Type ? " - Visit Type (" + data?.Type + ")" : ""

                    let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";

                    let visitTitle = (data?.VisitName === "" ? (data?.Title + ' - ') : (data?.VisitName + ' - ')) + StudyNameForEvents + typeIs
                    return [
                      <React.Fragment key={index}>
                        <div className="inner-wrap" >
                          <div className="reminder-alert">
                            <div className="reminder-img">
                              {data?.Type.trim() === 'Phone Visit' || data?.Type.trim() === 'Virtual Visit' ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                >
                                  <g
                                    id="Group_1804"
                                    data-name="Group 1804"
                                    transform="translate(-1009 -262)"
                                  >
                                    <g id="r-visit" transform="translate(-77 -173)">
                                      <g
                                        id="Group_1042"
                                        data-name="Group 1042"
                                        transform="translate(-8 7)"
                                      >
                                        <g
                                          id="Ellipse_96"
                                          data-name="Ellipse 96"
                                          transform="translate(1094 428)"
                                          fill="none"
                                          stroke="#e1c42b"
                                          stroke-width="1"
                                          stroke-dasharray="5"
                                        >
                                          <circle cx="22" cy="22" r="22" stroke="none" />
                                          <circle cx="22" cy="22" r="21.5" fill="none" />
                                        </g>
                                        <circle
                                          id="Ellipse_93"
                                          data-name="Ellipse 93"
                                          cx="17.5"
                                          cy="17.5"
                                          r="17.5"
                                          transform="translate(1099 433)"
                                          fill="#e1c42b"
                                        />
                                        <g
                                          id="visitor_1_"
                                          data-name="visitor (1)"
                                          transform="translate(1105.762 440)"
                                        >
                                          <g
                                            id="Group_1005"
                                            data-name="Group 1005"
                                            transform="translate(2.529 8.127)"
                                          >
                                            <g id="Group_1004" data-name="Group 1004">
                                              <path
                                                id="Path_1072"
                                                data-name="Path 1072"
                                                d="M67.551,201.836a2.115,2.115,0,1,0,2.115,2.115A2.117,2.117,0,0,0,67.551,201.836Zm0,3.172a1.058,1.058,0,1,1,1.058-1.058A1.059,1.059,0,0,1,67.551,205.008Z"
                                                transform="translate(-65.436 -201.836)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1007"
                                            data-name="Group 1007"
                                            transform="translate(10.8 8.538)"
                                          >
                                            <g id="Group_1006" data-name="Group 1006">
                                              <rect
                                                id="Rectangle_426"
                                                data-name="Rectangle 426"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1009"
                                            data-name="Group 1009"
                                            transform="translate(14.373 8.538)"
                                          >
                                            <g id="Group_1008" data-name="Group 1008">
                                              <rect
                                                id="Rectangle_427"
                                                data-name="Rectangle 427"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1011"
                                            data-name="Group 1011"
                                            transform="translate(10.8 10.903)"
                                          >
                                            <g id="Group_1010" data-name="Group 1010">
                                              <rect
                                                id="Rectangle_428"
                                                data-name="Rectangle 428"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1013"
                                            data-name="Group 1013"
                                            transform="translate(14.373 10.903)"
                                          >
                                            <g id="Group_1012" data-name="Group 1012">
                                              <rect
                                                id="Rectangle_429"
                                                data-name="Rectangle 429"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1015"
                                            data-name="Group 1015"
                                            transform="translate(10.8 3.861)"
                                          >
                                            <g id="Group_1014" data-name="Group 1014">
                                              <rect
                                                id="Rectangle_430"
                                                data-name="Rectangle 430"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1017"
                                            data-name="Group 1017"
                                            transform="translate(14.373 3.861)"
                                          >
                                            <g id="Group_1016" data-name="Group 1016">
                                              <rect
                                                id="Rectangle_431"
                                                data-name="Rectangle 431"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1019"
                                            data-name="Group 1019"
                                            transform="translate(10.8 6.226)"
                                          >
                                            <g id="Group_1018" data-name="Group 1018">
                                              <rect
                                                id="Rectangle_432"
                                                data-name="Rectangle 432"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1021"
                                            data-name="Group 1021"
                                            transform="translate(14.373 6.226)"
                                          >
                                            <g id="Group_1020" data-name="Group 1020">
                                              <rect
                                                id="Rectangle_433"
                                                data-name="Rectangle 433"
                                                width="2.115"
                                                height="1.057"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            id="Group_1023"
                                            data-name="Group 1023"
                                            transform="translate(0.238)"
                                          >
                                            <g id="Group_1022" data-name="Group 1022">
                                              <path
                                                id="Path_1073"
                                                data-name="Path 1073"
                                                d="M17.869,1.177V0H9.25V1.183H6.86V5.651A4.9,4.9,0,0,0,.238,10.235c0,1.106.73,2.92,2.233,5.544,1.076,1.88,2.168,3.49,2.179,3.506L5.135,20l.486-.715c.009-.013.718-1.059,1.556-2.447H20.219V1.185h0ZM5.135,17.891c-1.361-2.1-3.722-6.066-3.722-7.656a3.722,3.722,0,0,1,7.444,0C8.858,11.825,6.5,15.794,5.135,17.891Zm13.909-2.229H14.186V13.233H13.011v2.429H7.867c1.457-2.561,2.166-4.338,2.166-5.428a4.894,4.894,0,0,0-2-3.944V2.359h2.39V1.175h6.269V2.349l2.351.008Z"
                                                transform="translate(-0.238)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <g
                                      id="Group_1057"
                                      data-name="Group 1057"
                                      transform="translate(564 74)"
                                    >
                                      <g
                                        id="Path_1107"
                                        data-name="Path 1107"
                                        transform="translate(450 193)"
                                        fill="#f1f1f1"
                                      >
                                        <path
                                          d="M 17.5 34.5 C 12.95913982391357 34.5 8.690059661865234 32.731689453125 5.479189872741699 29.52080917358398 C 2.268310070037842 26.30994033813477 0.5 22.04085922241211 0.5 17.5 C 0.5 12.95913982391357 2.268310070037842 8.690059661865234 5.479189872741699 5.479189872741699 C 8.690059661865234 2.268310070037842 12.95913982391357 0.5 17.5 0.5 C 22.04085922241211 0.5 26.30994033813477 2.268310070037842 29.52080917358398 5.479189872741699 C 32.731689453125 8.690059661865234 34.5 12.95913982391357 34.5 17.5 C 34.5 22.04085922241211 32.731689453125 26.30994033813477 29.52080917358398 29.52080917358398 C 26.30994033813477 32.731689453125 22.04085922241211 34.5 17.5 34.5 Z"
                                          stroke="none"
                                        />
                                        <path
                                          d="M 17.5 1 C 13.09268951416016 1 8.949180603027344 2.716300964355469 5.832740783691406 5.832740783691406 C 2.716300964355469 8.949180603027344 1 13.09268951416016 1 17.5 C 1 21.90731048583984 2.716300964355469 26.05081939697266 5.832740783691406 29.16725921630859 C 8.949180603027344 32.28369903564453 13.09268951416016 34 17.5 34 C 21.90731048583984 34 26.05081939697266 32.28369903564453 29.16725921630859 29.16725921630859 C 32.28369903564453 26.05081939697266 34 21.90731048583984 34 17.5 C 34 13.09268951416016 32.28369903564453 8.949180603027344 29.16725921630859 5.832740783691406 C 26.05081939697266 2.716300964355469 21.90731048583984 1 17.5 1 M 17.5 0 C 27.16497993469238 0 35 7.835020065307617 35 17.5 C 35 27.16497993469238 27.16497993469238 35 17.5 35 C 7.835020065307617 35 0 27.16497993469238 0 17.5 C 0 7.835020065307617 7.835020065307617 0 17.5 0 Z"
                                          stroke="none"
                                          fill="#e1c42b"
                                        />
                                      </g>
                                      <g
                                        id="_7091190_online_learning_meeting_tv_conference_icon"
                                        data-name="7091190_online_learning_meeting_tv_conference_icon"
                                        transform="translate(456.361 200.154)"
                                      >
                                        <path
                                          id="Path_1104"
                                          data-name="Path 1104"
                                          d="M16.117,14.423l-2.158-1.234a.23.23,0,0,0-.308.076l-1.309,2.052-1.308-2.052a.23.23,0,0,0-.308-.076L8.568,14.423a.231.231,0,0,0-.116.2v2.384a.23.23,0,1,0,.46,0V14.757L10.765,13.7l1.384,2.172a.24.24,0,0,0,.388,0L13.921,13.7l1.852,1.059v2.25a.23.23,0,1,0,.46,0V14.623A.231.231,0,0,0,16.117,14.423Z"
                                          transform="translate(-1.703 -2.674)"
                                          fill="#e1c42b"
                                          stroke="#e7c235"
                                          stroke-width="0.8"
                                        />
                                        <path
                                          id="Path_1105"
                                          data-name="Path 1105"
                                          d="M12.6,11.156h.922A1.287,1.287,0,0,0,14.8,9.871V8.244a1.287,1.287,0,0,0-1.286-1.286H12.6A1.287,1.287,0,0,0,11.31,8.244V9.871A1.287,1.287,0,0,0,12.6,11.156Zm-.826-2.912a.827.827,0,0,1,.826-.826h.922a.827.827,0,0,1,.826.826V9.871a.827.827,0,0,1-.826.826H12.6a.827.827,0,0,1-.826-.826Z"
                                          transform="translate(-2.418 -1.124)"
                                          fill="#e1c42b"
                                          stroke="#e7c235"
                                          stroke-width="0.8"
                                        />
                                        <path
                                          id="Path_1106"
                                          data-name="Path 1106"
                                          d="M18.665,2.462H2.613a.975.975,0,0,0-.974.975V15.1a.975.975,0,0,0,.974.975H8.73v2.689H6.9a.23.23,0,1,0,0,.46h7.468a.23.23,0,1,0,0-.46H12.548V16.079h6.117a.975.975,0,0,0,.974-.975V3.437a.975.975,0,0,0-.974-.975Zm-6.577,16.3H9.19V16.079h2.9ZM19.179,15.1a.515.515,0,0,1-.514.515H2.613A.515.515,0,0,1,2.1,15.1V3.434a.515.515,0,0,1,.514-.511H18.665a.515.515,0,0,1,.514.515Z"
                                          fill="#e1c42b"
                                          stroke="#e7c235"
                                          stroke-width="0.8"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                >
                                  <g id="r-visit" transform="translate(-1086 -435)">
                                    <g
                                      id="Group_1042"
                                      data-name="Group 1042"
                                      transform="translate(-8 7)"
                                    >
                                      <g
                                        id="Ellipse_96"
                                        data-name="Ellipse 96"
                                        transform="translate(1094 428)"
                                        fill="none"
                                        stroke="#e1c42b"
                                        strokeWidth="1"
                                        strokeDasharray="5"
                                      >
                                        <circle cx="22" cy="22" r="22" stroke="none" />
                                        <circle cx="22" cy="22" r="21.5" fill="none" />
                                      </g>
                                      <g
                                        id="visitor_1_"
                                        data-name="visitor (1)"
                                        transform="translate(1105.762 440)"
                                      >
                                        <g
                                          id="Group_1005"
                                          data-name="Group 1005"
                                          transform="translate(2.529 8.127)"
                                        >
                                          <g id="Group_1004" data-name="Group 1004">
                                            <path
                                              id="Path_1072"
                                              data-name="Path 1072"
                                              d="M67.551,201.836a2.115,2.115,0,1,0,2.115,2.115A2.117,2.117,0,0,0,67.551,201.836Zm0,3.172a1.058,1.058,0,1,1,1.058-1.058A1.059,1.059,0,0,1,67.551,205.008Z"
                                              transform="translate(-65.436 -201.836)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1007"
                                          data-name="Group 1007"
                                          transform="translate(10.8 8.538)"
                                        >
                                          <g id="Group_1006" data-name="Group 1006">
                                            <rect
                                              id="Rectangle_426"
                                              data-name="Rectangle 426"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1009"
                                          data-name="Group 1009"
                                          transform="translate(14.373 8.538)"
                                        >
                                          <g id="Group_1008" data-name="Group 1008">
                                            <rect
                                              id="Rectangle_427"
                                              data-name="Rectangle 427"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1011"
                                          data-name="Group 1011"
                                          transform="translate(10.8 10.903)"
                                        >
                                          <g id="Group_1010" data-name="Group 1010">
                                            <rect
                                              id="Rectangle_428"
                                              data-name="Rectangle 428"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1013"
                                          data-name="Group 1013"
                                          transform="translate(14.373 10.903)"
                                        >
                                          <g id="Group_1012" data-name="Group 1012">
                                            <rect
                                              id="Rectangle_429"
                                              data-name="Rectangle 429"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1015"
                                          data-name="Group 1015"
                                          transform="translate(10.8 3.861)"
                                        >
                                          <g id="Group_1014" data-name="Group 1014">
                                            <rect
                                              id="Rectangle_430"
                                              data-name="Rectangle 430"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1017"
                                          data-name="Group 1017"
                                          transform="translate(14.373 3.861)"
                                        >
                                          <g id="Group_1016" data-name="Group 1016">
                                            <rect
                                              id="Rectangle_431"
                                              data-name="Rectangle 431"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1019"
                                          data-name="Group 1019"
                                          transform="translate(10.8 6.226)"
                                        >
                                          <g id="Group_1018" data-name="Group 1018">
                                            <rect
                                              id="Rectangle_432"
                                              data-name="Rectangle 432"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1021"
                                          data-name="Group 1021"
                                          transform="translate(14.373 6.226)"
                                        >
                                          <g id="Group_1020" data-name="Group 1020">
                                            <rect
                                              id="Rectangle_433"
                                              data-name="Rectangle 433"
                                              width="2.115"
                                              height="1.057"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g
                                          id="Group_1023"
                                          data-name="Group 1023"
                                          transform="translate(0.238)"
                                        >
                                          <g id="Group_1022" data-name="Group 1022">
                                            <path
                                              id="Path_1073"
                                              data-name="Path 1073"
                                              d="M17.869,1.177V0H9.25V1.183H6.86V5.651A4.9,4.9,0,0,0,.238,10.235c0,1.106.73,2.92,2.233,5.544,1.076,1.88,2.168,3.49,2.179,3.506L5.135,20l.486-.715c.009-.013.718-1.059,1.556-2.447H20.219V1.185h0ZM5.135,17.891c-1.361-2.1-3.722-6.066-3.722-7.656a3.722,3.722,0,0,1,7.444,0C8.858,11.825,6.5,15.794,5.135,17.891Zm13.909-2.229H14.186V13.233H13.011v2.429H7.867c1.457-2.561,2.166-4.338,2.166-5.428a4.894,4.894,0,0,0-2-3.944V2.359h2.39V1.175h6.269V2.349l2.351.008Z"
                                              transform="translate(-0.238)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <g
                                      id="Group_1057"
                                      data-name="Group 1057"
                                      transform="translate(641 247)"
                                    >
                                      <g
                                        id="Ellipse_6"
                                        data-name="Ellipse 6"
                                        transform="translate(450 193)"
                                        fill="#f1f1f1"
                                        stroke="#e1c42b"
                                        strokeWidth="1"
                                      >
                                        <circle
                                          cx="17.5"
                                          cy="17.5"
                                          r="17.5"
                                          stroke="none"
                                        />
                                        <circle cx="17.5" cy="17.5" r="17" fill="none" />
                                      </g>
                                      <g id="pin" transform="translate(460.484 201.571)">
                                        <g id="Group_1054" data-name="Group 1054">
                                          <path
                                            id="Path_1091"
                                            data-name="Path 1091"
                                            d="M92,0a6.667,6.667,0,0,0-5.866,9.835l5.5,9.95a.417.417,0,0,0,.729,0l5.5-9.954A6.668,6.668,0,0,0,92,0Zm0,10a3.333,3.333,0,1,1,3.333-3.333A3.337,3.337,0,0,1,92,10Z"
                                            transform="translate(-85.333)"
                                            fill="#e1c42b"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              )}
                            </div>
                            <div className="reminder-text-wrap upcoming-info">
                              <div
                                className="left-section"
                                onClick={() => open_moredetail(data)}
                              >
                                <div className="right-arrr">
                                  <h6>
                                    <b className="event-title">{visitTitle} </b>
                                  </h6>

                                </div>
                                <div className="time-date-wrap">
                                  <span>


                                    {Date_convert_without_time_zone(data.StartDate, 5)}
                                  </span>
                                </div>
                                {(data.Location === "" ||
                                  data.Location === undefined ||
                                  data.Location === null) || data?.Type.trim() === 'Phone Visit' || data?.Type.trim() === 'Virtual Visit' ? (
                                  " "
                                ) : (
                                  <div className="location d-flex">
                                    <img src="/media/misc/location.png" alt="" />
                                    <p className="rout mb-0">{data.Location}</p>
                                  </div>
                                )}
                              </div>
                              <div className="right-section">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      id="user-notification-tooltip"
                                      style={{ backgroundColor: "transparent", border: "0" }}
                                    >
                                      Event Details
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    className="resize-icon"
                                    src="/media\misc\details-gray.svg"
                                    onClick={() => {
                                      open_moredetail(data)
                                    }}
                                    alt=""
                                  />
                                </OverlayTrigger>

                              </div>

                            </div>
                            {true ? (
                              <div className="text-right">
                                <button type="button" className="blue-button btn-join" style={{ marginRight: 0 }} onClick={() => {
                                  Joincall(data?.Title, data?.CTMSEventID, data?.id, 2, index, visitTitle)
                                }}>
                                  Join <img src="/media/misc/on-video.svg" className="video-icon" alt="video" />{loading_status && index_value_upcoming_event === index && <span className="ml-3 spinner spinner-white"></span>}
                                </button>

                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </React.Fragment>,
                    ]
                  })
                ) : (
                  <div className="inner-wrap">
                    <div className="no-events">
                      {" "}
                      <img className="No-event-icon" src="/media\misc\no-events.png" alt="" />
                      <span>no upcoming events</span>{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Successfully popup */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloses_medication()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open_medication}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <h2 className="success-text mb-10 font-weight-bold success-head">
            Dosage taken successfully{" "}
          </h2>
          <button
            type="submit"
            onClick={handleCloses_medication}
            className="btn btn-primary mr-5 save-btn"
          >
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* Successfully popup */}
    </>
  )
}
