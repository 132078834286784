import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Checklist = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
   
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    let checkClearButton = commonId ? form_value[children_elements.UUID]?.[commonId] && form_value[children_elements.UUID]?.[commonId]?.length > 0 : form_value[children_elements.UUID] && form_value[children_elements.UUID]?.length > 0

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    return (
        !isFieldHidden ? <>
            <div className="input_label">
                <label className="label_" htmlFor="">{(children_elements.Meta.Label?.blocks?.[0].key || children_elements.Meta.Label?.blocks?.[0].text) ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>

                {children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}
            </div>
            <div className="check_box_container">
                {children_elements.Meta.DataSources.options?.map((check_data, index) => {

                    return [<div className="" key={index}>


                        {commonId ? <div>

                            {index === 0 && children_elements.Meta.EmptyOption?.label ? <div className="check_box_wrap"> <input type="checkbox"

                                value={children_elements.Meta.EmptyOption?.label}
                                name={children_elements.UUID}
                                onChange={(e) => { props.ON_change_inputfield(e, "checkbox", null, null, children_elements?.Parent, null, null, props.rowid) }}
                                checked={form_value[children_elements.UUID]?.[commonId]?.includes(children_elements.Meta.EmptyOption?.label)}
                               
                            />
                                <label className="label_" htmlFor="vehicle1">{children_elements.Meta.EmptyOption?.label}</label><br></br>

                            </div> : ""}
                            <div className="check_box_wrap">
                            <input type="checkbox"
                                value={check_data.value ? check_data.value : check_data.id}
                                name={children_elements.UUID}
                                onChange={(e) => { props.ON_change_inputfield(e, "checkbox", null, null, children_elements?.Parent, null, null, props.rowid) }}
                                checked={form_value[children_elements.UUID]?.[commonId]?.includes(check_data.id || check_data.value)}
                                
                            />
                            <label className="label_" htmlFor="vehicle1">{check_data.label}</label><br></br>
                            </div>
                        </div> : <div>
                            {index === 0 && children_elements.Meta.EmptyOption?.label ? <div className="check_box_wrap"><input type="checkbox"

                                value={children_elements.Meta.EmptyOption?.label}
                                name={children_elements.UUID}
                                onChange={(e) => { props.ON_change_inputfield(e, "checkbox", null, null, children_elements?.Parent) }}
                                checked={form_value[children_elements.UUID]?.includes(children_elements.Meta.EmptyOption?.label)}
                                
                            />
                                <label className="label_" htmlFor="vehicle1">{children_elements.Meta.EmptyOption?.label}</label><br></br>

                            </div> : ""}
                            <div className="check_box_wrap">
                            <input type="checkbox"
                                value={check_data.value ? check_data.value : check_data.id}
                                name={children_elements.UUID}
                                onChange={(e) => { props.ON_change_inputfield(e, "checkbox", null, null, children_elements?.Parent) }}
                                checked={form_value[children_elements.UUID]?.includes(check_data.id || check_data.value) || false}
                                        
                            />
                            <label className="label_" htmlFor="vehicle1">{check_data.label}</label><br></br>
                            </div>
                        </div>}


                    </div>]

                })}
                {checkClearButton ? <div className="clear-text">
                        <span title="Clear" onClick={() => { props.clear_fields(children_elements.UUID, props.rowid) }}>Clear</span>
                    </div> : ""}
            </div>
        </> : ""








    )
}
