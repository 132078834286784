import axios from "axios"

export const LOGIN_URL = `/api/auth/login/`
export const REQUEST_PASSWORD_URL = `/api/auth/forgotpasswordSentMail`
export const RESET_PASSWORD_URL = `/api/auth/forgotPasswordReset`
export const RESET_email_URL = `/api/patient/updateEmail/`
export const region_change_email = `/api/patient/authenticateEmail/`
export const GET_userinfo = `/api/patient/`
export const GET_userinfo_ = `/api/patient/GetPatientDetailsByID/`
export const Country_URL = `/api/country/findall/`
export const state_url = `/api/state/`
export const ctmsUser_info = ``
export const city_url = `/api/city/`
export const audit_trail = `/api/patient/GetAuditTrails/`
export const admin_view_data_dropdown = `/api/dashboard/fetchAllSitesForAdminDashboard/`
export const admin_view_list_of_admin = `/api/dashboard/fetchAllAdmins/`

export const admin_view_data_dashboard = `/api/dashboard/fetchAllStudiesForAdmin`
export const REGISTER_URL = `/api/auth/register`
export const city_url_All = `/api/city/findall`
export const update_profile_url = `/api/patient/updateprofile/`
export const reject_invite = `/api/dashboard/studydeclineupdatestatus`

export const update_profile_img_url = `/api/patient/upload/`
export const update_ticket_url = `/api/ticket/AddTicket`
export const update_password = `/api/auth/changePassword/`
export const update_email = `/api/auth/EmailAuthenticateSendMail`
export const get_all_tickets = `/api/ticket/getTickets/`
export const get_quick_search = `/api/calendarevent/findSearchResults`
export const get_calandar_data = `/api/calendarevent/getCalendarEvents`
export const get_single_event = `/api/calendarevent/findEventById/`
export const WebAppLoggedInFlag_url = `/api/auth/updateIsLoggedFlag/`
export const WebAppNotificationFlag_url = `/api/mark-notification-flag/`
export const document_archive_flag = `/api/subjectsharedocument/sharedDocArchieve`
export const document_view_flag = `/api/subjectsharedocument/updateViewStatus`
export const upcoming_event_url = `/api/calendarevent/findAllEvents`
export const dashboard_invite_api = `/api/dashboard/findAllDashboard/`
export const dashboard_invite_accept_N_reject_api = `/api/dashboard/studyStatusUpdate`
export const getall_notification = `/api/getAllNotification`
export const get_Global_notification = `/api/getAllNotificationWithoutStudy`
export const get_push_noti = `/api/calendarevent/fetchInAppEventNotifications`
export const clear_push_noti = `/api/auth/logout/`
export const ctms_videocall_autheticate = `/api/calendarevent/AuthenticateCTMSUserForTwilioCall`
export const ctms_form = `/api/patient/getPatienteSourceForm`
export const create_admin = `/api/auth/createAdmin/`
export const Change_med_status = `/api/medicationtreatment/updateMedicationsStatus`
export const Notification_status = `/api/mark-read/`
export const doc_upload = `/api/subjectsharedocument/docsupload`
export const doc_edit = `/api/subjectsharedocument/docsupload`
export const doc_edit_withoutfile = `/api/subjectsharedocument/updateSharedDocumentTitle`
export const get_doc = `/api/subjectsharedocument/GetSubjectDocuments`
// export const get_doc_ctms = `/api/document/findall/`
export const create_device_info = `/api/auth/createDeviceInfo`
export const twilio_call_end_status = `/api/calendarevent/validatePatientToJoinTwilio`
export const admin_login_to_patient = `/api/auth/adminLoggedinIntoPatientaccount`
export const initiate_call_for_patient = `/api/calendarevent/initiateCallForPatient`
export const active_deactie_admin = `/api/dashboard/activeDeactiveAdmin`
export const register_for_other_region = `/api/auth/updateEmailForAllRegions`
export const check_user_avalble = `/api/auth/regionChangeCheckAccountExist`
export const clear_push_not = `/api/auth/pushNotificationOff`
export const update_form_stat = `/api/patient/updatePatienteSourceFormStatus`
export const feedback_logo = `/api/patient/siteDetails`
export const verify_password_url = `/api/auth/eSignAuthentication/`
export const esource_details = '/api/patient/getFormUsingCTMSDetails'
export const get_tocken = '/api/auth/getCTMSSessionKey/'
export const get_patient_source_form = '/api/patient/getPatienteSourceFormByID/'
export const form__link_validate = '/api/patient/validateeFormLink'
export const get_doc_ctms = "/api/v1/econsent/patient-portal"
export const migrateData = "/api/migration/migratePatienteSourceForm"


let CryptoJS = require("crypto-js");

export function login(Email, Password_, GoogleID, FacebookID) {
  let Password = CryptoJS.AES.encrypt(Password_, 'dynamisch-secret').toString();
  const data = {
    Email: Email,
    Password: Password,
    GoogleID: GoogleID,
    FacebookID: FacebookID,
    isMobileLogin: "false"
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + LOGIN_URL, data)
}
export function register(
  FirstName,
  LastName,
  Email,
  Password,
  CountryID,
  Phone,
  CountryCodeID,

) {
  const data = {
    FirstName: FirstName,
    LastName: LastName,
    Phone: Phone,
    Email: Email,
    Password: CryptoJS.AES.encrypt(Password, 'dynamisch-secret').toString(),
    CountryID: CountryID,
    CountryCodeID: CountryCodeID,
    isMobileLogin: "false"

  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + REGISTER_URL, data)
}
// reset password gettingtoken
export function requestPassword(Email, host) {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + REQUEST_PASSWORD_URL, { Email, host })
}
//  restpassword
export function resetPassword(ResetToken, Password, ID, Url) {
  const data = {
    id: ID,
    ResetToken: ResetToken,
    Password: CryptoJS.AES.encrypt(Password, 'dynamisch-secret').toString(),
    isMobileLogin: "false"
  }
  // let baseurl = sessionStorage.getItem("end_point")

  return axios.post(Url + RESET_PASSWORD_URL, data)
}
// geting user data by id
export function getUserByToken(id_, PatientCTMSSiteID_) {
  // Authorization head should be fulfilled in interceptor.
  //we are using this to overcome admin login to other users account
  let id_from_storage
  let site_id_from_storage
  sessionStorage.getItem("id") ? id_from_storage = sessionStorage.getItem("id") : id_from_storage = localStorage.getItem("id")
  sessionStorage.getItem("site_id") ? site_id_from_storage = sessionStorage.getItem("site_id") : site_id_from_storage = localStorage.getItem("site_id")

  // when admin takes controls
  let data
  if (sessionStorage.getItem("is_admin_control") === "true") {
    id_from_storage = localStorage.getItem("id")
    site_id_from_storage = localStorage.getItem("site_id")
    data = {
      id: localStorage.getItem("id"),
      PatientCTMSSiteID: localStorage.getItem("site_id")
    }
  }
  else {
    data = {
      id: id_from_storage ? id_from_storage : id_,
      PatientCTMSSiteID: site_id_from_storage ? site_id_from_storage : PatientCTMSSiteID_,
    }

  }

  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + GET_userinfo_, data)
}

export function getUserByToken2() {
  // Authorization head should be fulfilled in interceptor.
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + GET_userinfo + localStorage.getItem("id"))
}
// 23-12-2021 : Create device info api
export function createDeviceInfo(data) {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + create_device_info, data)
}

//  country api
export function get_countries() {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + Country_URL)
}
// state api
export const get_state = async (id) => {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + state_url + `${id}`)
}
// city api
export const get_city = async (id) => {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + city_url + `${id}`)
}

// update profile information
export function update_profile(values) {
  const data = {
    FirstName: values.firstname,
    LastName: values.lastname,
    Address: values.address,
    Phone: values.phone,
    Email: values.email,
    CityID: values.city,
    BirthDate: values.date,
    id: localStorage.getItem("id"),
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + update_profile_url + localStorage.getItem("id"), data)
}
// update pro img
export function update_profile_img(file) {

  const data = new FormData()
  data.append("id", sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"))
  data.append("file", file)
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + update_profile_img_url, data)
}

//update password
export function updatePasswordMethod(currentPassword, cPassword) {

  const data = {
    pid: sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
    oldPassword: CryptoJS.AES.encrypt(currentPassword, 'dynamisch-secret').toString(),
    newPassword: CryptoJS.AES.encrypt(cPassword, 'dynamisch-secret').toString(),
    isMobileLogin: "false"

  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + update_password, data)
}

// dashboard apis for study imfo

export function Study_api(dropdown_Selected_data) {
  const params = new URLSearchParams()
  params.append("UserName", dropdown_Selected_data?.sites?.CTMSUsername)
  params.append("Password", dropdown_Selected_data?.sites?.CTMSPassword)
  params.append("PubKey", "as")
  const url_ = dropdown_Selected_data?.sites?.CTMSHostLink + "/api/v1/users/signin"
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // Authorization: null,
    },
  }

  if (dropdown_Selected_data !== undefined) {
    return axios.post(url_, params, config)
  }
}
export function Study_api_replica(dropdown_Selected_data) {
  const params = new URLSearchParams()
  params.append("UserName", localStorage.getItem("CTMSUsername"))
  params.append("Password", localStorage.getItem("CTMSPassword"))
  params.append("PubKey", "as")
  let start_url = sessionStorage.getItem("ctms_host_link")
  // eslint-disable-next-line no-self-assign
  start_url ? start_url = start_url : start_url = localStorage.getItem("CTMSHostLink")

  const url_ = start_url + "/api/v1/users/signin"

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // Authorization: null,
    },
  }
  if (dropdown_Selected_data !== undefined) {
    return axios.post(url_, params, config)
  }
}
// dropdown_Selected_data.sites.CTMSHostLink

export function Study_api2(SessionKey, dropdown_Selected_data) {

  const url_2 = dropdown_Selected_data.sites.CTMSHostLink

  return axios.get(
    url_2 +
    "/api/v1/patient-portal/study-info?Key=" +
    SessionKey +
    "&StudyID=" +
    dropdown_Selected_data.CTMSStudyID +
    "&PatientID=" +
    localStorage.getItem("PatientCTMSID")
  )

}

// dashboard invite api
export function Dashboard_invite_api() {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + dashboard_invite_api + localStorage.getItem("id"))
}
// accept or reject invite
export function Dashboard_invite_accept_n_reject(study_id, status) {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: study_id,
    Status: status,
    Email: localStorage.getItem("Email_address"),
    // PatientCTMSID: localStorage.getItem("PatientCTMSID"),

  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + dashboard_invite_accept_N_reject_api, data)
}

// reactTour flag toggle dashboard
export function WebAppLoggedInFlag_toggle() {
  const data = {
    id: localStorage.getItem("id"),
    WebAppLoggedInFlag: "false",
    MobileAppLoggedInFlag: "",
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + WebAppLoggedInFlag_url, data)
}
// reactTour flag toggle notification
export function WebAppNotificationFlag_toggle() {
  const data = {
    id: localStorage.getItem("id"),
    WebAppNotificationFlag: "false",
    MobileAppNotificationFlag: "",
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + WebAppNotificationFlag_url, data)
}

// ctms register status
export function Ctms_register_api(sessionkey, datas) {
  const url = datas.CTMSHostLink
  const PatientID_ = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/patient-portal/update-registration-status?Key=" +
    sessionkey +
    "&PatientID=" +
    PatientID_ +
    "&Status=" +
    2
  )
}

// ctms invite status
export function Ctms_invite_api(sessonkey, datas, status) {
  const PatientID_ = localStorage.getItem("PatientCTMSID")
  const url = datas.sites.CTMSHostLink
  const studyid = datas.CTMSStudyID


  return axios.get(
    url +
    "/api/v1/patient-portal/update-study-invite-status?Key=" +
    sessonkey +
    "&PatientID=" +
    PatientID_ +
    "&StudyID=" +
    studyid +
    "&Status=" +
    status
  )
}
// ctms register  session id get method
export function Register_session(register_resp_data) {
  localStorage.setItem("CTMSPassword", register_resp_data?.CTMSPassword)
  localStorage.setItem("CTMSUsername", register_resp_data?.CTMSUsername)

  const params = new URLSearchParams()
  params.append("UserName", register_resp_data?.CTMSUsername)
  params.append("Password", register_resp_data?.CTMSPassword)
  params.append("PubKey", "as")

  const url_ = register_resp_data?.CTMSHostLink + "/api/v1/users/signin"

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  if (register_resp_data !== undefined) {
    return axios.post(url_, params, config)
  }
}

// get notifications for study
export function Getall_notifications() {

  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + getall_notification, data)
}

// get Global notifications
export function Get_Global_notifications() {

  const data = {
    PatientID: sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
    StudyID: null,
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + get_Global_notification, data)
}

// notifications status change

export function Change_notification_status(id) {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + Notification_status + id)
}

// upload   subject shared document

export function update_document(file, title, desc, version, id, filetitle) {

  const data = new FormData()
  data.append("file", file[0])
  data.append("PatientID", localStorage.getItem("id"))
  data.append("StudyID", localStorage.getItem("StudyID_local"))
  data.append("Title", title)
  data.append("Description", desc)
  data.append("SiteID", localStorage.getItem("SiteID_local"))
  data.append("DocID", id)
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + doc_upload, data)
}

// edit shared doc without file
export function edit_document2(id, title, desc, url_doc) {
  const data = {
    DocID: id,
    Title: title,
    Description: desc,
    SiteID: localStorage.getItem("SiteID_local"),
    StudyID: localStorage.getItem("StudyID_local"),
    CTMSStudyID: localStorage.getItem("CTMSStudyID"),
    PatientCTMSID: localStorage.getItem("PatientCTMSID"),
    fullUrl: url_doc,
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + doc_edit_withoutfile, data)
}

// get CTMS document
export const get_document_ctms = async (key) => {
  // const PatientID = localStorage.getItem("id")
  // const study_id_local = localStorage.getItem("StudyID_local")
  // let baseurl = sessionStorage.getItem("end_point")

  const url = localStorage.getItem("CTMSHostLink")

  // return axios.get(baseurl + get_doc_ctms + PatientID + "/" + study_id_local)
  if (key == undefined) return
  return axios.post(url + get_doc_ctms + "?Key=" + key)
}

// get subject shared document

export const get_document = async () => {
  const PatientID = localStorage.getItem("id")
  const StudyID = localStorage.getItem("StudyID_local")
  const SiteID = localStorage.getItem("SiteID_local")
  let baseurl = sessionStorage.getItem("end_point")


  return axios.get(baseurl + get_doc + "/" + PatientID + "/" + StudyID + "/" + SiteID)
}
// download  view ctms document

export const view_download_document_ctms = async (key, doc_id) => {
  const url = localStorage.getItem("CTMSHostLink")

  return axios.get(
    url + "/api/v1/documents/download?Key=" + key + "&DocumentID=" + doc_id
  )
}

// archive document   flag  for pp

export function Document_archive_flag(id, flag) {
  const data = {
    id: id,
    DeletedFlag: flag,
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + document_archive_flag, data)
}

// ctms  archive document

export const Ctms_archive_doc = async (key, DocID, type) => {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(
    url +
    "/api/v1/patient-portal/delete-subject-shared-documents?Key=" +
    key +
    "&DocID=" +
    DocID +
    "&deleted=" +
    type
  )
}

// documet view status

export function Document_view_flag(id, type) {
  const data = {
    DocID: id,
    FormName: type,
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + document_view_flag, data)
}

// upcoming event dashboard page

export function Upcoming_events(id, study_id) {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
    SiteID: localStorage.getItem("SiteID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" }
  }

  return axios.post(baseurl + upcoming_event_url, data, config)
}

//update email address
export function update_email_method(email, host) {
  const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
  const dropdown_data_admin = JSON.parse(sessionStorage.getItem("dropdown_value"))

  const PatientName_ = (localStorage.getItem("PatientName"))
  let data

  if (dropdown_data || dropdown_data_admin) {
    if (dropdown_data) {
      data = {
        id: localStorage.getItem("id"),
        Email: email,
        CTMSHostLink: dropdown_data.sites.CTMSHostLink,
        CTMSUsername: dropdown_data.sites.CTMSUsername,
        CTMSPassword: dropdown_data.sites.CTMSPassword,
        PatientName: PatientName_,
        host: host,
        SiteID: localStorage.getItem("SiteID_local")

      }
    }
    // if admin is present  then this block will execute ,as payload parameters are not present for admin

    if (dropdown_data_admin) {
      data = {
        id: sessionStorage.getItem("id"),
        Email: email,
        CTMSHostLink: dropdown_data_admin.CTMSHostLink,
        CTMSUsername: dropdown_data_admin.CTMSUsername,
        CTMSPassword: dropdown_data_admin.CTMSPassword,
        PatientName: sessionStorage.getItem("PatientName"),
        host: host,
        SiteID: dropdown_data_admin?.id,

      }
    }
  } else {
    data = {
      id: sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
      Email: email,
      CTMSHostLink: "null",
      CTMSUsername: "null",
      CTMSPassword: "null",
      PatientName: sessionStorage.getItem("PatientName") ? sessionStorage.getItem("PatientName") : PatientName_,
      SiteID: dropdown_data_admin?.id ? dropdown_data_admin?.id : localStorage.getItem("SiteID_local")
    }
  }

  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + update_email, data)
}

// upload ticket
export function upload_ticket(file, SiteId, Title, Message, Type) {


  const data = new FormData()
  data.append("PatientID", localStorage.getItem("id"))
  data.append("file", file)
  data.append("SiteID", SiteId)
  data.append("Title", Title)
  data.append("Type", Type)
  data.append("Message", Message)
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + update_ticket_url, data)
}

// get all tickets

export function Get_all_tickets() {
  const data = {
    PatientID: localStorage.getItem("id"),
    SiteID: localStorage.getItem("SiteID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + get_all_tickets, data)
}

//  restemail
export function resetemail(id, mail, key, Url) {
  const mail_witout_space = mail.replace(/\s+/g, "+")

  // let baseurl = sessionStorage.getItem("end_point")

  return axios.get(Url + RESET_email_URL + id + "/" + mail_witout_space + "/" + key)
}

// get  Get_stipend

export const Get_stipend = async (key) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  const StudyID = localStorage.getItem("CTMSStudyID")
  return axios.get(
    url +
    "/api/v1/patient-portal/patient-stipend?Key=" +
    key +
    "&PatientID=" +
    PatientID +
    "&StudyID=" +
    StudyID
  )
}
// get  Get_stipend for quick search

export const Get_stipend_quicksearch = async (key, searchvalue) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  const StudyID = localStorage.getItem("CTMSStudyID")
  return axios.get(
    url +
    "/api/v1/patient-portal/patient-stipend?Key=" +
    key +
    "&PatientID=" +
    PatientID +
    "&StudyID=" +
    StudyID +
    "&searchText=" +
    searchvalue
  )
}

// get  stipend doc

export const Get_stipend_doc = async (id) => {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(url + "/report_results.php?report=16&invoiceid=" + id)
}

// medication status

export function Change_medication_status(id) {
  const data = {
    id: id,
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + Change_med_status, data)
}

// get calandar events

export function Get_calandar_data() {
  let baseurl = sessionStorage.getItem("end_point")

  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
  }
  return axios.post(baseurl + get_calandar_data, data)
}

// get  Get_stipend total 

export const Get_stipend_amount = async (key) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url + "/api/v1/patient-portal/rpay-available-balance?PatientID=" + PatientID + "&Key=" + key
  )
}
// stipen total study specific
export const Get_stipend_amount_study_specific = async (key, study_id) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")

  return axios.get(
    url + "/api/v1/patient-portal/rpay-available-balance?PatientID=" + PatientID + "&Key=" + key
  )
}

// get   total visit

export const Get_Total_visit_ = async (key, data) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  const studyid = data.CTMSStudyID

  return axios.get(
    url +
    "/api/v1/patient-portal/total-ending-status-visits?Key=" +
    key +
    "&PatientID=" +
    PatientID +
    "&StudyID=" +
    studyid
  )
}

// get single calender event
export const Get_single_event = async (id, key, ctmsHostLink, type) => {
  let baseurl = sessionStorage.getItem("end_point")
  const value = {
    id: id,
    CTMSHostLink: ctmsHostLink ? ctmsHostLink : sessionStorage.getItem("ctms_host_link") ? sessionStorage.getItem("ctms_host_link") : localStorage.getItem("CTMSHostLink"),
    SessionKey: key ? key : sessionStorage.getItem("sessionKeyValue"),
  }
  const data = axios.post(baseurl + get_single_event, value)
  if (type !== 1) {
    data.then(data => { sessionStorage.setItem("callStartDate", new Date(data.data[0].TwilioStartTime)) })

  }
  return data

}

// get quick search result

export function Quick_search(text) {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
    SiteID: localStorage.getItem("SiteID_local"),
    searchedText: text,
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + get_quick_search, data)
}

//to generate twilio token
export const Twilio_token = async (roomname, username, key, room_sid) => {

  let start_url = sessionStorage.getItem("ctms_host_link")
  // eslint-disable-next-line no-self-assign
  start_url ? start_url = start_url : start_url = localStorage.getItem("CTMSHostLink")
  sessionStorage.removeItem("ctms_host_link")
  // let webroom = "webroom"
  return axios.get(
    start_url + "/api/v1/patient-portal/get-access-token?Key=" + key + "&RoomName=" +
    roomname +
    "&identity=" +
    username + "&RoomSID=" + room_sid
  )


}
// to create twilio room
export const Twilio_room = async (roomname, key, CTMSEventID) => {
  let start_url
  sessionStorage.getItem("ctms_host_link") ? start_url = sessionStorage.getItem("ctms_host_link") : start_url = localStorage.getItem("CTMSHostLink")
   return axios.get(
    start_url + "/api/v1/patient-portal/create-twillio-video-room?Key=" + key + "&RoomName=" +
    roomname + "&CTMSEventID=" + CTMSEventID
  )
}


// to submit visit feedback
export function Feedback_visit(Feedback, FeedbackText, FeedbackID, url) {
  const url_ = url + "/api/v1/patient-portal/save-patient-visit-feedback"
  const params = new URLSearchParams()
  params.append("Feedback", Feedback)
  params.append("FeedbackText", FeedbackText)
  params.append("FeedbackID", FeedbackID)

  return axios.post(url_, params)
}


// get push notifications
export function Get_Push_Notification() {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local")
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + get_push_noti, data)
}


// logout api to clear token of push notification
export function Clear_pushnotification(timeout, adminid) {
  const data = {
    PatientID: localStorage.getItem("id"),
    Token: localStorage.getItem("PushToken"),
    Timeout: timeout ? timeout : "false",
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + clear_push_noti, data)
}

// ctms video call authenticate api

export function Ctms_videocall_auth(PatientID, EventID, AuthToken, baseurl_) {
  const data = {
    "PatientID": PatientID,
    "EventID": EventID,
    "AuthToken": AuthToken
  }
  let baseurl = sessionStorage.getItem("end_point")
  if (baseurl_) {
    baseurl = baseurl_
  }

  return axios.post(baseurl + ctms_videocall_autheticate, data)
}


// get carousel img data

export function Get_Carousel_img(url_for_api) {
  // const config = {
  //   headers: { "Access-Control-Allow-Origin": "*" }
  // }
  const url = url_for_api
  return axios.get(url)
}


// ctms form list

export function Get_ctms_formlist() {
  const data = {
    "PatientID": localStorage.getItem("id"),
    "StudyID": localStorage.getItem("StudyID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + ctms_form, data)
}


// ctms on click of form get form details
export function Ctms_single_form(sessionkey, FormID, datas) {
  const url = datas.CTMSHostLink
  const PatientID_ = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms?Key=" +
    sessionkey +
    "&PatientID=" +
    PatientID_ +
    "&FormID=" +
    FormID
  )
}
// ctms form done api
export function Done_changes_api(sessionkey, datas) {
  // const url = datas.sites.CTMSHostLink
  const url = datas.CTMSHostLink
  const config = {

    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  const params = new URLSearchParams()
  params.append("FormID", localStorage.getItem("FormID"))
  params.append("PatientID", localStorage.getItem("PatientCTMSID"))
  params.append("Type", 1)
  params.append("Key", sessionkey)
  return axios.post(url + "/api/v1/forms/patients/complete", params, config)
}

// ctms form save form
export function Save_changes_form_api(sessionkey, formvalue) {
  const url = localStorage.getItem("CTMSHostLink")

  // const formData = new FormData();
  // formData.append('post', new Blob([JSON.stringify(formvalue)], {
  //   type: "multipart/form-data"
  // }));


  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  let form_value = formvalue
  form_value.manualProviders = {}
  const data = {
    "force": false,
    "ArmID": localStorage.getItem("armId"),
    "extraSaveProps": {},
    // "TrackingID": "69391",
    "PatientID": localStorage.getItem("PatientCTMSID"),
    "CurrentFormPageID": sessionStorage.getItem("current_page_id"),
    "FormID": localStorage.getItem("FormID"),
    formData: form_value,
    "formSessionWindowUUID": sessionStorage.getItem("window_id"),
  }
  const data2 = new FormData()
  data2.append("PatientID", localStorage.getItem("PatientCTMSID"))
  data2.append("FormID", localStorage.getItem("FormID"))
  data2.append("formData", form_value)

  return axios.post(url + "/api/v1/forms/submissions?Key=" + sessionkey, data, config)
}



// ctms upload file type 
export function UploadFiletype(sessionkey, base64, file) {


  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }

  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("Name", file.name)
  params.append("DataString", base64)
  params.append("Type", "generic")


  return axios.post(url + "/api/v1/files/upload", params, config)
}

// ctms delete file type 
export function DeleteFiletype(sessionkey, hash) {
  const url = localStorage.getItem("CTMSHostLink") + "/api/v1/patient-portal/delete-document"
  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("Hash", hash)

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  }

  return axios.post(url, params, config)
}

// get saved values of ctms form
export function Get_saved_forms(sessionkey) {
  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  const FormID = localStorage.getItem("FormID")
  const PatientID = localStorage.getItem("PatientCTMSID")
  return axios.get(url + "/api/v1/forms/data?Key=" + sessionkey + "&FormID=" + FormID + "&PatientID=" + PatientID + "&CreateDefault=" + 1, config)
}

// get saved images of ctms form
export function Get_saved_images(sessionkey, Hash) {
  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    responseType: "arraybuffer"
  }

  return axios.get(url + "/api/v1/files?Key=" + sessionkey + "&Hash=" + Hash, config)

}

// get saved images of ctms form
export function Get_dropdown_value_ctmsform(sessionkey, url_end_point) {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(url + url_end_point + "?Key=" + sessionkey)

}

// get ctms form country list
export const ctms_country_list = async () => {
  const url = localStorage.getItem("CTMSHostLink")
  const endurl = "/api/v1/countries"
  return axios.get(url + endurl)
}

// get ctms user info like hostlink usernme
export const Get_ctms_userInfo = async (siteid) => {
  const url = sessionStorage.getItem("end_point")
  const endurl = "/api/dashboard/getSiteDetails/" + siteid
  return axios.get(url + endurl)
}


// api when invites get reject
export function Reject_invite_status(PatientCTMSID, PatientCTMSSiteID) {
  const data = {
    "PatientID": localStorage.getItem("id"),
    "PatientCTMSID": PatientCTMSID,
    "PatientCTMSSiteID": PatientCTMSSiteID
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.put(baseurl + reject_invite, data)
}

// get audit trail

export const Audit_trail = async () => {
  const id = localStorage.getItem("id")
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + audit_trail + `${id}`)
}
// get admin view dropdown

export const Admin_view_dropdown = async () => {
  const id = sessionStorage.getItem("id")
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + admin_view_data_dropdown + `${id}`)
}


// get admin list
export const GetAdmin_list = async () => {
  const id = sessionStorage.getItem("id")
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + admin_view_list_of_admin + `${id}`)
}
// toget admin view dashboard data
export const Admin_view_dashboard = async (SiteID) => {

  const data = {
    id: sessionStorage.getItem("id"),
    SiteID: SiteID
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + admin_view_data_dashboard, data)
}


// twilio biling api
export function Twilio_billing(key, sid, date_type) {

  let start_url = sessionStorage.getItem("ctms_host_link")
  // eslint-disable-next-line no-self-assign
  start_url ? start_url = start_url : start_url = localStorage.getItem("CTMSHostLink")
  const url = start_url
  let user_id = 0
  if (sessionStorage.getItem("is_ctms_user")) {
    user_id = 1
  }

  const params = new URLSearchParams()
  params.append("UserID", localStorage.getItem("PatientCTMSID"))
  params.append("EventID", localStorage.getItem("CTMSEventID"))
  params.append("RoomSID", sid)
  params.append("StudyID", localStorage.getItem("CTMSStudyID"))
  params.append("ToBeLogged", date_type)
  params.append("UserType", user_id)
  return axios.post(url + "/api/v1/patient-portal/track-virtual-visit-usage?Key=" + key, params)

}
// to change ststus of twilio call end
export const twilio_call_end_status_change = () => {

  const data = {
    EventID: localStorage.getItem("join_status_id"),
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + twilio_call_end_status, data)
}

// Funtion to Login the admin into patient account
export const admin_login_to_patient_account = (patientEmail) => {
  const data = {
    id: sessionStorage.getItem("id"),
    Email: patientEmail
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + admin_login_to_patient, data)
}

// Funtion to initiate call for patient from admin
export const initiate_call = (PatientID, eventid, baseurl_, room_sid) => {
  const data = {
    // PatientID: PatientID,
    EventID: eventid,
    RoomSID: room_sid
  }
  let baseurl = sessionStorage.getItem("end_point")
  if (baseurl_) {
    baseurl = baseurl_
  }

  return axios.post(baseurl + initiate_call_for_patient, data)
}

// to make user admin 

export function Make_admin(Email, FirstName, LastName) {
  const data = {
    "Email": Email,
    "SiteID": sessionStorage.getItem("PatientCTMSSiteID"),
    "FirstName": FirstName,
    "LastName": LastName

  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + create_admin, data)
}


// to activate or deactivate admin by superadmin

export function Active_deactie_admin(superid, id, flag) {
  const data = {
    "id": superid,
    "AdminID": id,
    "acitveFlag": flag

  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + active_deactie_admin, data)
}

// get esign dropdown
export function Get_esign_dropdown(sessionkey) {
  const url = localStorage.getItem("CTMSHostLink")

  return axios.get(url + "/api/v1/forms/signature-statements?Key=" + sessionkey)
}

// get submit esign 
export function esign_submit_api(Statement, current_obj, key, form_value, rid, username, password) {
  const url = localStorage.getItem("CTMSHostLink")
  const form_value_ = form_value
  let InputListRowID = ""

  let commonId = rid ? rid : form_value_[current_obj?.Parent]?.[0]

  if (commonId) {
    InputListRowID = commonId

  }
  const params = new URLSearchParams()
  params.append("UserName", username)
  params.append("Statement", Statement)
  params.append("ElementID", current_obj.UUID)
  params.append("PatientID", localStorage.getItem("PatientCTMSID"))
  params.append("InputListRowID", InputListRowID)
  params.append("PIN", "")
  params.append("shouldSave", true)
  params.append("Password", password)
  return axios.post(url + "/api/v1/forms/sign?Key=" + key, params)
}



//region change on register page if user is alredy present on differnt server

export function Register_region_change(email, host) {
  const data = {
    "Email": email,
    "host": host
  }
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" }
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + register_for_other_region, data, config)
}

//  region change email update
export function resetemail_region_change(id, mail, key, Url) {
  // let baseurl = sessionStorage.getItem("end_point")
  return axios.get(Url + region_change_email + id + "/" + mail + "/" + key)
}
// to check if user is present on other region or not
export function Check_user_availbality(email, host) {

  const data = {
    "Email": email,
  }
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" }
  }
  return axios.post(host + check_user_avalble, data, config)
}


// to clear push notification when region changes
export function Clear_push_notification_on_region_cahnge(host) {

  const data = {
    "PatientID": localStorage.getItem("id"),
    "Token": localStorage.getItem("PushToken"),
  }

  return axios.post(host + clear_push_not, data)
}

// GP: This function calls the api to check the form status
export function get_form_status(sessionkey, data, formID) {
  const url = data.CTMSHostLink

  let PatientID = localStorage.getItem("PatientCTMSID")

  return axios.get(
    url +
    "/api/v1/forms/pages/status?Key=" +
    sessionkey +
    "&PatientID=" +
    PatientID +
    "&FormID=" +
    formID
  )
}

// GP: This function calls the api to update the form status
export function update_form_status(patientId, id) {
  let baseurl = sessionStorage.getItem("end_point")

  const data = {
    "PatientID": patientId,
    "id": id
  }

  return axios.post(baseurl + update_form_stat, data)
}

// to get log for feedback screen
export function Get_Feedback_logo(host, SiteID) {

  const data = {
    "host": host,
    "SiteID": SiteID,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + feedback_logo, data)
}

// to verify password // for esign
export function verify_password(password) {
  let Password_ = CryptoJS.AES.encrypt(password, 'dynamisch-secret').toString();
  let queryParams_ = new URLSearchParams(window.location.search)

  const data = {
    "id": queryParams_.get("is_mob") === "true" ? queryParams_.get("id") : localStorage.getItem("id"),
    "Password": Password_,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + verify_password_url, data)
}

// to get esign parameter when click on email invites
export function Get_esource_paraeter(CTMSStudyID, CTMSSiteID, CTMSHostLink, CTMSFormID) {

  const link = "https://" + CTMSHostLink
  const data = {
    "CTMSStudyID": CTMSStudyID,
    "CTMSSiteID": CTMSSiteID,
    "CTMSHostLink": link,
    "CTMSFormID": CTMSFormID
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + esource_details, data)
}
// To get ctms sessionkey  // new way

export function get_ctms_token(site_ids) {
  let baseurl = sessionStorage.getItem("end_point")
  const site_id = site_ids ? site_ids : sessionStorage.getItem("SiteID_local") ? sessionStorage.getItem("SiteID_local") : localStorage.getItem("SiteID_local")
  const portal_id = sessionStorage.getItem("id_admin") ? sessionStorage.getItem("id_admin") : localStorage.getItem("id")
  return axios.get(baseurl + get_tocken + site_id + "/" + portal_id)
}


//to check status of ctms esource form // lock or unlock
export function Esource_form_status(sessionkey, FormID_) {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID_ = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms/sessionlocks?Key=" +
    sessionkey +
    "&FormID=" + FormID_ +
    "&PatientID=" +
    PatientID_ +
    "&Active=" + 1
  )
}

// to unlock esource form
export function Unlock_esource_form(sessionkey, PageID) {

  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("PageID", sessionStorage.getItem("current_page_id"))
  params.append("PatientID", localStorage.getItem("PatientCTMSID"))
  params.append("force", "1")
  params.append("MyStudyManager", 1)

  let start_url = localStorage.getItem("CTMSHostLink")

  const url_ = start_url + "/api/v1/forms/sessionlock/unlock"

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  return axios.post(url_, params, config)

}

// GP: Get patient source form by ID
export function get_patient_source_form_by_id(formid) {
  let baseurl = sessionStorage.getItem("end_point")

  return axios.get(baseurl + get_patient_source_form + formid)
}

//to check status of ctms esource page // lock or unlock
export function Esource_page_status(sessionkey, pageid, windowid) {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID_ = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms/sessionlock?Key=" +
    sessionkey +
    "&PageID=" + pageid +
    "&PatientID=" + PatientID_ +
    "&WindowID=" + windowid +
    "&OnDemand=" + 1 +
    "&MyStudyManager=" + 1
  )
}

// get Session data
export function get_session_data_by_id(sessionkey, id) {
  const url = localStorage.getItem("CTMSHostLink")

  return axios.get(
    url +
    "/api/v1/forms/sessionlock?Key=" + sessionkey +
    "&ID=" + id
  )
}


// unlock session by window id
export function unlock_by_window_id(sessionkey, id, window_id) {
  const params = new URLSearchParams()

  params.append("Key", sessionkey)
  params.append("ID", id)
  params.append("WindowID", window_id)

  let start_url = localStorage.getItem("CTMSHostLink")

  const url_ = start_url + "/api/v1/forms/sessionlock/unlock"

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  return axios.post(url_, params, config)
}


// to validate form link // coming from mobile
export function Validate_form_link(FormID, PatientID, Token) {

  const data = {
    "FormID": FormID,
    "PatientID": PatientID,
    "Token": Token,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + form__link_validate, data)
}

// to migrateData data on study accept
export function migrateDataTo(data, StudyID) {

  const info = {
    "eSource": data.eSource,
    "PatientID": localStorage.getItem("id"),
    "StudyID": StudyID,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + migrateData, info)
}



// geting user data by id
export function getUserByTokenForInvites(id_, PatientCTMSSiteID_) {

  const data = {
    id: id_,
    PatientCTMSSiteID: PatientCTMSSiteID_,
  }

  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + GET_userinfo_, data)

}

// to get twilio user count
export const getTwilioUserCount = async (key, roomName) => {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(
    url + "/api/v1/patient-portal/get-active-participants?Key=" + key + "&RoomName=" + roomName
  )
}