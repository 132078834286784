/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg"
import objectPath from "object-path"
import { useHtmlClassService } from "../../../_core/AppLayout"
import { toAbsoluteUrl } from "../../../../_helpers"
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns"
import {
  Change_notification_status,
  Get_Global_notifications,
  get_patient_source_form_by_id,
  Get_single_event,
} from "../../../../../app/modules/Auth/_redux/authCrud"
import { Date_convert } from "../../../../../app/modules/Auth/_redux/Common_code"
import { Link } from "react-router-dom"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux"
import { useHistory } from "react-router-dom"
import Badge from "@material-ui/core/Badge"



export function UserNotificationsDropdown() {
  // const [key, setKey] = useState("Alerts")
  // const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg")
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
    }
  }, [uiService])
  const [Notification_data, setNotification_data] = useState([])
  const notifications_ = useSelector((state) => state.auth.notifications, shallowEqual)
  const notifications_Glbal = useSelector(
    (state) => state.auth.Global_notification,
    shallowEqual
  )

  const dispatch = useDispatch()
  let history = useHistory()
  //testing

  useEffect(() => {
    Get_Global_notifications()
      .then((data) => {
      })
      .catch((err) => {
      })
  }, [])

  // to get notification from redux
  useEffect(() => {


    let sorted
    sorted = notifications_Glbal?.sort((a, b) => {
      return a.createdAt - b.createdAt
    })

    if (sorted) {
      setNotification_data(sorted)
    }

  }, [notifications_Glbal])

  // on click on notification
  const Notification_clicked = (data) => {

    // this part is to avoid status change on admin click
    if (sessionStorage.getItem("is_admin_control") === "true") {

    } else {
      Update_status(data.id)

    }
    dispatch(auth.actions.setCTMS_studyID_action(data.StudyID))

    setTimeout(() => {
      if (data.Web_Route === "dashboard") {
        history.push("/dashboard")
      }
      if (data.Web_Route === "My_documents") {
        history.push("/My_documents")
      }
      if (data.Web_Route === "More_details" || data.Web_Route === "Study_calendar") {
        Get_single_event_(data.DocID)
      }
      if (data.Web_Route[1] === "t") {
        window.open(data.Web_Route)
      }
      if (data.Web_Route === "MyForm") {
        get_patient_soruce_form(data.DocID)
      }
    }, 500)
  }

  // to get single event
  const Get_single_event_ = (id) => {
    Get_single_event(id)
      .then((data) => {
        if (data.data.length > 0) {
          rediret_to(data.data)
        }
      })
      .catch((err) => {
      })
  }

  // redirect method

  const rediret_to = (data) => {
    history.push({
      pathname: "/More_details",
      state: data[0],
    })
  }

  // to change status of notifications

  const Update_status = async (id) => {
    await Change_notification_status(id)
      .then((value) => {
        // Get_notifications()
        dispatch(auth.actions.requestGlobal_notification())
      })
      .catch((error) => {
      })
  }

  const converttimezone = (data) => {
    const date_asia = new Date(data).toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    })

    let custom_date = new Date(date_asia)
    const date_asia2 = date_asia.split(",")
    let day_ = ("0" + custom_date.getDate()).slice(-2)
    let month_ = ("0" + (custom_date.getMonth() + 1)).slice(-2)
    let year_ = custom_date.getFullYear()

    // return date_asia2[1] + " | " + date_asia2[0]
    return date_asia2[1] + " | " + day_ + "-" + month_ + "-" + year_
  }
  // clearing console
  useEffect(() => {
    // console.clear()
  })

  // GP: Get patient source form details by id
  const get_patient_soruce_form = (docid) => {
    const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
    let CTMSHostLink_ = dropdown_data.sites.CTMSHostLink.slice(8)
    let SiteID_local_ = localStorage.getItem("SiteID_local")
    let PatientCTMSID_ = localStorage.getItem("PatientCTMSID")
    let portal_id = localStorage.getItem("id")

    get_patient_source_form_by_id(docid).then((data) => {
      if (data.data?.eSourceForms.length > 0) {
        let formDetail = data.data?.eSourceForms[0]
        history.push("/Form_details?CTMSHostLink_=" + CTMSHostLink_ + "&FormID_=" + formDetail?.FormID + "&is_mob=" + false + "&PatientCTMSID_=" + PatientCTMSID_ + "&SiteID_local_=" + SiteID_local_ + "&FormStatus_=" + formDetail?.Status + "&ID_=" + formDetail?.id + "&CreatedAt_=" + formDetail?.AssignDate + "&id=" + portal_id)
      }
    }).catch((error) => { })
  }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item ">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <Badge
                badgeContent={Notification_data?.length}
                color="primary"
                className="msg-icon"
                overlap="rectangular"
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/bell.svg")} />
              </Badge>
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight data-tut="My_top_notification" className="">
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle "

          >
            <>

              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary tooltip-show"
                id="kt_quick_notifications_toggle"
              >
                <span
                  className={`Custom-tooltip ${Notification_data?.length > 0 ? "" : "hidefooter"}`}
                //  className='Custom-tooltip'

                > New Notifications
                </span>
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <Badge
                    badgeContent={Notification_data?.length}
                    color="primary"
                    className="msg-icon"
                    overlap="rectangular"
                  >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/bell.svg")} />
                  </Badge>
                </span>
                <span className={`${Notification_data?.length > 0 ? "pulse-ring " : "display-none"}`}></span>
                <span className={`${Notification_data?.length > 0 ? "pulse-ring " : "display-none"}`} />


              </div>
              {/* </OverlayTrigger> */}
            </>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg notification-form-wrap background-white">
            <form className="notification-form">
              <div className="notification-dropdown">
                {Notification_data?.map((data, index) => {
                  return [
                    <React.Fragment key={index}>
                      <div
                        className="notify-inner"
                        key={index}
                        onClick={() => {
                          Notification_clicked(data)
                        }}
                      >
                        <img
                          src={toAbsoluteUrl("/media/svg/icons/Code/bell-dummy.svg")} alt="unable to load"
                        />
                        <div className="notify-content">
                          {/* <p>{data.Description}</p> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.Description,
                            }}
                          />
                          <span>
                            <b>{data.eventName}</b>
                          </span>
                          <br />
                          <p style={{ textAlign: "right" }}>
                            {" "}
                            {Date_convert(data.updatedAt, 5)}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>,
                  ]
                })}
                {Notification_data?.length > 0 ? (
                  <div className="notify-see">
                    <Link to="/Notifiction">See All</Link>
                  </div>
                ) : (
                  <div className="notify-inner no-notications">
                    <img src={toAbsoluteUrl("/media/svg/icons/Code/bell-dummy.svg")} alt="unable to load" />
                    <div className="notify-content">
                      <p>No New Notifications</p>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}
