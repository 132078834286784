import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"
export const PhoneType = (props) => {
    const nested_data = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[nested_data?.Parent]?.[0]
   
    let countries = props.country_list
    let def_country = ""
    nested_data.Meta?.DefaultCountry === "US" ? def_country = "US" : def_country = nested_data.Meta?.DefaultCountry
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    const numCheck = (e) => {
        const characters = [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            '~', '`', '!', '@', '#', '$', '%', '^', '&', '*', ')', '(', '-', '=', '_', '+',
            '>', '<', '.', '?', '/', ':', ';', '|', '\\', ']', '}', '[', '{', "'", '"', ' '
        ];
        if (characters.includes(e.key)) e.preventDefault();
    }

      // Check element visibility when form_value changes
      useEffect(() => {
        let is_hidden = check_field_visibility(nested_data, form_value, commonId);
          setIsFieldHidden(is_hidden);
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>


            <div className="input_label">
                <label className="label_" htmlFor="">{nested_data.Meta.Label?.blocks?.[0].text ? nested_data.Meta.Label?.blocks?.[0].text : nested_data.Meta.Label}</label>{nested_data.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}
               
            </div>
            <div className="input_wrap">
                <div className="width flexwidth">
                    <input type="number"
                        className="width"
                        placeholder={nested_data.Meta.Placeholder}
                        required={nested_data.Meta.Required}
                        value={form_value[nested_data?.UUID]?.[commonId]?.phone ? form_value[nested_data?.UUID]?.[commonId]?.phone ? form_value[nested_data?.UUID]?.[commonId]?.phone : "" : form_value[nested_data.UUID]?.phone ? form_value[nested_data.UUID]?.phone : ""}
                        name={nested_data.UUID}                      
                        onKeyDown={(evt) => numCheck(evt)}
                        onChange={(e) => { props.ON_change_inputfield(e, "inputfield_phone", null, null, nested_data?.Parent, nested_data.Meta.DefaultCountry, null, props.rowid) }}
                        
                    />
                </div>

                {
                    nested_data.Meta.International === true ? <div>
                        <select
                            name={nested_data.UUID}
                            value={commonId ? form_value[nested_data?.UUID]?.[commonId]?.country ? form_value[nested_data?.UUID]?.[commonId]?.country : def_country : form_value[nested_data.UUID]?.country ? form_value[nested_data.UUID]?.country : def_country}
                            onChange={(e) => { props.ON_change_inputfield(e, "inputfield_country", null, null, nested_data?.Parent, nested_data.Meta.DefaultCountry, null, props.rowid) }}
                           
                        >
                            {
                                countries.map((country, index) => {
                                    return [
                                        <option key={index} value={country.Code}>{country.Name}</option>
                                    ]
                                })
                            }
                        </select>
                    </div> : null
                }
            </div>
            <p>{nested_data.Meta?.Note}</p>
        </> : ""
    )
}


