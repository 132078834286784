import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../../../app/modules/Auth/_redux/authRedux"
import {
  Change_notification_status,
  Get_single_event,
  get_patient_source_form_by_id
} from "../../../../app/modules/Auth/_redux/authCrud"
import { Date_convert } from "../../../../app/modules/Auth/_redux/Common_code"

import { Link } from "react-router-dom"
import { toAbsoluteUrl, } from "../../../../_common/_helpers"
import { useHistory } from "react-router-dom"

export function Dash_Recent_noti_wid() {
  const [Notification_data, setNotification_data] = useState([])
  const notifications_ = useSelector((state) => state.auth.notifications, shallowEqual)
  const dispatch = useDispatch()
  let history = useHistory()

  // sorting notification
  useEffect(() => {
    let sorted
    sorted = notifications_?.sort((a, b) => {
      return a.Status - b.Status
    })
    if (sorted) {
      setNotification_data(sorted)
    }
  }, [notifications_])

  // on click on notification
  const Notification_clicked = (data) => {
    // this part is to avoid status change on admin click
    if (sessionStorage.getItem("is_admin_control") === "true") {

    } else {
      Update_status(data.Web_Route, data.id)

    }

    setTimeout(() => {
      if (data.Web_Route === "dashboard") {
        history.push("/dashboard")
      }
      if (data.Web_Route === "My_documents") {
        history.push("/My_documents")
      }
      if (data.Web_Route === "More_details" || data.Web_Route === "Study_calendar") {
        // history.push("/Study_calendar")
        Get_single_event_(data.DocID)
      }
      if (data.Web_Route[1] === "t") {
        window.open(data.Web_Route)
      }
      if (data.Web_Route === "MyForm") {
        get_patient_soruce_form(data.DocID)
      }
    }, 900)

  }

  // to get single event
  const Get_single_event_ = (id) => {
    Get_single_event(id)
      .then((data) => {
        if (data.data.length > 0) {
          rediret_to(data.data)

        }
      })
      .catch((err) => {
      })
  }

  // redirect method

  const rediret_to = (data) => {
    history.push({
      pathname: "/More_details",
      state: data[0],
    })
  }

  // to change status of notifications

  const Update_status = async (Web_Route, id) => {
    await Change_notification_status(id)
      .then((value) => {
        // Get_notifications();
        if (Web_Route !== "My_documents") {
          dispatch(auth.actions.requestNotification())
        }
        dispatch(auth.actions.requestGlobal_notification())

      })
      .catch((error) => {
      })
  }



  useEffect(() => {
    // console.clear()
  })

  // GP: Get patient source form details by id
  const get_patient_soruce_form = (docid) => {
    const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
    let CTMSHostLink_ = dropdown_data.sites.CTMSHostLink.slice(8)
    let SiteID_local_ = localStorage.getItem("SiteID_local")
    let PatientCTMSID_ = localStorage.getItem("PatientCTMSID")
    let portal_id = localStorage.getItem("id")

    get_patient_source_form_by_id(docid).then((data) => {
      if (data.data?.eSourceForms.length > 0) {
        let formDetail = data.data?.eSourceForms[0]
        history.push("/Form_details?CTMSHostLink_=" + CTMSHostLink_ + "&FormID_=" + formDetail?.FormID + "&is_mob=" + false + "&PatientCTMSID_=" + PatientCTMSID_ + "&SiteID_local_=" + SiteID_local_ + "&FormStatus_=" + formDetail?.Status + "&ID_=" + formDetail?.id + "&CreatedAt_=" + formDetail?.AssignDate + "&id=" + portal_id)
      }
    }).catch((error) => { })
  }

  return (
    <>
      <div className="dash-noti">
        <div className="dash-noti-heading">
          <h5>Recent Notifications</h5>
          <div className="dash-noti-btns">
            <button type="button" className="blue-button">
              <Link to="/Notifiction" className="text-white">
                {" "}
                View All
              </Link>
            </button>
          </div>
        </div>

        {Notification_data?.length === 0 ? (
          <div className="dash-top">
            {" "}
            <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="No Notification found" />
            <p>No Notification found</p>{" "}
          </div>
        ) : (
          <div className="dash-noti-content">
            {Notification_data?.map((data, index) => {
              return [
                <div
                  data-tut="notification3"
                  className="noti-content-inner"
                  onClick={() => {
                    Notification_clicked(data)
                  }}
                  key={index}
                >
                  <div className="content-date">
                    <p data-tut="notification1">
                      {/* {data.createdAt.slice(8, 10)}-{data.createdAt.slice(5, 7)}-
                      {data.createdAt.slice(0, 4)} */}
                      {Date_convert(data.createdAt, 4)}


                    </p>
                    <p className="notification_time"> {Date_convert(data.createdAt, 1)}</p>
                  </div>
                  <div
                    className={`content-detail ${data.Status === false ? "active" : ""}`}
                  >
                    <div className="content-detail-img">
                      <img src="/media/misc/bell-bg.svg" alt="img" />
                    </div>
                    <div className="date-content-info">
                      {/* <p>{data.Description}</p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data.Description,
                        }}
                      />
                      <p>
                        <b>{data.eventName}</b>
                      </p>
                    </div>
                    <div className="content-time">
                      {data.Status === true ? (
                        <span>
                          <b>Seen</b>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>,
              ]
            })}
          </div>
        )}
      </div>
    </>
  )
}
