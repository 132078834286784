/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { connect } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import * as Yup from "yup"
import { toAbsoluteUrl } from "../../../../_common/_helpers"
import SVG from "react-inlinesvg"

import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { requestPassword } from "../_redux/authCrud"
// import Select from "react-select"
import { useSelector, shallowEqual } from "react-redux"

const initialValues = {
  email: "",
  region: "",
}

function ForgotPassword(props) {
  const { intl } = props
  const [isRequested, setIsRequested] = useState(false)
  const [loading, setLoading] = useState(false)
  const [css_class, setcss_class] = useState(true)

  const [error_message, seterror_message] = useState("error")
  const [error_message_visiblility, seterror_message_visiblility] = useState(false)
  const host = useSelector((state) => state.auth.end_point_url, shallowEqual)

  const [selectedOption_region, setselectedOption_region] = useState([
    {
      value: "0",
      label: "Region",
    }])
  const [country_name_, setcountry_name_] = useState([{

    value: "https://dev.mystudymanager.com",
    // value: "https://val.mystudymanager.com",
    label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="" />United States </div>,

  }, {
    value: "https://ca.mystudymanager.com",
    label: <div><img src="/media/misc/canada.svg" height="30px" width="30px" alt="" />Canada</div>,

  }])
  useEffect(() => {
    props.route_status("https://realtimestage.wpengine.com/study-login.php")
    // props.end_point("https://dev.mystudymanager.com")
    // props.end_point("https://val.mystudymanager.com")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // on hold region change

    // region: Yup.string()
    //   .required("Region is required")
    //   .nullable()
    //   .test("not blank", "Select Region", (value) => {
    //     return value != "0"
    //   }),

  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      requestPassword(values.email, host)
        .then((res) => {
          setIsRequested(true)
          seterror_message(res?.data.message)
          seterror_message_visiblility(true)
          setSubmitting(false)
          setLoading(false)
          setcss_class(true)
          formik.resetForm()


        })
        .catch((err) => {
          setIsRequested(false)
          setSubmitting(false)
          seterror_message(err.response?.data.message)
          seterror_message_visiblility(true)
          setLoading(false)
          setcss_class(false)


          // setStatus(
          //   intl.formatMessage(
          //     { id: "AUTH.VALIDATION.NOT_FOUND" },
          //     { name: values.email }
          //   )
          // )
        })
    },
  })
  const Setoptionvalue = (e) => {
    setselectedOption_region(e)
    formik.setFieldValue("region", e.value)
    props.end_point(e.value)

  }

  return (
    <>
      {/* {isRequested && <Redirect to="/auth" />} */}

      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Forgot Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your email to reset your password
          </div>
        </div>

        {error_message_visiblility && (
          <div
            // className="alert alert-custom alert-light-danger fade show mb-10"
            className={`alert alert-custom  fade show mb-10  ${css_class ? 'alert-light-success' : 'alert-light-danger'}`}
            role="alert"
          >
            <div className="alert-icon">
              <span className={` svg-icon svg-icon-3x${css_class ? 'svg-icon-success' : 'svg-icon-danger'}`}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}></SVG>{" "}
              </span>
            </div>
            <div className="alert-text font-weight-bold">{error_message}</div>
            <div
              className="alert-close"
              onClick={() => seterror_message_visiblility(false)}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
        )}

        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* on hold region change */}

          {/* <div className="form-group fv-plugins-icon-container">
            <Select
              value={selectedOption_region}
              onChange={(e) => {
                Setoptionvalue(e)
              }}
              options={country_name_}
              name="region"
              className="region-select-drop mb-0"
            />
            {formik.touched.region && formik.errors.region ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.region}</div>
              </div>
            ) : null}
          </div> */}


          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              placeholder="Email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              maxLength={51}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Submit
              {loading && <span className="ml-3 spinner spinner-white"></span>}

            </button>
            <Link to="/auth" className="forgot-cancel">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>

    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
