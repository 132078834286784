/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Date_of_birth = (props) => {
    const [maxDate, setMaxDate] = useState("")
    const children_elements_ = props.children_elements
    const form_value = props.form_value
    const current_date_ = props.current_date_
  
    let currentDate = new Date()
    let age = ""
    const [isFieldHidden, setIsFieldHidden] = useState(false)
   
    let commonId = props.rowid ? props.rowid : form_value[children_elements_?.Parent]?.[0]
    let checkClearButton = commonId ? form_value[children_elements_.UUID]?.[commonId] : form_value[children_elements_.UUID]

    let isAndroid = /(android)/i.test(navigator.userAgent);
    let isIosDevice = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
   
    useEffect(() => {
        let currentDate = new Date();
        let maxYear = currentDate.getFullYear() - children_elements_.Meta.MinAge
        let maxMonth = currentDate.getMonth() + 1 < 10 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
        let maxDay = currentDate.getDate() < 10 ? "0" + currentDate.getDate() : currentDate.getDate()
        let maxDate = `${maxYear}-${maxMonth}-${maxDay}`
        setMaxDate(maxDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

     // Check element visibility when form_value changes
     useEffect(() => {
        let is_hidden = check_field_visibility(children_elements_, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    return (
        !isFieldHidden ? <>

        <div className="input_label">
            <label className="label_" htmlFor="">{children_elements_.Meta.Label?.blocks?.[0].text ? children_elements_.Meta.Label?.blocks?.[0].text : children_elements_.Meta.Label}</label><p style={{ color: "red" }}>{children_elements_.Meta.Required ? "*" : ""}</p></div>
        <div className="input_wrap">
            {children_elements_?.Children?.map((children_elements, index) => {
                return [
                    <React.Fragment key={index}>
                        {children_elements.Meta.Hidden === false && children_elements.Type === "date_time" ?
                            <>
                                {children_elements.Children?.map((grand_childrendata, gnd_indx) => {
                                    if (grand_childrendata.Type === "date") {
                                        let dateofbirth = commonId ? form_value[grand_childrendata.UUID]?.[commonId] : form_value[grand_childrendata.UUID]
                                        if (dateofbirth) {
                                            let birthdate = new Date(dateofbirth)
                                            age = currentDate.getFullYear() - birthdate.getFullYear()

                                            if (currentDate.getMonth() < birthdate.getMonth()) {
                                                age = age - 1
                                            } else {
                                                if (currentDate.getMonth() == birthdate.getMonth()) {
                                                    if (currentDate.getDate() < birthdate.getDate()) { age = age - 1 }
                                                }
                                            }
                                        } else {
                                            age = ""
                                        }
                                    }

                                    let fieldValue = commonId ? form_value[grand_childrendata.UUID]?.[commonId] ? form_value[grand_childrendata.UUID]?.[commonId] : "" : form_value[grand_childrendata.UUID] ? form_value[grand_childrendata.UUID] : ""

                                    return [
                                        <React.Fragment key={gnd_indx}>
                                            {grand_childrendata.Meta.Hidden === false && grand_childrendata.Type === "date" ?
                                                <div className="input_date">
                                                    <div className="Date_picker_icon-wrap">
                                                        <input
                                                            // className={`date-field ${isIosDevice && !fieldValue ? "show-date-placeholder" : ""}`}
                                                            id="date-calendar-dob"
                                                            type="date"
                                                            required={grand_childrendata.Meta.Required}
                                                            min={children_elements_.Meta.Past ? "1950-11-15" : current_date_}
                                                            max={maxDate}
                                                            name={grand_childrendata.UUID}
                                                            value={commonId ? form_value[grand_childrendata.UUID]?.[commonId] ? form_value[grand_childrendata.UUID]?.[commonId] : "" : form_value[grand_childrendata.UUID] ? form_value[grand_childrendata.UUID] : ""}
                                                            onChange={(e) => { props.ON_change_inputfield(e, "inputfield_date_birth", children_elements_.UUID, null, children_elements_?.Parent, null, null, props.rowid) }}
                                                            onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield_date_birth", children_elements_.UUID, null, children_elements_, props.rowid) }}
                                                            // placeholder="dd/mm/yyyy"
                                                        />
                                                        
                                                        <img onClick={()=> document.getElementById("date-calendar-dob").showPicker()} src="/media/misc/picker_calendar.svg" className="picker-image" alt="unable to load" />
                                                        
                                                    </div>
                                                    <div className="mb-0 clear-btn-dob">
                                                        {checkClearButton ? <div className="mb-0 image_wrap">{<div className="clear-text">
                                                            <span title="Clear" onClick={() => { props.Clear_data_for_dates(grand_childrendata.UUID, children_elements_.UUID, children_elements_?.Parent, "dob", props.rowid) }}>Clear</span>
                                                           
                                                        </div>} </div> : ""}

                                                    </div>
                                                    <p className="position-absolute age-text">Age: {age !== "" ? age : ""}</p>
                                                </div>
                                                : ""}


                                            {grand_childrendata.Meta.Hidden === false && grand_childrendata.Type === "time" ?
                                                <input type="time" required={grand_childrendata.Meta.Required}
                                                    name={grand_childrendata.UUID}
                                                    value={form_value[grand_childrendata.UUID] ? form_value[grand_childrendata.UUID] : ""}
                                                    onChange={(e) => { props.ON_change_inputfield(e, "inputfield_date_birth", children_elements_.UUID) }}
                                                   
                                                /> : ""}
                                        </React.Fragment>]
                                })}
                            </>

                            : ""}

                        {children_elements_.Meta.Hidden === false && children_elements_.Type === "text" ?
                            <>
                                <input type="text"
                                    name={children_elements_.UUID}
                                    value={form_value[children_elements_.UUID] ? form_value[children_elements_.UUID] : ""}
                                    onChange={(e) => { props.ON_change_inputfield(e, "inputfield") }}
                                    
                                />
                            </>

                            : ""}

                    </React.Fragment>
                ]
            })}
        </div>
    </>:""
    )
}
