import React, { useState, useEffect, useRef } from "react"
import { useSubheader } from "../../_common/layout"
import Tour from "reactour"
import { toAbsoluteUrl } from "../../_common/_helpers"
import {
  Change_notification_status,
  WebAppNotificationFlag_toggle,
  Dashboard_invite_api,
  Get_single_event,
  get_patient_source_form_by_id,
} from "../modules/Auth/_redux/authCrud"
import { Date_convert } from "../modules/Auth/_redux/Common_code"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { useHistory } from "react-router-dom"
import { Spinner } from "react-bootstrap"

export const Notifiction = () => {
  const suhbeader = useSubheader()
  suhbeader.setTitle(" Notifiction")
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [Notification_data, setNotification_data] = useState([])
  const [search_value, setsearch_value] = useState("")
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const [lodaing_status, setlodaing_status] = useState(true)
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const notifications_ = useSelector((state) => state.auth.notifications, shallowEqual)
  const componentRef = useRef()
  const dispatch = useDispatch()
  let history = useHistory()

  // to get notification from redux
  useEffect(() => {
    if (studies_?.length > 0) {
      let sorted
      sorted = notifications_?.sort((a, b) => {
        return a.Status - b.Status
      })

      if (sorted) {
        setNotification_data(sorted)
      }
      if (notifications_) {
        setlodaing_status(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications_])
  // to call  notification api on load
  useEffect(() => {
    dispatch(auth.actions.requestNotification())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(auth.actions.requestGlobal_notification())
    if (!studies_) {
      apicall(1)
    }
    Getuseer_data()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let invites_length = 0
  // to get study and invites
  const apicall = (type) => {
    Dashboard_invite_api()
      .then((value) => {
        if (type === 1) {
          dispatch(auth.actions.setstudies_action(value.data[0].studies))
        }
        invites_length = value.data[1].studyInvites.length
        setTimeout(() => { }, 2000)
      })
      .catch((error) => { })
  }

  // getting user data
  const Getuseer_data = () => {
    dispatch(auth.actions.requestUser())
  }

  // on click on notification
  const Notification_clicked = (data) => {
    // this part is to avoid status change on admin click
    if (sessionStorage.getItem("is_admin_control") === "true") {

    } else {
      Update_status(data.Web_Route, data.id)

    }
    dispatch(auth.actions.requestGlobal_notification())

    setTimeout(() => {
      if (data.Web_Route === "My_documents") {
        history.push("/My_documents")
      }
      if (data.Web_Route === "dashboard") {
        apicall(2)

        if (invites_length > 0) {
          history.push("/dashboard")
        }
      }
      if (data.Web_Route === "More_details" || data.Web_Route === "Study_calendar") {
        Get_single_event_(data.DocID)
      }
      if (data.Web_Route[1] === "t") {
        window.open(data.Web_Route)
      }
      if (data.Web_Route === "MyForm") {
        get_patient_soruce_form(data.DocID)
      }
    }, 700)
  }
  // to get single event
  const Get_single_event_ = (id) => {
    Get_single_event(id)
      .then((data) => {
        if (data.data.length > 0) {
          rediret_to(data.data)

        }

      })
      .catch((err) => { })
  }

  // redirect method
  const rediret_to = (data) => {
    history.push({
      pathname: "/More_details",
      state: data[0],
    })
  }

  // to change status of notifications

  const Update_status = async (Web_Route, id) => {
    await Change_notification_status(id)
      .then((value) => {
        if (Web_Route !== "My_documents") {
          dispatch(auth.actions.requestNotification())
        }
        dispatch(auth.actions.requestGlobal_notification())
      })
      .catch((error) => { })
  }

  // to open react tour
  useEffect(() => {
    setTimeout(() => {
      if (user.WebAppNotificationFlag === true && notifications_?.length > 0) {
        // if (true) {
        setIsTourOpen(true)
      }
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // api call to change flag of tour
  const oncloseReactTour = () => {
    setIsTourOpen(false)
    WebAppNotificationFlag_toggle()
      .then((data) => {
        Getuseer_data()
      })
      .catch((error) => { })
  }
  // search filter
  const Setsearchvalue = (val) => {
    setsearch_value(val)
  }

  useEffect(() => {
    if (studies_ && studies_?.length === 0) {
      setlodaing_status(false)
    }
  }, [studies_])


  // GP: Get patient source form details by id
  const get_patient_soruce_form = (docid) => {
    const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
    let CTMSHostLink_ = dropdown_data.sites.CTMSHostLink.slice(8)
    let SiteID_local_ = localStorage.getItem("SiteID_local")
    let PatientCTMSID_ = localStorage.getItem("PatientCTMSID")
    let portal_id = localStorage.getItem("id")

    get_patient_source_form_by_id(docid).then((data) => {
      if (data.data?.eSourceForms.length > 0) {
        let formDetail = data.data?.eSourceForms[0]
        history.push("/Form_details?CTMSHostLink_=" + CTMSHostLink_ + "&FormID_=" + formDetail?.FormID + "&is_mob=" + false + "&PatientCTMSID_=" + PatientCTMSID_ + "&SiteID_local_=" + SiteID_local_ + "&FormStatus_=" + formDetail?.Status + "&ID_=" + formDetail?.id + "&CreatedAt_=" + formDetail?.AssignDate + "&id=" + portal_id)
      }
    }).catch((error) => { })
  }

  // steps of react tour
  const steps = [
    {
      // for
      selector: '[data-tut="notification1"]',
      content: function DemoHelperComponent() {
        return (
          <div className="tutorial-div">
            <img src={toAbsoluteUrl("/media/misc/tutorial_noti_date.svg")} alt="unable to load" />
            <p className="tutorial-para">
              Notification date and times are tracked in this column.
            </p>
          </div>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      // for
      selector: '[data-tut="notification2"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <img src={toAbsoluteUrl("/media/misc/tutorial_noti_search.svg")} alt="unable to load" />
              <p className="tutorial-para">
                Use the search bar to locate specific notifications.
              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      // for
      selector: '[data-tut="notification3"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <img src={toAbsoluteUrl("/media/misc/tutorial_noti_content.svg")} alt="unable to load" />
              <p className="tutorial-para">
                Each notification includes a description.  Select the notification to view additional details.              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
  ]
  //   tour end
  return (
    <>
      {lodaing_status ? (
        <div className="text-center">
          <Spinner animation="border" className="spinner-info" variant="primary" />
        </div>
      ) : (
        <>
          {Notification_data?.length === 0 ? (
            <div className="dash-top">
              {" "}
              <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
              <p>No Notification found</p>{" "}
            </div>
          ) : (
            <div className="row" ref={componentRef}>
              <div className="my-notifiation">
                <div className="my-notification-inner">
                  <h3>Notification</h3>
                  <div className="form-group search-box" data-tut="notification2">
                    <input
                      className="form-control mr-sm-1 search-box"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => {
                        Setsearchvalue(e.target.value)
                      }}
                    />
                  </div>

                  <div className="dash-noti-content">
                    {Notification_data?.filter((v) => {
                      return v.Description?.toLowerCase().includes(search_value.toLowerCase()) || v.eventName?.toLowerCase().includes(search_value.toLowerCase())
                    }).map((data, index) => {
                      return [
                        <div
                          data-tut="notification3"
                          className="noti-content-inner"
                          onClick={() => {
                            Notification_clicked(data)
                          }}
                          key={index}
                        >
                          <div data-tut="notification1" className="content-date" style={{ width: '12%' }}>
                            <p >{Date_convert(data.createdAt, 4)}</p>
                            <p className="notification_time">{Date_convert(data.createdAt, 1)}</p>
                          </div>
                          <div className={`content-detail ${data.Status === false ? "active" : ""}`}>
                            <div className="content-detail-img">
                              <img src="/media/misc/bell-bg.svg" alt="img" />
                            </div>
                            <div className="date-content-info">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.Description,
                                }}
                              />
                              <p>
                                <b>{data.eventName}</b>
                              </p>
                            </div>
                            <div className="content-time">

                              {data.Status === true ? (
                                <span>
                                  <b>Seen</b>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>,
                      ]
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* tour popup methods */}
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => oncloseReactTour()}
        rounded={2}
        showCloseButton={true}
        showNavigation={true}
        disableDotsNavigation={false}
        closeWithMask={false}
        lastStepNextButton={<div className="finish-arrow">Finish</div>}
        maskClassName=""
      />
    </>
  )
}
