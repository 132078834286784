import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { resetemail_region_change } from "../../../modules/Auth/_redux/authCrud"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"
import Icon from "@material-ui/core/Icon"
import { useHistory } from "react-router-dom"

function Register_email_verify(props) {
    const [message_, setmessage_] = useState("")
    const [opens, setopens] = useState(false)
    const [apistatus, setapistatus] = useState(true)
    let history = useHistory()

    // popup method
    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent)

    useEffect(() => {
        setopens(true)
        const queryParams = new URLSearchParams(window.location.search)
        const ID = queryParams.get("id")
        const Email = queryParams.get("Email")
        const Token = queryParams.get("ValidToken")
        const baseurl = queryParams.get("host")

        Register_Reset_email(ID, Email, Token, baseurl)
    }, [])

    // for region change email verify
    const Register_Reset_email = (ID, Email, Token, baseurl) => {
        resetemail_region_change(ID, Email, Token, baseurl)
            .then((res) => {
                setmessage_(res.data.message)
                setapistatus(true)
            })
            .catch((err) => {
                setmessage_(err.response?.data.message)
                setapistatus(false)
            })
    }

    // popup close
    const handleCloses = () => {
        setopens(false)
        history.push("/")

    }
    return (
        <>


            {/* Successfully popup */}
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloses()
                    }
                }}
                aria-labelledby="customized-dialog-title"
                open={opens}
                className="success-popup"
            >
                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon mb-5">
                        {apistatus ? (
                            <Icon className="fa fa-check icon" />
                        ) : (
                            <Icon className="fa fa-exclamation-triangle" />
                        )}
                    </div>
                    {/* <h2 className="font-weight-bold m-10 success-head"></h2> */}
                    <h2 className="success-text mb-10 font-weight-bold success-head">{message_}</h2>

                    <button
                        type="submit"
                        onClick={handleCloses}
                        className="btn btn-primary mr-5 save-btn"
                    >
                        Ok
                    </button>
                </DialogContent>
            </Dialog>
            {/* Successfully popup */}
        </>
    )
}

export default injectIntl(connect(null, auth.actions)(Register_email_verify))
