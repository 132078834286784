import React, { useState, useEffect } from "react"
import { useLocation } from "react-router"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Dashboard_invite_api, Get_single_event } from "../modules/Auth/_redux/authCrud"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { useHistory } from "react-router-dom"
import { Date_convert, Date_convert_without_time_zone } from "../../app/modules/Auth/_redux/Common_code"
import { CommonPopup } from "../pages/Common_popup"

import Highlighter from "react-highlight-words"

export const Global_search = (props) => {
  // const [medicationdata, setmedicationdata] = useState([])
  const [eventdat, seteventdat] = useState([])
  const [esource_data, setesource_data] = useState([])
  const [documentdat, setdocumentdat] = useState([])
  const [document_combined_data, setdocument_combined_data] = useState([]);
  const [stipenddata_paid, setstipenddata_paid] = useState([])
  const [Completed_consetform, setCompleted_consetform] = useState([])
  const [stipenddata_unpaid, setstipenddata_unpaid] = useState([])
  const [popupstatus, setpopupstatus] = React.useState(false)
  const [searchword, setsearchword] = useState("")
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const dispatch = useDispatch()
  let location = useLocation()
  let history = useHistory()
  // calling invite api
  useEffect(() => {
    if (!studies_) {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [])
  // calling invite api  to resolve refresh issue
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => {
      })
  }

  // assign values to usestae
  useEffect(() => {
    seteventdat(location.state.data[0]?.Events)
    setesource_data(location.state.data[3]?.eSourceForms)
    setdocumentdat(location.state.data[1]?.Documents)
    // setmedicationdata(location.state.data[1]?.Medications)
    setCompleted_consetform(location.state.data[2]?.CompletedConsentForms)
    setsearchword(location.state?.name)
    setstipenddata_paid(location.state.stipend?.paidStipends)
    setstipenddata_unpaid(location.state.stipend?.unpaidStipends)
  }, [location])

  // to combine  document array
  useEffect(() => {
    if (location.state.data[2]?.CompletedConsentForms || location.state.data[1]?.Documents) {
      var combined_data = [...documentdat, ...Completed_consetform]
      setdocument_combined_data(combined_data)
    }
    else {
      setdocument_combined_data([])

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Completed_consetform])



  // redirect to calendar page
  const open_moredetail = (data) => {
    history.push({
      pathname: "/More_details",
      state: data,
    })
  }
  // medecation button press

  // const Medication_button_pressed = (id) => {
  //   Change_medication_status(id)
  //     .then((data) => {
  //       dispatch(auth.actions.requestUpcomingEvent())
  //       setopen_medication(true)
  //     })
  //     .catch((err) => {})
  //   setmedication_button(true)
  //   setTimeout(() => {
  //     setmedication_button(false)
  //   }, 1000)
  // }

  // twilio call join
  const Joincall = (title, id, join_status_id, custom_title) => {
    localStorage.setItem("CTMSEventID", id)
    localStorage.setItem("join_status_id", join_status_id)

    Get_single_event(join_status_id).then(result => { Joincall_part2(title, id, result.data, custom_title) }).catch(err => { })

  }


  const Joincall_part2 = (title, id, data, custom_title) => {

    if (data[0].IsInitiates === true) {
      sessionStorage.setItem("RoomSID", data[0].RoomSID)

      history.push({
        pathname: "/Meeting",
        state: { title: title + id, only_title: custom_title },
      })
    }
    else {
      setpopupstatus(true)
    }

  }
  
  const close_popup = () => {
    setpopupstatus(false)
  }
  return (
    <div className="global-search">
      <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

      <div className="event-details ">
        <h2 className="heading"> Calendar Events </h2>
        {eventdat?.length > 0 ? (
          <>
            {" "}
            {eventdat?.map((data, index) => {
              let site_object = JSON.parse(localStorage.getItem("site_obj"))

              let typeIs = data?.Type ? " - Visit Type (" + data?.Type + ")" : ""
              let typeIs2 = data?.Type ? " - Visit Type (" + data?.Type + ")" : ""
              let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";

              let visitTitle = (data.VisitName === "" ? (data.Title + ' - ') : (data.VisitName + ' - ')) + StudyNameForEvents
              let visitTitle_with_type = (data.VisitName === "" ? (data.Title + ' - ') : (data.VisitName + ' - ')) + StudyNameForEvents + typeIs2

              return [
                <div className="quick-event" key={index}>
                  <div className="study-Details">
                    <img src="/media/misc/visit-icon.svg" className="visit-icon" alt="unable to load" />
                    <div className="ml-4">
                      <h4>
                        {/* {data.Title} */}
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={visitTitle}
                        />{typeIs}
                      </h4>
                      <p className="d-flex">
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={Date_convert_without_time_zone(data.StartDate, 5)}
                        />
                      </p>
                      {data.Location === undefined ||
                        data.Location === "" ||
                        data.Location === null || data?.Type.trim() === 'Phone Visit' || data?.Type.trim() === 'Virtual Visit' ? (
                        ""
                      ) : (
                        <div className="location d-flex mb-0">
                          <img src="/media/misc/location.png" alt="unable to load" />
                          <p className="rout mb-0 d-flex">
                            <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={[searchword]}
                              autoEscape={true}
                              textToHighlight={data.Location}
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center study-Details_button ">
                    <button
                      type="button"
                      className="join-button "
                      onClick={() => {
                        Joincall(data.Title, data.CTMSEventID, data.id, visitTitle_with_type)
                      }}
                    >
                      Join <img src="/media/misc/on-video.svg" className="video-icon" alt="unable to load" />
                    </button>
                    <img
                      src="/media/misc/details-gray.svg"
                      className="details-icon "
                      onClick={() => {
                        open_moredetail(data)
                      }}
                      title="Event Details"
                      alt="unable to load"
                    />
                  </div>
                </div>,
              ]
            })}{" "}
          </>
        ) : (
          <div className="no-data-found">
            <img src="/media/misc/no-data-found.svg" className="details-icon " alt="unable to load" />
            <p>No Data Found</p>
          </div>
        )}
      </div>

      {/* medication code */}
      {/*       
      <div className="event-details ">
        <h2 className="heading "> Medication</h2>
        {medicationdata?.length > 0 ? (
          <>
            {" "}
            {medicationdata?.map((value,index) => {
              return [
                <div className="today-plan-content-wrap" key={index}>
                  
                  <div className="today-plan-content">
                    <div className="time"></div>
                    <div className="title-wrap">
                      <span className="medication-title">
                        <b>
                          {" "}
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={value.Medication}
                          />
                        </b>
                      </span>
                  
                      <h6 className="time_right">
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={value.Regimen}
                        />{" "}
                      </h6>
                    </div>

                    <span className="rout">
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[searchword]}
                        autoEscape={true}
                        textToHighlight={value.Route}
                      />{" "}
                      {value.Route ? "  ," : " "}{" "}
                    </span>
                    <span className="rout d-flex">
                      {value.Dosage || value.DosageUnit ? "Dosage -" : ""}
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[searchword]}
                        autoEscape={true}
                        textToHighlight={value.Dosage}
                      />
                      {value.DosageUnit ? "- " : " "}{" "}
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[searchword]}
                        autoEscape={true}
                        textToHighlight={value.DosageUnit}
                      />
                    </span>
                    <p className="taken-dosages d-flex">
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[searchword]}
                        autoEscape={true}
                        textToHighlight={"Dosages Taken -"}
                      />

                      {value.MedicationCount[0].MedicationCount}
                    </p>
                    <div className="date-wrap">
                      <span className="date d-flex">
                        <strong>Start Date:</strong>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={Date_convert(value.StartDate, 2)}
                        />
                      </span>
                      {value.EndDate ? (
                        <span className="date">
                          <strong>End Date:</strong>{" "}
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={Date_convert(value.EndDate, 2)}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>,
              ]
            })}{" "}
          </>
        ) : (
          <div className="no-data-found">
            <img src="/media/misc/no-data-found.svg" className="details-icon " />
            <p>No Data Found</p>
          </div>
        )}
      </div> */}
      <div className="event-details my-stipend ">
        <h2 className="heading"> Stipends</h2>
        {stipenddata_paid?.length > 0 || stipenddata_unpaid?.length > 0 ? (
          <>
            {" "}
            {stipenddata_paid?.map((value, index) => {
              return [
                <div className="quick-event" key={index}>
                  <div className="study-Details">
                    <img src="/media/misc/receipt.svg" className="visit-icon" alt="unable to load" />
                    <div className="ml-4 left-side">
                      <h4>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={
                            value.usVisit === "0" ? value.VisitName : value.usVisitName
                          }
                        />
                      </h4>
                      <div className="d-flex status-wrap">
                        <p className="date">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={Date_convert(value.StipendDate, 4)}
                          />
                        </p>
                        {/* <p className="refcode">
                          #
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={value.StipendNumber}
                          />
                        </p> */}
                      </div>
                    </div>
                    <div className="Right-side">
                      <p className="status">
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={"Paid stipend"}
                        />
                      </p>
                      <p className="refcode amount">
                        <b>
                          {" "}
                          $
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={value.Stipend}
                          />
                        </b>
                      </p>
                    </div>
                  </div>
                  <div>

                  </div>
                </div>,
              ]
            })}
            {stipenddata_unpaid?.map((value, index) => {
              return [
                <div className="quick-event" key={index}>
                  <div className="study-Details">
                    <img src="/media/misc/receipt.svg" className="visit-icon" alt="unable to load" />
                    <div className="ml-4 left-side">
                      <h4>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={
                            value.usVisit === "0" ? value.VisitName : value.usVisitName
                          }
                        />
                      </h4>
                      <div className="d-flex status-wrap">
                        <p className="date">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={Date_convert(value.AssessDate, 4)}
                          />
                        </p>
                        <p className="refcode">{"        "}</p>
                      </div>
                    </div>
                    <div className="Right-side">
                      <p className="status unpaid">
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={"Pending stipend"}
                        />
                      </p>

                      <p className="refcode amount">
                        <b>
                          {" "}
                          $
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={value.Stipend}
                          />
                        </b>
                      </p>
                    </div>
                  </div>
                  <div>

                  </div>
                </div>,
              ]
            })}
          </>
        ) : (
          <div className="no-data-found">
            <img src="/media/misc/no-data-found.svg" className="details-icon " alt="unable to load" />
            <p>No Data Found</p>
          </div>
        )}
      </div>
      {/* document section */}
      <div className="event-details my-stipend my-document">
        <h2 className="heading">Study Consents</h2>
        {document_combined_data?.length > 0 ? (
          <>
            {" "}
            {document_combined_data?.map((value, index) => {
              return [
                <div className="quick-event" key={index}>
                  <div className="study-Details">
                    <img src="/media/misc/receipt.svg" className="visit-icon" alt="unable to load" />
                    <div className="ml-4 left-side">
                      <h4>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={
                            value.Title
                          }
                        />
                      </h4>
                      <div className="d-flex status-wrap">
                        <p className="date">
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchword]}
                            autoEscape={true}
                            textToHighlight={Date_convert(value.DateSigned, 4)}
                          />
                        </p>

                      </div>
                    </div>
                    <div className="Right-side">
                      <p className="status">
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchword]}
                          autoEscape={true}
                          textToHighlight={"Consent"}
                        />
                      </p>
                      <img
                        src="/media/misc/details-gray.svg"
                        className="details-icon "
                        onClick={() => { history.push("/My_documents") }}
                        title="Document Details"
                        alt="unable to load"
                      />
                    </div>
                  </div>
                  <div>
                  </div>
                </div>,
              ]
            })}

          </>
        ) : (
          <div className="no-data-found">
            <img src="/media/misc/no-data-found.svg" className="details-icon " alt="unable to load" />
            <p>No Data Found</p>
          </div>
        )}
      </div>

      {/* esource code */}

      <div className="top-form-wrap event-details visit-form">
        <h2 className="heading "> Visit Forms</h2>
        {esource_data?.length > 0 ? (
          <>
            {" "}
            {esource_data?.map((data, index) => {
              return [
                <div className="form-row" key={data.id} onClick={() => { history.push("/MyForm") }}
                >
                  <img src="/media/misc/NO-form.svg" className="form-icon" alt="unable to load" />
                  <div className="width">
                    <h4>
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[searchword]}
                        autoEscape={true}
                        textToHighlight={data.FormTitle}
                      />
                    </h4>
                    <div className="user-details">
                      <p className="user-name">Added by {data.CreatedBy} </p>
                      <p className="time">{Date_convert(data.createdAt, 5)}</p>
                      <p className="status">Status <span>{data.Status ? "Completed" : "Due"}</span></p>

                    </div>
                  </div>
                </div>
              ]
            })}{" "}
          </>
        ) : (
          <div className="no-data-found">
            <img src="/media/misc/no-data-found.svg" className="details-icon " alt="img" />
            <p>No Data Found</p>
          </div>
        )}
      </div>




    </div>
  )
}
