/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { connect } from "react-redux"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { toAbsoluteUrl, } from "../../../../_common/_helpers"
// import { useGoogleLogin } from "react-google-login"
// import { useGoogleLogout } from "react-google-login"
import { get_countries } from "../_redux/authCrud"
import { Ctms_register_api, register, get_ctms_token, Register_region_change } from "../_redux/authCrud"
import { useHistory } from "react-router-dom"
import SVG from "react-inlinesvg"

import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { withStyles } from "@material-ui/core/styles"

import Icon from "@material-ui/core/Icon"
import Select from "react-select"





function Registration(props) {
  const [country_N_code, setcountry_N_code] = useState([])
  let history = useHistory()
  const [error_message, seterror_message] = useState(false)
  const [error_message2, seterror_message2] = useState("Error")
  const [opens, setopens] = useState(false)
  const [opens_regin_popup, setopens_regin_popup] = useState(false);
  const [country_name_, setcountry_name_] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [show_hide_partof_popup, setshow_hide_partof_popup] = useState(true);

  const [loading_for_submit_part2, setloading_for_submit_part2] = useState(false);
  const [success_message, setsuccess_message] = useState("");
  const queryParams = new URLSearchParams(window.location.search)
  const is_email_present = queryParams.get("Email")
  const [selectedOption_country, setselectedOption_country] = useState({
    value: "0",
    label: " Country",
  })
  const [selectedOption_region, setselectedOption_region] = useState([
    {
      value: "0",
      label: "Region",
    }])

  // eslint-disable-next-line no-unused-vars
  const [region_name, setregion_name] = useState([{

    value: "https://dev.mystudymanager.com",
    // value: "https://val.mystudymanager.com",
    label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="" />United States </div>,

  }, {
    value: "https://ca.mystudymanager.com",
    label: <div><img src="/media/misc/canada.svg" height="30px" width="30px" alt="" />Canada</div>,

  }])


  // eslint-disable-next-line no-unused-vars
  const [selectedOption, setselectedOption] = useState({
    value: "0",
    label: " Country code",
  })

  // eslint-disable-next-line no-unused-vars
  const [country_code_, setcountry_code_] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [loading_for_no_button, setloading_for_no_button] = useState(false);
  const [first_part_hide_status, setfirst_part_hide_status] = useState(true);


  // api call on load
  useEffect(() => {
    const getdata = async () => {
      await get_countries()
        .then((val2) => {
          setcountry_N_code(val2.data)
        })
        .catch((err) => { })
    }
    getdata()


    return () => { }
  }, [])

  // cutom value for dropdown
  useEffect(() => {
    if (country_N_code.length > 0) {

      setcountry_code_(
        country_N_code.map((num, index) => {
          return { label: num.Name + " (" + num.Code + ")", value: num.id }
        })
      )

      setcountry_name_(
        country_N_code.map((num, index) => {
          return { label: num.Name, value: num.id }
        })
      )
    }
  }, [country_N_code])

  // custom value of dropdown

  const Setoptionvalue = (e, type) => {
    if (type === 1) {
      setselectedOption(e)
      formik.setFieldValue("country_code", e.value)
    }
    if (type === 2) {
      setselectedOption_country(e)
      formik.setFieldValue("country", e.value)
    }
    if (type === 3) {
      setselectedOption_region(e)
      formik_for_popup.setFieldValue("region", e.value)
      props.end_point(e.value)


    }
    if (type === 4) {
      setselectedOption_region(e)
      formik.setFieldValue("region", e.value)
      props.end_point(e.value)


    }
  }
  // to get url parameters

  const initialValues = {
    first_name: queryParams.get("FirstName") ? queryParams.get("FirstName") : "",
    last_name: queryParams.get("LastName") ? queryParams.get("LastName") : "",
    email: queryParams.get("Email") ? queryParams.get("Email") : "",
    password: "",
    changepassword: "",
    contact: "",
    country_code: queryParams.get("CountryCode") ? queryParams.get("CountryCode") : "",
    country: queryParams.get("CountryID") ? queryParams.get("CountryID") : "",
    region: "",

  }


  const [loading, setLoading] = useState(false)

  window.addEventListener("message", handlePostMessage)
  window.addEventListener("load", () => { })
  function handlePostMessage(event) {
    if (event.data.type === "profile") {
      setUserInfo(event.data.profile)
      alert("Hey")
    }
  }

  function setUserInfo(profile) { }
  // validation
  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .max(50, "Maximum 50 characters allowed")
      .min(2, "minimum 2 characters allowed")
      .required("First name is required")
      .nullable()
      .matches(/^[A-Za-z]+$/, "Invalid first name"),

    last_name: Yup.string()
      .trim()
      .max(50, "Maximum 50 characters allowed")
      .min(2, "minimum 2 characters allowed")
      .required("Last name is required")
      .matches(/^[A-Za-z]+$/, "Invalid Last name")
      .nullable(),
    email: Yup.string()
      .trim()
      .email("Wrong email format")
      .min(3, "Minimum 3 characters required")
      .max(50, "Maximum 50 characters allowed")
      .nullable()
      .required("Email is required"),
    contact: Yup.string()
      .trim()
      .min(10, "Minimum 10 digit required")
      .max(10, "Maximum 10 digit required")
      .matches(/^[0-9\b]+$/, "Invalid Phone number !")
      .required("Phone number is required"),

    // on hold
    // country_code: Yup.string()
    //   .required("Country code is required")
    //   .nullable()
    //   .test("not blank", "Select country code", (value) => {
    //     return value != "0"
    //   }),

    eventStartDate: Yup.date(),
    country: Yup.string()
      .required("Country is required")
      .nullable()
      .test("not blank", "Select country", (value) => {
        // eslint-disable-next-line eqeqeq
        return value != "0"
      }),
    // on hold

    // region: Yup.string()
    //   .required("Region is required")
    //   .nullable()
    //   .test("not blank", "Select Region", (value) => {
    //     return value != "0"
    //   }),

    password: Yup.string()
      .trim()
      .min(8, "Minimum 8 characters required !")
      .max(25, "Maximum 25 characters allowed")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, 1 uppercase, 1 lowercase, 1 number, and one special character."
      ),

    changepassword: Yup.string()
      .trim()
      .required("Confirm password is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const getInputClasses_forpopup = (fieldname) => {
    if (formik_for_popup.touched[fieldname] && formik_for_popup.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik_for_popup.touched[fieldname] && !formik_for_popup.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }
  // formik start

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      enableLoading()

      // register using email n passwprd
      register(
        values.first_name,
        values.last_name,
        values.email,
        values.password,
        values.country,
        values.contact,
        // values.country_code

      )
        .then((value) => {
          disableLoading()
          setSubmitting(false)
          // Ctms_register_status_update(value.data)
          common_sesson_ctms(value.data[0])
          localStorage.setItem("PatientCTMSID", value.data[0].PatientCTMSID)
          setsuccess_message(" You have registered successfully.")

          setopens(true)
        })
        .catch((err) => {
          setSubmitting(false)
          seterror_message2(err.response.data.message)
          seterror_message(true)
          disableLoading()
        })


    },
  })

  //  ctms register user status
  const Ctms_register_status_update = (sessionkey, datas) => {
    Ctms_register_api(sessionkey, datas)
      .then((value) => { })
      .catch((err) => { })
  }
  // ctms register user session key
  const common_sesson_ctms = async (datas) => {
    localStorage.setItem("PatientCTMSID", datas.PatientCTMSID)

    if (datas.PatientCTMSID) {
      await get_ctms_token(datas.SiteID)
        .then((value) => {
          Ctms_register_status_update(value.data, datas)
        })
        .catch((error) => { })
    }
  }
  // popup close
  const handleCloses = () => {
    setopens(false)
    // facebook_DISConnect()
    history.push("/login")
  }
  const handleCloses_regin_popup = () => {
    setshow_hide_partof_popup(true)
    setopens_regin_popup(false)
    formik_for_popup.resetForm()
  }
  const handleCloses_regin_popup_no_clicked = () => {
    setfirst_part_hide_status(true)
    setopens_regin_popup(false)

  }


  const handleCloses_error = () => {
    seterror_message(false)
  }



  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  // eslint-disable-next-line no-unused-vars
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions)


  useEffect(() => {
    // props.end_point("https://dev.mystudymanager.com")
    // props.end_point("https://val.mystudymanager.com")
    props.route_status("https://realtimestage.wpengine.com/study-registration.php")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for user with more than one country 

  const popup_validation = Yup.object().shape({

    popup_email: Yup.string()
      .trim()
      .email("Wrong email format")
      .min(3, "Minimum 3 characters required")
      .max(50, "Maximum 50 characters allowed")
      .required("Email is required"),
    region: Yup.string()
      .required("Region is required")
      .nullable()
      .test("not blank", "Select Region", (value) => {
        // eslint-disable-next-line eqeqeq
        return value != "0"
      }),

  })


  const formik_for_popup = useFormik({
    initialValues: {
      region: "",
      popup_email: queryParams.get("Email") ? queryParams.get("Email") : "",

    },
    validationSchema: popup_validation,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setloading_for_submit_part2(true)
      Register_region_change(values.popup_email, values.region).then(data => { Register_region_change_part2(data, 1) }).catch(err => { Register_region_change_part2(err, 2) }).finally(dtt => {
        setloading_for_submit_part2(false)
      })



    },
  })


  const Register_region_change_part2 = (data, type) => {
    if (type === 1) {
      setopens(true)
      setsuccess_message(data.data.message)

    }
    if (type === 2) {
      seterror_message2(data.response?.data.message)
      seterror_message(true)


    }
  }



  const customStyles = {

    singleValue: (provided, state) => {

      return {

        ...provided,

        color: state.data.label === " Country" ? "#B5B5C3" : "#000"

      }

    }

  }
  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {error_message && (
        <div
          className="alert alert-custom alert-light-danger fade show mb-10"
          role="alert"
        >
          <div className="alert-icon">
            <span className="svg-icon svg-icon-3x svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}></SVG>{" "}
            </span>
          </div>
          <div className="alert-text font-weight-bold">{error_message2}</div>
          <div className="alert-close" onClick={() => seterror_message(false)}>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
      )}
      <p className="patient-portal">--PATIENT PORTAL--</p>

      <Link to="#" className="text-center mb-10 mb-lg-20">
        <img
          alt="Logo"
          className="login-logo mb-2 "
          src={toAbsoluteUrl("/media/logos/RealTime_Logo-v2_My Study Manager_Horizontal.png")}
        />
      </Link>
      {/* first part  which will hide after condition */}

      {first_part_hide_status ? <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {/* region change on hold */}

        {/* 
        <div className="mb-7">
          <Select
            value={selectedOption_region}
            onChange={(e) => {
              Setoptionvalue(e, 4)
            }}
            options={region_name}
            name="region"
            className="region-select-drop mb-0"
          />
          {formik.touched.region && formik.errors.region ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.region}</div>
            </div>
          ) : null}
        </div> */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container first-name">
          <input
            placeholder="First Name"
            type="text"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "first_name"
            )}`}
            name="first_name"
            maxLength={51}
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.first_name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: last name */}
        <div className="form-group fv-plugins-icon-container first-name last-name">
          <input
            placeholder="Last Name"
            type="text"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "last_name"
            )}`}
            name="last_name"
            maxLength={51}
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.last_name}</div>
            </div>
          ) : null}
        </div>
        {/* end: last name */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "email"
            )}`}
            name="email"
            maxLength={51}
            autoComplete="new-password"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "password"
            )}`}
            name="password"
            maxLength={51}
            autoComplete="new-password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container margin-bottom-10">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            maxLength={50}
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.changepassword}</div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}
        {/* begin: country code 2*/}
        {/* on hold */}
        {/* <div className="form-group fv-plugins-icon-container county-code">
          <Select
            value={selectedOption}
            onChange={(e) => {
              Setoptionvalue(e, 1)
            }}
            options={country_code_}
            name="country_code"
          />
          {formik.touched.country_code && formik.errors.country_code ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.country_code}</div>
            </div>
          ) : null}
        </div> */}

        {/* end: country code 2*/}

        {/* begin: contact */}
        <div className="form-group fv-plugins-icon-container phone-no">
          <input
            placeholder="Phone Number"
            // type="number"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "contact"
            )}`}
            name="contact"
            maxLength={51}
            {...formik.getFieldProps("contact")}
          />
          {formik.touched.contact && formik.errors.contact ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contact}</div>
            </div>
          ) : null}
        </div>
        {/* end: contact */}


        {/* begin: country  */}
        <div className="form-group fv-plugins-icon-container county-code country">
          <Select
            value={selectedOption_country}
            className='placeholder-color'
            styles={customStyles}
            onChange={(e) => {
              Setoptionvalue(e, 2)
            }}
            options={country_name_}
            name="country"
          />

          {formik.touched.country && formik.errors.country ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.country}</div>
            </div>
          ) : null}
        </div>
        {/* end: country  */}


        <div className="form-group ">
          <button
            type="submit"
            disabled={
              formik.isSubmitting

            }
            className="btn btn-primary font-weight-bold px-9 py-4 mt-3 register-button"
          >
            <span>Register</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>

        <div className="mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 login-left-footer">
          <span className="font-weight-bold text-dark-50">Already have an account?</span>
          <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">
            Login
          </Link>{" "}
          here!
        </div>
      </form>

        :
        // second part is here
        <div className="text-center p-10">
          <form onSubmit={formik_for_popup.handleSubmit}
            className="user-form-details">
            <div className="form-group fv-plugins-icon-container county-code country">
              <Select
                value={selectedOption_region}
                onChange={(e) => {
                  Setoptionvalue(e, 3)
                }}
                options={region_name}
                name="region"
              />

              {formik_for_popup.touched.region && formik_for_popup.errors.region ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik_for_popup.errors.region}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="text"
                className={`form-control form-control-solid h-auto ${getInputClasses_forpopup("popup_email")}`}
                name="popup_email"
                maxLength={51}
                {...formik_for_popup.getFieldProps("popup_email")}

              />
              {formik_for_popup.touched.popup_email && formik_for_popup.errors.popup_email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik_for_popup.errors.popup_email}</div>
                </div>
              ) : null}
            </div>


            <div className="user-details-btn">
              <button type="button" onClick={() => {
                setfirst_part_hide_status(true); setLoading(false); formik.setSubmitting(false); setselectedOption_region(
                  {
                    value: "0",
                    label: "Region",
                  })
              }} className="btn btn-danger mr-5 no-btn"
              >Cancel</button>

              <button
                type="submit"
                // onClick={handleCloses_regin_popup_submit}
                className="btn btn-primary save-btn submit-btn"
              >
                Submit{loading_for_submit_part2 && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>


        </div>


      }



      {/* Successfully popup */}
      <Dialog
        // onClose={handleCloses}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloses()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={opens}
        className="success-popup"

      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <h2 className="font-weight-bold m-10 success-head"></h2>
          <h2 className="success-text mb-10 font-weight-bold success-head">
            {success_message}          </h2>
          <button
            type="submit"
            onClick={handleCloses}
            className="btn btn-primary mr-5 save-btn"
          >
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* Successfully popup */}




      {/* country selsct popup */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloses_regin_popup()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={opens_regin_popup}
        className="success-popup"

      >
        <DialogContent dividers className="text-center p-10">
          <div className="Confim-popup">
            <h2 className="success-text mb-10 mt-5">
              {is_email_present ? "Are you already registered on MyStudyManager with this or other email in any region?" : "Are you already registered on MyStudyManager with  other email in any region?"}
              <br />
              {is_email_present ? <b>{is_email_present}</b> : ""}
            </h2>
            <button
              type="submit"
              onClick={handleCloses_regin_popup_no_clicked}
              className="btn btn-danger mr-5 no-btn"
              disabled={loading_for_no_button}
            >
              No {loading_for_no_button && <span className="ml-3 spinner spinner-white"></span>}

            </button>
            <button
              type="submit"
              onClick={() => { setfirst_part_hide_status(false); setopens_regin_popup(false) }}
              className="btn btn-primary mr-5 save-btn"
              disabled={loading_for_no_button}

            >
              Yes
            </button>
          </div>

        </DialogContent>
      </Dialog>
      {/* country selsect popup */}



      {/* Failed dialog */}
      <Dialog
        onClose={handleCloses_error}
        aria-labelledby="customized-dialog-title"
        open={error_message}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-exclamation-triangle" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
          <p className="success-text mb-10">{error_message2}</p>
          <button type="submit" onClick={handleCloses_error} className="btn btn-primary mr-5 save-btn">
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* failed popup end */}

    </div >
  )
}

export default injectIntl(connect(null, auth.actions)(Registration))
