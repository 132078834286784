/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const ResoueceManger_type = (props) => {
    const nested_data = props.children_elements
    const form_value = props.form_value
    const open_uploaded_file = props.open_uploaded_file
   
    let commonId = props.rowid ? props.rowid : form_value[nested_data?.Parent]?.[0]
    const [index_id, setindex_id] = useState("")
    const [allFiles, setAllFiles] = useState([])
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    useEffect(() => {
        setindex_id(uuidv4)
    }, [])


    useEffect(() => {
        let filesArray = []
        if (form_value[nested_data.UUID]) {
            if (commonId) {
                filesArray = form_value[nested_data.UUID]?.[commonId]?.Files ? form_value[nested_data.UUID]?.[commonId]?.Files : []
                
                setAllFiles(filesArray)
            } else {
                filesArray = form_value[nested_data.UUID]?.Files ? form_value[nested_data.UUID]?.Files : []
                
                setAllFiles(filesArray)
            }
        } else {
            setAllFiles([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form_value, nested_data])

     // Check element visibility when form_value changes
     useEffect(() => {
        let is_hidden = check_field_visibility(nested_data, form_value, commonId);
         setIsFieldHidden(is_hidden);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>

            <div className="input_label">
                <label className="label_" htmlFor="">{nested_data.Meta.Label?.blocks?.[0].text ? nested_data.Meta.Label?.blocks?.[0].text : nested_data.Meta.Label}</label><p style={{ color: "red" }}>{nested_data.Meta.Required ? "*" : ""}</p>
            </div>

            <div className="input_wrap upload-btn-wrapper">
                <div className="image-wrap_btn">
                    <div className="mb-0 d-flex align-items-center flex-column ml-sm-0">
                        {nested_data.Meta?.Files?.map((file, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {file.Name ? <div className="mb-2 mr-2 mt-0 image_wrap">
                                        <p
                                            className="open-file"
                                            onClick={() => open_uploaded_file(file?.Hash, file?.Mime, file?.Name)}
                                        >{file.Name}</p>
                                    </div> : null}
                                </React.Fragment>
                            )
                        })}
                    </div>
                   
                </div>
            </div>

        </> : ""
    )
}
