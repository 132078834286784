/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import Participant from "./Participant";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { DialogActions, DialogTitle } from '@material-ui/core';
import Icon from "@material-ui/core/Icon"
import TimerForTwilio from "./TimerForTwilio"

import { withStyles } from "@material-ui/core/styles";
import { Twilio_billing, twilio_call_end_status_change, get_ctms_token,getTwilioUserCount } from "../modules/Auth/_redux/authCrud"

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "block",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));
const Room = ({
  room,
  handleLogout,
  event_title,
  is_audio_on,
  is_video_on,
  audio_level,
  sessionkey,
  roomNameTrim
}) => {
  const [participants, setParticipants] = useState([]);
  const [participants_audio_status, setparticipants_audio_status] = useState(
    []
  );
  const [participants_count, setparticipants_count] = useState(0);
  const classes = useStyles();
  const [Count, setCount] = useState(0);
  const [leave_popup, setleave_popup] = useState(false);
  const [reverse_count, setreverse_count] = useState(59);
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [mutevalue, setmutevalue] = useState(false);
  const [offvideo, setoffvideo] = useState(false);
  const [searchbox, setsearchbox] = useState("");
  const [popup_status, setpopup_status] = React.useState(false);
  const [refresh_status, setrefresh_status] = React.useState(false);
  const [can_leave_page, setcan_leave_page] = useState(true);
  const Local_p_audio_ = Array.from(room.localParticipant.audioTracks.values());
  const Local_p_audio_status = Local_p_audio_[0].track?.isEnabled;
  let local_participant_name = room.localParticipant.identity.split(" ");
  let local_participant_name_full = room.localParticipant.identity.split(" }");
  const [random_varaible, setrandom_varaible] = useState(23);
  const [popup_for_net_lost, setpopup_for_net_lost] = useState(false);
  const [time, setTime] = useState();
  useEffect(() => {
    let time_log = setInterval(() => {
      // Auto_mute_unmute()
    }, 20000)
    return (() => {
      clearInterval(time_log);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);



  useEffect(() => {
    if (is_video_on === false) {
      valuechange_video();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [is_video_on]);

  useEffect(() => {
    if (is_audio_on === false) {
      valuechange_audio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [is_audio_on]);

  //adding and removing participants
  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  // to mute unmute
  const valuechange_audio = () => {
    setmutevalue(!mutevalue);
    room.localParticipant.audioTracks.forEach((track) => {

      if (mutevalue) {
        track.track.enable();
      } else {
        track.track.disable();
      }
    });

  };

  // to enable disable video
  const valuechange_video = () => {
    setoffvideo(!offvideo);
    room.localParticipant.videoTracks.forEach((track) => {
      if (offvideo) {
        track.track.enable();
      } else {
        track.track.disable();
      }
    });
  };

  // side drawer methods

  const handleDrawerOpen = () => {
    setOpen(!open);
    setsearchbox("");
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setsearchbox("");
  };

  // to get participant list
  useEffect(() => {
    setparticipants_count(participants?.length);

    if (participants.length >> 0) {
      setparticipants_audio_status(
        participants.map((data) => {
          return Array.from(data.audioTracks.values());
        })
      );
    }
    // to fix mobile mute unmute issue
    if (participants?.length) {
      // Auto_mute_unmute()

    }
  }, [participants]);

  // to fix cross platform join issue
  const Auto_mute_unmute = () => {

    room.localParticipant.audioTracks.forEach((track) => {
      if (track.isTrackEnabled) {
        track.track.disable();
      }
      else {
        track.track.enable()
      }

    });
    setTimeout(() => {
      Auto_mute_unmute_part2()

    }, 100);

  }
  const Auto_mute_unmute_part2 = () => {
    room.localParticipant.audioTracks.forEach((track) => {
      if (track.isTrackEnabled) {
        track.track.disable();
      }
      else {
        track.track.enable();
      }
    });

  }

  //  popup methods
  const handleClose = () => {
    setpopup_status(false);
    setcan_leave_page(true);
  };
  const handleClose_network_popup = () => {
    setpopup_for_net_lost(false)
    handleLogout()
  }
  const handleClose_leave_popup = () => {
    setleave_popup(false);
    clearInterval(sessionStorage.getItem("reverse_count_verb"));
    setreverse_count(59)
    setrandom_varaible(12);

  }
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  room.on("trackSubscribed", (track) => {
    track.on("enabled", () => {
      setrefresh_status(Math.floor(Math.random() * 61));
    });
    track.on("disabled", () => {
      setrefresh_status(Math.floor(Math.random() * 67));
    });
  });

  // to detect dominantSpeakerChanged
  room.on("dominantSpeakerChanged", (participant) => {

    participants.map((data) => {
      const participent_ = document.getElementById(data.sid);
      if (participent_) {
        participent_.classList.remove("dominant-speaker");
      }
    });
    if (participant) {
      const participent_to_highlight = document.getElementById(participant.sid);
      if (participent_to_highlight) {
        participent_to_highlight.classList.add("dominant-speaker");
      }
    }
  });
  // to clear room when click on side menu
  useEffect(() => {
    const footerclass = document.getElementById("kt_footer");
    if (footerclass) {
      footerclass.classList.add("hidefooter");
    }
    const sidemenu = document.getElementById("kt_body");
    if (sidemenu) {
      sidemenu.classList.add("aside-minimize");
    }
    const togglebutton = document.getElementById("kt_aside_toggle");
    if (togglebutton) {
      togglebutton.classList.add("active");
    }
    const header_part = document.getElementById("kt_header");
    const header_part_logo = document.getElementById("kt_brand");
    // to remove white space form top
    const kt_wrapper = document.getElementById("kt_wrapper");
    const user_wrap = document.getElementById("user-wrap");
    const room_participant = document.getElementById("room");

    if (header_part) {
      header_part.classList.add("hidefooter");
      header_part_logo.classList.add("hidefooter");

      kt_wrapper.classList.add("header-pt");
      user_wrap.classList.add("user-wrap-height");
      room_participant.classList.add("drawer-height");
    }
    return () => {
      room.localParticipant.tracks.forEach((trackPub) => {
        trackPub.track.stop();
      });
      room.disconnect();
      if (footerclass) {
        footerclass.classList.remove("hidefooter");
        header_part.classList.remove("hidefooter");
        header_part_logo.classList.remove("hidefooter");

        kt_wrapper.classList.remove("header-pt");
        user_wrap.classList.remove("user-wrap-height");
        room_participant.classList.remove("drawer-height");
      }
      if (sidemenu) {
        sidemenu.classList.remove("aside-minimize");
      }
      if (togglebutton) {
        togglebutton.classList.remove("active");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // rough
  const mute_participant = (sid) => {
    room.participants.forEach((track) => {

      if (track.sid === sid) {
        // const audio_status2 = Array.from(track.audioTracks.values());

        track.audioTracks.forEach((val) => {
          val.track.enable();
          val.track.isEnabled = false;
          val.track.disable();
        });
      }
    });
  };
  // design class for participant count
  useEffect(() => {
    if (participants_count === 5) {

      document.getElementById("change-layout").classList.add("six-participant");
    } if (participants_count !== 5) {

      document.getElementById("change-layout").classList.remove("six-participant");
    }
    sessionStorage.setItem("participants_count", participants_count)


  }, [participants_count])

  let intervalId
  // to show popup when no one is in call
  useEffect(() => {

    if (participants_count === 0) {
      intervalId = setInterval(() => {
        setCount(prevCount => {
          if (prevCount === 1140) {
            clearInterval(intervalId);
            setleave_popup(true);
            Reverse_count_method()
          }
          return prevCount + 1
        });

      }, 1000);
    }




    return () => {
      clearInterval(intervalId);
      setCount(0)
    }

  }, [participants_count, random_varaible]);
  let reverse_count_verb;
  const Reverse_count_method = () => {
    reverse_count_verb = setInterval(() => {
      setreverse_count(rev => {
        if (rev === 1) {
          handleLogout_method()
        }
        return rev - 1
      })
    }, 1000);
    sessionStorage.setItem("reverse_count_verb", reverse_count_verb)
  }



  // method for twilio biling
  useEffect(() => {
    Get_tocken("StartTime")
  }, [])
  // we need token first to call api
  const Get_tocken = (date_type) => {

    get_ctms_token()
      .then((value) => {
        Get_tocken_part2(value.data?.SessionKey, date_type)


      })
      .catch((error) => { })
  }

  const Get_tocken_part2 = (key, date_type) => {
    // let roomsid = room.sid
    // on hold
    // Twilio_billing(key, roomsid, date_type).then(val => { }).catch(err => { })

  }

  // on click of leave button
  const handleLogout_method = () => {
    Get_tocken("EndTime")
    if (participants_count == 0) {
      twilio_call_end_status_change().then(data => { handleLogout() }).catch(err => { handleLogout() })
    } else {
      handleLogout()
    }


  }
  // if user closes tab
  useEffect(() => {

    const tidyUp = (event) => {

      if (event.persisted) {
        return
      }
      Get_tocken("EndTime")
      if (sessionStorage.getItem("participants_count") == 0) {
        twilio_call_end_status_change().then(data => { handleLogout() }).catch(err => { handleLogout() })
      } else {

        handleLogout()

      }

    }
    // when networl lost happans this will execute
    const network_lost = () => {
      setpopup_for_net_lost(true)
      setTimeout(() => {
        handleClose_network_popup()
      }, 4000);

    }
    window.addEventListener("pagehide", tidyUp)
    window.addEventListener("beforeunload", tidyUp)
    window.addEventListener("offline", network_lost)

    return () => {
      window.removeEventListener("pagehide", tidyUp)
      window.removeEventListener("beforeunload", tidyUp)
    }
  }, [])



  useEffect(() => {
    const windowFunctionBeforeLoad = (event) => {
      if (event.persisted) {
        return
      }
      // this api gives count of user
      getTwilioUserCount(sessionkey, roomNameTrim).then((data) => {
        if (data.data < 2 || data.data === "") {
          console.log("inside")
          twilio_call_end_status_change().then(data => { handleLogout() }).catch(err => { handleLogout() })
        }
      }).catch((err) => { })
    }

    window.addEventListener("pagehide", windowFunctionBeforeLoad)
    window.addEventListener("beforeunload", windowFunctionBeforeLoad)

    return () => {
      window.removeEventListener("pagehide", windowFunctionBeforeLoad)
      window.removeEventListener("beforeunload", windowFunctionBeforeLoad)
    }
  }, [])





  return (
    <div className="room" id="room">
      <Prompt
        when={can_leave_page}
        message="Are you sure you want to leave the call?"
      />
      <div className="d-flex justify-content-between align-items-center video-top-header">
        <h2 className="study-name">{event_title}</h2>
        <div className="timer-wrap">
          <img
            src="/media/misc/timer-clock.svg"
            className="icon"
            alt="unable to load"
          />
          <TimerForTwilio />
        </div>

        <div className="right-content">
          <div className="tooltip-show">

            <span
              className='Custom-tooltip show-member '

            >  {open ? "Hide Participants" : "Show Participants"}
            </span>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <img src="/media/misc/participant.svg" className="icon" alt="unable to load" />
              <span className="badge badge-light">
                {participants_count + 1}
              </span>
            </IconButton>

          </div>
          <div className="tooltip-show">

            <span
              className='Custom-tooltip '

            > {mutevalue ? "Unmute" : "Mute"}
            </span>
            <button
              className="option-button mute"
              onClick={valuechange_audio}
            >
              <img
                src={`${mutevalue
                  ? "/media/misc/mute-voice.svg"
                  : "/media/misc/mice.svg"
                  }`}
                className="icon"
                alt="unable to load"
              />
            </button>

          </div>
          <div className="tooltip-show">

            <span
              className='Custom-tooltip '

            >  {offvideo ? "Video on" : "Video off"}
            </span>
            <button
              className="option-button video-off"
              onClick={valuechange_video}
            >
              <img
                src={`${offvideo
                  ? "/media/misc/off-video.svg"
                  : "/media/misc/on-video.svg"
                  }`}
                className="icon"
                alt="unable to load"
              />
            </button>

          </div>
          <button
            className="join-button leave-btn"
            onClick={() => {
              setpopup_status(true);
              setcan_leave_page(false);
            }}
          >
            <img src="/media/misc/leave.svg" alt="unable to load" />
            Leave
          </button>
        </div>
      </div>

      <div className={classes.root}>
        <CssBaseline />

        <main
          style={{ padding: 0 }}
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div id="user-wrap" className="main-wapper">
            <div className="column">
              <div className="first_column">
                <div
                  id="change-layout" className={`remote-participants ${participants_count > 4 ? "last-participant" : ""
                    }
                    ${participants_count > 2 ? "mobile-view" : ""
                    }`}
                >
                  {participants_count === 0 ? (
                    <div className="one-participant">
                      <img src="/media/misc/userphoto.png" alt="unable to load" />
                      <h1>Waiting for participants to join.</h1>
                    </div>
                  ) : (
                    <>
                      {participants.map((participant, index) => {
                        return [
                          <React.Fragment key={index}>
                            {index < 8 && participant.state === "connected" ? (
                              <Participant
                                key={participant.sid}
                                id={participant.sid}
                                participant={participant}
                                type={2}
                                classname_={"showthis_participant" + index}
                                mute_participant={mute_participant}
                                audio_level={audio_level}
                              />
                            ) : (
                              ""
                            )}
                          </React.Fragment>,
                        ];
                      })}
                    </>
                  )}
                </div>
              </div>
              <div className="local-participant">
                {room ? (
                  <>
                    <Participant
                      key={room.localParticipant.sid}
                      participant={room.localParticipant}
                      isvideo_on={false}
                      type={1}
                      audio_level={audio_level}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>

              {participants_count > 8 ? (
                <div className="multiple-participant second_column">
                  <div className="participant_list">
                    {participants.map((participant, index) => {
                      return [
                        <>
                          {index > 7 ? (
                            <div className="participant_height">
                              <Participant
                                key={participant.sid}
                                id={participant.sid}
                                participant={participant}
                                type={2}
                                classname_={"showthis_participant" + index}
                                mute_participant={mute_participant}
                                audio_level={audio_level}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </>,
                      ];
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <h4 className="m-0 pl-5 text-white">Participants</h4>
            <IconButton onClick={handleDrawerClose} className="close-icon">
              <img src="/media/misc/cross.svg" className="close-icon" alt="unable to load" />
            </IconButton>
          </div>
          <Divider />
          <div className="search-participant">
            <input
              type="search"
              className="form-control"
              placeholder="Search Participants"
              value={searchbox}
              onChange={(e) => {
                setsearchbox(e.target.value);
              }}
            />
            <button
              type="button"
              className="close-icon-btn"
              onClick={() => {
                setsearchbox("");
              }}
            >
              <img src="/media/misc/cross.svg" className="close-icon" alt="unable to load" />
            </button>
          </div>
          <List>
            <>
              {participants
                .filter((v) => {
                  return v.identity
                    ?.toLowerCase()
                    .includes(searchbox.toLowerCase());
                })
                .map((data, index) => {
                  let name = data.identity.split(" ");
                  let name_full = data.identity.split("}");

                  return [
                    <div className="participant-list" key={index}>
                      <div className="participant-name">
                        <span className="default-pic">
                          {name[0][0]?.toUpperCase()}
                          {name[name.length - 3][0]?.toUpperCase()}
                        </span>
                        <h5 className="text-white">
                          {" "}
                          {name_full[0]}

                        </h5>
                      </div>
                      <div className="mice-icon">
                        <img
                          src={`${!participants_audio_status[index]?.[0]
                            ?.isTrackEnabled
                            ? "/media/misc/mute-voice.svg"
                            : "/media/misc/mice.svg"
                            }`}
                          alt="unable to load"
                        />
                      </div>
                    </div>,
                  ];
                })}

              <div className="participant-list horizantanl-line">
                <div className="participant-name">
                  <span className="default-pic">
                    {local_participant_name[0][0]?.toUpperCase()}
                    {local_participant_name[local_participant_name.length - 3][0]?.toUpperCase()}
                  </span>
                  <h5 className="text-white horizantal-line">
                    {local_participant_name_full[0]}
                  </h5>
                </div>
                <div className="mice-icon">
                  <img
                    src={`${!Local_p_audio_status
                      ? "/media/misc/mute-voice.svg"
                      : "/media/misc/mice.svg"
                      }`}
                    alt="unable to load"
                  />
                </div>
              </div>
            </>
          </List>
        </Drawer>
      </div>

      <div>
        {/* popup */}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={popup_status}
          className="log-out-wrap"
        >
          <DialogContent dividers className="middle-content video-logout">
            <Typography gutterBottom className="logut-content">
              Are you sure you want to Leave?
            </Typography>
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button
                className="btn btn-danger cancel-button w-80 mr-6"
                onClick={handleClose}
                to="#"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleLogout_method}
                className="btn btn-primary logout-btn w-80"
              >
                Leave
              </button>
            </div>
          </DialogActions>
        </Dialog>


        {/* leave popup start one member*/}

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={leave_popup}
          className="log-out-wrap"
        >
          <DialogTitle className="popup-heading text-center">Action Required  <b>{reverse_count} s</b> </DialogTitle>
          <DialogContent dividers className="text-center" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "15px" }}>
            <Icon
              className="fa fa-exclamation-triangle"
              style={{
                marginBottom: -4,
                fontSize: 17,
                marginLeft: 5,
                width: 20,
                color: "#ff5858",
              }}
            />
            <h4 className="success-text font-weight-bold success-head session-text">
              You seem to be alone in conference. Do you want to exit the conference?
            </h4>

          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button
                className="btn btn-danger cancel-button w-80 mr-6"
                onClick={handleClose_leave_popup}

              >
                I'll Stay
              </button>
              <button
                type="submit"
                onClick={handleLogout_method}
                className="btn btn-primary logout-btn"
              >
                Exit (<b>{reverse_count}s</b>)
              </button>
            </div>
          </DialogActions>
        </Dialog>
        {/* network lost popup  */}

        <Dialog
          onClose={handleClose_network_popup}
          aria-labelledby="customized-dialog-title"
          open={popup_for_net_lost}
          className="log-out-wrap"
        >
          <DialogContent dividers className="middle-content video-logout">
            <Typography gutterBottom className="logut-content">
              The network connection is lost, please try to connect again after some time.
            </Typography>
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button
                className="btn btn-danger cancel-button w-80 mr-6"
                onClick={handleClose_network_popup}
              >
                ok
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Room;
