
import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const NumericType = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    const main_comp = props.main_comp
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    let index_ = props.index
    
    const [isFieldHidden, setIsFieldHidden] = useState(false)

    
    let radio_id
    let radio_value
    if (main_comp) {
        radio_id = main_comp.Children[1].UUID
        radio_value = form_value[radio_id]
    }

    const numCheck = (e) => {
        const characters = [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            '~', '`', '!', '@', '#', '$', '%', '^', '&', '*', ')', '(', '-', '=', '_', '+',
            '>', '<', '?', '/', ':', ';', '|', '\\', ']', '}', '[', '{', "'", '"', ' '
        ];
        // eslint-disable-next-line eqeqeq
        if (children_elements?.Meta?.DecimalPlaces == "0" && e.key == ".") e.preventDefault();
        if (characters.includes(e.key)) e.preventDefault();
    }

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    // onchangeHandler
    const numeric_onchange_handler = (e) => {
        let after_decimal = e.target.value.split(".")?.[1]

        if (after_decimal) {
            let after_decimal_arr = after_decimal.split("")
            if (after_decimal_arr.length > children_elements?.Meta?.DecimalPlaces) {
                e.preventDefault();
                return;
            }
        }

        props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, main_comp, props.rowid)
    }
    return (
        !isFieldHidden ? <>

            <div className="input_label"> <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
            {main_comp?.Type === "drug_accountability" ?

                <div className="helptext-wrap">
                    <div className="input_wrap">
                        <div className="width flexwidth">
                            <input type="number"
                                className="width"
                                placeholder={children_elements.Meta.Placeholder}
                                required={children_elements.Meta.Required}
                                min={children_elements.Meta.Min}
                                max={children_elements.Meta.Max}
                                readOnly={index_ === 5}                               
                                value={form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                                name={children_elements.UUID}                              
                                onKeyDown={(evt) => numCheck(evt)}
                                onBlur={(e) => props.ON_blur_inputfield(e, "inputfield_numeric", children_elements.UUID, null, children_elements)}
                                onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, main_comp) }}
                            />
                            <p className="help_text">{children_elements.Meta?.FieldText}</p>
                            <p className="help_text">{children_elements.Meta?.Note} </p>
                        </div>
                    </div>
                </div>
                :
                <div className="helptext-wrap">
                    <div className="input_wrap">
                        {main_comp ? <div className="width flexwidth"> <input type="number" className="width"
                            placeholder={children_elements.Meta.Placeholder}
                            required={children_elements.Meta.Required}
                            min={children_elements.Meta.Min}
                            max={children_elements.Meta.Max}
                            onKeyDown={(evt) => numCheck(evt)} 
                            readOnly={index_ === 4}                           
                            value={form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                            name={children_elements.UUID}
                            onBlur={(e) => props.ON_blur_inputfield(e, "inputfield_numeric", children_elements.UUID, null, children_elements)}                          
                            onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, main_comp) }} />
                            {index_ === 2 && <p>  {(radio_value === "standard") ? <span className="help_text">inches</span> : <span className="help_text">{children_elements.Meta?.FieldText}</span>} </p>}
                            {index_ === 3 && <p>  {(radio_value === "standard") ? <span className="help_text">lbs</span> : <span className="help_text">{children_elements.Meta?.FieldText}</span>}</p>}

                            <p className="help_text">{children_elements.Meta?.Note} </p>
                           
                            {index_ === 4 && form_value[children_elements.UUID] ? <div className="clear_btn">
                                <img src="/media/misc/clear-.svg" className="copy-icon" alt="unable to load" title="Clear" onClick={(e) => { props.ON_change_inputfield(e, "clear", null, null, children_elements?.Parent, null, main_comp) }} />
                            </div> : ""}
                        </div>
                            :
                            <div className="width flexwidth">
                                <input type="number"
                                    className="width"
                                    placeholder={children_elements.Meta.Placeholder}
                                    required={children_elements.Meta.Required}
                                    min={children_elements.Meta.Min}
                                    max={children_elements.Meta.Max}
                                    onKeyDown={(evt) => numCheck(evt)}
                                    value={commonId ? form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                                    name={children_elements.UUID}
                                    onBlur={(e) => props.ON_blur_inputfield(e, "inputfield_numeric", children_elements.UUID, null, children_elements, props.rowid)}
                                    onChange={(e) => { numeric_onchange_handler(e) }}
                                    
                                />
                                <p className="help_text">{children_elements.Meta?.FieldText}</p>
                                <p className="help_text">{children_elements.Meta?.Note} </p>
                            </div>

                        }

                    </div>
                </div>




            }















            {/* 
            <div className="helptext-wrap">
                <div className="input_wrap">
                    {main_comp ? <div className="width"> <input type="number" className="width"
                        placeholder={children_elements.Meta.Placeholder}
                        required={children_elements.Meta.Required}
                        min={children_elements.Meta.Min}
                        max={children_elements.Meta.Max}
                        readOnly={index_ === 4}
                        value={form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : form_value[children_elements.UUID]}
                        name={children_elements.UUID}
                        onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, main_comp) }} />
                        {index_ === 2 && <p>  {(radio_value === "standard") ? <p className="help_text">inches</p> : <p className="help_text">{children_elements.Meta?.FieldText}</p>} </p>}
                        {index_ === 3 && <p>  {(radio_value === "standard") ? <p className="help_text">lbs</p> : <p className="help_text">{children_elements.Meta?.FieldText}</p>}</p>}

                        <p className="help_text">{children_elements.Meta?.Note} </p>
                        {index_ === 4 && <div className="clear_btn">
                            <button type="button" title="Clear" onClick={(e) => { props.ON_change_inputfield(e, "clear", null, null, children_elements?.Parent, null, main_comp) }}>Clear</button>
                        </div>}
                    </div>
                        :
                        <div className="width">
                            <input type="number"
                                className="width"
                                placeholder={children_elements.Meta.Placeholder}
                                required={children_elements.Meta.Required}
                                min={children_elements.Meta.Min}
                                max={children_elements.Meta.Max}
                                value={form_value[children_elements?.UUID]?.[commonId] ? form_value[children_elements?.UUID]?.[commonId] : form_value[children_elements.UUID]}
                                name={children_elements.UUID}
                                onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, main_comp) }} />
                            <p className="help_text">{children_elements.Meta?.FieldText}</p>
                            <p className="help_text">{children_elements.Meta?.Note} </p>
                        </div>

                    }




                </div>
            </div> */}

        </> : ""
    )
}

