import React from "react";
import { Footer } from "./footer/Footer";
import { AdminHeader } from "../../../app/pages/Admin_pages/Admin_header";



export function Admin_layout({ children }) {


    return (
        <>


            <AdminHeader />
            <div className="container">
                {children}
            </div>
            <div className="admin-footer">
                <Footer />
            </div>

        </>
    )
}
