import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Dashboard_invite_api, get_ctms_token } from "../modules/Auth/_redux/authCrud"
import { Spinner } from "react-bootstrap"
import { toAbsoluteUrl } from "../../_common/_helpers"
export const Site_info = () => {
  const dispatch = useDispatch()
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const [study_api_data, setstudy_api_data] = React.useState([])
  const logo = useSelector((state) => state.auth.logo, shallowEqual)
  const siteinfo = useSelector((state) => state.auth.siteinfo, shallowEqual)
  let end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const [lodaing_status, setlodaing_status] = useState(true)
  const logo_ = end_point_url + logo

  // to set site info
  useEffect(() => {
    if (siteinfo) {
      setstudy_api_data(siteinfo)
      setlodaing_status(false)
    }
  }, [siteinfo])

  useEffect(() => {
    if (studies_ && studies_?.length === 0) {
      setlodaing_status(false)
    }
  }, [studies_])

  // calling invite api
  useEffect(() => {
    if (!studies_) {
      apicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])
  // calling invite api  to resolve refresh issue
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        dispatch(auth.actions.setstudies_action(value.data[0].studies))
      })
      .catch((error) => { })
  }

  // to get sesson key which need for api call
  useEffect(() => {
    if (studies_?.length > 0) {
      const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))

      get_ctms_token().then((value) => {
        const combinedata = {
          key: value.data,
          dropdown_data: dropdown_data,
        }
        dispatch(auth.actions.request_siteInfo(combinedata))

        // setspinner_loading(true)
      }).catch(err => {  })


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  return (
    <>
      <div className="middle-content middle-content-wrap ">
        {lodaing_status ? (
          <div className="text-center">
            <Spinner animation="border" className="spinner-info" variant="primary" />
          </div>
        ) : (
          <>
            {study_api_data?.map((value, index) => {
              return [
                <div className="site-info site-info-wrap" key={index}>
                  <div className="site-info-title border-0">
                    <div className="site-info-content">
                      <img src={logo ? `${logo_}` : `/media/logos/logo.png`} className="site-logo" alt="" />

                      {(value.SiteLocationShortName === "" || value.SiteLocationShortName === null) &&
                        (value.SiteName === "" || value.SiteName === null) ? (
                        ""
                      ) : (
                        <>
                          {value.SiteLocationShortName === "" || value.SiteLocationShortName === null ? (
                            <h2>
                              Site: <span>{value.SiteName}</span>{" "}
                            </h2>
                          ) : (
                            <h2>
                              Site:<span>{value.SiteLocationShortName}</span>{" "}
                            </h2>
                          )}
                        </>
                      )}
                      {(value?.Address1 === "" || value?.Address1 === null) &&
                        (value?.Address2 === "" || value?.Address2 === null) &&
                        (value?.State === "" || value?.State === null) &&
                        (value?.City === "" || value?.City === null) &&
                        (value?.ZipCode === "" || value?.ZipCode === null) ? (
                        "  "
                      ) : (
                        <p>
                          <span>Site Address:</span>
                          {value?.Address1}
                          {value.Address1 && (value.Address2 || value?.State || value?.City || value?.ZipCode)
                            ? ", "
                            : ""}
                          {value?.Address2}
                          {value.Address2 && (value?.City || value?.State || value?.ZipCode) ? ", " : ""}
                          {value?.City}
                          {value.City && (value?.State || value?.ZipCode) ? ", " : ""}
                          {value?.State}{value.State && value?.ZipCode ? ", " : ""}
                          {value?.ZipCode}
                        </p>
                      )}

                      <div className="site-info-mail">
                        {value?.PrimaryEMail === "" || value?.PrimaryEMail === null || value?.PrimaryEMail === undefined ? (
                          ""
                        ) : (
                          <div className="site-info-mailbox">
                            <img src="/media/misc/email.png" alt="" />

                            <a href={`mailto:${value?.PrimaryEMail}`}>{value.PrimaryEMail}</a>

                            <br />
                          </div>
                        )}
                        {value?.Phone1 === "" || value?.Phone1 === null || value?.Phone1 === undefined ? (
                          ""
                        ) : (
                          <div className="site-info-mailbox without-border">
                            <img src="/media/misc/phone-call.png" alt="" />
                            <span>
                              <a href={`tel:${value?.Phone1}`}>{value?.Phone1}</a>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {(value?.DisplayedOnPatientPortal === null || value?.DisplayedOnPatientPortal === "") &&
                    (value?.Indication === "" || value?.Indication === null) &&
                    (value?.Protocol === "" || value?.Protocol === null) &&
                    (value?.Sponsor === "" || value?.Sponsor === null) &&
                    (value?.Description === "" || value?.Description === null) &&
                    (value?.FriendlyDescription === "" || value?.FriendlyDescription === null) ? (
                    ""
                  ) : (
                    <div className="site-info-title border-top-title">
                      <div className="site-info-left">
                        <p>Study Info</p>
                      </div>
                      <div className="site-info-right">
                        {value?.DisplayedOnPatientPortal === null || value?.DisplayedOnPatientPortal === "" ? (
                          value?.Indication && <p>
                            Indication: <span>{value?.Indication}</span>
                          </p>
                        ) : (
                          <p>
                            Indication: <span>{value?.DisplayedOnPatientPortal}</span>
                          </p>
                        )}
                        {value?.Protocol === "" || value?.Protocol === null ? (
                          ""
                        ) : (
                          <p className="side-border">
                            Protocol: <span>{value?.Protocol}</span>
                          </p>
                        )}
                        {value?.Sponsor === "" || value?.Sponsor === null ? (
                          ""
                        ) : (
                          <p>
                            Sponsor: <span>{value?.Sponsor}</span>
                          </p>
                        )}
                        {(value?.Description === "" || value?.Description === null) &&
                          (value?.FriendlyDescription === "" || value?.FriendlyDescription === null) ? (
                          ""
                        ) : (
                          <>
                            {value?.FriendlyDescription === "" || value?.FriendlyDescription === null ? (
                              <p>
                                Description: <span>{value?.Description}</span>
                              </p>
                            ) : (
                              <p>
                                Description: <span>{value?.FriendlyDescription}</span>
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>)}

                  {value.Investigator === "" || value.Investigator === null ? (
                    ""
                  ) : (
                    <div className="site-info-title">
                      <div className="site-info-left">
                        <p>Investigator</p>
                      </div>
                      <div className="site-info-right">
                        <p>
                          Name: <span>{value.Investigator}</span>
                        </p>
                      </div>
                    </div>
                  )}

                  {value.SubInvestigatorsName === "" || value.SubInvestigatorsName === null ? (
                    ""
                  ) : (
                    <div className="site-info-title">
                      <div className="site-info-left">
                        <p> Sub Investigator</p>
                      </div>
                      <div className="site-info-right">
                        <p>
                          Name: <span>{value.SubInvestigatorsName}</span>
                        </p>
                      </div>
                    </div>
                  )}

                  {(value.LeadCoordinatorEmail === null || value.LeadCoordinatorEmail === "") &&
                    (value.LeadCoordinatorName === null || value.LeadCoordinatorName === "") &&
                    (value.LeadCoordinatorPhone === null || value.LeadCoordinatorPhone === "") ? (
                    ""
                  ) : (
                    <div className="site-info-title">
                      <div className="site-info-left">
                        <p> Lead Coordinator</p>
                      </div>
                      <div className="site-info-right">
                        {value.LeadCoordinatorName === "" || value.LeadCoordinatorName === null ? (
                          ""
                        ) : (
                          <p>
                            Name: <span>{value.LeadCoordinatorName}</span>
                          </p>
                        )}

                        <div className="site-info-mail">
                          {value.LeadCoordinatorEmail === "" || value.LeadCoordinatorEmail === null || value?.LeadCoordinatorEmail === undefined ? (
                            ""
                          ) : (
                            <div className="site-info-mailbox">
                              <img src="/media/misc/email.png" alt="" />
                              <span>
                                <a href={`mailto:${value?.LeadCoordinatorEmail}`}>
                                  {value.LeadCoordinatorEmail}
                                </a>
                              </span>
                            </div>
                          )}
                          {value.LeadCoordinatorPhone === "" || value.LeadCoordinatorPhone === null || value?.LeadCoordinatorPhone === undefined ? (
                            ""
                          ) : (
                            <div className="site-info-mailbox without-border">
                              <img src="/media/misc/phone-call.png" alt="" />
                              <span>
                                <a href={`tel:${value?.LeadCoordinatorPhone}`}>
                                  {value.LeadCoordinatorPhone}
                                </a>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>,
              ]
            })}

            {study_api_data?.length === 0 || study_api_data === undefined ? (
              <div className="dash-top">
                {" "}
                <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="" />
                <p>No site data found</p>{" "}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  )
}
