import React, { useState, useEffect } from "react"
import { Get_dropdown_value_ctmsform, get_ctms_token } from "../../modules/Auth/_redux/authCrud"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Dropdowntype = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    const [dropdown_value, setdropdown_value] = useState([])
    // const dropdown_login_info = JSON.parse(localStorage.getItem("dropdown_login_info"))
    let checkClearButton = commonId ? form_value[children_elements.UUID]?.[commonId] : form_value[children_elements.UUID]
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    const api_call_present = children_elements.Meta.DataSources?.ajax
    // to detect ios devices
    let is_ios_device = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)


    if (api_call_present) {

    }

    useEffect(() => {
        if (api_call_present) {
            Get_dropdown_value(api_call_present)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api_call_present])

    const Get_dropdown_value = (url) => {

        get_ctms_token().then(value => { Get_dropdown_value_part2(value.data, url) }).catch(err => { })
    }

    const Get_dropdown_value_part2 = (key, url) => {

        Get_dropdown_value_ctmsform(key, url).then(value => { setdropdown_value(value.data.records) }).catch(err => { })
    }

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>
            {api_call_present ? <div className="main_container">
                <div className="input_label">
                    <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>  {children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
                <div className="input_wrap">
                    <select name={children_elements.UUID}
                        value={form_value[children_elements.UUID]?.[commonId] ? form_value[children_elements.UUID]?.[commonId] ? form_value[children_elements.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                        onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent) }}

                    >
                        <option value="">--select--</option>


                        {dropdown_value.map((check_data, index) => {
                            return [<React.Fragment key={index}>
                                {api_call_present === "/api/v1/medical-history/body-systems" && <option value={check_data.SystemID}>{check_data.System}</option>}
                                {api_call_present === "/api/v1/medical-history/conditions" && <option value={check_data.SystemID}>{check_data.System}</option>}

                                {api_call_present === "/api/v1/medical-history/surgeries" && <option value={check_data.Condition}>{check_data.Condition}</option>}

                                {api_call_present === "/api/v1/medical-history/treatment" && <option value={check_data.MedicationClassificationID}>{check_data.Medication}</option>}
                                {api_call_present === "/api/v1/medical-history/dose" && <option value={check_data.Dose}>{check_data.Dose}</option>}
                                {api_call_present === "/api/v1/medical-history/medication-route" && <option value={check_data.ID}>{check_data.Route}</option>}
                                {api_call_present === "/api/v1/medical-history/medication-regimen" && <option value={check_data.ID}>{check_data.Regimen}</option>}

                                {api_call_present === "/api/v1/medical-history/social-conditions" && <option value={check_data.Condition}>{check_data.Condition}</option>}


                            </React.Fragment>

                            ]
                        })}
                    </select>
                </div>

            </div> :
                <div className="main_container">
                    <div className="input_label">
                        <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>  {children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
                    <div className="input_wrap ">
                        <div className="select-box-wrap">
                            <select required name={children_elements.UUID}
                                value={commonId ? form_value[children_elements.UUID]?.[commonId] ? form_value[children_elements.UUID]?.[commonId] : "" : form_value[children_elements.UUID] ? form_value[children_elements.UUID] : ""}
                                onChange={(e) => { props.ON_change_inputfield(e, "inputfield", null, null, children_elements?.Parent, null, null, props.rowid) }}
                            >

                                {children_elements.Meta.EmptyOption?.label ? <option value="" >{children_elements.Meta.EmptyOption?.label}</option> : <option value="" >{" "}</option>}
                                {children_elements.Meta.DataSources.options?.map((check_data, index) => {
                                    return [<React.Fragment key={index}>
                                        {(children_elements.Meta.DataSources.options[0]?.label || children_elements.Meta.DataSources.options[0]?.id) ? <option value={check_data?.value ? check_data?.value : check_data.id}>{check_data.label ? check_data.label : check_data.id}</option>
                                            : <option value={check_data}>{check_data}</option>
                                        }
                                    </React.Fragment>

                                    ]
                                })}
                            </select>
                            {is_ios_device && <img src="/media/misc/down-arrow.svg" alt="unable to load" className="select-icon" />}
                        </div>
                        {checkClearButton ? <div className="clear-text">
                            <span title="Clear" onClick={() => { props.clear_fields(children_elements.UUID, props.rowid) }}>Clear</span>
                        </div> : ""}
                    </div>

                </div>}


        </> : ""
    )
}


