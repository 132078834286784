import React, {useState, useEffect} from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Date_time_type = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    const current_date_ = props.current_date_
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
   
    let checkClearButton = commonId ? form_value[children_elements.UUID]?.[commonId] : form_value[children_elements.UUID]
    
    let isAndroid = /(android)/i.test(navigator.userAgent);
    let isIosDevice = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    const [isFieldHidden, setIsFieldHidden] = useState(false)

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    return (
        !isFieldHidden ? <>


        <div className="input_label">
            <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}
        </div>
        <div className="input_wrap date_time_wrap">
            {children_elements?.Children?.map((children_elements_, index) => {
                let checkbox_status
                let full_date_value
                let full_date_value_aftertrim

                if (commonId) {
                    full_date_value = form_value[children_elements_?.Parent]?.[commonId]
                }
                else {
                    full_date_value = form_value[children_elements_?.Parent]

                }
                if (full_date_value) {
                    full_date_value_aftertrim = full_date_value.split("/")
                    full_date_value_aftertrim[5] ? checkbox_status = true : checkbox_status = false
                } else {
                    checkbox_status = false

                }

                let fieldValue = commonId ? form_value[children_elements_.UUID]?.[commonId] ? form_value[children_elements_.UUID]?.[commonId] : "" : form_value[children_elements_.UUID] ? form_value[children_elements_.UUID] : ""

                return [
                    <React.Fragment key={index}>
                        {children_elements_.Meta.Hidden === false && children_elements_.Type === "date" ? <div className="input_date">
                            <label className="label_" htmlFor="">{children_elements_.Meta.Label}</label>
                            <div className="Date_picker_icon-wrap">

                                <input
                                    // className={`date-field ${isIosDevice && !fieldValue ? "show-date-placeholder" : ""}`}
                                    id="date-calendar"
                                    name={children_elements_.UUID}
                                    type="date"
                                    required={children_elements_.Meta.Required}
                                    min={children_elements.Meta?.Past ? "1950-11-15" : current_date_}
                                    max={children_elements.Meta?.Future ? "2050-05-11" : current_date_}                                    
                                    value={commonId ? form_value[children_elements_.UUID]?.[commonId] ? form_value[children_elements_.UUID]?.[commonId] : "" : form_value[children_elements_.UUID] ? form_value[children_elements_.UUID] : ""}
                                    onChange={(e) => { props.ON_change_inputfield(e, "inputfield_date_time", children_elements_.Parent, "date", children_elements?.Parent, null, null, props.rowid) }}
                                    onBlur={(e) => { props.ON_blur_inputfield(e, "inputfield_date_time", children_elements.UUID, "date", children_elements, props.rowid) }}
                                    // placeholder="dd/mm/yyyy"
                                /> 
                                 <img  onClick={()=> document.getElementById("date-calendar").showPicker()} src="/media/misc/picker_calendar.svg" className="picker-image bg-white" alt="unable to load" />
                            </div></div> : ""}

                        {children_elements_.Meta.Hidden === false && children_elements_.Type === "time" ? <div className="input_time Date_picker_icon-wrap">
                            <label className="label_" htmlFor="">{children_elements_.Meta?.Label}</label>
                            <input
                                className=""
                                id="time-picker"
                                name={children_elements_.UUID}
                                type="time"
                                placeholder="Time"
                                max={children_elements_.Meta?.MaxDate}
                                min={children_elements_.Meta?.MinDate}
                                required={children_elements_.Meta?.Required}
                                value={commonId ? form_value[children_elements_.UUID]?.[commonId] ? form_value[children_elements_.UUID]?.[commonId] : "" : form_value[children_elements_.UUID] ? form_value[children_elements_.UUID] : ""}
                                onChange={(e) => { props.ON_change_inputfield(e, "inputfield_date_time", children_elements_.Parent, "time", children_elements?.Parent, null, null, props.rowid) }}
                               
                            />
                             <img onClick={()=> document.getElementById("time-picker").showPicker()}  src="/media/misc/time-clock.svg" className="picker-image" alt="unable to load" />

                        </div> : ""}
                        <div className="auto_clear">
                        {/* autofill time date data button */}
                            {index === 1 ? <div className="mb-0 image_wrap">{<div className="clear_btn">
                                
                                <img src="/media/misc/autofill.svg" className="copy-icon" alt="unable to load" title="Auto Fill"  onClick={() => { props.Auto_fill_value(children_elements_.Parent, children_elements, "date_time", props.rowid) }} />
                            </div>} </div> : ""}
                        {/* clear time date data button */}
                            {checkClearButton && index === 1 ? <div className="mb-0 image_wrap">{<div className="clear-text">
                               <span title="Clear" onClick={(e) => { props.Clear_data_for_date_and_time(e,children_elements_.Parent, children_elements, "date_time", props.rowid) }} >Clear</span>
                                
                            </div>} </div> : ""}
                            </div>
                            {index === 1 && children_elements.Meta.SupportOngoing === true ? <p className="mb-0 ongoing-check_inner"><input type="checkbox" className="ongoing-checkbox"
                                value={1}
                                onChange={(e) => { props.Clear_data_for_date_and_time(e, children_elements_.Parent, children_elements, "Ongoing",  props.rowid) }}
                            checked={checkbox_status}
                           
                            />
                                <label className="label_" htmlFor="vehicle1">Ongoing</label><br></br>

                            </p> : ""}
                            



                    </React.Fragment>
                ]
            })}
        </div>
        </> : ""
    )
}
