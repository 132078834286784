import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const FileMangertype = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    const open_uploaded_file = props.open_uploaded_file

    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]   
    const [index_id, setindex_id] = useState("")
    const [allFiles, setAllFiles] = useState([])
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    const [fieldDisable, setFieldDisable] = useState(false)

    useEffect(() => {
        setindex_id(uuidv4)
    }, [])


    useEffect(() => {
        let filesArray = []
        if (form_value[children_elements.UUID]) {
            if (commonId) {
                filesArray = form_value[children_elements.UUID]?.[commonId]?.Files ? form_value[children_elements.UUID]?.[commonId]?.Files : []
                
                setAllFiles(filesArray)
            } else {
                filesArray = form_value[children_elements.UUID]?.Files ? form_value[children_elements.UUID]?.Files : []
                
                setAllFiles(filesArray)
            }
        } else {
            setAllFiles([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value, children_elements])

     // Check element visibility when form_value changes
     useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])

    // setting the field disable flag
    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (allFiles?.length >= 1 && children_elements.Meta?.Multi == false) {
            setFieldDisable(true)
        } else {
            setFieldDisable(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFiles])

    return (
        !isFieldHidden ? <>
        <div className="main_container width mb-0">
            <div className="input_label">
                <label className="label_" htmlFor="">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
            <div className="input_wrap upload-btn-wrapper">
                <div className="image-wrap_btn">
                    <div>
                        <button className="form_btn">Browse + Upload</button>
                        <input type="file"
                            placeholder={children_elements.Meta.Placeholder}
                            required={children_elements.Meta.Required}
                            title={children_elements.Meta?.Title}
                            name={children_elements.UUID}
                            onChange={(e) => { props.ON_change_inputfield(e, "file", null, null, children_elements?.Parent, null, null, props.rowid, index_id) }}
                            disabled={fieldDisable}
                        />
                    </div>

                    {props.loading_status && index_id === props.index_for_loader && <span className="ml-3 spinner spinner-blue"></span>}
               
                   
                    <div className="mb-0 d-flex align-items-center flex-column ml-sm-0 img-upload-wrap">
                        {allFiles.map((file, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {file.Name ? <div className="mb-2 mr-2 mt-0 image_wrap">
                                        <p
                                            className="open-file"
                                            onClick={() => open_uploaded_file(file?.Hash, file?.Mime, file?.Name)}
                                        >{file.Name}</p>
                                        <div>
                                            <img src="/media/misc/trash-red.svg" className="copy-icon" alt="unable to load" title="Clear"
                                                onClick={() => {
                                                    props.Clear_data(children_elements.UUID, children_elements?.Parent, "itsfile", props.rowid, file.Hash)
                                                }} />
                                        </div>
                                    </div> : null}
                                </React.Fragment>
                            )
                        })}
                    </div>
                   
                </div>
            </div>
            </div>
        </> : ""
    )
}
