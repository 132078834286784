import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_common/layout";
import * as auth from "./authRedux";
import { getUserByToken, Validate_form_link } from "./authCrud";

function AuthInit(props) {
  sessionStorage.setItem("end_point","https://dev.mystudymanager.com/mystudym")
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  let { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const end_point_url = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const colour_them = useSelector((state) => state.auth.colour_them, shallowEqual)

  // storing end point when user opens other window
  useEffect(() => {
    let queryParams_ = new URLSearchParams(window.location.search)
    const pathname = window.location.pathname

    sessionStorage.setItem("end_point", end_point_url)

    // for  esource base url as it coming from mobile
    if (queryParams_.get("is_mob") === "true" && pathname === "/Form_details") {
      const url_for_forms = queryParams_.get("host")
      sessionStorage.setItem("end_point", "https://" + url_for_forms)

    }


  }, [end_point_url]);



  // for theme colour
  useEffect(() => {

    const sidemenu_colur = colour_them?.Branding_Color;
    const link_colour = colour_them?.HighLight_Color;
    const footer_colur = colour_them?.Accent_Color;

    document.documentElement.style.setProperty('--col1-color', sidemenu_colur ? sidemenu_colur : '#0C2339');
    document.documentElement.style.setProperty('--col2-color', link_colour ? link_colour : '#F68723');
    document.documentElement.style.setProperty('--col3-color', footer_colur ? footer_colur : '#21B3C4');


  }, [colour_them]);



  const pathname = window.location.pathname
  const queryParams = new URLSearchParams(window.location.search)
  let PatientCTMSID_ = queryParams.get("PatientCTMSID_")
  let name_ = queryParams.get("name")
  let CTMSHostLink_ = queryParams.get("CTMSHostLink_")
  // let FormID_ = queryParams.get("FormID_")
  let FormID_portal = queryParams.get("ID_")

  let colourcode = {
    Branding_Color: "#" + queryParams.get("BC"),
    Accent_Color: "#" + queryParams.get("AC"),
    HighLight_Color: "#" + queryParams.get("HC"),
  }

  // let ot = queryParams.get("ot")
  let id = queryParams.get("id")
  let site_id = queryParams.get("site_id")
  let is_mob = queryParams.get("is_mob")
  let SiteID_local_ = queryParams.get("SiteID_local_")
  let form_token = queryParams.get("token")


  // We should request user by authToken before rendering the application
  useEffect(() => {
    sessionStorage.setItem("mobile_form_is_open", "no")

    if (pathname === "/auth/user/site/feedback" || pathname === "/auth/user/VideoCall") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      authToken = undefined
    }
    if (pathname === "/Form_details") {
      if (is_mob === "true") {
        authToken = "ot"
        localStorage.clear()
        localStorage.setItem("CTMSHostLink", "https://" + CTMSHostLink_)
        localStorage.setItem("PatientCTMSID", PatientCTMSID_)
        localStorage.setItem("PatientName", name_)
        sessionStorage.setItem("SiteID_local", SiteID_local_)
        localStorage.setItem("id", id)
        sessionStorage.setItem("mobile_form_is_open", "yes")

        // to set theme colour for esource
        dispatch(props.settheme(colourcode));


      }
    }
    if (pathname === "/dashboard") {
      if (queryParams.get("view") === "secondary") {

        sessionStorage.setItem("is_admin_control", "true")
        sessionStorage.setItem("study_id_for_pp", queryParams.get("study_id_for_pp"))
        // dispatch(props.end_point("https://ca.mystudymanager.com"));

      } else {
        // sessionStorage.setItem("is_admin_control", null)
      }
    }
    const requestUser = async () => {
      try {
        if (!didRequest.current) {

          const { data: user } = await getUserByToken(id, site_id);

          dispatch(props.fulfillUser(user));
        }
      } catch (error) {

        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      // we are checking whether the form link is valid or not
      if (pathname === "/Form_details" && is_mob === "true") {
        Validate_form_link(FormID_portal, id, form_token).then(data => {
          sessionStorage.setItem("is_form_link_vliad", "yes")
          requestUser();
        }).catch(err => {
          // when relad happens we dont want to validate link again
          if (sessionStorage.getItem("is_form_link_vliad") === "yes") {
            requestUser();

          } else {
            dispatch(props.fulfillUser(undefined));
            setShowSplashScreen(false);
          }

        })
      } else {
        requestUser();

      }
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);

      if (pathname === "/Meeting") {
        let meeting_url = window.location.href
        sessionStorage.setItem("meeting_url", meeting_url)

      }
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}
export default connect(null, auth.actions)(AuthInit);

