import React, { Suspense, lazy, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_common/layout";
import { Help_comm } from "./pages/Help_comm";
import { My_documents } from "./pages/My_documents";
import { MyForm } from "./pages/MyForm";
import { Myform_single_record } from "./pages/Myform_single_record";
import { TimerForTwilio } from "./pages/TimerForTwilio";
import { My_stipends } from "./pages/My_stipends";
import { Notifiction } from "./pages/Notification";
import { Study_calendar } from "./pages/Sudy_calendar";
import { PreNotification } from "./pages/PreNotification";
import Email_update from "./modules/Auth/pages/Email_update"
import { Site_info } from "./pages/Site_info";
import { Details_page } from "./pages/Details_page";
import { VideoChat } from "./pages/VideoChat";
// import { Videochat_ } from "./pages/Twilio/Videochat_";
import { Global_search } from "./pages/Global_search"
import { DashboardPage } from "./pages/DashboardPage";
import { EsourceMailLink } from "./pages/EsourceMailLink"


const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {

  // eslint-disable-next-line no-unused-vars
  const [Isfirst_visit, setIsfirst_visit] = useState(false)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          Isfirst_visit ?
            <Redirect exact from="/" to="/PreNotification" />
            : <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/prenotification" component={PreNotification} />
        <ContentRoute path="/Help_comm" component={Help_comm} />
        <ContentRoute path="/Site_info" component={Site_info} />
        <ContentRoute path="/My_documents" component={My_documents} />
        <ContentRoute path="/MyForm" component={MyForm} />
        {/* <ContentRoute path="/testcode" component={TimerForTwilio} /> */}
        <ContentRoute exact path="/Form_details" component={Myform_single_record} />
        <ContentRoute path="/My_stipends" component={My_stipends} />
        <ContentRoute path="/Notifiction" component={Notifiction} />
        <ContentRoute path="/Study_calendar" component={Study_calendar} />
        <ContentRoute path="/reset-email" component={Email_update} />
        <ContentRoute path="/More_details" component={Details_page} />
        <ContentRoute exact path="/Meeting" component={VideoChat} />
        <ContentRoute exact path="/Esource" component={EsourceMailLink} />

        {/* <ContentRoute path="/Meeting_" component={Videochat_} /> */}
        <Route path="/user-profile" component={UserProfilepage} />
        <Route exact path="/Global_search" component={Global_search} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
