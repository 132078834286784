/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_common/layout";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const PreNotification = (props) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Reminders");
    const [open, setopen] = useState(false)
    const [open2, setopen2] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setopen(true);
        }, 3299);


    }, [])

    const redirect_to = () => {
        props.history.push("/dashboard")
    }

    const handleClose = () => {
        setopen(false);
        setopen2(true);


    }
    const handleClickOpens = () => {
        setopen(false);
        setopen2(true);

    }
    const handleClose2 = () => {
        setopen2(false);

    }


    return (

        <div>


            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="success-popup">
                {/* <DialogTitle id="customized-dialog-title" onClose={handleCloses}>
             Modal title
              </DialogTitle> */}
                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon">
                        <Icon className="fas fa-running" />
                    </div>
                    <h2 className="font-weight-bold m-10 success-head">Hello!</h2>
                    <p className="success-text mb-10">You have 2 visits today</p>
                    <button
                        type="submit"
                        onClick={handleClose}
                        className="btn btn-primary mr-5 save-btn"
                        disabled={false}  >
                        Ok
                    </button>

                    <button

                        onClick={redirect_to}
                        className="btn btn-primary mr-5 save-btn"
                        disabled={false}  >
                        Skip
                    </button>
                </DialogContent>

            </Dialog>

            {/* second dilog */}
            <Dialog onClose={handleClose2} aria-labelledby="customized-dialog-title" open={open2} className="success-popup">
                {/* <DialogTitle id="customized-dialog-title" onClose={handleCloses}>
             Modal title
              </DialogTitle> */}
                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon">
                        <Icon className="fas fa-stethoscope" />
                    </div>
                    <h2 className="font-weight-bold m-10 success-head">Hello!</h2>
                    <p className="success-text mb-10">Take your medicine</p>
                    <button
                        type="submit"
                        onClick={handleClose2}
                        className="btn btn-primary mr-5 save-btn"
                        disabled={false}  >
                        Ok
                    </button>

                    <button

                        onClick={redirect_to}
                        className="btn btn-primary mr-5 save-btn"
                        disabled={false}  >
                        Skip
                    </button>
                </DialogContent>

            </Dialog>
            <br />
            <button onClick={redirect_to}> Got it</button>

        </div>
    );
};
