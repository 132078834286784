/* eslint-disable use-isnan */
import React, { useState, useEffect, useRef } from "react"

const Participant = ({
  participant,
  type,
  id,
  classname_,
  mute_participant,
  audio_level,
}) => {

  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])
  const identity_ = participant.identity?.split(" ")
  const identity_full = participant.identity?.split("}")
  const audio_volume = audio_level / 10
  const videoRef = useRef()
  const audioRef = useRef()



  const audio_status2 = Array.from(participant.audioTracks.values())
  const video_status2 = Array.from(participant.videoTracks.values())

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => {
        return publication.track
      })
      .filter((track) => track !== null)

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    const trackSubscribed = (track) => {

      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track])
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track])
      }
    }

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track))
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track))
      }
    }

    participant.on("trackSubscribed", trackSubscribed)
    participant.on("trackUnsubscribed", trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {

        videoTrack.detach()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      audioRef.current.volume = audio_volume !== NaN ? audio_volume : 1
      return () => {
        audioTrack.detach()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTracks])

  // rough

  return (
    <div className={`participant ${classname_}`} id={id}>
      {type === 2 ? <h3>{identity_full[0]}</h3> : <h3>you</h3>}
      {true ? (
        <>
          <video
            className={`${video_status2[0]?.isTrackEnabled ? "" : "profile-default"}`}
            ref={videoRef}
            autoPlay={true}
            muted={false}
          />

          <div
            className={`${video_status2[0]?.isTrackEnabled ? "" : "default-pic-wrap"}`}
          >
            <span
              className={` default-pic ${video_status2[0]?.isTrackEnabled ? "profile-default" : ""
                }`}
            >
              {identity_[0][0]?.toUpperCase()}
              {identity_[identity_.length - 3][0]?.toUpperCase()}


            </span>
          </div>
        </>
      ) : (
        <video ref={videoRef} autoPlay={true} muted={false} />
      )}

      <audio ref={audioRef} autoPlay={true} muted={false} />

      {type === 2 ? (
        <div className="option-button-wrap">

          <button className="option-button mute">
            <img
              src={`${!audio_status2[0]?.isTrackEnabled
                ? "/media/misc/mute-voice.svg"
                : "/media/misc/mice.svg"
                }`}
              style={{ width: 18 }}
              className="Mute-icon"
              alt=""
            />
          </button>
          <button className="option-button video-off">
            <img
              src={`${!video_status2[0]?.isTrackEnabled
                ? "/media/misc/off-video.svg"
                : "/media/misc/on-video.svg"
                }`}
              style={{ width: 15 }}
              alt=""
            />
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Participant
