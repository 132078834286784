import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, } from "react-redux"


export const TimerForTwilio = () => {
    const [time, setTime] = useState(0);
    const timerForCall = useSelector((state) => state.auth.time, shallowEqual)
    let interval;
    useEffect(() => {
        // for admin
        if (timerForCall) {
            timerStartMethod()
        }
        // for user
        if (sessionStorage.getItem("userJoinedCall")) {
            sessionStorage.removeItem("userJoinedCall")
            timerStartMethod()
        }
        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerForCall])


    // to give  value to timer and to start the timer
    const timerStartMethod = () => {
        if (sessionStorage.getItem("callStartDate")) {
            let callStartDate = new Date(sessionStorage.getItem("callStartDate")).getTime()
            let currentDate = new Date().getTime()
            setTime(currentDate - callStartDate)
            interval = setInterval(() => {
                setTime((time) => time + 10);
            }, 10);
            sessionStorage.setItem("intervalValue", interval)
        }
        else {
            let callStartTimeForAdmin
            if (timerForCall) {
                callStartTimeForAdmin = new Date(timerForCall)
            }
            sessionStorage.setItem("callStartDate", callStartTimeForAdmin)
            timerStartMethod()

        }
    }

    // when window is in focus or loses focus  // to fix timer pause issue
    useEffect(() => {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === "visible") {
                timerStartMethod()
            } else {
                setTime(0)
                clearInterval(sessionStorage.getItem("intervalValue"))
            }
        });
        return () => {
            document.removeEventListener('visibilitychange', () => {
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="timer text-white">
                {Math.floor((time / 3600000)) > 0 ? <span className="digits mili-sec">
                    {("0" + Math.floor((time / 3600000))).slice(-2)}:
                </span> : <span></span>}
                <span className="digits">
                    {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
                </span>
                <span className="digits">
                    {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
                </span>
            </div>
        </>
    )
}
export default React.memo(TimerForTwilio);
