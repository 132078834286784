/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Get_ctms_formlist, Dashboard_invite_api } from "../modules/Auth/_redux/authCrud"
import { shallowEqual, useSelector, useDispatch } from "react-redux"
import * as auth from "../../app/modules/Auth/_redux/authRedux"
import { Date_convert } from "../modules/Auth/_redux/Common_code"
import { useHistory } from "react-router-dom"
import { Spinner } from "react-bootstrap";


export const MyForm = (props) => {
    let history = useHistory()

    const Global_notification = useSelector(
        (state) => state.auth.Global_notification,
        shallowEqual
    )
    const dispatch = useDispatch()
    const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
    const [formlist, setformlist] = useState([

    ])
    const notifications_ = useSelector((state) => state.auth.notifications, shallowEqual)
    const [loader_status, setloader_status] = useState(true);
    let portal_id = localStorage.getItem("id")

    // to fix refresh issue
    useEffect(() => {
        if (!studies_) {
            apicall()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])
    // calling invite api  to resolve refresh issue
    const apicall = () => {
        Dashboard_invite_api()
            .then((value) => {
                dispatch(auth.actions.setstudies_action(value.data[0].studies))
            })
            .catch((error) => { })
    }
    // to get form list when page load
    useEffect(() => {
        setloader_status(true)

        if (Global_notification && studies_?.length > 0) {
            Get_ctms_formlist_method()
        }
        else{
            setloader_status(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Global_notification])

    const Get_ctms_formlist_method = () => {
        Get_ctms_formlist().then(data => { setformlist(data.data.eSourceForms) }).catch(err => { }).finally(() => setloader_status(false))

    }


    const Formlist_clicked = (FormID, FormStatus, ID, CreatedAt) => {

        const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))


        let CTMSHostLink_ = dropdown_data.sites.CTMSHostLink.slice(8)

        let SiteID_local_ = localStorage.getItem("SiteID_local")

        let PatientCTMSID_ = localStorage.getItem("PatientCTMSID")



        history.push("/Form_details?CTMSHostLink_=" + CTMSHostLink_ + "&FormID_=" + FormID + "&is_mob=" + false + "&PatientCTMSID_=" + PatientCTMSID_ + "&SiteID_local_=" + SiteID_local_ + "&FormStatus_=" + FormStatus + "&ID_=" + ID + "&CreatedAt_=" + CreatedAt + "&id=" + portal_id)

    }
    return (
        <main className="my-form-wrapper">

            <div className="top-form-wrap">
                <div className="topheader">
                    <h3 className='heading'>Forms</h3>
                </div>
                <div className="left-tab">
                    <div variant="pills" className="flex-column ">

                        {formlist.map(data => {
                            let is_form_completed = false
                            data.patientesourceformsdetails.map((detail) => {
                                if (detail.CompletedStatus == true && detail.PatientID == portal_id) { is_form_completed = true }
                            })

                            let assigned_date = null
                            data.patientesourceformsdetails.map((detail) => {
                                if (detail.PatientID == portal_id) { assigned_date = detail.createdAt }
                            })

                            return [
                                <div className={`form-row ${is_form_completed ? "disable-field" : ""}`} key={data.id} onClick={() => { Formlist_clicked(data.FormID, data.Status, data.id, assigned_date) }}>
                                    <img src="/media/misc/NO-form.svg" className="form-icon" alt="unable to load" />
                                    <div className="width">
                                        <h4>{data.FormTitle}</h4>
                                        <div className="user-details">
                                            <p className="user-name">Added by {data?.CreatedBy ? data?.CreatedBy : "NA"} </p>
                                            
                                            <p className="time">{assigned_date ? Date_convert(assigned_date, 8) : "-"}</p>

                                            <p className="status">Status <span style={{ color: "#ff5858" }}>{is_form_completed ? "Completed" : "Due"}</span></p>

                                        </div>
                                    </div>
                                </div>
                            ]
                        })}
                        {formlist.length === 0 && !loader_status && <div className='no-form'>
                            <img src="/media/misc/NO-form.svg" className="form-icon" alt="unable to load" />
                            <p className="mt-2">No form available</p>
                        </div>}
                        {loader_status && (
                            <div className="text-center">
                                <Spinner
                                    animation="border"
                                    className="spinner-info"
                                    variant="primary"
                                />
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </main >
    );
};
