import React, { useState, useEffect } from "react"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const Yes_noType = (props) => {
    const children_elements = props.children_elements
    const form_value = props.form_value
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    let commonId = props.rowid ? props.rowid : form_value[children_elements?.Parent]?.[0]
    let checkClearButton = commonId ? form_value[children_elements.UUID]?.[commonId] : form_value[children_elements.UUID] 
    
    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(children_elements, form_value, commonId);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    
    return (
        !isFieldHidden ? <>
            <div className="input_label"><label className="label_">{children_elements.Meta.Label?.blocks?.[0].text ? children_elements.Meta.Label?.blocks?.[0].text : children_elements.Meta.Label}</label>{children_elements.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}</div>
            <div className="input_wrap yes_no_toggle">
                <div className="yes_btn">
                    <button type="button" className={` ${form_value[children_elements.UUID] === true || form_value[children_elements.UUID]?.[commonId] === true ? "active_btn_color" : "Questionbtn"}`} id={children_elements.UUID + 1} onClick={() => props.YesNo_clicked(children_elements.UUID, true, children_elements?.Parent, props.rowid)}> Yes</button>
                </div>
                <div className="no_btn">
                    <button type="button" className={` ${form_value[children_elements.UUID] === false || form_value[children_elements.UUID]?.[commonId] === false ? "active_btn_color" : "Questionbtn"}`} id={children_elements.UUID + 2} onClick={() => props.YesNo_clicked(children_elements.UUID, false, children_elements?.Parent, props.rowid)}> No</button>
                </div>
                <div className="na_btn">
                    <button type="button" className={` ${form_value[children_elements.UUID] === "n/a" || form_value[children_elements.UUID]?.[commonId] === "n/a" ? 'active_btn_color' : "Questionbtn"} `} id={children_elements.UUID + 3} onClick={() => props.YesNo_clicked(children_elements.UUID, "n/a", children_elements?.Parent, props.rowid)}> NA</button>
                </div>
                <div>
                    {(checkClearButton !== undefined) && (checkClearButton === true || checkClearButton === false || checkClearButton === "n/a" || checkClearButton !== "") && <div className="clear-text">
                        <span title="Clear" onClick={() => { props.Clear_data(children_elements.UUID, children_elements?.Parent, null, props.rowid) }} >Clear</span>
                      
                        </div>}
                </div>

            </div>
        </> : ""
    )
}













