/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import objectPath from "object-path"
import { useHtmlClassService } from "../../../../_core/AppLayout"
import * as auth from "../../../../../../app/modules/Auth"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"
import { Spinner } from "react-bootstrap"
import {
  get_ctms_token,
  Quick_search,
  Get_stipend_quicksearch,
  Get_Push_Notification, Get_single_event
} from "../../../../../../app/modules/Auth/_redux/authCrud"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { useHistory } from "react-router-dom"
import { Date_convert_without_time_zone, } from "../../../../../../app/modules/Auth/_redux/Common_code"
import { messaging, onMessage } from '../../../../../_helpers/firebase';
import { CommonPopup } from "../../../../../../app/pages/Common_popup"




export function SearchDropdown() {

  localStorage.setItem("rendercount", '1')
  const [drpdown_value, setdrpdown_value] = useState("")
  const [drpdown_value2, setdrpdown_value2] = useState(1)
  const [open, setOpen] = React.useState(false)
  const [open_admin_join_popup, setopen_admin_join_popup] = React.useState(false)
  const [popupstatus, setpopupstatus] = React.useState(false)

  const [quick_search_loading, setquick_search_loading] = React.useState(false)
  // const [study_api_data, setstudy_api_data] = React.useState([])
  const [push_notification_data, setpush_notification_data] = React.useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const dispatch = useDispatch()
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const colour_them = useSelector((state) => state.auth.colour_them, shallowEqual)

  // to clear console
  useEffect(() => {
    setInterval(() => {
      console.clear()
    }, 1000);
  }, []);

  // for  theme colour
  useEffect(() => {
    const sidemenu_colur = colour_them?.Branding_Color;
    const link_colour = colour_them?.HighLight_Color;
    const footer_colur = colour_them?.Accent_Color;

    document.documentElement.style.setProperty('--col1-color', sidemenu_colur ? sidemenu_colur : '#0C2339');
    document.documentElement.style.setProperty('--col2-color', link_colour ? link_colour : '#F68723');
    document.documentElement.style.setProperty('--col3-color', footer_colur ? footer_colur : '#21B3C4');


  }, [colour_them]);
  // const logo = useSelector((state) => state.auth.logo, shallowEqual)
  const CTMS_study_ID = useSelector((state) => state.auth.ctms_studyID, shallowEqual)
  const notifications_ = useSelector((state) => state.auth.notifications, shallowEqual)
  const stipends = useSelector((state) => state.auth.stipend, shallowEqual)
  const siteinfo = useSelector((state) => state.auth.siteinfo, shallowEqual)
  const Global_notification = useSelector((state) => state.auth.Global_notification, shallowEqual)
  localStorage.setItem("Global_notification", JSON.stringify(Global_notification))


  const uiService = useHtmlClassService()
  let history = useHistory()

  useEffect(() => {
    setSearchTerm("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    searchvalue = ""
  }, [notifications_, stipends, siteinfo])
  // redux call to get studies
  useEffect(() => {
    if (studies_) {

      // if admin is from single site  we are showing drodown with single site   //  when admin is accesing user account
      if (sessionStorage.getItem("is_admin_control") === "true") {

        const study_id_for_filter = JSON.parse(sessionStorage.getItem("study_id_for_pp"))

        const specificItem = studies_.find((item) => item.id === study_id_for_filter)

        const hostlink_for_filter = specificItem?.sites?.CTMSHostLink

        const filtered_data_for_dropdown = studies_.filter((item) => item?.sites?.CTMSHostLink === hostlink_for_filter)

        setdropdown_data(filtered_data_for_dropdown)
        localStorage.setItem("dropdowndata", filtered_data_for_dropdown)


      }
      else {

        setdropdown_data(studies_)
        localStorage.setItem("dropdowndata", JSON.stringify(studies_))

      }


    }
  }, [studies_])

  const [dropdown_data, setdropdown_data] = useState([])
  const [dropdown_data_temp, setdropdown_data_temp] = useState([
    { id: 1, value: "No studies assigned" },
  ])

  const [dropdown_Selected_data, setdropdown_Selected_data] = useState([])

  //to get session id
  const Get_sessionId = async (searchvalue, eventdata) => {
    await get_ctms_token()
      .then((value) => {
        Getstipend_search_data(searchvalue, eventdata, value.data)
      })
      .catch((error) => {
      })
  }

  // to get default value of dropdown
  useEffect(() => {
    // if admin is present this block will execute
    if (sessionStorage.getItem("is_admin_control") === "true") {
      Set_admin_dropdown(sessionStorage.getItem("study_id_for_pp"))
    }
    else {
      setTimeout(() => {

        setdropdown_Selected_data(dropdown_data[0])
        if (dropdown_data.length > 0) {
          setdrpdown_value(dropdown_data[0].id)
          localStorage.setItem("id_for_dropdown", dropdown_data[0].id)
          sessionStorage.setItem("id_for_dropdown", dropdown_data[0].id)


        }
      }, 200)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown_data])

  // we are setting dropdown value if admin is there
  const Set_admin_dropdown = (value, type) => {
    if (dropdown_data?.length > 0) {
      const num = Number(value)
      let specificItem;
      if (type === 2) {
        specificItem = dropdown_data.find((item) => item.CTMSStudyID === num)
      } else {
        specificItem = dropdown_data.find((item) => item.id === num)
      }
      localStorage.setItem("site_id", specificItem?.sites?.id)
      dispatch(auth.actions.requestUser())
      type == 2 ? setdrpdown_value(specificItem?.id) : setdrpdown_value(value)
      setdropdown_Selected_data(specificItem)
    }

  }



  // setting value of dropdown in local storage for api calls
  useEffect(() => {
    if (dropdown_Selected_data?.id) {
      localStorage.setItem("CTMSSiteTitle", dropdown_Selected_data.sites?.CTMSSiteTitle)
      localStorage.setItem("CTMSStudyID", dropdown_Selected_data.CTMSStudyID)
      localStorage.setItem("SiteID_local", dropdown_Selected_data.sites?.id)
      localStorage.setItem("StudyID_local", dropdown_Selected_data.id)


      localStorage.setItem("CTMSStudySponsor", dropdown_Selected_data.CTMSStudySponsor)
      localStorage.setItem("CTMSStudyProtocol", dropdown_Selected_data.CTMSStudyProtocol)
      localStorage.setItem("CTMSIndication", dropdown_Selected_data.CTMSIndication)
      localStorage.setItem("EnrollmentStatus", dropdown_Selected_data.patientstudies[0].EnrollmentStatus)

      localStorage.setItem(
        "CTMSDisplayedOnPatientPortal",
        dropdown_Selected_data.CTMSDisplayedOnPatientPortal
      )
      localStorage.setItem("CTMSHostLink", dropdown_Selected_data.sites?.CTMSHostLink)
      localStorage.setItem("CTMSPassword", dropdown_Selected_data.sites?.CTMSPassword)
      localStorage.setItem("CTMSUsername", dropdown_Selected_data.sites?.CTMSUsername)
      localStorage.setItem("site_id", dropdown_Selected_data.sites?.id)
      localStorage.setItem("site_obj", JSON.stringify(dropdown_Selected_data))
      let colourcode = {
        Accent_Color: dropdown_Selected_data.sites?.Accent_Color,
        Branding_Color: dropdown_Selected_data.sites?.Branding_Color,
        HighLight_Color: dropdown_Selected_data.sites?.HighLight_Color
      }
      dispatch(auth.actions.settheme(colourcode))
      dispatch(auth.actions.setlogo_action(dropdown_Selected_data.sites.SiteLogoURL))
      dispatch(auth.actions.requestUser())
      dispatch(auth.actions.requestGlobal_notification())
      const pathname = window.location.pathname
      // when meeting url is share and user is not login then we 
      // are storing that url and open in window when user login
      let meeting_url = sessionStorage.getItem("meeting_url")
      if (meeting_url) {
        window.open(meeting_url, "_self")
        sessionStorage.removeItem("meeting_url")
      }



      if (pathname === "/user-profile/personal-information-readonly") {
        dispatch(auth.actions.requestNotification())
      }
      if (pathname === "/My_documents") {
        dispatch(auth.actions.requesShareddDoc())
        dispatch(auth.actions.requesCTMSdDoc())
        get_ctms_token()
          .then((value) => {
            if (value.data.length > 0) {
              dispatch(auth.actions.requesCTMSdDoc(value.data))
            }
          })
          .catch((error) => {
          })
      }

      if (pathname === "/Notifiction" || pathname === "/dashboard" || pathname === "/MyForm") {
        dispatch(auth.actions.requestNotification())
      }
      if (pathname === "/dashboard") {
        dispatch(auth.actions.requestUpcomingEvent())
      }
      if (pathname === "/Study_calendar") {
        dispatch(auth.actions.request_Calandar_data())
      }
      if (pathname === "/Global_search") {
        history.push("/dashboard")
      }
      if (pathname === "/More_details") {
        history.push("/Study_calendar")
      }
      if (pathname === "/My_stipends") {
        get_ctms_token()
          .then((value) => {
            // Get_study(value.data?.SessionKey)
            dispatch(auth.actions.request_mystipned(value.data))
          })
          .catch((error) => {
          })
      }
      if (pathname === "/Site_info") {
        get_ctms_token()
          .then((value) => {
            const dropdown_data = JSON.parse(localStorage.getItem("site_obj"))
            const combinedata = {
              key: value.data,
              dropdown_data: dropdown_data,
            }
            dispatch(auth.actions.request_siteInfo(combinedata))
          })
          .catch((error) => {
          })
      }
      // this will redirect  to forms list  as on study change  form should also change (url parameter)
      if (pathname === "/Form_details") {
        if (sessionStorage.getItem("mobile_form_is_open") !== "yes" && sessionStorage.getItem("esourcemailRequest") !== "true"
        ) {
          history.push("/MyForm")
        }
        else {
          // if esource is open from mail link we are changing dropdown
          if (sessionStorage.getItem("esourcemailRequest") === "true") {
            const queryParams = new URLSearchParams(window.location.search)
            const ctmsStudyId = queryParams.get("CTMSStudyID")
            if (ctmsStudyId) {
              Set_admin_dropdown(ctmsStudyId, 2)
            }
          }
        }

      }
    }
    setCtmsKey()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown_Selected_data])

  const setCtmsKey = () => {
    get_ctms_token()
      .then((value) => {
        sessionStorage.setItem("sessionKeyValue", value.data)
      })
      .catch((error) => {
      })
  }



  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.search.layout") === "offcanvas",
      headerLogo: uiService.getLogo(),
    }
  }, [uiService])

  const handleChange = (event) => {
    const num = Number(event)
    const specificItem = dropdown_data.find((item) => item.id === num)

    localStorage.setItem("site_id", specificItem?.sites?.id)
    dispatch(auth.actions.requestUser())
    setdrpdown_value(event)
    localStorage.setItem("id_for_dropdown", event)
    sessionStorage.setItem("id_for_dropdown", event)
    setdropdown_Selected_data(specificItem)
  }

  useEffect(() => {
    if (CTMS_study_ID) {
      const num = CTMS_study_ID

      const specificItem = dropdown_data.find((item) => item.id === num)
      if (specificItem) {
        localStorage.setItem("site_id", specificItem.sites.id)
        dispatch(auth.actions.requestUser())
        setdrpdown_value(specificItem.id)
        setdropdown_Selected_data(specificItem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CTMS_study_ID])

  // popup methods
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)


  const handleClose = () => {
    // setstudy_api_data([])
    setOpen(false)

  }

  // popup methods end
  let searchvalue
  const QuicksearchOp = (e) => {
    searchvalue = e
    setSearchTerm(e)
  }

  const Gofor_search_page = (e) => {
    e.preventDefault()
    const pathname = window.location.pathname
    if (pathname !== "/Global_search" && searchTerm.length < 1) {
      return
    }
    setquick_search_loading(true)
    if (searchTerm.trim().length > 0) {
      Quick_search(searchTerm)
        .then((data) => {
          Get_sessionId(searchTerm, data.data)
        })
        .catch((err) => {
          setquick_search_loading(false)
        })
    } else {
      setTimeout(() => {

        history.push({
          pathname: "/Global_search",
          state: { data: [], name: "", stipend: [] },
        })
      }, 10)

      setquick_search_loading(false)
    }
  }

  // to reset search

  const resetsearch = () => {
    setSearchTerm("")
    let pathname = window.location.pathname
    searchvalue = ""
    if (pathname === "/Global_search") {

      history.push({
        pathname: "/Global_search",
        state: { data: [], name: "", stipend: [] },
      })
    }
  }

  // to get stipend api
  const Getstipend_search_data = (searchvalue, eventdata, SessionKey) => {
    Get_stipend_quicksearch(SessionKey, searchvalue)
      .then((data_) => {
        setquick_search_loading(false)

        history.push({
          pathname: "/Global_search",
          state: { data: eventdata, name: searchvalue, stipend: data_.data },
        })
      })
      .catch((data) => {
        setquick_search_loading(false)
      })
  }
  // clearing console  and calling push notification
  useEffect(() => {

    const pathname_ = window.location.pathname


    if (studies_?.length > 0) {
      if (localStorage.getItem('rendercount') === '1' && pathname_ !== "/auth/login" && pathname_ !== "/auth/registration") {
        call_push_api()
      }
    }
    return () => {
      clearTimeout(time_interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies_])

  // redirect to meeting
  const Joincall = (title, id) => {
    history.push({
      pathname: "/Meeting",
      state: { title: title + id, only_title: title },
    })
  }
  // redirect to calendar page
  const open_moredetail = (data) => {
    history.push({
      pathname: "/More_details",
      state: data,
    })
  }

  // to show popup after every 5 min
  let time_interval;
  const call_push_api = () => {
    localStorage.setItem('rendercount', '2')
    handleClose()
    time_interval = setInterval(() => {
      Get_Push_Notification().then(data => {
        setpush_notification_data(data.data[0].EventNotifications)
        if (data.data[0].EventNotifications.length > 0 && !open) {
          setOpen(true)
        }
        if (data.data[0].EventNotifications.length === 0) {
          setOpen(false)
        }
      }).catch(err => { })

    }, 180000);
  }

  useEffect(() => {

    if (sessionStorage.getItem("is_admin_control") === "true") {
      const tidyUp = (event) => {
        if (event.persisted) {
          return
        }
        localStorage.removeItem("site_id");
        localStorage.removeItem("id");

      }

      return () => {

      }
    }
  }, [])

  useEffect(() => {
  }, [dropdown_Selected_data])

  // for firebase notification 
  onMessage(messaging, (payload) => {
    dispatch(auth.actions.requestGlobal_notification())
    const pathname_ = window.location.pathname

    // to chnage dropdown enrollent status we will call that api only if this block execute
    if (payload.notification.title === "Enrollment Status Update") {
      sessionStorage.setItem("Enrollment_status", "true")

    }
    // when ctms user join call we want to show popup
    if (payload.notification.title === "Calendar Event") {
      sessionStorage.setItem("twilio_ctms_user_status", "true")

    }
    // when user changes email addres we are forcefully making that user logout
    if (payload.notification.title === "Logout") {
      dispatch(auth.actions.logout())

    }
    if (pathname_ === "/Notifiction" || pathname_ === "/dashboard" || pathname_ === "/MyForm") {
      dispatch(auth.actions.requestNotification())
    }

    if (Notification.permission === 'granted') {
      let notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
        // image:""
        // icon:""
      })
    }
  })




  // when window loses focous and gets in focous



  const Change_Dropdown_value = useCallback(() => {
    setdrpdown_value(localStorage.getItem("id_for_dropdown"))
    let temp_dropdowndata = JSON.parse(localStorage.getItem("dropdowndata"))
    const num = Number(localStorage.getItem("id_for_dropdown"))
    const specificItem = temp_dropdowndata?.find((item) => item.id === num)
    setdropdown_Selected_data(specificItem)


  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === "visible") {

        // to set dropdownvalue if multiple tabs are open and then changes dropdown
        if (localStorage.getItem("id_for_dropdown") !== sessionStorage.getItem("id_for_dropdown")) {
          // avoiding for admin view
          if (sessionStorage.getItem("is_admin_control") !== "true") {
            Change_Dropdown_value()
          }
        }
        const pathname_ = window.location.pathname

        if (pathname_ !== "/auth/login") {
          dispatch(auth.actions.requestGlobal_notification())
        }
        if ((pathname_ === "/Notifiction" || pathname_ === "/dashboard" || pathname_ === "/MyForm") && pathname_ !== "/auth/login") {
          dispatch(auth.actions.requestNotification())
        }
        // when email chamges and background push notification arrives we are storing that

        if (sessionStorage.getItem("email_changed") === "yes") {
          dispatch(auth.actions.logout())
          sessionStorage.removeItem("email_changed")
        }

      } else {
        // we will compare this arrray  before and after  for push notification
        const pp = localStorage.getItem("Global_notification")
        localStorage.setItem("Global_notification2", pp)

      }

    });

    return () => {

      document.removeEventListener('visibilitychange', () => {
      });

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  // when push notification arrives we are calling this 
  useEffect(() => {

    const pathname_ = window.location.pathname
    if (Global_notification) {
      // when app is in background and gets in focus we are calling api to change enrolletment status
      let pp = localStorage.getItem("Global_notification2")
      if (pp?.length > 10) {
        // comparing two notification before and after if count is differnt
        if (JSON.parse(pp).length !== Global_notification?.length) {

          if (Global_notification[0]?.App_Route === "DetailScreen" && Global_notification[0]?.Description === "Your Doctor / Study Coordinator has joined virtual visit for ") {
            sessionStorage.setItem("twilio_ctms_user_status", "true")
            localStorage.removeItem("Global_notification2")

          }
        }

      }
      localStorage.removeItem("Global_notification2")

      if (sessionStorage.getItem("twilio_ctms_user_status") === "true" && Global_notification[0]?.Description === "Your Doctor / Study Coordinator has joined virtual visit for ") {
        sessionStorage.removeItem("twilio_ctms_user_status")

        const notification_arrived_date = new Date(Global_notification[0]?.createdAt)
        // cheking when notification arrived if it is with in 4 min we are showing popup
        if (new Date().getTime() - notification_arrived_date.getTime() < 250000 && pathname_ !== "/Meeting") {
          setopen_admin_join_popup(true)
        } else {
        }
      }
    }

  }, [Global_notification]);

  //  popup start

  const handleCloses = () => {
    setopen_admin_join_popup(false)
  }
  // when click on join button on popup
  const Join_twilio_call = async () => {

    await get_ctms_token(Global_notification[0].SiteID)
      .then((value) => {
        localStorage.setItem("join_status_id", Global_notification[0]?.DocID)
        Get_single_event(Global_notification[0]?.DocID, value.data, Global_notification[0].CTMSHostLink).then(result => { Joincall_part2(result.data) }).catch(err => { })

      })
      .catch((error) => {
      })



  }

  const Joincall_part2 = (data) => {
    if (data[0].IsInitiates === true) {
      localStorage.setItem("CTMSEventID", Global_notification[0]?.CTMSEventID)
      localStorage.setItem("join_status_id", Global_notification[0]?.DocID)
      sessionStorage.setItem("RoomSID", data[0].RoomSID)

      // to change dropdown value
      // dispatch(auth.actions.setCTMS_studyID_action(Global_notification[0]?.StudyID))
      Set_admin_dropdown(Global_notification[0]?.StudyID)
      const num = Number(Global_notification[0]?.StudyID)
      const specificItem = dropdown_data.find((item) => item.id === num)
      let StudyNameForEvents = specificItem.CTMSDescription === "" ? ' (' + specificItem.CTMSStudySponsor + ', ' + specificItem.CTMSStudyProtocol + ")" : specificItem.CTMSDescription + ' (' + specificItem.CTMSStudySponsor + ', ' + specificItem.CTMSStudyProtocol + ")";
      let titleIs = Global_notification[0].EventVisitName + ' - ' + StudyNameForEvents
      setopen_admin_join_popup(false)
      history.push({
        pathname: "/Meeting",
        state: { title: Global_notification[0]?.Title + Global_notification[0]?.CTMSEventID, only_title: Global_notification[0]?.eventName },
      })
    }
    else {
      setpopupstatus(true)
      setopen_admin_join_popup(false)

    }

  }
  const close_popup = () => {
    setpopupstatus(false)
  }


  // redirect to meeting
  const join_event = (data) => {
    localStorage.setItem("join_status_id", data?.id)
    Get_single_event(data?.id).then(result => { join_event_part2(result.data) }).catch(err => { })
  }

  const join_event_part2 = (data) => {
    if (data[0].IsInitiates === true) {
      localStorage.setItem("CTMSEventID", data[0]?.CTMSEventID)
      localStorage.setItem("join_status_id", data[0]?.id)
      sessionStorage.setItem("RoomSID", data[0].RoomSID)

      // to change dropdown value
      // dispatch(auth.actions.setCTMS_studyID_action(Global_notification[0]?.StudyID))
      Set_admin_dropdown(data[0]?.StudyID)
      const num = Number(data[0]?.StudyID)
      // const specificItem = dropdown_data.find((item) => item.id === num)
      // let StudyNameForEvents = specificItem.CTMSDescription === "" ? ' (' + specificItem.CTMSStudySponsor + ', ' + specificItem.CTMSStudyProtocol + ")" : specificItem.CTMSDescription + ' (' + specificItem.CTMSStudySponsor + ', ' + specificItem.CTMSStudyProtocol + ")";
      // let titleIs = data[0].EventVisitName + ' - ' + StudyNameForEvents

      let site_object = JSON.parse(localStorage.getItem("site_obj"))
      let typeIs = data[0]?.Type ? " - Visit Type (" + data[0]?.Type + ")" : ""
      let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";
      let visitTitle = (data[0]?.VisitName === "" ? (data[0]?.Title + ' - ') : (data[0]?.VisitName + ' - ')) + StudyNameForEvents + typeIs


      setopen_admin_join_popup(false)
      history.push({
        pathname: "/Meeting",
        state: { title: data[0]?.Title + data[0]?.CTMSEventID, only_title: visitTitle },
      })
    }
    else {
      setpopupstatus(true)
      setopen_admin_join_popup(false)

    }

  }

  return (
    <>
      {/* to show common popup */}
      <CommonPopup popupstatus={popupstatus} close_popup={close_popup} />

      {/* dialog start */}
      <Dialog
        onClose={(event, reason) => {
          if (true) {
            handleClose()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="event-reminder"
      >


        <DialogContent className="middle-content Event-reminder-wrap">
          <div className=" top-header">
            <div className="top-header-contet">
              <div className="d-flex align-items-center">
                <img
                  src="/media/svg/icons/Code/bell.svg"
                  className="reminder-icon mr-2 "
                  alt=""
                />
                <h4 className="mb-0">Event Reminder</h4>
              </div>

              <img
                src="/media/misc/close.svg"
                className="details-icon "
                onClick={handleClose}
                alt=""
              />
            </div>

          </div>
          <div className="event-details-text">
            <p className="text">You have the following event to attend <span className="color-text"></span></p>
          </div>


          {push_notification_data.map((value, index) => {
            let site_object = JSON.parse(localStorage.getItem("site_obj"))

            let typeIs = value?.Type ? " - Visit Type (" + value?.Type + ")" : ""

            let StudyNameForEvents = site_object.CTMSDescription === "" ? ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")" : site_object.CTMSDescription + ' (' + site_object.CTMSStudySponsor + ', ' + site_object.CTMSStudyProtocol + ")";

            let visitTitle = (value?.VisitName === "" ? (value?.Title + ' - ') : (value?.VisitName + ' - ')) + StudyNameForEvents + typeIs

            return [
              <div className="event-details " key={index}>
                <div className='d-flex align-items-start event-content'>
                  <h5 className="event-name">{visitTitle}</h5>
                  <img
                    src="/media/misc/details-gray.svg"
                    className="details-icon "
                    onClick={() => {
                      handleClose()
                      open_moredetail(value)
                      handleClose()
                    }
                    }
                    alt=""
                  />
                </div>
                <div className="time-wrap">
                  <div className="d-flex align-items-center mr-2">
                    <div>
                      <img
                        className="clock-icon"
                        src="/media/misc/wall-clock.png"
                        alt=""
                      />
                    </div>
                    <span className="event-time">{Date_convert_without_time_zone(value?.StartDate, 1)}</span>
                  </div>
                  <button className="join-button" type="button" onClick={() => {
                    // Joincall(value?.Title, value?.CTMSEventID)
                    join_event(value)
                    handleClose()
                  }}  > Join</button>

                </div>

              </div>
            ]
          })}
        </DialogContent>
      </Dialog>

      {/* dialog end */}
      <div className="topbar-search">
        <div className="select-study-drop">
          <label>Select Study </label>
          {studies_?.length > 0 ?

            <>
              <TextField
                select
                className="select-dropdown"
                id="study_select_dropdown"
                value={drpdown_value || drpdown_value2}
                onChange={(e) => { handleChange(e.target.value) }}
                disabled={dropdown_data?.length < 2}
              >

                {dropdown_data?.length < 1
                  ? dropdown_data_temp.map((data, index) => (
                    <MenuItem value={data.id} key={index}>No studies assigned</MenuItem>
                  ))
                  : dropdown_data.map((data, index) => (
                    <MenuItem key={data.id} value={data.id} className="dropdownfor">
                      <p>
                        <b>
                          {data.sites.CTMSSiteTitle}{" "}
                          {data.CTMSDisplayedOnPatientPortal || data.CTMSIndication
                            ? " -"
                            : ""}{" "}
                          {data.CTMSDisplayedOnPatientPortal
                            ? data.CTMSDisplayedOnPatientPortal
                            : data.CTMSIndication}{" "}
                        </b>
                        ( {data.CTMSStudySponsor}, {data.CTMSStudyProtocol} )
                        <b>{data.patientstudies[0].EnrollmentStatus !== null ? " - " + data.patientstudies[0].EnrollmentStatus : ""}</b>

                      </p>
                    </MenuItem>

                  ))}
              </TextField>
              <span className="Custom-tooltip"> {`
              ${dropdown_Selected_data?.sites?.CTMSSiteTitle} ${dropdown_Selected_data?.sites?.CTMSSiteTitle !== "" ? " - " : ""
                } ${dropdown_Selected_data?.CTMSDisplayedOnPatientPortal === ""
                  ? dropdown_Selected_data?.CTMSIndication
                  : dropdown_Selected_data?.CTMSDisplayedOnPatientPortal
                } ${"("}
              ${dropdown_Selected_data?.CTMSStudySponsor}  ${","}${dropdown_Selected_data?.CTMSStudyProtocol
                } ${")"} - ${dropdown_Selected_data?.patientstudies ? dropdown_Selected_data.patientstudies[0]?.EnrollmentStatus : ""}

            `}</span>

            </>
            :
            <>
              <TextField
                select
                className="select-dropdown"
                id="study_select_dropdown"
                value={drpdown_value || drpdown_value2}
                onChange={(e) => { handleChange(e.target.value) }}
                disabled={dropdown_data?.length < 2}

              >

                {dropdown_data?.length < 1
                  ? dropdown_data_temp.map((data, index) => (
                    <MenuItem value={data.id} key={index}>No studies assigned</MenuItem>
                  ))
                  : dropdown_data.map((data, index) => (
                    <MenuItem key={data.id} value={data.id} className="dropdownfor">
                      <p>
                        <b>
                          {data.sites.CTMSSiteTitle}{" "}
                          {data.CTMSDisplayedOnPatientPortal || data.CTMSIndication
                            ? "-"
                            : ""}{" "}
                          {data.CTMSDisplayedOnPatientPortal
                            ? data.CTMSDisplayedOnPatientPortal
                            : data.CTMSIndication}{" "}
                        </b>
                        ( {data.CTMSStudySponsor}, {data.CTMSStudyProtocol})
                        <b>{data.patientstudies[0].EnrollmentStatus !== null ? "- " + data.patientstudies[0].EnrollmentStatus : ""}</b>

                      </p>
                    </MenuItem>

                  ))}
              </TextField>
              <span className="Custom-tooltip">  No studies assigned</span>
            </>
          }

        </div>

        {!layoutProps.offcanvas && (
          <Dropdown
            alignRight
            drop="down"
            onToggle={() => {

            }}
            id="kt_quick_search_toggle"
          >
            <form className="height-100">
              <div className="search-wrap height-100">
                <div className="tooltip-show">
                  <input
                    className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1 search-box"
                    type="Search"
                    placeholder="Search calendar events, stipends, study consents and visit forms..."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => QuicksearchOp(e.target.value)}
                    maxLength={51}

                  />
                  {!searchTerm && <span
                    className='Custom-tooltip  '

                  >  Search calendar events by name or date, consent documents by name or type, visit forms by name, visit stipends by visit name
                  </span>}
                </div>

                {quick_search_loading && (
                  <Spinner animation="border" className="spinner-info" variant="primary" />
                )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"
                    >
                      Clear
                    </Tooltip>
                  }
                >
                  <button type="button" className="close-icon-btn" onClick={resetsearch}>
                    <img src="/media/misc/clear.svg" className="close-icon" alt="" />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"
                    >
                      Search
                    </Tooltip>
                  }
                >
                  <button
                    type="submit"
                    className="search-icon-btn"
                    onClick={(e) => { Gofor_search_page(e) }}
                  >
                    <img src="/media/misc/search_icon.svg" className="search-icon" alt="" />
                  </button>
                </OverlayTrigger>
              </div>
            </form>
          </Dropdown>
        )}
      </div>

      {/* popups  admin joined call reminder */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloses()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open_admin_join_popup}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="card-toolbar">
            {/* <div className="study-name"><span className="study-label">Study Name: </span>{Global_notification ? Global_notification[0]?.StudyName : ""}</div> */}
            <div>Your Doctor / Study Coordinator has joined the virtual visit for<span className="text_style"> {Global_notification ? Global_notification[0]?.eventName : ""}</span> </div>
            <div className="card-button-wrap">
              <button className="btn btn-danger mr-6" onClick={handleCloses} >
                Cancel
              </button>
              <button
                type="submit"
                onClick={Join_twilio_call}
                className="btn btn-primary logout-btn"
              >
                Join
                <img src="/media/misc/on-video.svg" className="video-icon" alt="video" />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default React.memo(SearchDropdown)