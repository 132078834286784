/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react"
import { get_ctms_token, Get_saved_images } from "../../modules/Auth/_redux/authCrud"
import { check_field_visibility } from "../../modules/Auth/_redux/Common_code"


export const ImageManager_type = (props) => {
    const nested_data = props.children_elements
    const form_value = props.form_value
    const open_uploaded_file = props.open_uploaded_file
    let commonId = props.rowid ? props.rowid : form_value[nested_data?.Parent]?.[0]
    const [isFieldHidden, setIsFieldHidden] = useState(false)
    const [imagesArray, setImagesArray] = useState([])
    const [filesFromMeta, setFilesFromMeta]=useState([])
    const [isImagesLoading, setIsImagesLoading]= useState(false)

    // Method to convert image into base64
    const arrayBufferToBase64 = (Arraybuffer) => {
        return new Promise((resolve, reject) => {
            let binary = '';
            const bytes = new Uint8Array(Arraybuffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            const file = window.btoa(binary);
            const url = `data:image/png;base64,` + file;
            resolve(url)
        })
    }

    // Method to get saved image form CTMS using file Hash
    const getSavedImageFromCtms = async (files) => {
        let arr = []
        try {
            await get_ctms_token().then(async (value) => {
                for (let file of files) {
                    let imageData = await Get_saved_images(value?.data, file?.Hash)
                    let url = await arrayBufferToBase64(imageData.data)
                    
                    arr.push({
                        imgLink: url,
                        hash: file?.Hash,
                        mime: file?.Mime,
                        name: file?.Name
                    })
                }
            })
            setIsImagesLoading(false)
            setImagesArray([...arr])
        } catch (err) {
            setIsImagesLoading(false)
        }
    }
    
    // Storing all files from MetaData to the state variable on nested data change
    useEffect(() => {
        setFilesFromMeta(nested_data.Meta?.Files)
    }, [nested_data])

    // Calling the method to get images on filesFromMeta array changes
    useEffect(() => {
        if (filesFromMeta?.length > 0) {
            setIsImagesLoading(true)
            getSavedImageFromCtms(filesFromMeta)
        }
    }, [filesFromMeta])

    // Check element visibility when form_value changes
    useEffect(() => {
        let is_hidden = check_field_visibility(nested_data, form_value, commonId);
        setIsFieldHidden(is_hidden);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_value])
    return (
        !isFieldHidden ? <>


            <div className="input_label label-flex-width">
                <label className="label_" htmlFor="">{nested_data.Meta.Label?.blocks?.[0].text ? nested_data.Meta.Label?.blocks?.[0].text : nested_data.Meta.Label}</label> {nested_data.Meta.Required ? <p style={{ color: "red" }}>*</p> : ""}
            </div>
            <br />
            <div className="input_wrap upload-btn-wrapper">
                <div className="image-wrap_btn">
                {isImagesLoading && <span className="ml-3 spinner spinner-blue"></span>}
                    <div className="mb-0 d-flex align-items-center flex-column ml-sm-0 img-upload-wrap image-grid ">
                        {imagesArray?.length > 0 && imagesArray.map((img, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {img?.imgLink && <div className="mb-2 mr-2 mt-0 image_wrap">
                                        <img src={img?.imgLink} alt="Unable to load"
                                            onClick={() => open_uploaded_file(img?.hash, img?.mime, img?.name)}
                                            className="image-upload"
                                        />
                                    </div>}
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                <p className="note_text">{nested_data.Meta.Note}</p>
            </div>
        </> : ""
    )
}
