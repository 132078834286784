/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react"
import {
  Dash_events_Wid,
  Dash_Top_Wid,
  Dash_Recent_noti_wid,
  Dash_AllReminder_Wid,
} from "../../../_common/_partials/widgets"
import Tour from "reactour"
import { toAbsoluteUrl } from "../../../_common/_helpers"
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux"
import { Dashboard_invite_api, WebAppLoggedInFlag_toggle } from "../Auth/_redux/authCrud"
import * as auth from "../Auth/_redux/authRedux"
import { Spinner } from "react-bootstrap"
import { useHistory } from "react-router-dom"

export function DashboardPage(props) {
  const [studies, setstudies] = useState([])
  const [invites, setinvites] = useState([])
  const [isnew, setisnew] = useState(true)
  const [is_userdata_present, setis_userdata_present] = useState(true)
  const dispatch = useDispatch()
  const studies_ = useSelector((state) => state.auth.studies, shallowEqual)
  const [lodaing_status, setlodaing_status] = useState(true)
  const notifications_ = useSelector((state) => state.auth.notifications, shallowEqual)
  const Global_notification = useSelector(
    (state) => state.auth.Global_notification,
    shallowEqual
  )
  let history = useHistory()

  // when push notification arrives we are calling this 
  useEffect(() => {

    // const pathname_ = window.location.pathname
    if (Global_notification) {
      // when app is in background and gets in focus we are calling api to change enrolletment status
      let pp = localStorage.getItem("Global_notification2")
      if (pp?.length > 10) {
        // comparing two notification before and after if count is differnt
        if (JSON.parse(pp).length !== Global_notification?.length) {

          if (Global_notification[0]?.App_Route === "EnrollmentStatus") {
            sessionStorage.setItem("Enrollment_status", "true")
            localStorage.removeItem("Global_notification2")

          }

        }

      }
      localStorage.removeItem("Global_notification2")
      Dashboard_invite_api()
        .then((value) => {
          setinvites(value.data[1]?.studyInvites)
          // this code is to change enrollment status in dropdown
          if (sessionStorage.getItem("Enrollment_status") === "true") {
            setstudies(value.data[0]?.studies)
            dispatch(auth.actions.setstudies_action(value.data[0]?.studies))

            sessionStorage.removeItem("Enrollment_status")
          }

        })
        .catch((error) => { })


    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Global_notification]);
  useEffect(() => {
    if (notifications_?.length === 0 || notifications_?.length > 0) {
      if (studies_?.length > 0) {
        dispatch(auth.actions.requestUpcomingEvent())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [notifications_])

  // calling invite  study api
  useEffect(() => {
    apicall()
    Getuseer_data()
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [])
  // loader status
  useEffect(() => {
    if (studies_ && studies_?.length === 0) {
      setlodaing_status(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [studies_])

  useEffect(() => {

    if (studies_) {
      dispatch(auth.actions.requestGlobal_notification())
      dispatch(auth.actions.requestNotification())
    }
    if (!studies_) {
      apicall_forstudy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])

  // getting user data
  const Getuseer_data = () => {
    dispatch(auth.actions.requestUser())
  }

  //to check whether user has any study or not
  useEffect(() => {
    if (studies.length > 0) {
      setisnew(false)
      setlodaing_status(false)
    }
    if (invites.length > 0) {
      setisnew(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [studies, invites])
  useEffect(() => {
    if (studies?.length > 0) {
      setis_userdata_present(true)
    } else setis_userdata_present(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [studies, invites])

  //if user is already loggned in but not invited
  useEffect(() => {
    if (studies_?.length < 1 && invites.length > 0) {
      localStorage.setItem("site_id", invites[0]?.sites.id)

      //add sites.id here
      Getuseer_data()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [invites, studies_])

  // to get invites and study
  const apicall = () => {
    Dashboard_invite_api()
      .then((value) => {
        setstudies(value.data[0]?.studies)
        setinvites(value.data[1]?.studyInvites)
      })
      .catch((error) => { })
  }

  // to avoid refresh issue we are calling separte api for study

  const apicall_forstudy = () => {
    Dashboard_invite_api()
      .then((value) => {
        setstudies(value.data[0]?.studies)
        setinvites(value.data[1]?.studyInvites)

        dispatch(auth.actions.setstudies_action(value.data[0]?.studies))
      })
      .catch((error) => { })
  }

  const user = useSelector((state) => state.auth.user, shallowEqual)
  localStorage.setItem("PatientCTMSID", user.PatientCTMSID)



  //  popup start
  // eslint-disable-next-line no-unused-vars
  const [open, setopen] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const handleCloses = () => {
    setopen(false)
  }


  // eslint-disable-next-line no-unused-vars
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  //react tour start

  const oncloseReactTour = () => {
    setIsTourOpen(false)
    // change flage
    WebAppLoggedInFlag_toggle()
      .then((data) => {
        Getuseer_data()
      })
      .catch((error) => { })
  }

  const [isTourOpen, setIsTourOpen] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (user.WebAppLoggedInFlag === true) {
        // if (true) {

        setIsTourOpen(true)
      }
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])
  const steps = [
    {
      //  dashboard Tutotorial
      selector: '[data-tut="dashboard_"]',
      content: function DemoHelperComponent() {
        return (
          <div className="tutorial-div">
            <h5 className="text-center education-head">Dashboard</h5>
            <img src={toAbsoluteUrl("/media/misc/tutorial_Dashboard.svg")} alt="unable to load" />
            <p className="tutorial-para">
              The dashboard will provide a summary of calendar events and other important study notifications.
            </p>
          </div>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },

    {
      // Study Calendar Tutotorial
      selector: '[data-tut="study_cal"]',
      content: function DemoHelperComponent() {
        return (
          <div className="tutorial-div">
            <h5 className="text-center education-head">Calendar</h5>
            <img src={toAbsoluteUrl("/media/misc/tutorial_study_calendar.svg")} alt="unable to load" />
            <p className="tutorial-para">
              Your calendar keeps you on track for study success by organizing study activities, upcoming visits, and other events.
            </p>
          </div>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      // MyDocuments tutorials
      selector: '[data-tut="my_doc"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <h5 className="text-center education-head">Study Consents</h5>
              <img src={toAbsoluteUrl("/media/misc/tutorial_my_documents.svg")} alt="img" />
              <p className="tutorial-para">
                Important documents will be shared between you and the research team. This section allows you to view, download and check your document history.
              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      // form tour
      selector: '[data-tut="Myform_"]',
      content: function DemoHelperComponent() {
        return (
          <div className="tutorial-div">
            <h5 className="text-center education-head"> Visit Forms</h5>
            <img src={toAbsoluteUrl("/media/misc/tutorial_forms.svg")} alt="unable to load" />
            <p className="tutorial-para">
              Your study team may request that you complete online forms/questionnaires.  Quickly view and manage all forms in this section.
            </p>
          </div>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },

    {
      // Notifications tutorial
      selector: '[data-tut="my_notification"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <h5 className="text-center education-head">Notifications</h5>
              <img src={toAbsoluteUrl("/media/misc/tutorial_notifications.svg")} alt="unable to load" />
              <p className="tutorial-para">
                Your research team will send you important notifications while participating in a study.  Quickly view and manage all notifications from this section.              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      selector: '[data-tut="my_stip"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <h5 className="text-center education-head">Stipends</h5>
              <img src={toAbsoluteUrl("/media/misc/tutorial_my_stipends.svg")} alt="unable to load" />
              <p className="tutorial-para">
                Everyone loves to get paid! Use this section to check your stipend balance and history.
              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      selector: '[data-tut="Help_commun"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <h5 className="text-center education-head">Help & Communication</h5>
              <img src={toAbsoluteUrl("/media/misc/tutorial_help_communication.svg")} alt="unable to load" />
              <p className="tutorial-para">
                Have a question about your study participation? Contact your research team to receive the support you need .             </p>
            </div>
          </>
        )
      },
    },
    {
      selector: '[data-tut="My_top_notification"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <h5 className="text-center education-head">Notifications</h5>
              <img src={toAbsoluteUrl("/media/misc/tutorial_notifications.svg")} alt="unable to load" />
              <p className="tutorial-para">
                The notification bell helps you quickly identify incoming notifications!
              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
    {
      selector: '[data-tut="my_profile"]',
      content: function DemoHelperComponent() {
        return (
          <>
            <div className="tutorial-div">
              <img src={toAbsoluteUrl("/media/misc/tutorial_my_profile.svg")} alt="unable to load" />
              <p className="tutorial-para">
                Manage your profile and password anytime you want!
              </p>
            </div>
          </>
        )
      },
      style: {
        backgroundColor: "",
        color: "white",
      },
    },
  ]
  // when click on join button on popup
  // eslint-disable-next-line no-unused-vars
  const Join_twilio_call = () => {
    localStorage.setItem("CTMSEventID", Global_notification[0]?.CTMSEventID)
    localStorage.setItem("join_status_id", Global_notification[0]?.DocID)

    history.push({
      pathname: "/Meeting",
      state: { title: Global_notification[0]?.Title + Global_notification[0]?.CTMSEventID, only_title: Global_notification[0]?.Title },
    })
  }


  return (
    <>
      {lodaing_status ? (
        <div className="text-center">
          <Spinner animation="border" className="spinner-info" variant="primary" />
        </div>
      ) : (
        <>
          {isnew === true ? (
            <div className="dash-top">
              {" "}
              <img src={toAbsoluteUrl("/media/misc/no-data.svg")} alt="unable to load" />
              <p>No Study Assigned</p>{" "}
            </div>
          ) : null}

          <div className="row">
            <div className="col-lg-12 dashboard-main-content">
              {/* used for invite links */}
              <Dash_Top_Wid studies={studies} invites={invites} apicall={apicall_forstudy} />

              {is_userdata_present ? (
                <>
                  <div className="dashboard-content">
                    {/* used for show visits upcoming events etc */}
                    <Dash_events_Wid />
                    {/* used for notification */}
                    <Dash_Recent_noti_wid />
                  </div>
                  {/* used for Reminder */}
                  <Dash_AllReminder_Wid />
                </>
              ) : null}
            </div>
          </div>

          {/* tour popup methods */}
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => oncloseReactTour()}
            rounded={2}
            showCloseButton={true}
            showNavigation={true}
            disableDotsNavigation={false}
            // nextButton={<>Next</>}
            // prevButton={<>Back</>}
            closeWithMask={false}
            // lastStepNextButton={<div className="finish-arrow"><img src={toAbsoluteUrl("/media/misc/finish-arrow.svg")} alt="unable to load" /></div>}
            lastStepNextButton={<div className="finish-arrow">Finish</div>}
            maskClassName=""
          />

          {/* popups  admin joined call reminder */}
          {/* <Dialog
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleCloses()
              }
            }}
            aria-labelledby="customized-dialog-title"
            open={false}
            className="success-popup"
          >
            <DialogContent dividers className="text-center p-10">
              <div className="card-toolbar">
                <div className="study-name"><span className="study-label">Study Name: </span>{Global_notification ? Global_notification[0]?.StudyName : ""}</div>
                <div>Your Doctor / Study Coordinator has joined virtual visit for {Global_notification ? Global_notification[0]?.Title : ""} </div>
                 <div className="card-button-wrap">
                <button className="btn btn-danger mr-6" onClick={handleCloses} >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={Join_twilio_call}
                      className="btn btn-primary logout-btn"
                    >
                      Join
                      <img src="/media/misc/on-video.svg" class="video-icon" alt="video"/>
                </button>
             </div>
              </div>
            </DialogContent>
          </Dialog> */}
        </>
      )}
    </>
  )
}
export default connect(null, auth.actions)(DashboardPage)
